import React, {ChangeEvent, FC, useState} from "react";
import {PhotoTaker} from "../../Elements/elements";
import {setCameraAccess, showMessageError} from "../../../redux/reducers/system/actions";
import {SystemState} from "../../../redux/reducers/system/@types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import "./ChatMessages.css";
import {toBase64, userAPI} from "../../../api";
import sendMessage from "../../../images/sendMessage.svg";
import {CChatMessage, ChatDataState, EnumChatMessageType, IChatDialog} from "../../../redux/reducers/chat/@types";
import {setChatMessages} from "../../../redux/reducers/chat/chatActions";
import {UserState} from "../../../redux/reducers/user/@types";
import {Command} from "./Command";

const fileToDataUri = (file:any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        //@ts-ignore
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
})

export enum EnumWindowMode { START, CAMERA, ATTACHE, FINISH }
interface IProps {
    onClose: Function

    dialog: IChatDialog
}
export const ChatPhotoMessage: FC<IProps> = ({ onClose,  dialog  }) => {
    const dispatch = useDispatch();

    let system : SystemState = useSelector((state:RootState)=> ({system: state.systemReducer})).system;
    let user : UserState = useSelector((state:RootState)=> ({user: state.userReducer})).user;
    let chat : ChatDataState = useSelector((state:RootState)=> ({chat: state.chatReducer})).chat;


    let [localBlobFile, setLocalBlobFile] = useState<Blob|undefined>(undefined);
    let [localImage, setLocalImage] = useState<string>('');
    let [isCamera, setCamera] = useState<boolean>(false);
    let [windowMode, setWindowMode] = useState<EnumWindowMode>(EnumWindowMode.START);
    const [inputValue, setInputValue] = useState(''),
          [isSending, setIsSending] = useState(true);

    const handleChange = async (photos: Blob[]) => {

        dispatch(setCameraAccess(true));
        if (photos.length) {
            setLocalBlobFile(photos[0]);
            const data = await toBase64(photos[0]) as string;
            setLocalImage( data);
            setWindowMode(EnumWindowMode.FINISH)
        } else {
            setCamera(false);
            setWindowMode(EnumWindowMode.START)
        }
    };

    const  dataURItoBlob = async (data: any) => {
        let dataURI: any = await fileToDataUri(data);
        setLocalImage(dataURI);
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var bb = new Blob([ab]);
        return bb;
    }
    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value)
    };
    // useEffect(()=>{
    //    if (mode === EnumWindowMode.ATTACHE) {
    //        let el = document.getElementById('chat_file_input');
    //        if (el) el.click();
    //    }
    // }, [mode]);

    const onSendMessage = async (commandValue: string = '') => {
        if (!localBlobFile) return ;
        setIsSending(true);

        let resBlob = await userAPI.setChatPhoto(user.driver_id,  dialog.dialog_id, "photo", "jpg", localBlobFile)
            .catch( (err)=> showMessageError(  "Ошибка загрузки файла на сервер <br/>" + err.message ,dispatch));
        if (!resBlob) return ;
        if (resBlob.data.status != "success" || !resBlob.data.file_id)
            return showMessageError(  "Ошибка загрузки файла на сервер <br/>" + resBlob.data.error_message ,dispatch);

        let res = await userAPI.sendChatMessages(user.driver_id,  dialog.dialog_id, inputValue, dialog.group_id, resBlob.data.file_id)
             .catch( (err)=> showMessageError(  "Ошибка отправки сообщения <br/>" + err.message ,dispatch));
        if (!res) return ;
        if (res.data && res.data.status === 'success') {
               let msg = new CChatMessage(dialog.dialog_id, user.driver_id, inputValue, undefined, EnumChatMessageType.OUT, resBlob.data.file_id);
               msg.img = localImage;
               console.log('new msg', msg);
               dispatch(setChatMessages([...chat.messages, msg]));
               closeThisWindow();
        } else
            showMessageError(  "Ошибка отправки сообщения <br/>" + res.data.error_message ,dispatch)
    };

    const onSelectFile = () => {
        //setWindowMode(EnumWindowMode.ATTACHE);
        let el = document.getElementById('chat_file_input');
        if (el) el.click();
    };

    const closeThisWindow = () => {
        setWindowMode(EnumWindowMode.START);
        onClose();
    };

    return (

        <>
            {windowMode == EnumWindowMode.FINISH &&
                <div>
                    <div className="chatBackground" onClick={() => closeThisWindow()}/>
                    <div className="commonContainer chat_photo_message chat_file_message">
                        <img src={localImage} />
                        <div className="chat_footer_container">

                            <input placeholder='Напишите сообщение' value={inputValue} onChange={onChangeInput} />
                            <button className="chat_footer_container-button"  >
                                <img alt='Отправить сообщение' src={sendMessage} onClick={() => onSendMessage()} />
                            </button>
                        </div>
                    </div>
                </div>
            }
            {windowMode == EnumWindowMode.START &&

                <div className={"chat_footer_container-commands-container"}>
                    <Command key={'photo-id'} command={{id:'1',name:'Сделать фото'}} onSelectCommand={()=>setWindowMode(EnumWindowMode.CAMERA)}   />
                    <Command key={'file-id'} command={{id:'1',name:'Прикрепить файл'}} onSelectCommand={()=>onSelectFile()}   />
                     <input type="file" id="chat_file_input" style={{width:10}}
                      accept="image/*"
                      onChange={async (e) => {
                               if (e.target && e.target.files && e.target.files.length !== 0) {

                                           console.log('chat_file_input', e.target  && e.target.files && e.target.files.length ? e.target.files[0] : '');

                                        setLocalBlobFile( await dataURItoBlob(e.target.files[0]) );
                                        console.log('chat_file_input', await dataURItoBlob(e.target.files[0]));
                                          setWindowMode(EnumWindowMode.FINISH)
                                       }
                                    }}
                             />
                </div>
            }
            {windowMode == EnumWindowMode.CAMERA &&
                <div className="make_photo_chat_window">
                        <PhotoTaker
                            id="passport_photo"
                            title={<div style={{paddingTop:"20px"}}>Подтверждение</div>}
                            editing={true}
                            dontLoadFromHardDrive={true}
                            ask={!system.was_camera_accessed}
                            frames={[
                                {
                                    index: 1,
                                    title: "Сделайте фото",
                                    box: true,
                                    facing: 'environment',
                                    width: '99%',
                                    height: '42%'
                                }
                            ]}
                            pictures={ localBlobFile ? [localBlobFile] : []}
                            onChange={ async (photos: Blob[]) => { handleChange(photos); } }
                            onError={() => dispatch(setCameraAccess(true))}
                            camera={isCamera}
                            onClose={() => { closeThisWindow()}}
                        />
                    </div>
            }
        </>
        )



};

