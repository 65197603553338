import {
    ActionsTypes,
    CChatMessage,
    ChatDataState,
    EnumChatMessageType,
    IChatDialog,
    IChatGroup,
    IChatNewMessage,
    SET_CHAT_COMMANDS, SET_CHAT_DIALOG_ID,
    SET_CHAT_GROUPS,
    SET_CHAT_MESSAGES,
    SET_CHAT_UNREAD_MESSAGES,
    SET_CHAT_VISIBLE
} from "./@types"
import {userAPI} from "../../../api";
import {
    setChatCommands,
    setChatGroups,
    setChatMessages,
    setChatSelectedDialogId,
    setChatUnreadMessages,
    setChatVisible
} from "./chatActions";
import {showMessageError, showMessageErrorPromise} from "../system/actions";


let initialState: ChatDataState = {
    groups: [],
    has_unread_messages: {status: false, list:[]},
    messages: [],
    is_visible: false,
    commands: [],
    selected_dialog_id:'',
};

const chatReducer = (state = initialState, action: ActionsTypes) => {
    switch (action.type) {
        case SET_CHAT_GROUPS:
            return {...state, groups:[...action.groups]};
        case SET_CHAT_UNREAD_MESSAGES:
            return {...state, has_unread_messages: action.has_unread_messages };
        case SET_CHAT_MESSAGES:
            return {...state, messages: action.messages };
        case SET_CHAT_VISIBLE:
            return {...state, is_visible: action.is_visible };
        case SET_CHAT_COMMANDS:
            return {...state, commands: action.commands };
        case SET_CHAT_DIALOG_ID:
            return {...state, selected_dialog_id: action.selected_dialog_id };
        default:
            return state
    }
};

export const openChatWindow = async (dialog_id: string|null, dispatch: any) => {
    if (!dialog_id || dialog_id === 'undefind') return ;
    dispatch(setChatVisible(true));
    dispatch(setChatSelectedDialogId(dialog_id))

};

export const getChatInitialStat = async (session_driver_id: string, dispatch: any, is_first:boolean = true)   => {
     let res  = await userAPI.getChatGroups(session_driver_id).catch((err) => showMessageError(  "Ошибка загрузки списка групп чатов <br/>" + err.message ,dispatch));
     if (!res) return false;
     if (!res.data || res.status !== 200 || !Array.isArray(res.data))
         return (await showMessageErrorPromise("Ошибка загрузки списка групп чатов ! <br/> Ошибка структуры данных", dispatch));

     dispatch(setChatGroups(res.data));

     let resCmd  = await userAPI.getChatCommands(session_driver_id).catch((err) => showMessageError(  "Ошибка загрузки списка быстрых комманд для чата <br/>" + err.message ,dispatch));
     if (!resCmd.data || resCmd.status !== 200 || !Array.isArray(resCmd.data))
            return (await  showMessageErrorPromise(  "Ошибка загрузки  списка быстрых комманд для чата ! <br/> Ошибка структуры данных" ,dispatch));

     dispatch(setChatCommands(resCmd.data));
     if (!is_first) return true;

     let groups : IChatGroup[] = res.data;
     dispatch(setChatUnreadMessages({status : groups.some(x=> x.dialogs.some(z=> z.unread_message_count > 0)), list:[]} ));

     // // проверяем есть ли чат с поддержкой если нет то создаем его
     if (!groups.some(x=> x.group_id === "2598f0ae-e1df-11eb-8ea7-00155d010f14")) {
           let res  = await userAPI.setChatNewDialog(session_driver_id,  "2598f0ae-e1df-11eb-8ea7-00155d010f14" ).catch((err) => showMessageError(  "Ошибка создания чата с поддержкой <br/>" + err.message ,dispatch));
           if (!res) return false;
           if (res.data && res.data.status === "success") {
               let state: any =  await getChatInitialStat(session_driver_id, dispatch, false);
               return state as boolean;
           }
           else
               return (await  showMessageErrorPromise("Ошибка создания чата с поддержкой <br/>" + res.data.error_message, dispatch));
     }

     return true;
};

export const setChatMessagesIsRead = async (driver_id:string, messages: CChatMessage[],    dispatch:any) => {
    // находим не прочитанные и помечаем их прочитанными
    let new_messages_id : {id:string}[] = messages.filter(x=> x.in_out === EnumChatMessageType.IN && !x.read).map(x=>({id:x.id}));
    if (!new_messages_id.length) return messages;

    let res = await userAPI.setChatMessagesIsRead(driver_id, new_messages_id).catch((e) => {showMessageError('Ошибка получения сообщений ' + e.message, dispatch)});
    if (!res || res.data.status !== 'success') return undefined;

    messages.filter(x=> new_messages_id.some(z=> z.id === x.id) ).forEach( x=> x.read = true);
    return messages;
}

export const setChatNewMessages = async (driver_id:string, messages: IChatNewMessage[], dialog_id :string, chat : ChatDataState, dispatch:any) => {
  let groups = [...chat.groups];
  if (messages.length == 0) {
      if (chat.has_unread_messages.status)
          dispatch(setChatUnreadMessages({status:false, list:[]}));
     return ;
  }
  let tmp = messages.filter(m=> !chat.has_unread_messages.list.some(x=> x == m.id));

  if (tmp.length > 0)
      dispatch(setChatUnreadMessages({status:true, list: messages.map(x=>x.id)}));

  let dialog_list : IChatDialog[] =  groups.map(g=>  g.dialogs ).flat();

  // проверяем всле ли диалоги есть у нас в структуре state чата
  let isAll = true;
  messages.forEach( m => { if (!dialog_list.some(d=> d.dialog_id == m.dialog_id)) isAll = false; });
  if (!isAll)
      if (( await getChatInitialStat(driver_id, dispatch)) == undefined) return ;

  // проверяем наличие новых сообщений в диалогах и помечаем диалоги непрочитанными
  groups.forEach( g => {
     g.dialogs.forEach( d=> d.unread_message_count = messages.some(m=>m.dialog_id == d.id && m.dialog_id != dialog_id) ? 1 : 0 )
  });
  dispatch(setChatGroups(groups));

  if (!dialog_id) return;
  let new_messages :CChatMessage[] = messages.filter( x=> x.dialog_id == dialog_id)
                                             .map(x=> new CChatMessage(dialog_id, x.from_user_id, x.message, x.date, EnumChatMessageType.IN, '', x.id) );
  if (new_messages.length != messages.length && new_messages.length > 0)
      dispatch(setChatUnreadMessages({status:true, list:new_messages.map(x=>x.dialog_id)}));

  new_messages = chat.messages.concat(new_messages);
  let all_msg : CChatMessage[] | undefined = await setChatMessagesIsRead(driver_id, new_messages, dispatch);
  if (all_msg  ) {
      console.log('setChatNewMessages' );
      dispatch(setChatMessages(all_msg));
  }
};

export const setChatNewGroupByOrder = async (driver_id: string, order_id: string, dispatch: any) => {
    let res = await userAPI.getChatAllUsersByOrder(driver_id, order_id).catch((e) => {showMessageError('Ошибка получения списка диалогов <br>' + e.message, dispatch)});
    if (!res || !Array.isArray(res.data) ) return undefined;
    await getChatInitialStat(driver_id, dispatch);
}


export default chatReducer
