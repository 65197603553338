import React from "react";
import './ButtonSave.css';


import Loader from "react-loader-spinner";

interface IProps {
    isSavingData:boolean;
    isActive:boolean;
    onClickSave:()=>void;
    onClickBack:()=>void;
}


const ButtonSave: React.FunctionComponent<IProps> = ({isSavingData, isActive, onClickSave, onClickBack} ) => {
    //console.log('isActive', isActive);
    return (
              <>
                     {isSavingData ?
                         <div id="bank_next" className="saving_button inactive">
                                <Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
                                <div className="next_button_text"> Сохранение ...</div>
                         </div>
                         :
                         <div id="bank_next" className={"next_button "+ (isActive  ? '' : ' inactive')} onClick={onClickSave}>
                                 <div className="next_button_text">  Сохранить  </div>
                         </div>
                     }

                     <div id="bank_back" className="bank_link" onClick={onClickBack}>Вернуться назад</div>
            </>
    );
};

export default ButtonSave;
