import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setOrder, setOrderPhotos} from "../../redux/reducers/orders/actions";
import {
  clearSystemStatus,
  popSystemStatus,
  removeSystemStatus,
  setOverlay, setPlayMode, setReloadOrderList,
  setSystemStatus, showMessageError, showMessageErrorPromise, showMessageInfo, showMessageInfoPromise,
  showMessageTrunk,
  toggleOrdersWindow
} from "../../redux/reducers/system/actions";

import moment from "moment";
import "moment/locale/ru";
import "./OrderFullCard.css";

import Button from "../Elements/Button/Button";
import CargoCard, {CargoTypes} from "./CargoCard/CargoCard";
import VehicleRequirements from "./VehicleRequirements/VehicleRequirements";
import {
  COrderPhoto,
  EnumOrderStatusID,
  EnumStatusExecution,
  OrderFullInfo,
  OrderRoutePoint,
  OrdersState
} from "../../redux/reducers/orders/@types";
import {getRouteMutation, userAPI} from "../../api";
import {RouteInfo} from "../../redux/reducers/map/@types";
import { setIndexNextPoint, setRoutes} from "../../redux/reducers/map/actions";
import {OrdersListType, SystemStatus} from "../../@types";
import {EnumWindowMessageType} from "../../redux/reducers/system/@types";
import {executionOrderThunk} from "../../redux/reducers/orders/reducers";
import {ReactSVG} from "react-svg";
import CloseSVG from "../App/svg/close.svg";
import HeaderCollapseExpand from "../Elements/HeaderCollapseExpand/HeaderCollapseExpand";
import Overlay from "../Elements/Overlay/Overlay";
import {
  getDriverRouteToNextPoint,
  numWord,
  setCurrentDriverPosition, setSystemServiceInfo
} from "../../redux/reducers/system/system_functions";
import {getDistance} from "../Elements/HereMap/hereFunctions";
import {setChatNewGroupByOrder} from "../../redux/reducers/chat/chatReducers";
import ImageViewWindow from "../ImageViewWindow/ImageViewWindow";
import IconPhoto from "../App/svg/photo2.svg";
import {EnumPhotoType} from "../OrderPlayWindow/OnPlacePlayWindow/OnPlacePlayWindow";
import MakePhotoPlayWindow from "../OrderPlayWindow/MakePhotoPlayWindow/MakePhotoPlayWindow";
import QuestionWindow from "../Elements/QuestionWindow/QuestionWindow";
import {ResponseCheckOrderTaking} from "../../api/@types";
import DriverUpdateValuesForm, {REJECT_ORDER_BY_PARAMS} from "./DriverUpdateValuesForm/DriverUpdateValuesForm";
import {APP_NAME, IS_DEBUG, USE_DEBUG_ROUTE} from "../../deployment";
import Loader from "react-loader-spinner";



interface IProps {
  order: OrderFullInfo;
  onClose: Function;
  zIndex:number;
  full_mode?: boolean;
}

interface ILocalState {
  route: RouteInfo | null,
  show_vehicle: boolean,
  show_cargo: boolean,
  is_full_mode: boolean,
  show_point_index: number[],
}

const OrderFullCard: React.FC<IProps> = ({ onClose, order, zIndex, full_mode  }) => {
  const dispatch = useDispatch();

  const { user,system, orders } = useSelector((state: RootState) => ({

    user: state.userReducer,
    system: state.systemReducer,
    orders: state.ordersReducer
  }));



  const { additionalRequirements, bodyOptionCharacteristics, bodyOptionCharacteristicsValues, bodyTypes, carTypes } = orders;

  const [orderPhotos, setOrderPhotos] = useState<COrderPhoto[]>([]);
  const [currentImageView, showImageView] = useState<{id:string, blob:Blob}|undefined>(undefined);
  const [isOrderPhotoShow, setOrderPhotoShow] = useState<{type:EnumPhotoType, point:number}|undefined>(undefined);
  const [isShowQuestionWindow, setShowQuestionWindow] = useState<string>('');
  const [isButtonClickStartPlay, setButtonClickStartPlay] = useState<boolean>(false);
  const [isOrderCanExecute, setOrderCanExecute] = useState<boolean>(false);
  const [errorExecutingMessage, setErrorExecutingMessage] = useState<string>('');
  const [isAppendValuesToShowForm, setAppendValuesToShowForm] = useState<{id:string, description:string; name:string, value_needed?:string}[]>(
      [
        // {   description:'медкнижки ',
        //     id:"9cebde11-25e2-11ea-a9ad-00155d8e4e05",
        //     name :   "body_option_characteristics",
        //     value_needed : "2022-10-11T00:00:00"
        //   }
        // {id:'xz', description:'', name:'size'},
        // {id:'xz', description:'', name:'body_option_id'},
        // {id:'xz', description:'', name:'car_type_id'},
        // {id:'be6cfcfe-66a1-11eb-8e78-000c298a28ba', description:'Растентовка', name:'body_option_characteristics', value_needed:"Бок"},
        // {id:'9cebde15-25e2-11ea-a9ad-00155d8e4e05', description:'Гидроборт', name:'body_option_characteristics'},
        // {id:'9cebde18-25e2-11ea-a9ad-00155d8e4e05', description:'Акт о дезинфекции', name:'body_option_characteristics'},
        // {id:'9cebde1b-25e2-11ea-a9ad-00155d8e4e05', description:'Медкнижка', name:'body_option_characteristics'},
        // {id:'fc3de6d0-40dd-11ea-a9c6-00155d8e4e05', description:'Тип пандуса', name:'body_option_characteristics'},
        // {id:'d8c67cfe-40df-11ea-a9c6-00155d8e4e05', description:'Термописец', name:'body_option_characteristics'},
        // {id:'11a53925-52d4-11ea-a9c9-00155d8e4e03', description:'Рефрижератор', name:'body_option_characteristics'},

        ]);


  const [localState, setLocalState] = useState<ILocalState>( {
    route : null,
    show_vehicle: false,
    show_cargo: false,
    show_point_index: [],
    is_full_mode: full_mode == undefined || full_mode ,
  } );

  const [realRouteTime, setRealRouteTime] = useState<{arrival_time:string, leave_time:string}[]>([]);

  const getDistanceToStartOrder = () =>{
    if (user.current_coordinates)
      return getDistance(order.routes[0].adress_latitude , order.routes[0].adress_longitude,
                         user.current_coordinates.lat, user.current_coordinates.lon);
    return '00';
  };

  // Возвращает время в минутах до начала заказа
  const getTimeToStartOrder = (): number => {
    const now = moment();
    let order_date = order.date.split('T')[0]+'T'+order.date.split('T')[1];
    const startTime = moment(order_date);
    let diff = startTime.diff(now, "minutes");
    return diff;
  };

  const handleClose = () => {
    dispatch(toggleOrdersWindow(true));
    dispatch(setOrder({}, OrdersListType.All));
    dispatch(setOverlay(false));
  };

  const startPlay = async () =>{
    if (!system.is_tracker_mode && !IS_DEBUG && !USE_DEBUG_ROUTE) {
      return dispatch(setSystemStatus(SystemStatus.RegistrationDownloadApplication));
    }

    if (isButtonClickStartPlay) return;

    setButtonClickStartPlay(true);

    if (localState.route)  dispatch(setRoutes(localState.route));
    let data : any = await executionOrderThunk({status:EnumStatusExecution.STARTING, point:-1, order_id: order.id, driver_id: user.driver_id}, dispatch);
    let tmp : any = await setSystemServiceInfo({order_id: order.id, driver_id: user.driver_id, device_id: system.tracker_id}, dispatch);
    if (data) {
      dispatch(clearSystemStatus());
      dispatch(setIndexNextPoint(0));
      dispatch(setSystemStatus(SystemStatus.PlayWindow));
      await setChatNewGroupByOrder(user.driver_id, order.id, dispatch);

      if (localState.route && localState.route.points[0]) {
        let pos = await setCurrentDriverPosition(dispatch, user.driver_id, true, localState.route, system.is_tracker_mode);
        let npos = {lat: localState.route.points[0].lat, lon: localState.route.points[0].lng} ;
        if (pos) await getDriverRouteToNextPoint(dispatch, [pos, npos]);
        dispatch(setPlayMode(true));
      }
    }
    setButtonClickStartPlay(false);
  };

  const showMap = () => {
    console.log('on map start', localState.route)
    if (localState.route)  dispatch(setRoutes(localState.route));
    else return;
    setLocalState((s)=>({...s, is_full_mode: false}))
    dispatch(clearSystemStatus());
    dispatch(setSystemStatus(SystemStatus.SmallOrderCard));
    // dispatch(setOrder(order, order.type ? order.type: OrdersListType.All));
    // dispatch(setOverlay(false));

  }

  const loadRealExecution = async () =>{
    if (!order || !order.id) return ;

    let res = await userAPI.getOrderExecution(order.id, user.driver_id).catch((err) => showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка получения данных текущего заказа " + err.message},dispatch));
    if (!res.data || res.status !== 200 || !Array.isArray(res.data) )
      return ;//showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка структуры данных информации о выполняемом заказе !"},dispatch)

    let points = res.data;
    if (!points || !Array.isArray(points)) return ;
    let real_route : {arrival_time:string, leave_time:string}[] = [];
    points.forEach( (x,index)=> {
      if (index == 0) return;
      if (x.point>real_route.length) real_route.push({arrival_time:'', leave_time:''})
      if (x.status == EnumStatusExecution.AT_POINT) real_route[x.point-1].arrival_time = x.time;
      if (x.status == EnumStatusExecution.LEFT_POINT) real_route[x.point-1].leave_time = x.time;
      if (x.status == EnumStatusExecution.FINISH) real_route[real_route.length-1].leave_time = x.time;
    })
    setRealRouteTime(real_route)
  };

  const loadPhotos = async () =>{
    let res = await userAPI.getOrderPhoto( user.driver_id, order.id).catch((err) => showMessageError("Ошибка получения фото текущего заказа <br/>" + err.message,dispatch));
    if (!res || !res.data || res.status !== 200) return ;
    if (res.data.status == 'error') return showMessageError("Ошибка получения фото текущего заказа <br/>" + res.error_message,dispatch);
    if (!Array.isArray(res.data.files)) return showMessageError("Ошибка структуры данных хранения фото заказа " ,dispatch);
    setOrderPhotos(res.data.files);
  };

  useEffect(()=>{
    if (order && order.id && user.driver_id) {
      getRouteMutation(order.id).then((res)=>{
        if (!res || !res.data) return showMessageError("Не найден маршрут для заказа !", dispatch);
        setLocalState((s) => ({...s, route: res.data }))
      })
      loadRealExecution().then(()=>{});
      loadPhotos().then();
    }
  },[user.driver_id]);
  const  toggleShowPointInfo = (i:number) => {
    if (localState.show_point_index.indexOf(i)>=0)
      setLocalState((s)=> ({...s, show_point_index: localState.show_point_index.filter(x=> x!= i )}));
    else
      setLocalState((s)=> ({...s, show_point_index: [...localState.show_point_index, i]}))
  };

  const cancelOrder = async () => takeOrder(EnumStatusExecution.REJECTED);


  const takeOrder = async (status = EnumStatusExecution.CONFIRMED) =>{
    // if (!system.is_tracker_mode) {
    //   //await showMessageErrorPromise(  `Для продолжения работы необходимо запустить '${APP_NAME}' ` , dispatch);
    //   dispatch(setSystemStatus(SystemStatus.RegistrationDownloadApplication));
    //   return ;
    // }
    if (isAppendValuesToShowForm.length)
        setAppendValuesToShowForm([]);

    if (status == EnumStatusExecution.CONFIRMED) {
      let response : ResponseCheckOrderTaking | any = await userAPI.checkOrderTaking(user.driver_id, order.id, status).catch((e)=>{
        showMessageError(  "Ошибка проверки параметров ТС для заказа " +order.number, dispatch); return undefined;
      });
      if (!response) return ;
      if (response.data.status == "error" ) {
          let unsuitable_message = response.data && Array.isArray( response.data.unsuitable_values) ?  response.data.unsuitable_values.map( (x: any) => x.description).join(',<br/>') : '';
          if (unsuitable_message) return showMessageError("К сожалению вы не можете принять данный заказ по следующей причине:<br/><br/><strong style=' font-weight: 500; text-transform: uppercase;'>" + unsuitable_message.toLowerCase() + "</strong>", dispatch);
          if (response.data.empty_values && Array.isArray(response.data.empty_values) && response.data.empty_values.length > 0)
            return setAppendValuesToShowForm(response.data.empty_values)
          if (response.data.error_message) return showMessageError(response.data.error_message, dispatch);
          return showMessageError("Непонятная ошибка при проверки параметров ТС. Просьба обратиться к разаработчикам.", dispatch);
      }
    }

    dispatch(setSystemStatus(SystemStatus.WaitingWindow));
    setShowQuestionWindow('');
    let res = await userAPI.takeOrder(user.driver_id, order.id, status).catch((e)=>{
      showMessageError(  "Ошибка работы с заказом" , dispatch);
      return null;
    });
    dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
    if (!res || !res.data || res.data.status==='error')
          return  showMessageError('Ошибка принятия заказа <br/>' + (res && res.data &&  res.data.error_message ? res.data.error_message :'') , dispatch);

    await showMessageInfoPromise(status == "confirmed" ? `Заказ №${order.number} успешно Вам назначен!` : 'Зафиксирован отказ!' , dispatch);
    dispatch(setRoutes(null));
    if (status == "confirmed")
      dispatch(setReloadOrderList({order_id : order.id}));
    onClose();
  };

  const getRealHours = (index:number)  =>{
    if (index>= realRouteTime.length) return <></>;
    return ( <span className='realTime'>{moment(realRouteTime[index].arrival_time).format("HH:mm")+' - ' +moment(realRouteTime[index].leave_time).format("HH:mm")}</span>);
  }
  const getHours = ( point: {arrival_traffic_time:string, departure_traffic_time: string})  =>{
    return  <span className='planTime'>{moment(point.arrival_traffic_time).format("HH:mm")+' - ' +moment(point.departure_traffic_time).format("HH:mm")}</span>;
  }
  const setErrorExecuteMessage = (val:string) :boolean =>{
    setErrorExecutingMessage(val);
    return false;
  }
  const canExecuteOrder = () :boolean =>{
     console.log('canExecuteOrder');

    if (!user.authenticated)
      return setErrorExecuteMessage(`Пользователь не авторизован`);
    if (order.status_id && order.status_id == EnumOrderStatusID.EXECUTING) return true;
    if (order.type !== OrdersListType.Set)
      return setErrorExecuteMessage(`Тип заказа ${order.type} не соответствует назначенному`);
    if (order.type == OrdersListType.Set && (IS_DEBUG || USE_DEBUG_ROUTE)) return true;

    if (order.status_id && order.status_id == EnumOrderStatusID.COMPLETED)
      return setErrorExecuteMessage(`Статус заказа выполнен`);
    if (order.status_id && order.status_id !== EnumOrderStatusID.SET_DRIVER)
      return setErrorExecuteMessage(`Статус заказа ${order.status_id} не соответствует "водитель подобран" `);
    // if (getTimeToStartOrder() > 180) return false;
    return true;
  };

  const canOrderTake = ():boolean =>  order.status_id === EnumOrderStatusID.SEARCH_DRIVER;
  const isOrderTaken = ():boolean =>  order.status_id !== EnumOrderStatusID.SEARCH_DRIVER && order.status_id !== EnumOrderStatusID.CANCELED;
  const setOverlay = (is_full_mode:boolean)  =>  {
    console.log(is_full_mode);
    setLocalState((s)=> ({...s, is_full_mode}))
  }

   useEffect(()=>{
     setOrderCanExecute( canExecuteOrder())
   }, [full_mode]);
   useEffect(()=>{
     setLocalState((s)=> ({...s, is_full_mode: full_mode == undefined ? true : full_mode}))
   }, [full_mode]);

  let order_date = order.date ? order.date.split('T')[0]+'T'+order.date.split('T')[1] : '';
  const  getPhone = (phone : string) : string => {
    if (phone.length ==0) return "";
    if (phone[0] == '+' || phone[0] != '7' ) return phone;
    return  "+"+ phone;
  }

  const isShowContacts =  Math.abs( moment(order_date).diff(moment(new Date()), 'days')) <= 3 ;

  const savePhoto = async (blob:Blob) => {
    console.log('savePhoto');
    if (blob && isOrderPhotoShow) {
      let res = await userAPI.setOrderPhoto(order.id, user.data.id, isOrderPhotoShow.point, isOrderPhotoShow.type, blob).catch(e=> showMessageError('Ошибка сохранения фото<br>' + e.message, dispatch));
      if (!res) return;
      if (res.data.status !== 'success') return showMessageError('Ошибка сохранения фото<br>' + res.data.error_message, dispatch);

      dispatch(setOrderPhotos([...orders.photos, new COrderPhoto(res.data.id, isOrderPhotoShow.point, blob)]))
    }
    setOrderPhotoShow(undefined)
  };

  if (isOrderPhotoShow) return(<MakePhotoPlayWindow  onClose={()=>setOrderPhotoShow(undefined)} onSave={savePhoto} />);

  if (currentImageView ) return(<ImageViewWindow  title={"Фото кузова с грузом"} onClose={()=>showImageView(undefined)} file={currentImageView.blob} />);
  // console.log(order.type, user.authenticated);
  return (
    <>
      {localState.is_full_mode  && <Overlay z_index={35} onClick={()=> {console.log('clicl'); dispatch(setRoutes(null)); onClose();}}  />}

      <HeaderCollapseExpand window_name='FullCardWindow' class_name="page_order_full_card" isFullMode={localState.is_full_mode} onMoveEnd={(is_full_mode:boolean)=>setOverlay(is_full_mode)} >


        <div className="page_order_full_card_content">
          <div className="bottom_window_header" >
            <div className="window_close_btn"  onClick={(e) =>  {dispatch(setRoutes(null)); onClose();}} ><ReactSVG src={CloseSVG} /></div>
            <div className="bottom_window_title">{`Заказ №${order.number}`} </div>
             <div className="bottom_window_description">{`${moment(order_date).format("dd DD.MM.YYYY").toUpperCase()} к ${moment(order_date).format("HH:mm")}`} </div>
          </div>
          {/*<div className="bottom_window_content short_content" style={{ maxHeight: "150px", overflow: "hidden" }}>*/}
          {/*  <div className="order_info_section" style={{ marginTop: 0 }}>*/}
          {/*    <div className="order_info_roadmap_title">{order.routes.length} {numWord(order.routes.length, ["точка", "точки", "точек"])} | {100} км</div>*/}
          {/*    <ul className="order_info_roadmap">*/}
          {/*      {order.routes.map((item: any, i: number) => {*/}
          {/*        return (*/}
          {/*          <li key={item.id}>*/}
          {/*            <div className="order_info_roadmap_address">{item.adress}</div>*/}
          {/*          </li>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <Button type="grey" style={{ color: "#5364ff" }}>На карте 11</Button>*/}
          {/*  <VehicleRequirements*/}
          {/*    additionalRequirements={additionalRequirements}*/}
          {/*    bodyTypes={bodyTypes}*/}
          {/*    bodyOptionCharacteristics={bodyOptionCharacteristics}*/}
          {/*    bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}*/}
          {/*    carTypes={carTypes}*/}
          {/*    orderData={order}*/}
          {/*  />*/}
          {/*  <div className="order_info_section">*/}
          {/*    <div className="order_info_section_name">Груз</div>*/}
          {/*    {Boolean(order.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={order.cargo} />}*/}
          {/*    {Boolean(order.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={order.cargo} />}*/}
          {/*    {Boolean(order.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={order.cargo} />}*/}
          {/*  </div>*/}

          {/*</div>*/}

          <div className={"bottom_window_content full_content " + (canOrderTake() ? 'order-can-be-taken' : '')}>
            <div className="order_info_section" style={{ marginTop: 0 }}>
              <div className="order_info_roadmap_title">
                {order.routes && order.routes.length} {numWord(order.routes.length, ["точка", "точки", "точек"])}
                | { localState.route ? Math.round(localState.route.distance/1000) : '?'} км
              </div>
              <ul className="order_info_roadmap">
                {order.routes && order.routes.map((item: OrderRoutePoint, i: number) => {
                  return (
                    <li key={item.id} className={ 'number' + (i+1)}>
                      <div className="order_info_roadmap_address">{item.adress}</div>

                      {localState.route &&
                        <div className="order_info_datetime">
                          {localState.route && Array.isArray(localState.route.points) ? getHours(localState.route?.points[i]) : ''}
                          {i===0 && <span className="order_info_datetime-words">Прибытие - убытие</span>}
                          {getRealHours(i) }
                        </div>
                      }
                      { localState.show_point_index.indexOf(i)>=0 &&
                        <div className="order_info_point_detail">
                          {item.adress_comment &&  <div className="order_info_comment"><span>Дополнительная информация</span> {item.adress_comment}</div>}
                          <div className={"order_info_persons " + (isShowContacts  === true ? isShowContacts : 'hidden_persons')}>
                            <div className="order_info_persons_title">Контактные лица: {isShowContacts }</div>

                            {item.contact_persons.map( (x,j)=> <a href={ x.phone ? "tel:"+ getPhone(x.phone) : ''} >
                                <div key={j+'cop1'} className="order_info_one_person">{x.full_name ? x.full_name : '' }</div>
                                <div key={j+'cop2'} className="order_info_one_person">
                                    {x.phone ? x.phone : '' } {x.phone_ext ? ' доб. ' + x.phone_ext : '' }
                                </div>
                              </a>)}
                            {item.contact_persons.length === 0  || !item.contact_persons.some(x=> x.full_name || x.phone)? <div className="no_persons">нет данных</div> : ''}
                          </div>
                          {item.company  && <div className="order_info_from">От кого: <span className="contact_name">{item.company }</span></div> }
                          <div className="order_info_task">
                            <div className="order_info_task_header">Задача</div>
                            <div className="order_info_task_comment">на точке быть вежливым</div>
                            <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                            {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                            {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                            {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                            {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}
                          </div>
                          <div>
                            {orderPhotos.length > 0 &&
                              <div className="on_place_window__photo">
                                {orderPhotos.filter(x=>x.point_number == i && x.blob).map(x=> <img className="photo_button_img" src={URL.createObjectURL(x.blob)} onClick={() => showImageView(x)}/>)}
                              </div>
                            }
                            {order.status_id == EnumOrderStatusID.EXECUTING &&
                                <div className="order_info_task">
                                  <Button type={"green"} size={"large"} onClick={() =>setOrderPhotoShow({type:EnumPhotoType.ORDER_DOCUMENTS_PHOTO, point:i})}>
                                    Добавить фото документов
                                    <ReactSVG src={IconPhoto}/>
                                  </Button>
                                </div>
                            }
                          </div>
                        </div>
                      }
                      <div className="link_show_point" onClick={()=> { toggleShowPointInfo(i) }}>
                        {user.authenticated && isOrderTaken() && <span>{ localState.show_point_index.indexOf(i)>=0 ? 'Скрыть детали' : 'Показать детали' }</span>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <Button type="grey" style={{ color: "#5364ff" }} onClick={showMap} disabled={!localState.route}>На карте</Button>
            <div className="space" />
            { localState.show_vehicle && <VehicleRequirements
              additionalRequirements={additionalRequirements}
              bodyTypes={bodyTypes}
              bodyOptionCharacteristics={bodyOptionCharacteristics}
              bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}
              carTypes={carTypes}
              orderData={order}
            /> }

            <Button type="white"  onClick={()=> setLocalState( (s) => ({...s, show_vehicle: !localState.show_vehicle}))} >
              { localState.show_vehicle ? 'Скрыть требования к ТС' : 'Показать требования к ТС' }
            </Button>

            {localState.show_cargo &&
              <div className="order_info_section">
                <div className="order_info_section_name">Груз</div>
                <div className="order_info_section_name_description">Название наименование</div>
                {Boolean(order.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={order.cargo}/>}
                {Boolean(order.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={order.cargo}/>}
                {Boolean(order.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={order.cargo}/>}
                {!order.cargo.packages.length && !order.cargo.pallets.length && !order.cargo.places.length && <div className="noCargo">Информация о грузе отсутсвует</div>}
              </div>
            }
            <div className="space" />
            <Button type="white"  onClick={()=> setLocalState( (s) => ({...s, show_cargo: !localState.show_cargo}))} >
              { localState.show_cargo ? 'Скрыть информацию о грузе' : 'Показать  информацию о грузе' }
            </Button>
            {order.errors && order.errors.length > 0 &&
            // {errors.length > 0 &&
              <div className="order_errors" >
                <div className="order_errors__header">ОШИБКИ</div>
                <div className="order_errors__title">Сказываются на доверии к вам</div>
                <div  className="order_errors__block">
                  {order.errors.map((x,i) =>  <div className={"order_errors__block_tem"} key={'err_'+i}>{x}</div>)}
                </div>
              </div>
            }
            {(order.type === OrdersListType.Offer || order.type === OrdersListType.Free ) && user.authenticated &&
              <div className="order_info_control_section">
                <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {getDistanceToStartOrder()} км</div>
                <Button type="primary" size="large" onClick={takeOrder}>ПРИНЯТЬ ЗАКАЗ</Button>
                <div className='cancel_order' onClick={()=>setShowQuestionWindow("Вы уверены, что хотите отказаться от выбранного заказа ?")}>ОТКАЗАТЬСЯ от заказа</div>
              </div>
            }

            {
              isOrderCanExecute ?
                  <div className="order_info_control_section">
                    <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {getDistanceToStartOrder()} км</div>
                    <Button type={isButtonClickStartPlay ? "grey" : "green"} size="large" onClick={startPlay}>{isButtonClickStartPlay ? <Loader type="ThreeDots" color="#fff" width={'3rem'} height={'3rem'} /> : 'НАЧАТЬ ВЫПОЛНЕНИЕ'} </Button>
                  </div>
                :
                  <div className="order_info_control_section">
                     <div className="order_info_control_info">{errorExecutingMessage}</div>
                  </div>
            }

            {!user.authenticated &&
              <div className="order_info_control_section">
                <Button type="primary" size="large" onClick={()=>{
                  dispatch(popSystemStatus());
                  dispatch(setSystemStatus(SystemStatus.Registration))
                }}>ПРИНЯТЬ ЗАКАЗ</Button>
              </div>
            }
          </div>
         {/*</BottomWindow>*/}
          {
            isShowQuestionWindow &&
            <QuestionWindow title={isShowQuestionWindow}
                            onYes={cancelOrder}
                            onNo={()=>setShowQuestionWindow('')}/>
          }
        </div>
      </HeaderCollapseExpand>
      {isAppendValuesToShowForm.length > 0 &&
        <DriverUpdateValuesForm values={isAppendValuesToShowForm}
                                onSave={()=>{
                                  setAppendValuesToShowForm([]);
                                  takeOrder();
                                }}
                                onClose={(value:any)=>{
                                  setAppendValuesToShowForm([]);
                                  if (value === REJECT_ORDER_BY_PARAMS)
                                    setShowQuestionWindow("К сожалению не все параметры удовлетворяют критериям заказа, отказаться от выбранного заказа ?");

                                }}/>}
    </>
  );
}


export default  OrderFullCard ;

