import React, {useEffect} from "react";
import './ChatButton.css'
import ChatsSVG from "../../App/svg/chats.svg";
import {Button} from "../../Elements/elements";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {setChatVisible} from "../../../redux/reducers/chat/chatActions";
import {setSystemStatus} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";


const ChatButton: React.FC = ({}) =>{
    const dispatch = useDispatch();
    const {    chat, user } = useSelector((state: RootState) => ({ chat: state.chatReducer, user: state.userReducer}));

    useEffect(()=>{
        // let doc = document.getElementById('play_sound_ding');
        // //@ts-ignore
        // if (doc) doc.play();

    },[]);
    useEffect(()=>{
        if (chat.has_unread_messages.status) {
            let doc = document.getElementById('play_sound_ding');

            setTimeout(()=> {
                    //@ts-ignore
                    try {if (doc) doc.play(); } catch {}
                }, 0);
        }
    },[chat.has_unread_messages]);

    const onButtonClick = () => {
        if (user.authenticated)
            dispatch(setChatVisible(true));
        else
            dispatch(setSystemStatus(SystemStatus.VeryQuickRegistration));
    };

    return (
        <div className="just_chat_button_block">
            <Button type="default" className={"chat_main_button " + (chat.has_unread_messages.status ? " chat_main_button-point" : '' ) } icon={ChatsSVG} onClick={onButtonClick}/>
        </div>
    )
};

export  default ChatButton;
