import React, {FC, useEffect, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import {CPackInfo, OrderFullInfo, PackedItemType} from "../../../redux/reducers/orders/@types";
import {ordersAPI} from "../../../api";
import {Overlay} from "../../Elements/elements";
import Loader from "react-loader-spinner";

interface IProps{
    onClose:()=>void
    order: OrderFullInfo,
}
const SchemaPlayWindow : FC<IProps>  = ({order, onClose}) =>{

    const packWnd = useRef<HTMLDivElement>(null);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState('');
    const [packInfo, setPackInfo] = useState<CPackInfo|undefined>(undefined);

    const loadData = async () => {
        setLoading(true)

        let info: CPackInfo = await ordersAPI.getPackInfo({ cargo: order.cargo,
                                                                        body_option_id: order.body_option_id,
                                                                        body_option_characteristics: order.body_option_characteristics
                                                                  });
        setLoading(false);
        if (!info) return setError('Ошибка загрузки данных');
        setPackInfo(info);

    }
    useEffect(()=> { loadData(); },[]);

    let items: PackedItemType[] =  [],
          ratio = 0;
    if (packInfo) {
        items = packInfo.packed_items;
        if (packWnd && packWnd.current) {
            // if (packInfo.width > packInfo.height) {
            //     let v = Math.round(+packWnd.current.getBoundingClientRect().width / packInfo.width * packInfo.height);
            //     packWnd.current.setAttribute('height', v+'px' );
            //     console.log('new height', v);
            // } else {
            //     let v = Math.round(+packWnd.current.getBoundingClientRect().width / packInfo.height * packInfo.width);
            //     packWnd.current.setAttribute('height', v+'px' );
            //     console.log('new height', v);
            // }
            ratio = packInfo.width > packInfo.height ? +packWnd.current.getBoundingClientRect().width /  (packInfo.width  ) :
                                                       +packWnd.current.getBoundingClientRect().height / (packInfo.height  )
            // ratio = +packWnd.current.getBoundingClientRect().width /  (packInfo.width  )
        }
    }
    console.log('ratio',ratio, packInfo?.width, packWnd?.current?.getBoundingClientRect().width)
    return (
        <>
        <div className="play_window full_window  schema_play_window">
            <div className="window_close_btn" onTouchEnd={(e)=> e.stopPropagation()} onClick={(e) =>  onClose()} ><ReactSVG src={CloseSVG} /></div>
            <div className="order_play_info" style={ {borderTop: "unset"} }>
                <div className="order_play_info_on_place_title">Схема расположения груза</div>
            </div>
            <div className="order_play_info" style={ {borderTop: "unset", paddingTop:'10px'} }>

                    <div className="schema_play_window__cargo"  ref={packWnd}   >
                        {isError && <div className="schema_play_window__cargo__error">{isError}</div>}
                        {isLoading && <div className="schema_play_window__cargo__loading">Загрузка данных&nbsp;<Loader  type="ThreeDots" color="#888E99"  width={20} height={5} /></div>}
                        {items.length ? items.map((block: PackedItemType, index: number) => {
                            return (
                                // packInfo && packInfo?.width > packInfo?.height ?
                                <div key={'blk'+index} className='cargoSizeBlock' style={{
                                width: parseFloat(block.height.toString()) * ratio + 'px',
                                    height: parseFloat(block.width.toString()) * ratio + 'px',
                                    left: parseFloat(block.y.toString()) * ratio + 'px',
                                    top: parseFloat(block.x.toString()) * ratio + 'px'
                            }}>
                                <div className='sizeBlock'/>
                                </div>
                                    // :
                                    // <div key={'blk'+index} className='cargoSizeBlock' style={{
                                    //     height : parseFloat(block.height.toString()) * ratio + 'px',
                                    //     width: parseFloat(block.width.toString()) * ratio + 'px',
                                    //     top: parseFloat(block.y.toString()) * ratio + 'px',
                                    //     left: parseFloat(block.x.toString()) * ratio + 'px'
                                    // }}>
                                    //     <div className='sizeBlock'/>
                                    // </div>
                            );
                        }) : ''}
                    </div>

            </div>
        </div>
            <Overlay is_visible={true}/>
        </>
    )
};

export default SchemaPlayWindow;
