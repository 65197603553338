export const SET_CHAT_GROUPS = 'SET_CHAT_GROUPS';
export const SET_CHAT_COMMANDS = 'SET_CHAT_COMMANDS';
export const SET_CHAT_UNREAD_MESSAGES = 'SET_CHAT_UNREAD_MESSAGES';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const SET_CHAT_VISIBLE = 'SET_CHAT_VISIBLE';
export const SET_CHAT_DIALOG_ID = 'SET_CHAT_DIALOG_ID';


export enum EnumChatMessageType {
    IN = 'in', OUT='out', UNKNOWN =''
}
export interface IChatCommands {
    id:string,
    name:string
}

export class IChatNewMessage {
    id: string = ""; // "ed7e6b49-d924-43de-bf45-2c9ec84c16f4",
    group_id: string = ""; // "2598f0ab-e1df-11eb-8ea7-00155d010f14",
    dialog_id: string = ""; // "f839c056-00e2-11ed-8eb4-00155d010f1b",
    from_user_id: string = ""; // "b235d4fc-a3a4-11e9-a990-00155d8e4e03",
    message: string = ""; //"Текст сообщения",
    date: string =""; //"2021-08-26Т09:10:12"
}

export class CChatMessage {
    id: string = ""; //"bd091609-4c2f-4feb-aaff-95e8ed239c58",
    dialog_id: string = ""; //"f839c103-00e2-11ed-8eb4-00155d010f1b",
    from_user_id: string = ""; //"00000000-0000-0000-0000-000000000000",
    message: string = "";//"Здраствуйте, помогите мне, пожалуйста",
    date: string = ""; //"2022-07-11T11:17:42",
    read: boolean = false;
    in_out: EnumChatMessageType = EnumChatMessageType.OUT; //"out"
    file_id:string = "";
    img:string = "";

    constructor(dialog_id:string="", from_user_id:string = "", message:string = "",
                date:string="", in_out = EnumChatMessageType.OUT, file_id = "", id = "") {
        this.id = id;
        this.dialog_id = dialog_id;
        this.message = message;
        this.from_user_id = from_user_id;
        this.in_out = in_out;
        this.file_id = file_id;
        this.date = date ? date : (new Date()).toLocaleString().replace(', ', 'T');
    }
}

export interface IChatIsReadDialogMessage {
    id:string,
    date:string,
    read:boolean
}
export interface IChatDialog {
    id: string, //00000000-0000-0000-0000-000000000000,
    full_name: string, //Поддержка,
    dialog_id: string, //f839c103-00e2-11ed-8eb4-00155d010f1b,
    order_id: string, //00000000-0000-0000-0000-000000000000,
    unread_message_count: number, //1
    group_id: string, // проставляется в ручную чтобы не тащить весь колхоз
    order_number:string,
    order_date:string,
}

export interface IChatGroup {
    group_id: string, //2598f0ae-e1df-11eb-8ea7-00155d010f14,
    name: string, // Поддержка,
    dialogs: IChatDialog[]
}

export interface IChatUser {
    id: string, // "b235d4fc-a3a4-11e9-a990-00155d8e4e03",
    full_name: string, // "ТЕСТ ЗАКАЗЧИК",
    group_id: string, // "2598f0ab-e1df-11eb-8ea7-00155d010f14",
    order_id: string, // "652ae3a3-f074-11ec-8eb3-00155d010f1b",
    dialog_id: string, // "f839c056-00e2-11ed-8eb4-00155d010f1b"
}

export interface ChatDataState {
    groups: IChatGroup[],
    has_unread_messages: {status:boolean,list: string[]},
    messages: CChatMessage[],
    is_visible:boolean,
    commands: IChatCommands[],
    selected_dialog_id:string,
}

export interface SetChatGroupsAction {
    type: typeof SET_CHAT_GROUPS
    groups: IChatGroup[]
}
export interface SetChatCommandsAction {
    type: typeof SET_CHAT_COMMANDS
    commands: IChatCommands[]
}
export interface SetChatUnreadMessagesAction {
    type: typeof SET_CHAT_UNREAD_MESSAGES
    has_unread_messages: {status: boolean, list: string[]}
}

export interface SetChatMessagesAction {
    type: typeof SET_CHAT_MESSAGES
    messages: CChatMessage[]
}

export interface SetChatVisibleAction {
    type: typeof SET_CHAT_VISIBLE
    is_visible: boolean
}

export interface SetChatSelectedDialogIdAction {
    type: typeof SET_CHAT_DIALOG_ID
    selected_dialog_id: string
}

export type ActionsTypes =    SetChatGroupsAction
                            | SetChatUnreadMessagesAction
                            | SetChatVisibleAction
                            | SetChatCommandsAction
                            | SetChatSelectedDialogIdAction
                            | SetChatMessagesAction;
