import React, {useEffect, useRef, useState} from "react";
import './VehicleRegistration.css';
import BackIcon from '../../App/svg/back-arrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import {popSystemStatus, showMessageError} from "../../../redux/reducers/system/actions";
import {RootState} from "../../../redux/store";


import {
    CharacteristicTypeEnum,
    DropDownListItem,
    IBOCharacteristics,
    SystemState
} from "../../../redux/reducers/system/@types";
import {UserInfo, UserState, VehicleInfo} from "../../../redux/reducers/user/@types";

import ButtonSave from "../ButtonSave/ButtonSave";

import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import {validateBrand, validateYear2000} from "../../../api/validate";

import InputPhotoValue from "../InputPhotoValue/InputPhotoValue";
import {TemporaryState} from "../../../redux/reducers/temporary/@types";
import {loadPhotos, registrationAPI, sendPhoto} from "../../../api";
import InputSelectValue from "../InputSelectValue/InputSelectValue";
import {
    filterCategory,
    getBOCharacteristics,
    getBodyOptionsListIDs,
    getCategoriesList,
    getCharacteristicValueList, getPassFromCharacteristics, getPassName, isValueTrue,
    wait_before_update
} from "../../../redux/reducers/system/system_functions";
import InputCheckBoxValue from "../InputCheckBoxValue/InputCheckBoxValue";
import {MULTIPLE_CARS_OWNER, SINGLE_CAR_OWNER} from "../../../redux/reducers/owner_select/@types";
import {setUserInfo, tryUpdateData, updateData} from "../../../redux/reducers/user/actions";
import QuestionWindow from "../../Elements/QuestionWindow/QuestionWindow";
import SelectVehicleAddress from "./SelectVehicleAddress/SelectVehicleAddress";
import ImageViewWindow from "../../ImageViewWindow/ImageViewWindow";

export const passList = [

    {
        "name": "Отсуствует",
        "type": "Boolean",
        "id": ""
    }, {
        "name": "Пропуск ЦКАД",
        "type": "Boolean",
        "id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44"
    },
    {
        "name": "Пропуск МКАД",
        "type": "Boolean",
        "id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42"
    },
    {
        "name": "Пропуск ТТК",
        "type": "Boolean",
        "id": "22dba865-4bd4-11df-ab17-005056000008"
    },
    {
        "name": "Пропуск СК",
        "type": "Boolean",
        "id": "22dba867-4bd4-11df-ab17-005056000008"
    }
];

interface IProps {
    zIndex:number;
    val:number;

}

const VehicleRegistration: React.FunctionComponent<IProps> = ({zIndex,val } ) => {


    const  dispatch = useDispatch();

    const isFirstLoad = useRef({isTrue:true}).current;
    const isFirstLoadSaving = useRef({isTrue:true}).current;

    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const temp : TemporaryState     = useSelector( (state: RootState)=> ({temp:state.temporaryReducer})).temp;

    const [wasError, setError]                = useState(false);
    const [isAllFieldsFill, setAllFieldsFill] = useState(false);
    const [isImgLoading, setImgLoading] = useState(false);
    const [wasChanged, setChanged]            = useState(false);
    const [isSavingData, setSavingData]       = useState(false);
    const [localBlobVehicleFiles, setBlobVehicleFiles] = useState<(Blob|null)[]>([null, null, null]);
    const [localBlobSTSFiles, setBlobSTSFiles]   = useState<(Blob|null)[]>([null,null]);
    // console.log('edited_vehicle', temp.edited_vehicle.data);
    const [state, setState] = useState<VehicleInfo>(temp.edited_vehicle.data);
    let [characteristics, setCharacteristics] = useState<IBOCharacteristics[]>([]);
    let [selectedDriver, setSelectedDriver] = useState<string>( '');
    let [isShowQuestionWindow, setShowQuestionWindow] = useState<boolean>( false);

    const [photoFile, setPhotoFile] = useState<Blob|null>(null);
    const [isImageView, showImageView] = useState<string>('');
    const [selectedPass, setSelectedPass] = useState<string>( "");



    const closeWindow = () =>{
        dispatch(popSystemStatus());
    };

    const onDeleteVehicle = async ()   => {
        let newUserData : UserInfo = {...user.data};
        newUserData.cars = newUserData.cars.filter((x,index)=> index != temp.edited_vehicle.index);
        setSavingData(true);
        dispatch(setUserInfo(newUserData));
        await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
        setSavingData(false);
    }

    const onSaveData = async ()=> {
        if (isSavingData) return ;
        setError(false);

        if (!isValidFields()) {
            setError(true);
            return;
        }
       await saveDataAction();
    }

    const saveDataAction = async (save_by_time=false) =>{

        let newUserData : UserInfo = {...user.data};
        let car = {...state};
        console.log('car state', car);
        if (user.data?.multiple_car_owner == SINGLE_CAR_OWNER ){
            if (newUserData.drivers.length)
                car.drivers = [user.data.drivers[0]];
        }

        if (user.data?.multiple_car_owner == MULTIPLE_CARS_OWNER && selectedDriver != ""){
            let driver = user.data.drivers.find( (x, index) =>  index+'' == selectedDriver+'');
            if (driver)
                car.drivers = [driver];
        }

        if (temp.edited_vehicle.index == -1) {
            newUserData.cars.push(car);
            if(save_by_time) {
                temp.edited_vehicle.index = 0;
               // dispatch(setEditVehicleData( 0, temp.edited_vehicle.data));
            }
        }
        else
            newUserData.cars[temp.edited_vehicle.index] = car;

        dispatch(setUserInfo(newUserData));
        if(!save_by_time) {
            setSavingData(true);
            await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
        } else  {
            let res = await wait_before_update(3000);
            if (res) {
                setSavingData(true);
                await updateData(newUserData, dispatch);
            }
        }

        setSavingData(false);
    }

    const setVehiclePhotoFileName= async (value:Blob|null , index:number) => {
        let files = [...localBlobVehicleFiles];
        files[index] = value;
        setBlobVehicleFiles(files);

        if (!value) return ;
        let response = await sendPhoto(value , `${user.driver_id}-vehicle-${index}.jpg`);
        if (response) {
            while (state.files.length> 3) state.files.pop();
            state.files[index] = response.id;
            setState((s)=> ({...s,  files: state.files}))
        }
    };


    const setSTSPhotoFileName = async (value:Blob|null , index:number) => {
        let files : (Blob|null)[] = [...localBlobSTSFiles];
        files[index] = value;
        setBlobSTSFiles(files);
        if (!value) return ;
        let response = await sendPhoto(value , `${user.driver_id}-sts-${index}.jpg`);
        if (response) {
            while (state.certificate.files.length> 2) state.certificate.files.pop();
            state.certificate.files[index] = response.id;
            setState((s)=> ({...s, certificate: { ...state.certificate ,  files: state.certificate.files}}))
        }
    };

    const isValidFields = () : boolean =>{
        // console.log('isValidFields state', state);
        if (user.authenticated && state.car_type_id && state.body_option_id && state.certificate.number) return true;

        if (localBlobSTSFiles.some(x => x ==null) || localBlobVehicleFiles.some(x => x ==null) ) return false;
        console.log('aa');
        let val =  state.body_option_id !='' && state.car_type_id != '' && state.size.width !="" && state.size.height !="" && state.size.length !="" &&
                   state.certificate.certificate_number !=""  &&
                   state.certificate.certificate_serial !=""  &&
                   state.certificate.date !=""  &&
                   state.certificate.number !=""  &&
                   state.certificate.model !="" &&
                   state.certificate.free_weight !="" &&
                   state.certificate.max_weight !="" &&
                   state.certificate.brand !="" &&
                   state.certificate.VIN !="";
        return  val;
    };

    const onAddressChanged= (value:string | any, lon?:number, lat?:number) => {
        setState((s)=>({...s, adress : {...s.adress, adress: value, longitude:''+lon, latitude:''+lat}}));
    };

    // useEffect(()=>{
    //     //@ts-ignore
    //     Window.stated = state;
    //     //@ts-ignore
    //     Window.blob1 = localBlobPassportFiles;
    //     //@ts-ignore
    //     Window.blob2 = localBlobDriverFiles;
    //     setChanged(true);
    //
    // }, []);
    const onCharacteristicSelect = (ch: IBOCharacteristics, value : boolean|string) => {
        console.log('onCharacteristicSelect ch=%s , value=%s ', ch, value);
        if (!characteristics.some(x=> x.id == ch.id)) {
            characteristics.push(ch);
        } else
        characteristics.filter(x=> x.id === ch.id).forEach(x=> {
            if (ch.type === CharacteristicTypeEnum.Boolean && typeof value === "boolean") {
                x.selected = value;
            }
            if (ch.type === CharacteristicTypeEnum.Number && typeof value === "string") {
                x.selected = +value > 0;
            }

            if (ch.type === CharacteristicTypeEnum.Number && typeof value === "number") {
                x.selected = +value > 0;
            }

            if (ch.type === CharacteristicTypeEnum.Ref && typeof value === "string") {
                x.selected = value !== '';
            }
            ch.value = value;
        });
        let body_option_characteristics = characteristics.filter(x=>x.selected).map(x=>({id:x.id, value:x.value}));
        setState((s)=>({...s, body_option_characteristics : body_option_characteristics}));

        characteristics.sort( (a,b) =>  a.name < b.name ? 1: -1)
                       .sort( (a,b) =>  a.type < b.type ? 1: -1);

        setCharacteristics([...characteristics]);
        //setSelectedPass(getPassFromCharacteristics(characteristics));
    };



    const onSelectDriver = (value: DropDownListItem)   => {
        setSelectedDriver(value.id)
    }

    const getListDriver = () : DropDownListItem[] => {
        let dd : DropDownListItem[] = [];
        dd = user.data.drivers.length ? user.data.drivers.map((x,index)=> ({id: ''+index, text: x.passport.full_name })) : [];
        dd.push({id:"",  text: "выбрать потом"});
        return dd;
    }
    useEffect(()=>{
        // console.log('here 0', localBlobVehicleFiles, localBlobSTSFiles, state, state.certificate);
        //@ts-ignore
        Window.stated = state;
        //@ts-ignore
        Window.blob1 = localBlobVehicleFiles;
        //@ts-ignore
        Window.blob2 = localBlobSTSFiles;
        //console.log('useEffect');
        if (isFirstLoad.isTrue) {
            ( async () => {
                setImgLoading(true);
                let filesBlob = await loadPhotos(state.certificate.files);
                while (filesBlob.length> 2) filesBlob.pop();
                console.log('filesBlob.length', filesBlob.length);
                setBlobSTSFiles(filesBlob.length ? filesBlob : [null,null]);

                filesBlob = await loadPhotos(state.files);
                while (filesBlob.length> 3) filesBlob.pop();

                setBlobVehicleFiles(filesBlob.length ? filesBlob : [null,null,null]);
                setImgLoading(false)

            })();
            if (state.drivers.length) {
                let i = user.data.drivers.findIndex( x=> x.passport.full_name === state.drivers[0].passport.full_name);
                console.log('i=', i);
                if (i>=0) setSelectedDriver(i+'');
            }


        } else {
            setChanged(true);
            setAllFieldsFill(isValidFields());
            //console.log('useEffect ', isValidFields())

        }
        return () => {
             isFirstLoad.isTrue = false
        }
    }, [localBlobVehicleFiles, localBlobSTSFiles, state, state.certificate]);

    useEffect(()=>{
        console.log('here 1', state.car_type_id , state.body_option_id, wasError);
        let categoryList = filterCategory(system.categories, state.car_type_id, state.body_option_id );
        if (!categoryList.length)  return;

        let chs = getBOCharacteristics(categoryList, state.body_option_id);
        chs.forEach(x=> {
            let ch = state.body_option_characteristics.find(a=> a.id === x.id);
            if (!ch)  return;
            x.value = ch.value;
            x.selected = true;
        });
        chs.sort( (a,b)=> a.name >= b.name ? -1 :  1)
           .sort( (a,b)=> a.type >= b.type ? -1 :  1);

        state.body_option_characteristics.filter(a=> passList.some(p => p.id ==  a.id))
            .forEach( x=> {
                let ch :IBOCharacteristics = { id: x.id, name: "пропуск " + getPassName( x.id), type: CharacteristicTypeEnum.Boolean,
                    selected: isValueTrue(x.value), value:  isValueTrue(x.value), body_option_characteristics_values:[]};
                chs.push(ch);
            });
        setCharacteristics(chs);
        setSelectedPass(getPassFromCharacteristics(chs));

    },[state.car_type_id , state.body_option_id, wasError ]);

    useEffect(()=>{
        console.log('useEffect state', state);
        if (!isFirstLoadSaving.isTrue) {
            setChanged(true);
            if (!user.authenticated)
                saveDataAction(true);
        }
        return () => {
            isFirstLoadSaving.isTrue = false
        }
    }, [state]);

    const deleteFile = async () =>{

        let index = localBlobVehicleFiles.findIndex(x=> x==photoFile);
        console.log('index1', index)
        if (index >=0 ) {
            let tmp :any = [...state.files];
            console.log('tmp', tmp, tmp[0])
            let response = await registrationAPI.deleteFile(tmp[index]);
            console.log('response', response)
            if (response.status === 'error') return showMessageError('Ошибка удаления файла ' + response.error_message, dispatch );
            tmp[index] = '';
            setState((s)=> ({...s,  files: tmp}))
            tmp = [...localBlobVehicleFiles];
            tmp[index] = null;
            setBlobVehicleFiles(tmp);
        }

        index = localBlobSTSFiles.findIndex(x=> x==photoFile);
        console.log('index2', index)
        if (index >=0 ) {
            let tmp :any = [...state.certificate.files];
            let response = await registrationAPI.deleteFile(tmp[index]);
            console.log('response', response)
            if (response.status === 'error') return showMessageError('Ошибка удаления файла ' + response.error_message, dispatch );
            tmp[index] = '';
            setState((s)=> ({...s, certificate: { ...state.certificate ,  files: tmp}}));
            tmp = [...localBlobSTSFiles];
            tmp[index] = null;
            setBlobSTSFiles(tmp);
        }
        showImageView('');
        setPhotoFile(null);
    };

    // useEffect( ()=>{  }, [characteristics]);
    const  onSelectPass = (pass_id:string) =>{
        let prev_value = selectedPass;
        setSelectedPass(pass_id);
        console.log('onSelectPass pass_id=%s prev=%s', pass_id, prev_value);
        if (pass_id == prev_value) return;
        let ch :IBOCharacteristics = { id: pass_id, name: "пропуск " + getPassName(pass_id), type: CharacteristicTypeEnum.Boolean,
                                       selected: pass_id!="", value: pass_id!="", body_option_characteristics_values:[]
                                     };

        if (pass_id == "" || prev_value != "") {
            let vch = {...ch};
            vch.id = prev_value;
            if (prev_value!="")  onCharacteristicSelect(vch, false)
        }

        if (pass_id != "" && getPassName(pass_id)!="") {
            onCharacteristicSelect(ch, true);
        }

    };


    if (isImageView && photoFile)
        return(<ImageViewWindow  title={isImageView} onClose={()=>showImageView('')} onDelete={deleteFile} file={photoFile} />);
    return (
            <>
                 <div className="registration_driver_data_window">
                     <div className="common_form_window_title" onClick={closeWindow}>
                          <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                        <div className="page_title"> Данные ТС  </div>
                     </div>
                     <div>&nbsp;</div>
                     <div className="common_form_window_content ">
                         <div className="driver_input_container_many_items">
                             <InputSelectValue name={"Грузоподъемность"} list={getCategoriesList(system, state.body_option_id)}
                                               classAppend="short"
                                               wasError={wasError}
                                               isSelected={true}
                                               value={state.car_type_id}
                                               onSelect={(item) => setState({...state,car_type_id : item.id})}
                             />
                             <InputSelectValue name={"Тип кузова"}
                                               list={getBodyOptionsListIDs(system, state.car_type_id)}
                                               classAppend="short"
                                               wasError={wasError}
                                               isSelected={true}
                                               value={state.body_option_id}
                                               onSelect={(item) => setState({...state,body_option_id : item.id})}
                             />
                             <InputSelectValue      list={passList.map(x=> ({id:x.id, text: x.name}))}
                                                    key={"ch_passes"}
                                                    wasError={false}
                                                    classAppend={"short"}
                                                    name={'Пропуск'}
                                                    isSelected={selectedPass != ''}
                                                    value={selectedPass}
                                                    canEnterValue={false}
                                                    onSelect={(item) => onSelectPass(item.id)} />

                             {
                                 state.car_type_id && state.body_option_id &&
                                 characteristics.filter(x=> !passList.map(p=> p.id == x.id))
                                     .map(x=> {
                                             if ( x.type === CharacteristicTypeEnum.Boolean )
                                                 return  <InputCheckBoxValue  key={"ch_"+x.id}  isSet={x.selected}
                                                                              name={x.name}
                                                                              classAppend="short"
                                                                              onChange={() => {onCharacteristicSelect(x, !x.selected)}} />;
                                             if (x.type === CharacteristicTypeEnum.Ref) {
                                                 if (x.name == 'Тип пандуса') console.log('Тип пандуса',x);
                                                 return <InputSelectValue
                                                     list={getCharacteristicValueList(system.categories, x, state.body_option_id, state.car_type_id, x.name == 'Тип пандуса')}
                                                     key={"ch_" + x.id}
                                                     wasError={false}
                                                     classAppend={"short"}
                                                     name={x.name}
                                                     isSelected={x.selected}
                                                     value={x.value}
                                                     canEnterValue={x.name == 'Тип пандуса'}
                                                     all_values={system.driver_body_option_characteristics_values}
                                                     onSelect={(item) => onCharacteristicSelect(x, item.id)}/>;

                                             }
                                             if (x.type === "number")
                                                 return <InputSelectValue     list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}
                                                                               value={x.value}
                                                                               key={"ch_"+x.id}
                                                                               name={x.name}
                                                                              classAppend={"short"}
                                                                               wasError={false}
                                                                               isSelected={x.selected}
                                                                               onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                                         })

                             }
                             <InputFilteredValue name="Длина кузова*" placeholder="в метрах"
                                                 regexp={/[0-9,]{1,10}/} value={state.size.length}
                                                 onValueChanged={(value)=> setState({...state, size:{...state.size, length: ''+value}})}
                                                 subTitle="Пример 3,2"
                                                 classAppend="short"
                                                 maxLength={5}
                                                 type={'number'}
                                                  wasError={wasError} />

                             <InputFilteredValue name="Ширина кузова*" placeholder="в метрах"
                                                 regexp={/[0-9,]{1,10}/} value={state.size.width}
                                                 onValueChanged={(value)=> setState({...state, size:{...state.size, width: ''+value}})}
                                                 subTitle="Пример 1,9"
                                                 classAppend="short"
                                                 maxLength={5}
                                                 type={'number'}
                                                 wasError={wasError} />

                             <InputFilteredValue name="Высота кузова*" placeholder="в метрах"
                                                 regexp={/[0-9,]/} value={state.size.height}
                                                 subTitle="Пример 1,8"
                                                 classAppend="short"
                                                 maxLength={5}
                                                 onValueChanged={(value)=> setState({...state, size:{...state.size, height: ''+value}})}
                                                  wasError={wasError} />
                         </div>
                         <SelectVehicleAddress name={"Укажите место стоянки ТС (гаража)*"}
                                            value={state.adress.adress} onValueChanged={onAddressChanged}
                                            placeholder={"Россия, Москва, Московский проспект"}
                                            wasError={wasError}/>

                         <div className='sub_title'>Прикрепите фото ТС</div>
                         <div className="driver_input_container_many_items">
                             {["Кузов внутри", "ТС спереди", "ТС сбоку"].map( (x, index)=>
                                 <InputPhotoValue name={x}
                                                  key = {"input_photo"+index}
                                                  file={localBlobVehicleFiles[index]}
                                                  fileIndex={index}
                                                  settingIndex={5 + index}
                                                  is_loading={isImgLoading && !localBlobVehicleFiles[index]}
                                                  onValueChanged={(value,index) => setVehiclePhotoFileName(value, index)}
                                                  wasError={wasError}
                                                  onPhotoClick={()=>{showImageView(x); setPhotoFile(localBlobVehicleFiles[index])}}
                                 />

                             )}
                         </div>

                         <div className='sub_title'>Свидетельство о регистрации ТС (СТС)</div>
                         <div className="driver_input_container_many_items">
                                         <InputFilteredValue name="Гос. номер*" placeholder="А777ВС77"
                                                             regexp={/[0-9аАвВсСеЕнНкКмМоОрРтТхХуУ]/}
                                                             mask="x999xx99#"
                                                             formatChars={{'x': '[А-Яа-я]', '9': '[0-9]', '#': '[0-9]'}}
                                                             value={state.certificate.number}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, number: ''+value}})}
                                                             subTitle="Только цифры и русские буквы"
                                                             classAppend="short"
                                                             toUpperCase={true}
                                                             wasError={wasError}/>

                                         <InputFilteredValue name="VIN номер*" placeholder="введите VIN номер"
                                                             regexp={/[0-9aAbBcCdDeEfFgGhHjJkKlLmMnNoOpPrRsStTuUvVwWxXyYzZ]/}
                                                             mask="*****************"
                                                             value={state.certificate.VIN}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, VIN: ''+value}})}
                                                             subTitle="Только цифры и англ. буквы"
                                                             classAppend="short input_long_short_text"
                                                             toUpperCase={true}
                                                             wasError={wasError}/>

                                         <InputFilteredValue name="Марка ТС" placeholder="как в СТС"
                                                             value={state.certificate.brand}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, brand: ''+value}})}
                                                             classAppend="short"
                                                             wasError={wasError}
                                                             validator={validateBrand}
                                         />

                                         <InputFilteredValue name="Модель ТС" placeholder="как в СТС"
                                                             value={state.certificate.model}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, model: ''+value}})}
                                                             classAppend="short"
                                                             wasError={wasError}
                                                             validator={validateBrand}/>

                                         <InputFilteredValue name="Год выпуска" placeholder="ГГГГ"
                                                             mask={"2###"}
                                                             formatChars={{'#': '[0-9]'}}
                                                             value={state.certificate.date}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, date: ''+value}})}
                                                             classAppend="short"
                                                             wasError={wasError}
                                                             validator={validateYear2000}
                                                             />

                                         <InputFilteredValue name="Серия СТС" placeholder="23 XX"
                                                             mask="99 XX"
                                                             formatChars={{'9': '[0-9]', 'X': '[0-9A-Za-zА-Яа-я]'}}
                                                             value={state.certificate.certificate_serial}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, certificate_serial: ''+value}})}
                                                             classAppend="short"
                                                             toUpperCase={true}
                                                             wasError={wasError} />

                                         <InputFilteredValue name="Номер СТС" placeholder="172122"
                                                             mask="999999"
                                                             value={state.certificate.certificate_number}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, certificate_number: ''+value}})}
                                                             classAppend="short"
                                                             wasError={wasError} />

                                         <InputFilteredValue name="Разр. макс. масса" placeholder="как в СТС"
                                                             regexp={/^[0-9,]{1,10}$/}
                                                             value={state.certificate.max_weight}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, max_weight: ''+value}})}
                                                             classAppend="short"
                                                             maxLength={5}
                                                             type={'number'}
                                                             wasError={wasError} />
                                         <InputFilteredValue name="Снаряженная масса" placeholder="как в СТС"
                                                             regexp={/^[0-9,]{1,10}$/}
                                                             value={state.certificate.free_weight}
                                                             onValueChanged={(value)=> setState({...state, certificate:{...state.certificate, free_weight: ''+value}})}
                                                             classAppend="short"
                                                             maxLength={5}
                                                             type={'number'}
                                                             wasError={wasError} />

                                     </div>


                         <div className='sub_title'>Прикрепите фото СТС</div>
                         <div className={"driver_input_container_many_items " }>
                             {["Сторона 1", "Сторона 2"].map( (x, index)=>
                                 <InputPhotoValue name={x}
                                                  key = {"input_driver_photo"+index}
                                                  file={localBlobSTSFiles[index]}
                                                  fileIndex={index}
                                                  wasError={wasError}
                                                  settingIndex={8 + index}
                                                  is_loading={isImgLoading && !localBlobSTSFiles[index]}
                                                  onValueChanged={(value,index) => setSTSPhotoFileName(value, index)}
                                                  onPhotoClick={()=>{showImageView(x); setPhotoFile(localBlobSTSFiles[index])}}
                                 />

                             )}
                        </div>

                         {user.data.multiple_car_owner && <>
                             <div className='sub_title'>Выберите водителя</div>
                             <div className="driver_input_container_many_items">
                                 <InputSelectValue classAppend='input_filtered_full gray'
                                                   onSelect={onSelectDriver}
                                                   list={getListDriver()}
                                                   value={selectedDriver}
                                                   isSelected={true} name={""} wasError={false}/>
                             </div>
                         </>}
                         {( (user.data.multiple_car_owner && temp.edited_vehicle.index!=-1) || user.data.cars.length > 1 || true) && <div className='sub_title sub_title_delete' onClick={()=> setShowQuestionWindow(true)}>Удалить данное ТС</div>}
                         <ButtonSave onClickSave={onSaveData}  onClickBack={closeWindow} isSavingData={isSavingData} isActive={isValidFields()} />
                     </div>
                     {
                         isShowQuestionWindow &&
                             <QuestionWindow title={"Вы уверены, что хотите удалить выбранное ТС ?"}
                                             onYes={onDeleteVehicle}
                                             onNo={()=>setShowQuestionWindow(false)}/>
                     }
                 </div>
           </>
    );
};

export default VehicleRegistration;
