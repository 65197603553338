import React, {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";
import BackIcon from "../../App/svg/back-arrow.svg";
import {popSystemStatus, setSystemStatus} from "../../../redux/reducers/system/actions";
import {useDispatch, useSelector} from "react-redux";
import {
    DriverInfo,
    UserInfo,
    UserState,
    VehicleCertificate,
    VehicleInfo,
    VehicleSize
} from "../../../redux/reducers/user/@types";
import {RootState} from "../../../redux/store";
import {SystemState} from "../../../redux/reducers/system/@types";
import {setAuthenticated, setUserInfo, tryUpdateData, updateData} from "../../../redux/reducers/user/actions";

import {validateName} from "../../../api/validate";
import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import './MiddleRegistrationForm.css';
import {
    getBodyOptionsListIDs,
    getDriverCategoriesList
} from "../../../redux/reducers/system/system_functions";
import InputSelectValue from "../InputSelectValue/InputSelectValue";
import ButtonSave from "../ButtonSave/ButtonSave";
import {CheckPhoneState} from "../../../redux/reducers/check_phone/@types";
import {SystemStatus, UserStatus} from "../../../@types";
import {setCookie} from "../../../redux/reducers/system/cookies";

interface IProps {

}
const MiddleRegistrationForm: React.FunctionComponent<IProps> = ({} ) => {
    const  dispatch = useDispatch();
    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const phone : CheckPhoneState   = useSelector( (state: RootState)=> ({system:state.checkPhoneReducer})).system;

    const user_data = user.data;

    const [wasError, setError]                = useState(false);

    const [isAllFieldsFill, setAllFieldsFill] = useState(false);
    const [isSavingData, setSavingData]       = useState(false);
    const [bodyOptionId, setBodyOptionId]     = useState(user_data && user_data.cars && user_data.cars.length ? user_data.cars[0].body_option_id :  '');
    const [carTypeId, setCarTypeId]           = useState(user_data && user_data.cars && user_data.cars.length ? user_data.cars[0].car_type_id :'');
    const [fio, setFIO]       = useState(user_data && user_data.person ? user_data && user_data.person.full_name : '');
    const [bodySize, setBodySize]       = useState<VehicleSize>(user_data && user_data.cars && user_data.cars.length ? user_data.cars[0].size : {length:'', width:'', height:'' });

    const isValidFields = () => {
         return !(fio == '' || bodyOptionId=='' || carTypeId == '' || bodySize.length=='' || bodySize.width=='' || bodySize.height=='');
    }

    const saveDataAction = async (save_by_time=false) => {

        console.log('aaa',save_by_time);

        let newUserData : UserInfo = {...user.data};
        newUserData.person = {...newUserData.person, full_name: fio, phone_number: phone.phone};
        newUserData.passport = {...newUserData.passport, full_name: fio};
        newUserData.accept_agreement = true;

        let car : VehicleInfo = new VehicleInfo();
        car.body_option_id = bodyOptionId;
        car.car_type_id = carTypeId;
        car.size = bodySize;
        car.certificate = new VehicleCertificate();
        car.certificate.number = phone.phone;
        car.certificate.VIN = "70101110011";

        let driver  = new DriverInfo();
        driver.driver_certificate.full_name = fio;
        driver.passport.full_name = fio;
        driver.phone_number = phone.phone;
        car.drivers.push(driver);

        newUserData.cars = [car];
        newUserData.draft = false;

        dispatch(setUserInfo(newUserData));
        setSavingData(true);
        let response = await tryUpdateData(newUserData, dispatch, true, user.authenticated, system.statuses, false);
        setSavingData(false);
        if (response && response.id ) {
            setCookie("driver_id", response.driver_id, 100);
            newUserData.id = response.id;
            newUserData.driver_id = response.driver_id;
            dispatch(setAuthenticated({authenticated: true,
                driver_id: response.driver_id,
                full_name: fio,
                status: UserStatus.Working,
                data: newUserData,
                current_coordinates: user.current_coordinates,
                has_tracker:''
            }));
            dispatch(setSystemStatus(SystemStatus.RegistrationDownloadApplication))
        }
        // newUserData.recommendations = [...state];
        // dispatch(setUserInfo(newUserData));
        // if (!save_by_time) {
        //     setSavingData(true);
        //     await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
        //     setSavingData(false);
        // }
        // else {
        //     let res = await wait_before_update(3000);
        //     if (res) {
        //         setSavingData(true);
        //         await updateData(newUserData, dispatch);
        //         setSavingData(false);
        //     }
        // }
    }

    const onSaveData = async ()=> {
        if (isSavingData) return ;
        setError(false);

        if (!isValidFields()) {
            setError(true);
            return;
        }
        await saveDataAction();
    };

    const closeWindow = () =>{
        dispatch(popSystemStatus());
    };

    useEffect(()=>{
        //@ts-ignore
        window.state_middle = state;
    }, []);
    useEffect(()=>{
        setAllFieldsFill(isValidFields());
    }, [fio, bodyOptionId, bodySize, carTypeId]);


    return (
        <div className="registration_driver_data_window middle_registration_form">
            <div className="common_form_window_title" onClick={closeWindow}>
                <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                <div className="page_title"> Введите ваши данные  </div>

            </div>
            <div className="page_title page_title_2"> Для для дальнейшей работы <br/>пожалуйста заполните поля ниже.</div>

            <div className="common_form_window_content ">
                <InputFilteredValue name="ФИО*" placeholder="Иванов Иван Иванович"
                                    regexp={/[А-Яа-я -]/} value={fio}
                                    onValueChanged={ (value ) => setFIO( '' + value)}
                                    validator={validateName} wasError={wasError} />
                <div className="driver_input_container_many_items">
                    <InputSelectValue name={"Грузоподъемность"} list={getDriverCategoriesList(system)}
                                      classAppend="short"
                                      wasError={wasError}
                                      isSelected={true}
                                      value={carTypeId}
                                      onSelect={(item) => setCarTypeId( item.id )}
                    />
                    <InputSelectValue name={"Тип кузова"}
                                      list={getBodyOptionsListIDs(system, carTypeId,true)}
                                      classAppend="short"
                                      wasError={wasError}
                                      isSelected={true}
                                      value={bodyOptionId}
                                      onSelect={(item) => setBodyOptionId(  item.id )}
                    />
                </div>
                <div className="driver_input_container_many_items">

                    <InputFilteredValue name="Длина кузова*" placeholder="в метрах"
                                        regexp={/[0-9,]{1,10}/} value={bodySize.length}
                                        onValueChanged={(value)=> setBodySize({...bodySize,   length: ''+value})}
                                        subTitle="Пример 3,2"
                                        classAppend="short"
                                        maxLength={5}
                                        type={'number'}
                                        wasError={wasError} />

                    <InputFilteredValue name="Ширина кузова*" placeholder="в метрах"
                                        regexp={/[0-9,]{1,10}/} value={bodySize.width}
                                        onValueChanged={(value)=> setBodySize({...bodySize,    width: ''+value})}
                                        subTitle="Пример 1,9"
                                        classAppend="short"
                                        maxLength={5}
                                        type={'number'}
                                        wasError={wasError} />

                    <InputFilteredValue name="Высота кузова*" placeholder="в метрах"
                                        regexp={/[0-9,]/} value={bodySize.height}
                                        subTitle="Пример 1,8"
                                        classAppend="short"
                                        maxLength={5}
                                        onValueChanged={(value)=> setBodySize({...bodySize,    height: ''+value})}
                                        wasError={wasError} />
                </div>

                <ButtonSave onClickSave={onSaveData} onClickBack={closeWindow} isSavingData={isSavingData} isActive={isAllFieldsFill} />

            </div>
        </div>
    )

};

export default MiddleRegistrationForm;
