// import H from "@here/maps-api-for-javascript";
import {CoordinatePoint, MapPoint, RouteArchive, TypeLonLat} from "../../../redux/reducers/map/@types";
import {HereMarkerIconFixed, HereMarkerIconPulsar} from "./HereMarkerIconFixed";
import {map} from "./HereMap";
import  turf  from "turf"
import {HereMarkerBlue, HereMarkerBlueAndMessage} from "./HereMarkerIcon";

const  ROUTE_LINE = 'route_line';
const  ROUTE_DRIVER_LINE = 'route_driver_line';
export const  ROUTE_DRIVER_LINE_MEDIAN = 'route_driver_line_median';
export const  ROUTE_DRIVER_LINE_FULL = 'route_driver_line_full';
export const  ROUTE_DRIVER_LINE_REAL = 'route_driver_line_real';
const  ROUTE_ARCHIVE_LINE = 'route_archive_line';
export const  MARKER_1 = 'MARKER_1';
export const  MARKER_2 = 'MARKER_2';
export const  MARKER_3 = 'MARKER_3';
export const  MARKER_4 = 'MARKER_4';
export const  MARKER_PULSAR = 'MARKER_PULSAR';

export  const  setMapCenter= ( point:{lat:number,lng:number, zoom?:number}, map : H.Map) => {
    map.setCenter({lat: point.lat, lng: point.lng});
    map.setZoom(point.zoom? point.zoom : 14);
};

export function hereMarker(label:string , coordination:number[], index :number|string = 1, info : {arrivalDate?:string, departureDate?:string, address?: string, distance?:number, time?:number }, type = 0) {
    // let icon = (type === 0) ? HereMarkerIcon(label) : HereMarkerIconFixed(label, number);
     let icon =  HereMarkerIconFixed(label, index);
     if (type === 1  )
        icon =  HereMarkerBlue(label);
     if (type == 2)
         icon = info.address ? HereMarkerBlueAndMessage(label, info) :  HereMarkerBlue('');

    let labelParts = label.split('<br>');

    labelParts[1] = labelParts[1] === undefined ? '' : labelParts[1];


    let fontColor = labelParts[1].indexOf('локация') >= 0 ? '#888E99' : '#000000';

    // icon = (type === 0) ?
    //     icon.replace('{NUMBER}', '<div style="color: #888E99;">'+labelParts[0] + ' ' + index +
    //         '</div><div style="font-weight: 500; color: '+fontColor+'">' + labelParts[1]+'</div>') :
    //     icon.replace('{NUMBER}', labelParts[0]); // уберем номер из описания


    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(
                icon.replace('{NUMBER}',
                    labelParts[0] + ' ' + index + '<br>' + labelParts[1])
            ),
            data: {name: type ==0 ? MARKER_1 : MARKER_2, text :  getPointText(info, label)}
        });
    // @ts-ignore
    marker.draggable = true;
    // @ts-ignore
    marker.itemIndex = +index - 1;

    return marker;
}

export const createDomMarkerPulsar = (coordination:number[]) => {
    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
     , {
         // @ts-ignore
         volatility: true,
         icon: new H.map.DomIcon(  HereMarkerIconPulsar() ),
         data: {name: MARKER_PULSAR}
     });
    // @ts-ignore
    marker.draggable = false;
    return marker;
}

export const createDomMarker = (coordination:number[], index :number|string = 1, icon:string, marker_type: string, marker_text: string = '', draggable:boolean = false) => {
    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(  icon ),
            data: {name: marker_type, text :  marker_text}
        });
    // @ts-ignore
    marker.draggable = draggable;
    // @ts-ignore
    marker.itemIndex = +index;
    return marker;
}

export const removeMarkers = (map: H.Map) => {
    removeObjectFromMap(map, MARKER_1);
};

export const clearMap = (map: H.Map) => {
    removeObjectFromMap(map, MARKER_1);
    removeObjectFromMap(map, MARKER_2);
    removeObjectFromMap(map, MARKER_3);
    removeObjectFromMap(map, ROUTE_LINE);
};

export function getPointText(point:{arrivalDate?:string,departureDate?:string, address?: string  }, label:string) {
    let arrivalDate =  new Date(point.arrivalDate? point.arrivalDate: 'now');
    let departureDate = new Date(point.departureDate? point.departureDate: 'now');

    let arrivalTime = arrivalDate.getDate() ?
        arrivalDate.getHours().toString().padStart(2, '0') + ':'
        + arrivalDate.getMinutes().toString().padStart(2, '0')
        : '00:00';

    let departureTime = departureDate.getDate() ?
        departureDate.getHours().toString().padStart(2, '0') + ':'
        + departureDate.getMinutes().toString().padStart(2, '0')
        : '00:00';

    let address = point.address ? point.address : '';

    return '<b>' + label + '</b><br/>' + address
        + '<br/><br/>планируемое время<br/>прибытие ' + arrivalTime
        + '<br/>убытие ' + departureTime;
}

export  const  calculateRoute = (platform: H.service.Platform, waypoints: string[], map:H.Map, is_clear_routes:boolean=true  ) => {

    if (waypoints.length<2) return ;

    let router = platform.getRoutingService();

    var routeRequestParams : any = {
        representation: 'display',
        routeattributes : 'waypoints,summary,shape,legs',
        mode: "balanced;truck;traffic:enabled;",
        language:'ru-RU',
        truckRestrictionPenalty:'strict',
        trailersCount:1,
    };

    waypoints.forEach(
        (point,index)=> routeRequestParams['waypoint'+ index] = point
    );

    console.log(routeRequestParams);

    let wPoint :CoordinatePoint[]=[];
    waypoints.forEach( x=> {
        let arr = x.split(',').map(x=> +x);
        if (arr.length)
            wPoint.push( [arr[1], arr[0]]);
    });

    return new Promise( (resolve, reject) => {
        router.calculateRoute(
            routeRequestParams,
            (result :any) => {

                if (result && result.response && result.response.route ) {
                    let route = result.response.route[0];
                    let lineLonLat = addRouteShapeToMap(route.shape, map, is_clear_routes);
                }

                resolve(result);
            },
            (d) => {   resolve(null); }
        );
    } )
};


export function drawDriverRouteToMap(shape: any[], map:H.Map, type = ROUTE_DRIVER_LINE){
    if (type != ROUTE_DRIVER_LINE_FULL)
        removeObjectFromMap(map, type);
    // console.log('drawDriverRouteToMap shape=', shape)
    if (!shape || shape.length < 2 ) return;

    var lineString = new H.geo.LineString();
    shape.forEach( (x:any) => {
        lineString.pushPoint({lat: x[0], lng: x[1]})
    });

    let color = 'rgba(255, 0, 0, 0.7)';
    if (type == ROUTE_DRIVER_LINE) color = 'rgba(0, 255, 255, 0.8)';
    if (type == ROUTE_DRIVER_LINE_MEDIAN) color = 'rgba(128, 128, 255, 0.8)';
    if (type == ROUTE_DRIVER_LINE_FULL) color = 'rgba(99, 99, 99, 0.8)';
    let p =  new H.map.Polyline( lineString, { data:{name: type},
        style: {
            lineWidth: type == ROUTE_DRIVER_LINE ? 6 : 3,
            strokeColor: color
    }});
    map.addObject(p);
}


export function drawArchiveRouteToMap(route: RouteArchive[], map:H.Map){
    removeObjectFromMap(map, ROUTE_ARCHIVE_LINE);
    // console.log('drawDriverRouteToMap shape=', shape)
    if (!route || route.length < 2 ) return;

    var lineString = new H.geo.LineString();
    route.forEach( (x:RouteArchive) => {
        lineString.pushPoint({lat: x.lat, lng: x.lon})
    });

    let p =  new H.map.Polyline( lineString, { data:{name: ROUTE_ARCHIVE_LINE}, style: { lineWidth: 3, strokeColor: 'rgba(17,170,13,0.8)'}});
    map.addObject(p);
}


export function addRouteShapeToMap(shape: string[], map:H.Map, removeOldObjects = true,  lineWidth:number=3, color:string = 'rgba(72, 148, 224, 0.8)'){
    if (removeOldObjects)
        removeObjectFromMap(map, ROUTE_LINE);
    // console.log('shape', shape, removeOldObjects);
    if (!shape || !shape.length) return;
    var lineString = new H.geo.LineString();
    shape.forEach( (x:any) => {
        let points : any[] = x.split(',');
        lineString.pushPoint({lat: +points[0], lng: +points[1]})
    });

    let p =  new H.map.Polyline( lineString, { data:{name: ROUTE_LINE}, style: { lineWidth: lineWidth, strokeColor: color}});
    map.addObject(p);
    // map.getViewModel().setLookAtData({
    //     bounds: p.getBoundingBox()
    // });


}

export function centerMapBox(map: H.Map, route:[number[]]) {

        let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];
        // let rect = {top:route[0][1], left:route[0][0], right:route[route.length-1][1], bottom:route[route.length-1][0]}
        let rect = {bottom:route[0][1], right:route[0][0], left:route[route.length-1][1], top:route[route.length-1][0]}
        var lineString = new H.geo.LineString();
        route.forEach( (x:any) => {
            lineString.pushPoint({lat: +x[0], lng: +x[1]})
        });

        // route.forEach((x:number[])=> {
        //         x = x.reverse();
        //         if (rect.top == 0 || rect.top > x[1]) rect.top = x[1];
        //         if (rect.left == 0 || rect.left > x[0]) rect.left = x[0];
        //         if (rect.right == 0 || rect.right < x[0]) rect.right = x[0];
        //         if (rect.bottom == 0 || rect.bottom < x[1]) rect.bottom = x[1];
        //     }
        // );
        let bbox = lineString.getBoundingBox();
        console.log('rect', bbox.getTop(), bbox.getLeft(), bbox.getBottom(), bbox.getRight())
        //new H.geo.Rect(bbox.getTop(), bbox.getLeft(), bbox.getBottom(), bbox.getRight())
        let p =  new H.map.Polyline( lineString, { data:{name: ROUTE_LINE}, style: {  }});
        map.getViewModel().setLookAtData({
            bounds:  p.getBoundingBox()
        });
        setTimeout(()=>{
            let p = map.getViewModel().getLookAtData().position;
            let zoom = map.getZoom();

            if (p) p.lat -= 0.005;
            map.getViewModel().setLookAtData({
                position:  p,
                zoom : zoom -  zoom*0.06
            });

            // map.setZoom(zoom -  zoom*0.05 );
        }, 0)


}

export function centerPoint(map: H.Map, inx:number) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];
    listObj.filter( (x:any)=> x.data && (x.data.name === MARKER_1 || x.data.name === MARKER_2) ).
    forEach((x:any, index)=> {
      if (index == inx) {
          console.log('x =>',);
          map.setCenter( x.getGeometry());
      }
    })
}

export function removeObjectFromMap( map: H.Map, nameObject:string) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];

    let objects: H.map.Object[] = listObj.filter( (obj : H.map.Object) => {

        // @ts-ignore
        if (obj.data && obj.data.name === nameObject) return true;
        return  false;
    });
    if (objects && objects.length)
        map.removeObjects(objects);
}


export function drawWayOnMap(points: MapPoint[], map: H.Map, color = 'rgba(224, 148, 72, 0.8)') {

    var lineString = new H.geo.LineString();

    points.forEach( point => lineString.pushPoint({lat:point.lat, lng:point.lng}));

    map.addObject(new H.map.Polyline(
        lineString, { data:null, style: { lineWidth: 5, strokeColor: color }}
    ));
}


export const getDistanceP2P = (pointA: number[], pointB: number[]  ) => {
    if (!pointA || pointA.length < 2 || !pointB || pointB.length < 2)
        return 0;
    const from = turf.point( pointA  );
    const to = turf.point( pointB );
    let distance = turf.distance(from, to,   "metres" );
    return distance;
};


export const getNearestPointOnLine = (pointA: number[], line: [number[]] ) : [number[], number, number] => {
    let r = 100000000, i = 0;
    line.forEach((x,index)=> {
        let d = getDistanceP2P(pointA, x);
        if (r > d) {
            r = d;
            i=index;
        }
    })
    return [ line[i], r, i ];
}

export const getDistanceABLine = (pointA: number[], pointB: number[], line: [number[]]  ) => {
    const from = turf.point( pointA  );
    const to = turf.point( pointB);
    const way = turf.lineString( line );

    var sliced =  turf.lineSlice(from, to, way) ;
    let distance = turf.lineDistance(sliced,   "meters" );
    return distance;
};

export const getDistanceLine = (pointA: number[], line: [number[]], fromStart:boolean = false ) => {
    if (!pointA || pointA.length < 2 || !line || line.length < 2)
        return 0;

    const from = turf.point( pointA  );
    const way = turf.lineString( line );
    const to = turf.point( line[line.length-1] );
    var sliced = fromStart ? turf.lineSlice(from, to, way) : turf.lineSlice(from, to, way);
    let distance = turf.lineDistance(sliced,   "meters" );
    return distance;
};

// export const getBearing = (pointA: TypeLonLat,pointB: TypeLonLat) =>{
//     const start = turf.point( [pointA.lat, pointA.lon]  );
//     const end = turf.point( [pointB.lat, pointB.lon]  );
//     return turf.bearing(start, end)
// }

// Converts from degrees to radians.
function toRadians(degrees:number) {
    return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
function toDegrees(radians:number) {
    return radians * 180 / Math.PI;
}


export function getBearing(start: TypeLonLat, end: TypeLonLat){
    let startLat = toRadians(start.lat);
    let startLng = toRadians(start.lon);
    let destLat = toRadians(end.lat);
    let destLng = toRadians(end.lon);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}

export const getDistance = (latFrom: number, lonFrom: number, latTo: number, lonTo: number): number => {
    const R = 6371;
    const dLat = (latTo - latFrom) * (Math.PI/180);
    const dLon = (lonTo - lonFrom) * (Math.PI/180);
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(latFrom * (Math.PI/180)) * Math.cos(latTo * (Math.PI/180)) * Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c;

    return Math.round(d);
};


/**
 * Ease function
 * @param   {H.geo.IPoint} startCoord   start geo coordinate
 * @param   {H.geo.IPoint} endCoord     end geo coordinate
 * @param   number durationMs           duration of animation between start & end coordinates
 * @param   function onStep             callback executed each step
 * @param   function onStep             callback executed at the end
 */
export function ease(
    startCoord = {lat: 0, lng: 0},
    endCoord = {lat: 1, lng: 1},
    durationMs = 200,
    onStep = console.log,
    onComplete = function() {},
) {
    var raf = window.requestAnimationFrame || function(f) {window.setTimeout(f, 16)},
        stepCount = durationMs / 16,
        valueIncrementLat = (endCoord.lat - startCoord.lat) / stepCount,
        valueIncrementLng = (endCoord.lng - startCoord.lng) / stepCount,
        sinValueIncrement = Math.PI / stepCount,
        currentValueLat = startCoord.lat,
        currentValueLng = startCoord.lng,
        currentSinValue = 0;

    function step() {
        currentSinValue += sinValueIncrement;
        currentValueLat += valueIncrementLat * (Math.sin(currentSinValue) ** 2) * 2;
        currentValueLng += valueIncrementLng * (Math.sin(currentSinValue) ** 2) * 2;

        if (currentSinValue < Math.PI) {
            onStep({lat: currentValueLat, lng: currentValueLng});
            raf(step);
        } else {
            onStep(endCoord);
            onComplete();
        }
    }

    raf(step);
}
