import {to_coordinates_array, TypeLonLatExtended} from "../../../redux/reducers/map/@types";
import turf from "turf";
import {MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS} from "../../../deployment";
import {NearestPointData} from "./Navigator";

const COUNT_POINT: number = 15;
const X_ACCURACY_MAX_VALUE: number = 21;                // значение точность меньше которой приходят в 90% точные координаты
const X_ACCURACY_MAYBE_VALUE: number = X_ACCURACY_MAX_VALUE * 6;   // значение точности до которого приходят нормальные координаты
const O_CORRECTION_FACTOR: number = 0.2;                // поправочный коэффициент
const V_AVERAGE_SPEED_TRUCK: number = 99000/3600;       // средняя скорость движения газели по городу в м/с
const MG_DEVIATION_ROUTE: number = 25;          // допустимое отклонение GPS от маршрута в метрах
const MC_COUNT_DEVIATION_ROUTE: number = 9;    // допустимое отклонение GPS от маршрута количество раз подряд
const MAX_EMPTY_POINT_COUNT: number = 3;       // количество "пустых" точек до того как показывать пульсар
const GOOD_POINT_COUNT: number = 10;           // используется для расчета процента хороших точек за последние GOOD_POINT_COUNT


export class NavigatorController {

				private static accuracy_array: number[] = [];
				private static distance_array: number[] = [];
				private static angle_list: number[] = [];
				private static points: { point: TypeLonLatExtended, is_good:boolean }[] = [];

				private static empty_point_count: number = 0;
				private static count_deviation_points: number = 0;
				private static previous_time: number | undefined = undefined;
				private static previous_point: {lat:number, lon: number} | undefined = undefined;
				private	static route : number[][] = [];
				private	static last_index_point_on_route : number  = 0;

				static get_middle_quartile(arr:number[]) {
								// считаем межквартильные значения
								let q1 = Math.round((arr.length+1)/4);
								let q3 = Math.round((3*(arr.length+1))/4);
								let iqr = Math.abs(arr[q1] - arr[q3]);
								console.log(`get_middle_distance q1=${q1} q3=${q3} iqr=${iqr}`);
								return (arr.filter(x=> x < iqr).reduce((a, b) => a + b, 0)/arr.length).toFixed(2)
				}
				/***
					* Рассчитываем среднюю точность за последние N точек
					* @param a - аккуратность
					* @param n - количество точек для расчета
					*/
				static get_middle_accuracy(a:number, n:number){
								if (this.accuracy_array.length>=n) this.accuracy_array.shift()
								this.accuracy_array.push(a)
								this.accuracy_array.sort()
								// считаем межквартильные значения
								let q1 = Math.round((this.accuracy_array.length+1)/4);
								let q3 = Math.round((3*(this.accuracy_array.length+1))/4);
								let iqr = Math.abs(this.accuracy_array[q1] - this.accuracy_array[q3]);
								console.log(`get_middle_accuracy q1=${q1} q3=${q3} iqr=${iqr}`);
								return this.accuracy_array.filter(x=> x < iqr).reduce((a, b) => a + b, 0)/this.accuracy_array.length
 			}

				/***
					* Рассчитываем дистанцию до предыдущей точки
					* @param point - текущая точка
					*/
				static get_distance_to_previous_point(point:{lat:number, lon: number}){
								if (!this.previous_point) {
												this.previous_point = point;
												return 0.001;
								}

								let point_1 = turf.point(  [this.previous_point.lat, this.previous_point.lon]);
								let point_2 = turf.point([point.lat, point.lon]);
								let distance = turf.distance(point_1, point_2, 'meters') + 0.1;
								this.previous_point = point;
								return distance;
				}

				/***
					* Рассчитываем среднюю дистанцию за последние N точек
					* @param d - расстояние между точками
					* @param n - количество точек для расчета
					*/
				static get_middle_distance(d:number, n:number){
								if (this.distance_array.length>=n) this.distance_array.shift()
								this.distance_array.push(d)
								this.distance_array.sort()
								// считаем межквартильные значения
								let q1 = Math.round((this.distance_array.length+1)/4);
								let q3 = Math.round((3*(this.distance_array.length+1))/4);
								let iqr = Math.abs(this.distance_array[q1] - this.distance_array[q3]);
								console.log(`get_middle_distance q1=${q1} q3=${q3} iqr=${iqr}`);
								return this.distance_array.filter(x=> x < iqr).reduce((a, b) => a + b, 0)/this.distance_array.length
				}

				/***
					* Получаем разницу времени между текущей и предыдущей точкой
					* @param t - время текущей точки
					* @param just_lust - берем просто последнюю
					*/
				static get_time_difference (t:number, just_lust: boolean = false){
								let index = -1;
								for(let i = this.points.length - 1; i >= 0; i--)
												if (this.points[i].is_good || just_lust) {
																index = i; break
												}
								if (index < 0) return 1;
								let prev_time = this.points[index].point.date_utc;
							 return  prev_time ?  Math.abs(t - prev_time)/1000 : 1;
				}

				/***
					* Добавляем точку в массив
					* @param driver_point
					* @param is_good
					*/
				static append_point(driver_point: TypeLonLatExtended, is_good:boolean = true) {
								this.points.push({point: driver_point, is_good});
								return is_good;
				}
				static get_percent_good_points() {
								let tmp = this.points.length > GOOD_POINT_COUNT ? GOOD_POINT_COUNT : this.points.length;
								tmp =	( this.points.slice( -1*tmp).map(x=> Number(x.is_good) ).reduce((a,b)=> a+b, 0)/ tmp ) * 100;
								return tmp;
				}

				static	get_last_good_points( count:number, driver_point:TypeLonLatExtended) {
						return this.points.filter(x=> x.is_good && !(x.point.lat==driver_point.lat && x.point.lon==driver_point.lon))
																								.slice(-1 * count).map(x=> x.point)
			 }
				/***
					 * Функция проверки достаточно достоверности координат GPS
 					* @param driver_point - координаты которые пришли от GPS
					*/
				static is_good_accuracy(driver_point:TypeLonLatExtended) {
								let A = driver_point.accuracy ?? 1000; // Точность GPS
								let fAn = this.get_middle_accuracy(A, COUNT_POINT);
								return (A < X_ACCURACY_MAX_VALUE || A < fAn )
				}
				static is_good_distance(driver_point:TypeLonLatExtended) {
								let D = this.get_distance_to_previous_point(driver_point)
								let fDn = this.get_middle_distance(D, COUNT_POINT);
								let t = this.get_time_difference(driver_point.date_utc ?? 0)
							 let maxD = V_AVERAGE_SPEED_TRUCK * t;
								console.warn(`is_this_point_good D=${D.toFixed(2)}, t=${t.toFixed(2)},  fDn=${fDn}, maxD=${maxD}`);
								return ((D < V_AVERAGE_SPEED_TRUCK * t  || D < fDn ))
				}

				/***
					* Проверка показывать ли пульсар если длительное время не приходили данные с координатами
					* @param val - если есть любое значение то счетчик кол-ва пустых координат обнулится
					*/
				static is_show_pulsar(val: any = undefined){
								this.empty_point_count = val ? 0 : this.empty_point_count + 1;
								return this.empty_point_count > MAX_EMPTY_POINT_COUNT
				}

				static set_route(route:number[][]) {
								this.route = route;
								this.last_index_point_on_route = 0;
				}
				/**
					* Ищем ближайшую точку на маршруте по прямой и по маршруту
					* @param point - исходная точка GPS
					* @param radius - радиус поиска в метрах по маршруту по умолчанию 0 - не используется
					* @return [index_point, min_dist, route_distance]
					*           - index_point - индекс точки на маршруте
					*           - min_dist - мин. расстояние по прямой до точки
					*           - route_distance - расстояние по маршруту от последней точки до найденной
					*/
				static get_index_nearest_point_on_route(point: TypeLonLatExtended, radius: number = 0) : [number, number, number] | null  {
								let turf_point = turf.point(to_coordinates_array(point));
								radius = Math.round(radius);

								if (!this.route.length) return null;
								let from = (this.last_index_point_on_route - radius) < 0 ? 0 :this.last_index_point_on_route - radius;
								let to = (this.last_index_point_on_route + radius) > this.route.length  || radius == 0 ? this.route.length :this.last_index_point_on_route + radius;

								let min_dist = 1000000;
								let index_point =  0;
								for (let i = from; i < to ; i++) {
												let distance = turf.distance(turf_point, turf.point(this.route[i]), 'meters');
												if (distance > min_dist) continue
												min_dist = distance;
												index_point = i;
								}
								return [index_point, min_dist, index_point - this.last_index_point_on_route]
				}

				static get_index_point_by_distance(d:number){
								return this.last_index_point_on_route + d;
				}

				static set_index_point(i:number) { this.last_index_point_on_route = i;}

				/**
					* Возвращаем допустимый радиус поиска точки на маршруте в зависимости от прошедшего времени
					* с момента когда нашли предыдущую точку на маршруте
					* @param t - время в секундах от предыдущей точки
					*/
				static get_radius_search(t:number) {
								return V_AVERAGE_SPEED_TRUCK * t;
				}

				/**
					* Проверяем необходимо ли строить новый маршрут если количество точек отклонения
					* от маршрута достигло критического значения
					* @param v - если указан то обнуляем количество точек отклонения т.к. найдена точка на маршруте
					*/
				static is_need_new_route(v:any = undefined){
							this.count_deviation_points = v ? 0 : this.count_deviation_points + 1;
							return this.count_deviation_points > MC_COUNT_DEVIATION_ROUTE
				}

				static get_last_good_point(n = 1) {
							let x=	this.points.filter(x=>x.is_good);
							if (x.length > n) return x.slice(-1*n)[0];
							return undefined;
				}
				static get_angle(point: TypeLonLatExtended) {
							 let last_point : any = this.get_last_good_point(2);
								if (!last_point) return 0;
								var point1 = turf.point([point.lat, point.lon]);
								var point2 = turf.point([last_point.point.lat, last_point.point.lon]);
								var angle = turf.bearing(point1, point2);
								this.angle_list.push(angle)
								return angle.toFixed(2)
				}
				static get_median() {
								let arr = this.points.filter(x=> x.is_good).slice(-16);

								let xyz = arr.map(x=> {
												let lat = x.point.lat;
												let lon = x.point.lon;
												let X = Math.cos(lat) * Math.cos(lon)
												let Y = Math.cos(lat) * Math.sin(lon)
												let Z = Math.sin(lat)
												return [X,Y,Z]
								})
								let pnt = xyz.map((v,i)=> {
												if (i==0) return [arr[0].point.lat,arr[0].point.lon];
												let x=0,y=0,z=0;
												xyz.slice(0,i).forEach(vv=> {x+=vv[0]; y+=vv[1]; z += vv[2];});
												x = x/i; y=y/i; z=z/i;
												let lon = Math.atan2(y, x)
												let hyp = Math.sqrt(x * x + y * y)
												let lat = Math.atan2(z, hyp)
												return [arr[i].point.lat + lat, arr[i].point.lon + lon]
								})
								return pnt;
				}
				static get_middle_angle() {
								let angle_list = []
								let cnt = 16;
								let to = Math.round(cnt*0.3)
								let from = cnt - Math.round(cnt*0.3)
								let arr = this.points.filter(x=> x.is_good).slice(-1*cnt);
								for (let i =0; i <arr.length && i < to; i++)
										for (let j = from; j<arr.length; j++){
														var point1 = turf.point([arr[i].point.lat, arr[i].point.lon]);
														var point2 = turf.point([arr[j].point.lat, arr[j].point.lon]);
														var angle = turf.bearing(point1, point2);
														angle_list.push(angle)
										}
 								return this.get_middle_quartile(this.angle_list)
				}

				static  check_low_signal(last_point_count: number = 10, good_signal_time_in_sec = 1.5) : { is_lower: boolean, arr : string } {
								let points = this.points.slice(-1*last_point_count);
								let time_array = []
								for (let i=1; i< points.length; i++) {
												//@ts-ignore
												let delta = points[i].point.date_utc - points[i-1].point.date_utc;
												time_array.push(delta/1000)
								}
								if (!time_array.length) return  { is_lower: false, arr : '' };
								let middle = time_array.reduce( (a,b) => a + b ) / time_array.length

								let last_good_val_len = 3;
								let last_good_val = time_array.reverse().slice(last_good_val_len).filter(x => x < good_signal_time_in_sec).length
								// console.log('move_car check_low_signal', time_array.map(x=> x.toFixed(2)).join(','), middle, last_good_val_len, last_good_val);
								return {is_lower : !(middle < good_signal_time_in_sec || last_good_val == last_good_val_len), arr: time_array.map(x=> Math.round(x)).join(',')}

				}
}