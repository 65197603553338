import Overlay from "../../Elements/Overlay/Overlay";
import Button from "../../Elements/Button/Button";
import React from "react";
import "./AskAboutRouteForm.css"

interface IProps {
    pointNumber: number;
    onCancel:()=>void;
    onSelectHereRoute:()=>void;
    onSelectYandexRoute:()=>void;
}
const AskAboutRouteForm: React.FC<IProps> = ({ pointNumber, onCancel, onSelectHereRoute, onSelectYandexRoute}) => {
    return (
        <>
            <Overlay z_index={135} is_visible={true} onClick={onCancel}  />
            <div className="ask_about_route" >
                <div className="ask_about_route_title">
                    Показать маршрут движения <br/> до точки №{pointNumber}
                </div>
                <div className="ask_about_route_buttons">
                    <Button type="primary" size="middle" onClick={onSelectHereRoute}>на Карте</Button>
                    <Button type="error" size="middle" onClick={onSelectYandexRoute}>в Яндекс</Button>
                </div>
                <div className="ask_about_route_cancel">
                    <Button type="white" size="middle" onClick={onCancel}>Отмена</Button>
                </div>
            </div>
        </>
    )
}

export default AskAboutRouteForm;
