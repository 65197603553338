import {COrderPhoto, EnumStatusExecution, OrderFullInfo, OrdersState} from "../../../../redux/reducers/orders/@types";
import {connect, useDispatch, useSelector} from "react-redux";
import Button from "../../../Elements/Button/Button";
import {RootState} from "../../../../redux/store";
import "./PointCard.css";
import React, {useEffect, useState} from "react";
import CallSVG from "../../../App/svg/call.svg";
import WriteSVG from "../../../App/svg/write.svg";
import {ReactSVG} from "react-svg";
import {setSystemStatus, showMessageError} from "../../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../../@types";
import IconPhoto from "../../../App/svg/photo2.svg";
import {EnumPhotoType} from "../../OnPlacePlayWindow/OnPlacePlayWindow";
import {userAPI} from "../../../../api";
import {setOrderPhotos} from "../../../../redux/reducers/orders/actions";
import {UserState} from "../../../../redux/reducers/user/@types";
import MakePhotoPlayWindow from "../../MakePhotoPlayWindow/MakePhotoPlayWindow";
import ImageViewWindow from "../../../ImageViewWindow/ImageViewWindow";
import SchemaPlayWindow from "../../SchemaPlayWindow/SchemaPlayWindow";
import PhotoButtons from "../../PhotoButtons/PhotoButtons";
import {setChatVisible} from "../../../../redux/reducers/chat/chatActions";

interface ILocalProps {
    order: OrderFullInfo,
    orderPoint: number,
    currentPoint: number,
    onShowRoute: (index:number)=>void,
    onShowPointOnMap: (index:number)=>void,
    setOnPlace:Function,
    onTaskComplete:Function|undefined,
    onNextOrFinish:Function|undefined,
    showButtonOnPlace:boolean,
    showButtonTaskComplete:boolean,
    showButtonNextFinish:boolean,
}

const PointCard: React.FC<ILocalProps> = ({order,orderPoint, currentPoint,onShowRoute,onShowPointOnMap,  setOnPlace, onTaskComplete,onNextOrFinish, showButtonOnPlace, showButtonTaskComplete,showButtonNextFinish}) => {
    const dispatch = useDispatch();
    const [isFullMode, setFullMode] = useState<boolean>(false);
    const [isShowSchema, setShowSchema] = useState<boolean>(false);

    let is_next = (currentPoint+1) < order.routes.length;

    const toggleFullMode = () =>{
       setFullMode(!isFullMode)
    };
    const onCallClick = () => {
        dispatch(setSystemStatus(SystemStatus.Chat));
        dispatch(setChatVisible(true));
    }

    const onMessageClick = () => {
        dispatch(setSystemStatus(SystemStatus.Chat))
        dispatch(setChatVisible(true));
    }

    let class_line = "play_window_row play_window_row_line";
    const item = order.routes[orderPoint];
    if (orderPoint === order.routes.length-1)  class_line = "play_window_row play_window_row_line no_border";



    return (
        <>
            {isShowSchema && <SchemaPlayWindow order={order} onClose={()=>setShowSchema(false)} />}
            <div className={"point_card_block " + ( orderPoint == currentPoint ? "point_card_block__current" : '') + (orderPoint ==0 ? ' first_block' : '')}>
                <div className={"decoration-line " + (orderPoint < currentPoint ?  'decoration-line__left': '') +  ( (orderPoint+1) >= order.routes.length ?  'decoration-line__finish' : '') }/>
                <div className="play_window_row">
                    <div className="point_number">{orderPoint + 1}</div>
                    <div className="point_address">{item.adress}</div>
                </div>
                <div className={class_line}>
                    <Button type="grey" size="small" className="Button45"
                            onClick={()=> {  if (onShowPointOnMap) onShowPointOnMap(orderPoint)  }}>На карте
                    </Button>
                    <Button type="darkgrey" size="small" className="Button45"
                            onClick={()=> {if (onShowRoute) onShowRoute(orderPoint)}}>Маршрут
                    </Button>
                </div>
                <div className={class_line+ " center flex-column"}>
                    {item.adress_comment &&  <div className="order_info_comment">{item.adress_comment}</div>}
                    <div className="order_info_point_detail">
                    { isFullMode &&
                        <>
                        <div className="order_info_persons">
                            <div>
                                <div className="order_info_persons_title">Контактные лица:</div>
                                {item.contact_persons.map( (x,i)=> <div key={i+'cop'} className="order_info_one_person">{x.full_name ? x.full_name : 'ФИО не указано' }</div> )}
                            </div>
                        </div>
                        <div className="order_info_persons_buttons">
                            <div className='person_button first' onClick={()=>onCallClick()}><ReactSVG src={CallSVG} style={{marginRight:'10px', marginBottom: '-5px'}}/>Позвонить</div>
                            <div className='person_button' onClick={()=>onMessageClick()}><ReactSVG  src={WriteSVG} style={{marginRight:'10px', marginBottom: '-5px'}}/>Написать</div>
                        </div>
                        <div className="order_info_from"><span>От кого:</span><span className={"contact_name " + (item.company ? '' :' no-item')}>{item.company ? item.company : 'отсутсвует' }</span></div>
                        <div className="order_info_from"><span>К кому: </span><span className="contact_name no-item">отсутсвует</span></div>


                        <div className="order_info_task">
                            <div className="order_info_task_header">Задача</div>
                            <div className="order_info_task_comment">на точке быть вежливым</div>
                            <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                            {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                            {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                            {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                            {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}
                        </div>
                        </>
                    }
                        { ((orderPoint <= currentPoint && isFullMode) || (orderPoint == currentPoint)) && <PhotoButtons orderPoint={orderPoint}/>}

                        {orderPoint == currentPoint && (order.cargo.places.length + order.cargo.packages.length + order.cargo.pallets.length) > 0 &&
                            <div className="order_info_task_sm">
                                <Button type={  "white_blue" } size={"large"} onClick={() =>setShowSchema(true)}>Схема расположения груза</Button>
                            </div>
                        }

                        {orderPoint == currentPoint && showButtonOnPlace &&
                            <div >
                                <Button type={"primary"} size={"large"}
                                    onClick={() => { setOnPlace(EnumStatusExecution.AT_POINT)}}
                                >Я прибыл на место</Button>
                            </div>
                        }
                        {orderPoint == currentPoint && showButtonNextFinish &&
                            <div >
                                <Button type={"primary"} size={"large"}
                                    onClick={() => onNextOrFinish ? onNextOrFinish(is_next ?  EnumStatusExecution.LEFT_POINT :  EnumStatusExecution.FINISH , currentPoint-1) : ''}
                                >{is_next ? 'СЛЕДУЮЩАЯ ТОЧКА' : 'ЗАВЕРШИТЬ ЗАКАЗ'}</Button>
                            </div>
                        }

                        {orderPoint == currentPoint && showButtonTaskComplete &&
                            <Button type="primary" size="large" className="Button100"
                                    onClick={()=> {  if (onTaskComplete) onTaskComplete(EnumStatusExecution.LEFT_POINT, currentPoint) }}> {
                                        item.action_loading ? 'Погрузка выполнена':
                                        item.action_unloading ? 'Разгрузка выполнена':
                                        item.action_documents ? 'Документы получены':
                                       item.action_documents ? 'Экспедирование выполнено': 'Задача выполнена'
                            }
                            </Button>
                        }

                    <div className="link_show_point" onClick={()=> { toggleFullMode() }}>
                        { <span>{ isFullMode ? 'Скрыть детали' : 'Показать детали' }</span>}
                    </div>
                    </div>
                </div>

            </div>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    state: {
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, { })(PointCard);
