import {
    OwnerSelectState,
    SELECT_OWNER_TYPE,
    SET_TIN_ENTERED,
    SET_AGREE,
    SET_TIN_VERIFYING,
    OwnerSelectActionTypes, TIN_IP
} from "./@types";

let initialState: OwnerSelectState = {
	owner_type_selected: false,
  multiple_car_owner: false,
  tin_entered: false,
  type: TIN_IP,
  tin: '',
  agree: false,
  tin_verifying: false
}

const ownerSelectReducer = (state = initialState, action: OwnerSelectActionTypes) => {
  switch (action.type) {
    case SELECT_OWNER_TYPE:
      return {
        ...state,
        owner_type_selected: action.owner_type_selected,
          multiple_car_owner: action.multiple_car_owner
      }
    case SET_TIN_ENTERED:
      return {
        ...state,
        tin_entered: action.tin_entered,
        type: action.tin_type,
        tin: action.tin
      }
    case SET_AGREE:
    	return {
    		...state,
    		agree: action.agree
    	}
		case SET_TIN_VERIFYING:
    	return {
    		...state,
    		tin_verifying: action.tin_verifying
    	}
    default:
      return state;
  }
}

export default ownerSelectReducer;
