import Button from "../../Elements/Button/Button";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import "./FullPlayWindow.css"

import {EnumStatusExecution, OrderFullInfo} from "../../../redux/reducers/orders/@types";
import Overlay from "../../Elements/Overlay/Overlay";
import PointCard from "./PointCard/PointCard";
import moment from "moment";
import TouchWorker from "../../TouchWorker/TouchWorker";
import MakePhotoPlayWindow from "../MakePhotoPlayWindow/MakePhotoPlayWindow";
import ImageViewWindow from "../../ImageViewWindow/ImageViewWindow";
import SchemaPlayWindow from "../SchemaPlayWindow/SchemaPlayWindow";
import {EnumPhotoType} from "../OnPlacePlayWindow/OnPlacePlayWindow";
import {setSystemStatus} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import {setChatVisible} from "../../../redux/reducers/chat/chatActions";

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onShowRoute:(index:number)=> void,
    onShowPointOnMap:(index:number)=> void,
    onFullMode:Function,
    setOnPlace:Function | undefined,
    onTaskComplete:Function | undefined,
    onNextOrFinish:Function | undefined,
    zIndex?:number,
}

const FullPlayWindow: React.FC<ILocalProps> = ({order, currentPoint,onShowRoute,onShowPointOnMap,onFullMode,setOnPlace, onTaskComplete, onNextOrFinish, zIndex }) => {
    const dispatch = useDispatch();
    const setMinSize = (val: boolean) => {
        if (val && onFullMode) onFullMode();
    }
    const touch = new TouchWorker(setMinSize);
    const getDate = () => {
        let arr = order.date.split('T')
        let order_date =arr.length > 0 ? moment(new Date(arr[0])).format('DD.MM.YY') : '';
        let time_1 = arr.length > 1 ? moment(new Date(arr[0] + 'T' + arr[1])).format('hh:mm') : '';
        let time_2 = arr.length > 2 ? moment(new Date(arr[0] + 'T' + arr[2])).format('hh:mm') : '';
        return <span className="full_window__datetime">{time_1}{time_2 ? '-' + time_2 : ''} &nbsp;&nbsp;{order_date}</span>;
    };



    return (
        <>
            <div className="play_window full_window play_window_absolute" style={zIndex ? {zIndex:zIndex} : {}}

            >
                {order.routes.map((p,i)=>
                    <PointCard  order={order} currentPoint={currentPoint} orderPoint={i}
                                onShowRoute={onShowRoute} onShowPointOnMap={onShowPointOnMap}
                                showButtonOnPlace={setOnPlace !== undefined}
                                showButtonTaskComplete={onTaskComplete !== undefined}
                                showButtonNextFinish={onNextOrFinish !== undefined}
                                setOnPlace={(status:EnumStatusExecution)=> {
                                    if (setOnPlace) setOnPlace(status, currentPoint)
                                }}
                                onTaskComplete={onTaskComplete}
                                onNextOrFinish={onNextOrFinish}
                                key={"point_card_"+i} />)}

                <div className="order_play_info">
                    <div className="order_play_info_number">Заказ №{order.number}</div>
                    <div className="order_play_info_time">{getDate()}</div>
                    <div  className="order_play_info_price_title">Стоимость</div>
                    <div  className="order_play_info_price_value">{order.amount} ₽</div>
                    <div><Button type="transparent" onClick={()=> {if (onFullMode) onFullMode()} }
                                 className="Button100">Скрыть подробности заказа</Button> </div>
                    {/*<div className="bottom_window_problem_link">Сообщить о проблеме</div>*/}
                    <div className="bottom_window_problem_link" onClick={()=>dispatch(setChatVisible(true))}>Сообщить о проблеме</div>
                    {/*<div className="driver_status_window_info">Вы можете прервать работу при<br />возникновении поломок автомобиля или других непреодолимых обстоятельствах</div>*/}
                    <div>&nbsp;</div>
                </div>
            </div>
            <Overlay is_visible={true} zIndex={zIndex ? zIndex-1 : 30} onClick={onFullMode}/>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    state: {
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, { })(FullPlayWindow);
