import Overlay from "../../Elements/Overlay/Overlay";

import React from "react";
import "./YandexRouteForm.css"
import {TypeLonLat} from "../../../redux/reducers/map/@types";


interface IProps {
    point1:TypeLonLat;
    point2:TypeLonLat;
    onClose:()=>void;

}
//
const YandexRouteForm: React.FC<IProps> = ({ point1, point2, onClose}) => {
    console.log(`https://yandex.ru/map-widget/v1/?ll=30.404874%2C59.873790&mode=routes&rtext=${point1.lat}%2C${point1.lon}~${point2.lat}%2C${point2.lon}&rtt=auto&ruri=~&source=serp_navig&z=13`);
    return (
        <>
            <Overlay z_index={137} is_visible={true} onClick={onClose}  />
            <div className="yandex_route_form" >

                <iframe src={`https://yandex.ru/map-widget/v1/?ll=30.404874%2C59.873790&mode=routes&rtext=${point1.lat}%2C${point1.lon}~${point2.lat}%2C${point2.lon}&rtt=auto&ruri=~&tilt=0.8726646259971648&z=17`}
                        width="100%"
                        height="100%"
                        frameBorder="0"  />
                <div className="close_button"  onClick={onClose}>ЗАКРЫТЬ</div>
            </div>
        </>
    )
}

export default YandexRouteForm;
