import Button from "../../Elements/Button/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./OnPlacePlayWindow.css"
import CheckSVG from "../../App/svg/round-check.svg"
import {COrderPhoto, EnumStatusExecution, OrderFullInfo, OrdersState} from "../../../redux/reducers/orders/@types";
import {RootState} from "../../../redux/store";
import {MapDataState} from "../../../redux/reducers/map/@types";
import moment, {Moment} from "moment";
import {ReactSVG} from "react-svg";
import CallSVG from "../../App/svg/call.svg";
import WriteSVG from "../../App/svg/write.svg";
import {setSystemStatus, showMessageError} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import SchemaPlayWindow from "../SchemaPlayWindow/SchemaPlayWindow";
import IconPhoto from "../../App/svg/photo2.svg"
import MakePhotoPlayWindow from "../MakePhotoPlayWindow/MakePhotoPlayWindow";
import ImageViewWindow from "../../ImageViewWindow/ImageViewWindow";
import {Overlay} from "../../Elements/elements";
import {registrationAPI, userAPI} from "../../../api";
import {apiDriverUrl} from "../../../deployment";
import {UserState} from "../../../redux/reducers/user/@types";
import {setOrderPhotos} from "../../../redux/reducers/orders/actions";
import PhotoButtons from "../PhotoButtons/PhotoButtons";
import {setChatVisible} from "../../../redux/reducers/chat/chatActions";

export enum EnumPhotoType {
    ORDER_CARGO_PHOTO = 'order_cargo_photo', ORDER_DOCUMENTS_PHOTO ='order_documents_photo', UNKNOWN=''
}

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onGood?:Function,
    onNextOrFinish:Function | undefined,
    is_finish?:boolean
    is_next?:boolean
    zIndex?:number,
}

const OnPlacePlayWindow: React.FC<ILocalProps> = ({order, currentPoint, onGood,onNextOrFinish,is_finish, is_next,zIndex}) => {

    const dispatch = useDispatch();
    const map : MapDataState = useSelector((state: RootState) => (  state.mapReducer ));
    const user : UserState = useSelector((state: RootState) => ({user: state.userReducer})).user;
    const orders : OrdersState = useSelector((state: RootState) => ({orders: state.ordersReducer})).orders;
    const [photoFiles, setPhotoFiles] = useState<COrderPhoto[]>(orders.photos.filter(x=> x.point_number == currentPoint));

    if (onNextOrFinish)
        currentPoint = currentPoint +1 < order.routes.length  ? currentPoint +1 : currentPoint;
    const [isOrderPhotoShow, setOrderPhotoShow] = useState<EnumPhotoType>(EnumPhotoType.UNKNOWN);
    const [isShowSchema, setShowSchema] = useState<boolean>(false);
    const [currentImageView, showImageView] = useState<{id:string, blob:Blob}|undefined>(undefined);

    const item = order.routes[currentPoint];


    const getTime = () => {
        let arrival_traffic_time : Moment = map.route &&  currentPoint <  map.route?.points.length && map.route?.points[currentPoint].arrival_traffic_time ? moment(new Date(map.route?.points[currentPoint].arrival_traffic_time )): moment(new Date());
        let departure_traffic_time : Moment = map.route &&  currentPoint <  map.route?.points.length && map.route?.points[currentPoint].departure_traffic_time  ? moment(new Date(map.route?.points[currentPoint].departure_traffic_time )): moment(new Date()).add(45, "minutes");
        return arrival_traffic_time.format("hh:mm") + ' - ' + departure_traffic_time.format("hh:mm")
    }

    const onCallClick = () => {
        dispatch(setSystemStatus(SystemStatus.Chat))
        dispatch(setChatVisible(true));
    }

    const savePhoto = async (blob:Blob) => {
        console.log('savePhoto');
        if (blob) {
            let res = await userAPI.setOrderPhoto(order.id, user.data.id, currentPoint, isOrderPhotoShow, blob).catch( e=> showMessageError('Ошибка сохранения фото<br>' + e.message, dispatch));
            if (!res) return;
            if (res.data.status !== 'success') return showMessageError('Ошибка сохранения фото<br>' + res.data.error_message, dispatch);

            dispatch(setOrderPhotos([...orders.photos, new COrderPhoto(res.data.id, currentPoint, blob)]))
        }
        setOrderPhotoShow(EnumPhotoType.UNKNOWN)
    };

    useEffect(()=>{
        setPhotoFiles(orders.photos.filter(x=> x.point_number == (onNextOrFinish ? currentPoint-1 : currentPoint)));
    }, [orders.photos]);
    const onMessageClick = () => {
        dispatch(setSystemStatus(SystemStatus.Chat))
        dispatch(setChatVisible(true));
    };

    const deleteFile = async () =>{
        if (!currentImageView) return ;
        let res = await userAPI.deleteOrderPhoto(  user.data.id, currentImageView.id).catch(  e=>  showMessageError('Ошибка удаления фото<br>' + e.message, dispatch));
        if (!res) return ;

        dispatch(setOrderPhotos([...orders.photos.filter(x=>x.id!=currentImageView.id)]));
        showImageView(undefined);
    };

    if (currentImageView && photoFiles.length) return(<ImageViewWindow  title={"Фото кузова с грузом"} onClose={()=>showImageView(undefined)} onDelete={deleteFile} file={currentImageView.blob} />);
    if (isOrderPhotoShow) return(<MakePhotoPlayWindow  onClose={()=>setOrderPhotoShow(EnumPhotoType.UNKNOWN)} onSave={savePhoto} />);
    if (isShowSchema) return(<SchemaPlayWindow order={order} onClose={()=>setShowSchema(false)} />);
    return (
        <>
            <div className="play_window full_window on_place_window" style={zIndex ? {zIndex:zIndex} : {}}>
                <div className="order_play_info" style={ {borderTop: "unset"} }>
                    <div className="order_play_info_on_place_title">На точке</div>
                    <div className="order_play_info_on_place_time">{getTime()} &nbsp;&nbsp;&nbsp; <span  className="order_info_datetime-words">Прибытие - убытие</span></div>
                    {item.adress_comment &&  <div className="order_info_comment">{item.adress_comment}</div>}
                    <div className="order_info_point_detail">
                            <div className="order_info_persons">
                                <div className="order_info_persons_title">Контактные лица:</div>
                                {item.contact_persons.map( (x,j)=> <div key={j+'cop0'}>
                                    <div key={j+'cop1'} className="order_info_one_person">{x.full_name ? x.full_name : '' }</div>
                                    <div key={j+'cop2'} className="order_info_one_person">
                                        {x.phone ? x.phone : '' } {x.phone_ext ? ' доб. ' + x.phone_ext : '' }
                                    </div>
                                </div>)}
                                {item.contact_persons.length === 0  || !item.contact_persons.some(x=> x.full_name || x.phone)? <div className="no_persons">нет данных</div> : ''}
                            </div>
                            <div className="order_info_persons_buttons">
                                <div className='person_button first' onClick={()=>onCallClick()}><ReactSVG src={CallSVG} style={{marginRight:'10px', marginBottom: '-5px'}}/>Позвонить</div>
                                <div className='person_button' onClick={()=>onMessageClick()}><ReactSVG  src={WriteSVG} style={{marginRight:'10px', marginBottom: '-5px'}}/>Написать</div>
                            </div>
                            <div className="order_info_from"><span>От кого:</span><span className={"contact_name " + (item.company ? '' :' no-item')}>{item.company ? item.company : 'отсутсвует' }</span></div>
                            <div className="order_info_from"><span>К кому: </span><span className="contact_name no-item">отсутсвует</span></div>
                            <div className="order_info_task">
                                <div className="order_info_task_header">Задача</div>
                                <div className="order_info_task_comment">на точке быть вежливым</div>

                                {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                                {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                                {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                                {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}

                                <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                            </div>
                        {(order.cargo.places.length + order.cargo.packages.length + order.cargo.pallets.length) > 0 &&
                            <div className="order_info_task">
                                <Button type={ (is_next || is_finish) ? "gray_blue" : "primary"} size={"large"} onClick={() =>setShowSchema(true)}>Схема расположения груза</Button>
                            </div>
                        }
                        {/*item.action_cargo_photo && !is_next && !is_finish && photoFiles.length == 0 &&*/}
                        {/*    <div className="order_info_task">*/}
                        {/*        <Button type={"primary"} size={"large"} onClick={() =>setOrderPhotoShow(EnumPhotoType.ORDER_CARGO_PHOTO)}>*/}
                        {/*            Добавить фотографии груза*/}
                        {/*            <ReactSVG src={IconPhoto}/>*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}

                        {/*    <div className="order_info_task">*/}
                        {/*        <Button type={"green"} size={"large"} onClick={() =>setOrderPhotoShow(EnumPhotoType.ORDER_DOCUMENTS_PHOTO)}>*/}
                        {/*            Добавить фото документов*/}
                        {/*            <ReactSVG src={IconPhoto}/>*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*{photoFiles.length > 0 &&*/}
                        {/*    <div className="on_place_window__photo">*/}
                        {/*        {photoFiles.map(x=> <img className="photo_button_img" src={URL.createObjectURL(x.blob)} onClick={() => showImageView(x)}/>)}*/}
                        {/*    </div>*/}
                        <PhotoButtons orderPoint={currentPoint}/>

                    </div>
                    {!is_next && !is_finish &&
                        <div style={{paddingTop:'40px'}}>
                            <Button type={"grey"} size={"large"} onClick={() => { if (onGood) onGood() }} >Хорошо</Button>
                        </div>
                    }
                    { (is_next || is_finish) &&
                        <div style={{paddingTop:'40px', paddingBottom:'20px'}}>
                            <Button type={"primary"} size={"large"} icon={CheckSVG}
                                    onClick={() => {
                                        console.log('is_next', is_next, onNextOrFinish)
                                        if (onNextOrFinish) onNextOrFinish( is_next ?  EnumStatusExecution.LEFT_POINT :  EnumStatusExecution.FINISH , currentPoint-1) }
                                    } >
                                    {is_next ? 'СЛЕДУЮЩАЯ ТОЧКА' : 'ЗАВЕРШИТЬ ЗАКАЗ'}</Button>
                            <div className="driver_status_window_problem"  onClick={()=>dispatch(setChatVisible(true))}>Сообщить о проблеме</div>
                        </div>
                    }


                </div>
            </div>
            <Overlay zIndex={zIndex ? zIndex-1: 30} is_visible={true} onClick={()=>{}}/>
        </>
    );
};


export default OnPlacePlayWindow ;
