import React, {FC, useEffect, useState} from "react";
import './TestRouter.css'
import {useDispatch, useSelector} from "react-redux";
import {
    clearSystemStatus,
    setPlayMode,
    setSystemStatus,
    showMessageError
} from "../../../redux/reducers/system/actions";
import {setArchiveRoute, setDriverPosition, setIndexNextPoint, setRoutes} from "../../../redux/reducers/map/actions";
import {SystemStatus} from "../../../@types";
import {getRouteMutation, ordersAPI} from "../../../api";
import {RouteInfo} from "../../../redux/reducers/map/@types";
import turf from "turf";
import {NAVIGATION_GET_COORDINATE_TIME_IN_MS} from "../../../deployment";
import {RootState} from "../../../redux/store";

var interval :any = undefined;
var currentIndex :number = 0;
const TestRouter: FC = ({}) => {
    const dispatch = useDispatch();
    // const order_id= "080768d3-d0eb-11ec-8eb3-00155d010f1b";
    // const order_id= "4bb079d1-d20e-11ec-8eb3-00155d010f1b";
    const order_id= "34754435-cc8d-11ec-8eb3-00155d010f1b";
    // const order_id= "0fdcc20b-db3f-11ec-8eb3-00155d010f1b";
    let mapReducer = useSelector((state: RootState) => state.mapReducer);

    const [isPressed, setPressed] = useState<boolean>(false);
    const [routeHere, setRouteHere] = useState< RouteInfo | null>(null);
    const [routeDetailHere, setRouteDetailHere] = useState< number[][]  >([]);



    /**
     * Функция расширения точек на маршруте до дистанции 1 метр
     * @param routeInfo - описание маршрута RouteInfo
     * @return route - описание маршрута RouteInfo с расширенным координатным набором
     */
    const extendsPoints = (routeInfo:RouteInfo) =>{

        setRouteDetailHere(routeInfo.shapes[0].map((x:string)=> [+x.split(',')[0], +x.split(',')[1]]));
        return dispatch(setRoutes(routeInfo));

        let route = routeInfo.shapes[0].map((x:string)=> [+x.split(',')[0], +x.split(',')[1]]);
        let detailRoute : number[][] = [];
        var line = turf.lineString(route);
        var length = turf.lineDistance(line,   'meters' );
        for (let i=0; i < length;i++){
            let along = turf.along(line, i, 'meters');

            detailRoute.push( along.geometry.coordinates );
        }
        console.log('extendsPoints', detailRoute);
        console.log('extendsPoints', routeInfo);
        setRouteDetailHere(detailRoute);
        //@ts-ignore
        routeInfo.shapes[0] = detailRoute.map(x=> ( x[0]+","+ x[1]));
        dispatch(setRoutes(routeInfo));
    }

    useEffect(()=>{
        getRouteMutation(order_id).then((res)=>{
            if (!res || !res.data) return showMessageError("Не найден маршрут для заказа !", dispatch);
            setRouteHere(  res.data )
            dispatch(setRoutes(res.data));
            extendsPoints(res.data)
        })

        ordersAPI.getArchiveRoute(order_id).then((res)=>{
            if (!res || !res.data) return;
            dispatch(setArchiveRoute(res.data));
        })

    },[]);
    useEffect(()=>{
        // dispatch(clearSystemStatus());
        dispatch(setIndexNextPoint(1));
        // dispatch(setSystemStatus(SystemStatus.PlayWindow));
        dispatch(setPlayMode(isPressed))
        if (interval) clearInterval(interval);
        if (isPressed) {
            let startIndex = 0;
            for (let i = 0; i< mapReducer.archive_route.length;i++ ) {
                let distance = turf.distance(turf.point([mapReducer.archive_route[i].lat,mapReducer.archive_route[i].lon]), turf.point(routeDetailHere[0]), 'meters')
                if (distance < 20) { startIndex = i; break; }
                // console.log(distance)
            }
            currentIndex = startIndex;
            interval = setInterval(()=>{
                // dispatch(setDriverPosition( mapReducer.archive_route[currentIndex] ));
                currentIndex++;
                // console.log(mapReducer.archive_route[currentIndex])
            }, NAVIGATION_GET_COORDINATE_TIME_IN_MS)
        }

    },[isPressed]);

    return(
           <>
                <button className={'startEmmitButton ' + (isPressed ? 'pressed' : '')} onClick={()=>setPressed(!isPressed)}>{isPressed ? 'Останов' : 'Запуск'}</button>
           </>
          )
}


export default TestRouter;
