export enum UserStatus {
  Working = "Готов",
  DayOff = "Отказался",
  Unauthenticated = "unauthenticated",
  ChatUser = "is_chat_user",
}

export enum OrdersListType {
  Free = "free",
  Set = "set",
  Offer = "offer",
  All = "all",
  Last= "last",
  OnComing= "oncomming",
}

export enum RegistrationStatus {
	CheckPhone = 'check_phone',
	OwnerSelect = 'owner_select',
	BankData = 'bank_data',
	PassportData = 'passport_data',
	UpdatePassportData = 'update_passport_data',
	LicenseData = 'license_data',
	Vehicle = 'vehicle',
	UpdateVehicle = 'update_vehicle',
	Finalize = 'finalize',
	QuickRegistration = 'quick_registration',
	CommonForm = 'common_form'
}

export enum SystemStatus {
	Main = 'main_screen',
	Registration = 'registration',
	RegistrationMiddleForm = 'registration_middle_form',
	RegistrationDownloadApplication = 'registration_download_application',
	RegistrationOwnerSelect = 'registration_owner_select',
	RegistrationCommonForm = 'registration_common_form',
	RegistrationBankData = 'registration_bank',
	RegistrationDriverData = 'registration_driver',
	RegistrationVehicleData = 'registration_vehicle',
	RegistrationRecommendationData = 'registration_recommendation',
	Profile = 'profile',
	Help = 'help',
	Statistic = 'statistic',
	Partnership = 'partnership',
	OrderBook = 'order_book',
	Menu = 'show_menu',
	Overlay = 'overlay',
	FullOrderCard = 'full_order_card',
	SmallOrderCard = 'small_order_card',
	DriverStatusWindow = 'driver_status_window',
	DatePickerWindow = 'date_picker_window',
	CloseRoute = 'close_route',
	WaitingWindow = 'waiting_window',
	MessageWindow = 'message_window',
	PlayWindow = 'play_window',
	Chat = 'chat_window',
	VeryQuickRegistration = 'very_quick_registration',
}

export enum EnumOrderStatus{
	SET = 'set',
	OFFER = 'offer'
}
export const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End', 'Control', 'Shift'];
