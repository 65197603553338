import React, {useEffect, useState} from "react";
import './VeryQuickRegistration.css';
import {CancelBtn} from "../../Elements/elements";
import {popSystemStatus, setSystemStatus, showMessageError} from "../../../redux/reducers/system/actions";
import {setRegistrationStatus} from "../../../redux/reducers/registration/actions";
import {useDispatch, useSelector} from "react-redux";
import Loader from "react-loader-spinner";
import InputMask from "react-input-mask";
import {ReactSVG} from "react-svg";
import CheckSVG from "../../App/svg/check.svg";
import {trimPhone, validateName} from "../../../api/validate";
import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import {phoneAPI, userAPI} from "../../../api";
import {SystemStatus, UserStatus} from "../../../@types";
import {UserInfo} from "../../../redux/reducers/user/@types";
import {authUser, createQuickUser, setAuthenticated, setUserInfo} from "../../../redux/reducers/user/actions";
import {RootState} from "../../../redux/store";
import {setChatVisible} from "../../../redux/reducers/chat/chatActions";
import {Simulate} from "react-dom/test-utils";
import InputSelectValue from "../InputSelectValue/InputSelectValue";
import {getBodyOptionsListIDs, getDriverCategoriesList} from "../../../redux/reducers/system/system_functions";

enum EnumErrorState {NONE=0, ERROR_SEND_CODE = 1, ERROR_FIO = 2, ERROR_VERIFY_CODE = 4, ERROR_CHECK_PHONE = 8 , ERROR_CAR_TYPE = 16, ERROR_BODY_OPTIONS = 32, ERROR_PHONE = 64};

interface IProps { }
const VeryQuickRegistration : React.FunctionComponent<IProps> = ({}) => {
    const  dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isCodeEnteredRight, setCodeEnteredRight] = useState(false);
    const [isPhoneEntered, setPhoneEntered] = useState(false);
    const [isCodeEntered, setCodeEntered] = useState(false);
    const [hasError, setHasError] = useState<EnumErrorState>(EnumErrorState.NONE);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [fio, setFIO] = useState('');

    const [bodyOptionId, setBodyOptionId] = useState(   '');
    const [carTypeId, setCarTypeId]       = useState( '');


    const { user, system  } = useSelector((state: RootState) => ({  user: state.userReducer, system:state.systemReducer}));

    const wasFioError = () => {
        setHasError(hasError | EnumErrorState.ERROR_FIO)
    };

    const goToChat = () => {
        if (hasError != EnumErrorState.NONE || !isPhoneEntered || !isCodeEnteredRight) return;
    };

    const onPhoneCodeChanged =  (e : any) => {
       let code = e.target.value;
        if (code.indexOf('_') === -1) {
            code = code.replace(/ /g, '');
            if (code != "") checkCode(code);
            else setCodeEntered(false);
        } else {
            setCodeEntered(false);
        }
        setPhoneCode(code);
    };
    const  clearError = (error: EnumErrorState) => {
        if ( (hasError & error) == error) setHasError(hasError - error);
    }
    useEffect(()=>console.log('hasError=>', hasError), [hasError]);
    const onPhoneNumberChanged =  (e : any) => {
        if (isLoading) return ;

        let phoneNumber = e.target.value;
        if (phoneNumber.indexOf('_') === -1) {
            phoneNumber = trimPhone(phoneNumber);
            if (phoneNumber=="") {
                setPhoneEntered(false);
                setHasError(hasError|EnumErrorState.ERROR_PHONE);
            }
            else {
                clearError( EnumErrorState.ERROR_PHONE);
                checkPhone(phoneNumber);
            }
        } else {
            setPhoneEntered(false);
        }
        setPhoneNumber(phoneNumber);
    };

    const createNewUserAndLogin = async () => {

        setCodeEntered(true);

        setLoading(true);
        let isOk = await createQuickUser( fio, phoneNumber, user.current_coordinates, user.authenticated , system.statuses, carTypeId, bodyOptionId,  dispatch);
        setLoading(false);
        if (isOk) {
            await dispatch(popSystemStatus());
            setTimeout(()=>dispatch(setChatVisible(true)), 100);
        } else {
            showMessageError('Ошибка регистрации пользователя, попробуйте позднее!', dispatch )
        }


    };

    const checkCode = async (code:string) => {
        setLoading(true);
        let res = await userAPI.auth(phoneNumber, code).catch(e=> null);
        setLoading(false);
        console.log(res);
        if (!res || !res.data) return showMessageError('Ошибка соединения с сервером. Попробуйте повторить операцию позже!', dispatch);
        let response = res.data;
        if (response.status == 'error') {
            if (response.error_code === 1) // неверный код
                return setHasError((hasError | EnumErrorState.ERROR_VERIFY_CODE));
            if (response.error_code === 2) { // ненайдена анкета водителя
                await createNewUserAndLogin();
            }
        }
        if (response.status == 'success') {
            authUser(res.data.driver_id, dispatch).then( action=> {
                console.log('action ', action);
                dispatch(popSystemStatus());
               // dispatch(setSystemStatus(SystemStatus.Chat))
                 setTimeout(()=> dispatch(setChatVisible(true)), 100);
            });
        }
    };

    const checkErrors =  () => {
        let val = EnumErrorState.NONE;
        if (!carTypeId) val |= EnumErrorState.ERROR_CAR_TYPE;
        if (!bodyOptionId) val |= EnumErrorState.ERROR_BODY_OPTIONS;
        if (!fio) val |= EnumErrorState.ERROR_FIO;
        if (phoneNumber.indexOf('_') > 0 || !phoneNumber) val |= EnumErrorState.ERROR_PHONE;

        setHasError(val);
    }
    const checkPhone = async (phone:string) => {
        setLoading(true);
        let res = await phoneAPI.sendSms(phone);
        setLoading(false);
        if (res.data && res.data.status === 'success') {
            setPhoneEntered(true);
        } else {
            console.log('EnumErrorState.ERROR_CHECK_PHONE');
            setHasError(hasError|EnumErrorState.ERROR_CHECK_PHONE);
        }
    }

    const toMainScreen = () => {
        // setCodeSent(false);
        dispatch(popSystemStatus());

    };

    return(
        <div className="very-quick-registration-form">
            <div className="very-quick-registration-form-container">
                <CancelBtn onClick={toMainScreen}/>
                <div className="very-quick-registration-form__title">Для отправки сообщений введите ваши данные</div>

                <div className="check_phone_input_container">
                    <InputFilteredValue name="Введите свое ФИО*" placeholder="Иванов Иван Иванович"
                                        regexp={/[А-Яа-я -]/} value={fio}
                                        onValueChanged={ (value ) => setFIO( '' + value)}
                                        validator={validateName} wasError={ (hasError & EnumErrorState.ERROR_FIO) == EnumErrorState.ERROR_FIO } />
                </div>
                <div className="driver_input_container_many_items">
                    <InputSelectValue name={"Грузоподъемность ТС"} list={getDriverCategoriesList(system)}
                                      classAppend="short"
                                      wasError={ (hasError & EnumErrorState.ERROR_CAR_TYPE) == EnumErrorState.ERROR_CAR_TYPE}
                                      isSelected={true}
                                      value={carTypeId}
                                      onSelect={(item) => {clearError( EnumErrorState.ERROR_CAR_TYPE); setCarTypeId( item.id )}}
                    />
                    <InputSelectValue name={"Тип кузова"}
                                      list={getBodyOptionsListIDs(system, carTypeId,true)}
                                      classAppend="short"
                                      wasError={(hasError & EnumErrorState.ERROR_BODY_OPTIONS) == EnumErrorState.ERROR_BODY_OPTIONS}
                                      isSelected={true}
                                      value={bodyOptionId}
                                      onSelect={(item) => { clearError( EnumErrorState.ERROR_BODY_OPTIONS); setBodyOptionId(  item.id )}}
                    />
                </div>

                <label className="check_phone_label">
                    <div className="check_phone_label_text">Введите свой номер телефона</div>
                    <div className="check_phone_input_container">
                        <InputMask mask="+7(999) 999-99-99" id="check_phone_phone"
                                   className={'check_phone_input' + ( ((isPhoneEntered && hasError) || ((hasError & EnumErrorState.ERROR_PHONE) == EnumErrorState.ERROR_PHONE)) ? ' error ' + hasError : '')}
                                   type="tel" value={phoneNumber} onChange={onPhoneNumberChanged}

                        >
                            {(inputProps: any) => <input {...inputProps} />}
                        </InputMask>
                        {isPhoneEntered && <ReactSVG src={CheckSVG} className="check_phone_check"/>}
                        { (hasError&EnumErrorState.ERROR_SEND_CODE) == EnumErrorState.ERROR_SEND_CODE && <div className="check_phone_error">Ошибка, попробуйте другой телефон</div>}
                    </div>
                </label>
                {isPhoneEntered && !fio && <div className="check_phone_error">Для продолжения необходимо ввести ФИО</div>}
                <div className={"check_phone_block " + (isPhoneEntered && fio ? 'active' : 'hidden')}>
                    <div className="check_phone_label_text">
                        Вам придет SMS с кодом подтверждения :
                    </div>
                    <div className="check_phone_input_container">
                        <InputMask mask="9 9 9 9" id="check_phone_code"
                                   className={'check_phone_input' + (hasError ? ' error' : '')}
                                   type="tel" value={phoneCode} onChange={onPhoneCodeChanged}>
                            {(inputProps: any) => <input {...inputProps} />}
                        </InputMask>
                           {/*{timeLeft.current > 0 && !verifyCodeError &&*/}
                        {/*    <div className="check_phone_text_grey">Отправить новый код можно через {time} сек...</div>*/}
                        {/*}*/}
                        {isCodeEntered && <ReactSVG src={CheckSVG} className="check_phone_check" style={{"right": "33%"}}/> }
                    </div>
                    {(hasError&EnumErrorState.ERROR_VERIFY_CODE) == EnumErrorState.ERROR_VERIFY_CODE && <div className="check_phone_error">Введенный код некорректен.</div>}
                    {(hasError&EnumErrorState.ERROR_CHECK_PHONE) == EnumErrorState.ERROR_CHECK_PHONE && <div className="check_phone_error">Ошибка. Попробуйте еще раз.</div>}
                </div>
                <div id="check_phone_verify_code"  className={'check_phone_next_button ' + (!isCodeEnteredRight || isLoading ? 'inactive' : '')}
                     onClick={goToChat}>
                    {isLoading && <div className={'button_loader'}><Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'}/></div>}
                    <div className="check_phone_start_text" onClick={checkErrors}>Далее</div>
                </div>
            </div>
        </div>
    )
};

export default VeryQuickRegistration;
