import React, {useEffect, useState} from "react";
import "./DriverUpdateValuesForm.css"
import Button from "../../Elements/Button/Button";
import InputFilteredValue from "../../Registration/InputFilteredValue/InputFilteredValue";
import {
    IBOSmallCharacteristics,
    UserInfo,
    UserState,
    VehicleInfo,
    VehicleSize
} from "../../../redux/reducers/user/@types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {CharacteristicTypeEnum, SystemState} from "../../../redux/reducers/system/@types";
import {setUserInfo, tryUpdateData} from "../../../redux/reducers/user/actions";
import {
    getBodyOptionsListIDs,
    getCharacteristicValueList,
    getDriverCategoriesList, getPassFromCharacteristics
} from "../../../redux/reducers/system/system_functions";
import InputSelectValue from "../../Registration/InputSelectValue/InputSelectValue";
import { Overlay } from "../../Elements/elements";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import {BodyOptionCharacteristic, OrdersState} from "../../../redux/reducers/orders/@types";
import InputCheckBoxValue from "../../Registration/InputCheckBoxValue/InputCheckBoxValue";
import ButtonSave from "../../Registration/ButtonSave/ButtonSave";
import {passList} from "../../Registration/VehicleRegistration/VehicleRegistration";
import SelectorYesNo from "./SelectorYesNo";
import moment from "moment";
export const REJECT_ORDER_BY_PARAMS = 'cancel the order';

interface IProps {
    onSave:Function,
    onClose:Function,
    values: {id:string, description:string; name:string, value_needed?: string}[]
}
const DriverUpdateValuesForm: React.FC<IProps> = ({ onSave, onClose, values }) => {

    const  dispatch = useDispatch();

    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const orders  : OrdersState      = useSelector( (state: RootState)=> ({orders:state.ordersReducer})).orders;
    const user_data = user.data;

    const [bodySize, setBodySize] = useState<VehicleSize>(user_data && user_data.cars && user_data.cars.length ? {...user_data.cars[0].size} : {length:'', width:'', height:'' });
    const [wasError, setWasError] = useState<boolean>(false);
    const [isSavingData, setSavingData] = useState<boolean>(false);
    const [bodyOptionId, setBodyOptionId] = useState(user_data && user_data.cars && user_data.cars.length ? user_data.cars[0].body_option_id :  '');
    const [carTypeId, setCarTypeId]       = useState(user_data && user_data.cars && user_data.cars.length ? user_data.cars[0].car_type_id :'');
    const [isAllFieldsFill, setAllFieldsFill] = useState(false);
    const [characteristics, setCharacteristic] = useState<IBOSmallCharacteristics[]>(user_data && user_data.cars && user_data.cars.length ? [...user_data.cars[0].body_option_characteristics] : []);

    const bodyOptionCharacteristics = [... orders.bodyOptionCharacteristics].sort( (a,b) =>  a.type < b.type ? 1: -1);
    const [selectedPass, setSelectedPass] = useState<string>(getPassFromCharacteristics(user_data.cars[0].body_option_characteristics));
    const [isCancel, setCancel] = useState<{id:string, value: boolean}[]|undefined>(values.some(value => value.name == 'body_option_characteristics' && value.value_needed) ? [] : undefined);

    const saveDataAction = async ( ) => {

        let newUserData : UserInfo = {...user.data};

        let car : VehicleInfo = newUserData.cars.length ? {...newUserData.cars[0]} : new VehicleInfo();
        // car.pass = selectedPass;
        // if (values.some(x=> x.name == 'pass') && !car.pass ) {
        //     let v = values.find(x=> x.name == 'pass');
        //     car.pass = v ? v.id : selectedPass;
        // }
        car.body_option_id = bodyOptionId;
        car.car_type_id = carTypeId;
        car.size = bodySize;
        car.body_option_characteristics = [...characteristics];

        if (newUserData.cars.length > 0)
            newUserData.cars[0] = car;
        else
            newUserData.cars =  [car];

        newUserData.draft = false;

        dispatch(setUserInfo(newUserData));
        setSavingData(true);
        let response = await tryUpdateData(newUserData, dispatch, true, true, system.statuses, false);
        setSavingData(false);
        if (response && response.id ) {
            onSave();
        }

    };

    const isValidFields = () => {
        let val = !( bodyOptionId=='' || carTypeId == ''
                        || bodySize.length=='' || bodySize.width=='' || bodySize.height==''
                        || bodySize.length=='0' || bodySize.width=='0' || bodySize.height=='0'
                        || (isCancel &&  isCancel.length != values.filter(value => (value.name == 'pass' || value.name == 'body_option_characteristics') && value.value_needed).length)
                    );

        setAllFieldsFill(val);
        return val;
    };

    const onCharacteristicSetValue = (id:string, value : any)=> {
        let newChs = characteristics.filter(x => x.id != id);
        if (value === true || (value!=false && value!=='-1')) newChs.push({ id, value});
        console.log('newChs', newChs);
        isValidFields();
        setCharacteristic(newChs);
    };

    const onSaveData = async ()=> {
        if (isSavingData) return ;
        setWasError(false);

        if (!isValidFields()) {
            setWasError(true);
            return;
        }

        if (isCancel && isCancel.some(x=> !x.value))
            return onClose(REJECT_ORDER_BY_PARAMS);
        await saveDataAction();
    };

    useEffect(()=>{isValidFields()}, [bodySize, carTypeId, bodyOptionId, isCancel]);
    useEffect(()=>{window.scrollTo(0, 0)}, []);
    useEffect(()=>{console.log('isCancel', isCancel)}, [isCancel]);
    values.sort((a :any, b:any) => {
        let ch_a : BodyOptionCharacteristic | undefined = bodyOptionCharacteristics.find(x=> x.id == a.id);
        let ch_b : BodyOptionCharacteristic | undefined = bodyOptionCharacteristics.find(x=> x.id == b.id);
        if (ch_a?.type == CharacteristicTypeEnum.Number) return -1;
        return  1;
    })
    return (
        <>
        <div className="driver_update_form">
            <div className="driver_update_form_container">
                <div className="driver_update_form_title">Для продолжения необходимо заполнить следующую информацию:</div>
                <div className="driver_update_form_values">
                    <div className="driver_input_container_many_items">
                    {values.some(value => value.name == 'size')
                        &&
                            <>
                                <InputFilteredValue name="Длина кузова*" placeholder="в метрах"
                                                    regexp={/[0-9,]{1,10}/} value={bodySize.length}
                                                    onValueChanged={(value)=> setBodySize({...bodySize,   length: ''+value})}
                                                    subTitle="Пример 3,2"
                                                    classAppend="short"
                                                    maxLength={5}
                                                    type={'number'}
                                                    wasError={wasError} />

                                <InputFilteredValue name="Ширина кузова*" placeholder="в метрах"
                                                    regexp={/[0-9,]{1,10}/} value={bodySize.width}
                                                    onValueChanged={(value)=> setBodySize({...bodySize,    width: ''+value})}
                                                    subTitle="Пример 1,9"
                                                    classAppend="short"
                                                    maxLength={5}
                                                    type={'number'}
                                                    wasError={wasError} />

                                <InputFilteredValue name="Высота кузова*" placeholder="в метрах"
                                                    regexp={/[0-9,]/} value={bodySize.height}
                                                    subTitle="Пример 1,8"
                                                    classAppend="short"
                                                    maxLength={5}
                                                    onValueChanged={(value)=> setBodySize({...bodySize,    height: ''+value})}
                                                    wasError={wasError} />
                             </>
                    }

                        {values.some(value => value.name == 'car_type_id')
                            && <InputSelectValue name={"Грузоподъемность"} list={getDriverCategoriesList(system)}
                                                           classAppend="short"
                                                           wasError={wasError}
                                                           isSelected={true}
                                                           value={carTypeId}
                                                           onSelect={(item) => setCarTypeId( item.id )}
                                />
                        }
                        {values.some(value => value.name == 'body_option_id')
                            && <InputSelectValue name={"Тип кузова"}
                                                           list={getBodyOptionsListIDs(system, carTypeId,true)}
                                                           classAppend="short"
                                                           wasError={wasError}
                                                           isSelected={true}
                                                           value={bodyOptionId}
                                                           onSelect={(item) => setBodyOptionId(  item.id )}
                                />
                        }
                        {/*{values.some(value => value.name == 'body_option_characteristics' && value.id=="d4b6a710-5fe5-11ea-a9c9-00155d8e4e03") &&*/}

                        {/*    <InputSelectValue  list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}*/}
                        {/*        value={heightVehicle}*/}
                        {/*        key={"ch_d4b6a710-5fe5-11ea-a9c9-00155d8e4e03"}*/}
                        {/*        name={"Высота ТС"}*/}
                        {/*        classAppend={"short"}*/}
                        {/*        wasError={false}*/}
                        {/*        isSelected={heightVehicle!=''}*/}
                        {/*        onSelect={(item) => {setHeightVehicle, onCharacteristicSetValue("d4b6a710-5fe5-11ea-a9c9-00155d8e4e03", item.id)} />;*/}
                        {/*}*/}
                        {values.filter(value => value.name == 'body_option_characteristics' )
                               .map(value =>  {

                                   let ch : BodyOptionCharacteristic | undefined = bodyOptionCharacteristics.find(x=> x.id == value.id);
                                   if (!ch) return <div className="error_characteristic">Не найдена, {value.name}</div>;
                                   let vl : IBOSmallCharacteristics | undefined = characteristics.find(x=> x.id == value.id);
                                   if (ch.type == CharacteristicTypeEnum.Number)
                                     return  <InputFilteredValue name={ch.name} placeholder="в метрах"
                                                           regexp={/[0-9,]/} value={bodySize.height}
                                                           subTitle="Пример 2,3"
                                                           classAppend="short"
                                                           maxLength={5}
                                                           onValueChanged={(value)=> {

                                                               if (!+value) setCancel([...(isCancel ? isCancel.filter(x=> x.id!=ch?.id):[])]);
                                                               else setCancel([...(isCancel ? isCancel.filter(x=> x.id!=ch?.id):[]), {id: ch ? ch.id : '', value: true}]);
                                                               onCharacteristicSetValue(ch ? ch.id : '', value)
                                                           }}
                                                           wasError={wasError} />
                                   if (value.value_needed && ch) {
                                        // console.log('value.value_needed', ch.id);
                                        // return <></>;
                                        return <SelectorYesNo title={ch.name} value={value.value_needed} ch_id={ch.id}  key={'yesno_'+ch.id}
                                                              onSetNo={()=>{
                                                                  setCancel([...(isCancel ? isCancel.filter(x=> x.id!=ch?.id):[]), {id: ch?.id as string, value: false}] )
                                                                  onCharacteristicSetValue(ch ? ch.id : '', false)
                                                              }}
                                                              onSetYes={(value_id)=>{
                                                                  setCancel([...(isCancel ? isCancel.filter(x=> x.id!=ch?.id):[]), {id: ch?.id as string, value: true}]);
                                                                  onCharacteristicSetValue(ch ? ch.id : '', value_id)
                                                              }}/>
                                   }
                                   if ( ch.type === CharacteristicTypeEnum.Boolean )
                                       return  <InputCheckBoxValue  key={"ch_"+ch.id}  isSet={ !!vl && (vl.value === true ||  vl.value === 'true') } name={ch.name} classAppend="short"
                                                                    onChange={() => {onCharacteristicSetValue(ch ? ch.id : '', vl &&  vl.value === true ? false : true)}} />;

                                    if (ch.type === CharacteristicTypeEnum.Ref)
                                        return  <InputSelectValue
                                                    list={getCharacteristicValueList(system.categories, ch ? {id:ch.id} : {id:'-1'}, bodyOptionId, carTypeId )}
                                                    key={"ch_"+ch.id} wasError={false} classAppend={"short"} name={ch.name}
                                                    isSelected={!!vl} value={vl ? vl.value : ''}
                                                    canEnterValue={ch.name == 'Тип пандуса'}
                                                    onSelect={(item) => onCharacteristicSetValue(ch ? ch.id : '', item.id)} />;

                               })
                        }
                        {values.filter(value => value.name == 'pass' ).map(value => {
                            if (value.value_needed)
                                return <SelectorYesNo key={'yesno_pass'}
                                                        title={''} value={value.id} ch_id={'pass'}
                                                      onSetNo={()=>{
                                                          setCancel([...(isCancel ? isCancel.filter(x=> x.id!='pass'):[]), {id: 'pass', value: false} ] );
                                                          onCharacteristicSetValue(value.id, false)
                                                      }}
                                                      onSetYes={(value_id)=>{
                                                          setCancel([...(isCancel ? isCancel.filter(x=> x.id!='pass'):[]), {id: 'pass', value: true} ]);
                                                          onCharacteristicSetValue(value.id, true)
                                                      }}/>

                            return <InputSelectValue list={passList.map(x => ({id: x.id, text: x.name}))}
                                                     key={"ch_passes"}
                                                     wasError={false}
                                                     classAppend={"short"}
                                                     name={'Пропуск'}
                                                     isSelected={selectedPass != ''}
                                                     value={selectedPass}
                                                     canEnterValue={false}
                                                     onSelect={(item) => setSelectedPass(item.id)}/>
                        })
                        }





                    </div>
                </div>
                <div className="driver_update_form_buttons">
                    <div className="window_close_btn" onClick={()=>onClose()}><ReactSVG src={CloseSVG} /></div>
                    <ButtonSave onClickSave={onSaveData} onClickBack={()=>onClose()} isSavingData={isSavingData} isActive={isAllFieldsFill} />

                </div>
            </div>

        </div>
        <Overlay zIndex={35}/>
        </>

    );
}

export default DriverUpdateValuesForm;
