import React, {useEffect, useState} from "react";
import './InputSelectValue.css';
import optionToggler from "../../../images/option.svg";
import {DropDownListItem} from "../../../redux/reducers/system/@types";
import {ReactSVG} from "react-svg";
import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import {BodyOptionCharacteristic} from "../../../redux/reducers/orders/@types";


interface InputSelectValueProps {
    onSelect: ({ id, text }: DropDownListItem) => void;
    onClick?:Function;
    list: DropDownListItem[];
    isSelected: boolean;
    showTitleWhenSelect?: boolean;
    value?:string;
    name:string,
    classAppend?:string,
    wasError:boolean,
    canEnterValue?:boolean,
    all_values?: BodyOptionCharacteristic[],
}
export const InputSelectValue: React.FC<InputSelectValueProps> = ({

                                                                              onSelect,
                                                                              onClick,
                                                                              list,
                                                                              isSelected = false,
                                                                              showTitleWhenSelect = true,
                                                                              value = '',
                                                                              name= '',
                                                                              classAppend= '',
                                                                              wasError= false,
                                                                              canEnterValue=false,
                                                                              all_values=[]
                                                                          }) => {
    if (value == '00000000-0000-0000-0000-000000000000') value = '';
    if (canEnterValue) {
        console.log('InputSelectValue', list, value, all_values);

    }
    const initValue = () :DropDownListItem | undefined=>{
        if (list.find(x=> x.id === value)) return  list.find(x=> x.id === value);
        if (!value && list.some(x=>x.id=="")) return  list.find(x=> x.id == "");

        if (all_values.find(x=> x.id === value)) {

            let v = all_values.find(x=> x.id === value);

            if (v) {
                list= [...list];
                list.push({id: v.id, text:v.name});
                return initValue();
            }
        }
        if (!value) return undefined;
       // console.log('herere');
        list= [...list];
        list.push({id: value, text:value});
        return  {id: value, text:value};
    }

    const [openDropDown, setOpenDropDown] = useState(false);
    const [selectedItem, setSelectedItem] = useState<DropDownListItem|undefined>( initValue());
    let [isComplete, setComplete] = useState(value!='');
    let [isError, setError] = useState(false);
    let [enteredValue, setEnteredValue] = useState('');
   // console.log(name, ' => ', list, value);


    const onEnterValue = ( ) => {
        console.log('onEnterValue');
        let dd :DropDownListItem = {id: enteredValue, text : enteredValue};
        onSelect(dd);
        clickDropDown();
    }

    const clickDropDown = () => {
        console.log('clickDropDown');
        if (list.length)
            setOpenDropDown((prevState) => !prevState);
        if (onClick && !openDropDown) onClick();
    };
    const clickDropDownItem = (item: DropDownListItem) => {
        setSelectedItem(item);
        onSelect(item);
        setError(  wasError && (!selectedItem || !selectedItem.id));
    };
    useEffect(()=>{
        console.log(selectedItem)
        if (!isSelected)
            setOpenDropDown(false);
    }, [isSelected]);

    useEffect(()=>{
        //console.log('localValue', selectedItem);
        setSelectedItem(value ? list.find(x=> x.id === value) : list.some(x=>x.id=="") ? list.find(x=> x.id == "") : undefined);
    }, [value])

    useEffect(()=>{
        console.log('localValue', selectedItem);
        setError(  wasError && (!selectedItem || !selectedItem.id))
    }, [wasError])

    return (
        <div className={classAppend ? classAppend : 'input_filtered_full'}>
            <div className='input_filtered_container_name'>{name}</div>
            <div className={'input_filtered_container select-item '  + (isComplete ? ' complete' : (isError ? ' error' : ''))}>
                <div
                    className={
                        "drop-down " +  (openDropDown ?  " drop-down--show " :'') + (isSelected ? " drop-down-selected " :'')
                    }
                    onClick={  clickDropDown }
                >
                    <div className="drop-down__header" >
                        { selectedItem ? <div>{selectedItem.text}</div> : '' }
                        { list.length > 0 && <div><ReactSVG src={optionToggler} className="drop-down__toggler"/></div>}
                    </div>
                    {openDropDown && (
                        <div className="drop-down__container"  >
                            <ul className="drop-down__list">
                                {list.map((item) => (
                                    <li
                                        key={item.id}
                                        onClick={() => clickDropDownItem(item)}
                                        className="drop-down__item"
                                    >
                                        {item.text}
                                    </li>
                                ))}
                                {canEnterValue && <li
                                    key={'sdfsdf'}
                                    className="drop-down__item"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <InputFilteredValue
                                        value={''} name={''}
                                        regexp={/[0-9,]{1,6}/}
                                        placeholder={'Иное (см)'}
                                        onValueChanged={(v)=>setEnteredValue(''+v)}
                                        onBlur={onEnterValue} />
                                </li>}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export  default InputSelectValue;
