import React, {ChangeEvent, FC, useState} from 'react'
import plusInMessage from '../../../images/plusInMessage.svg'
import attachInMessage from '../../../images/attachment.svg'
import sendMessage from '../../../images/sendMessage.svg'
import {useDispatch, useSelector} from 'react-redux'
import {userAPI} from '../../../api'
import {CChatMessage, IChatCommands, IChatDialog} from "../../../redux/reducers/chat/@types";
import {RootState} from "../../../redux/store";
import {showMessageError} from "../../../redux/reducers/system/actions";
import {setChatMessages} from "../../../redux/reducers/chat/chatActions";
import {Command} from "./Command";
import {ChatPhotoMessage, EnumWindowMode} from "./ChatPhotoMessage";

interface IProps{
    dialog: IChatDialog
}

export const ChatFooter: FC<IProps> = ({ dialog}) => {
    const dispatch = useDispatch();

    const {user} = useSelector((state: RootState)=> ({user:state.userReducer}));
    const {chat} = useSelector((state: RootState)=> ({chat:state.chatReducer}));
    // const commands = useSelector(getChatCommandsSelector)

    const [inputValue, setInputValue] = useState(''),
          [showCommands, setShowCommands] = useState(false),
          [showAttachMenu, setShowAttachMenu] = useState(false),
          [showSendWithFile, setShowSendWithFile] = useState<EnumWindowMode>(EnumWindowMode.START),
          [buttonDisabler, setButtonDisabler] = useState(false);

    const add = () => {
        setShowCommands(prev => !prev)
    }

    const onSendMessage = async (commandValue: string = '') => {

        setButtonDisabler(prev => !prev);

        const messageText = commandValue === '' ? inputValue : commandValue;

        let res = await userAPI.sendChatMessages(user.driver_id, dialog.dialog_id, messageText, dialog.group_id)
                               .catch( (err)=> showMessageError(  "Ошибка отправки сообщения <br/>" + err.message ,dispatch));
        if (!res) return ;
        if (res.data && res.data.status === 'success') {
            let msg = new CChatMessage(dialog.dialog_id, user.driver_id, messageText);
            console.log('new msg array', [...chat.messages, msg])
            dispatch(setChatMessages([...chat.messages, msg]));
            setInputValue('');
        } else
            showMessageError(  "Ошибка отправки сообщения <br/>" + res.data.error_message ,dispatch)
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value)
    };


    const onSelectSendFileMode = (mode: EnumWindowMode) => {
        setShowSendWithFile(mode);
        setShowAttachMenu(false);
    }
    const onSelectCommand = (command: IChatCommands) => {
        setInputValue(command.name);
        setShowCommands(false);
    };

    return (
        <>

            <div className="chat_footer_container">

                <button className={"chat_footer_container-button attachment " }   onClick={()=>setShowAttachMenu(!showAttachMenu)}  >
                    <img alt='Добавить' src={attachInMessage} className={ (showAttachMenu ?'active':'')} />
                </button>
                <button className={"chat_footer_container-button " + (showCommands ?'active':'')}   onClick={add} disabled={buttonDisabler}>
                    <img alt='Добавить' src={plusInMessage} />
                </button>
                <input placeholder='Напишите сообщение' value={inputValue} onChange={onChangeInput} />
                <button className="chat_footer_container-button" disabled={buttonDisabler} >
                    <img alt='Отправить сообщение' src={sendMessage} onClick={() => onSendMessage()} />
                </button>
            </div>

            <div className={"chat_footer_container-commands " + (showCommands ? 'active':'')} >
                <div className={"chat_footer_container-commands-container"}>
                {  chat.commands.map(command => <Command key={command.id} command={command} onSelectCommand={onSelectCommand}   />) }
                </div>
            </div>

            <div className={"chat_footer_container-commands chat_footer_container-menu " + (showAttachMenu ? 'active':'')} >
                  <ChatPhotoMessage  onClose={()=>setShowAttachMenu(false)} dialog={dialog}  />
                {/*<div className={"chat_footer_container-commands-container"}>*/}
                {/*     <Command key={'photo-id'} command={{id:'1',name:'Сделать фото'}} onSelectCommand={()=>onSelectSendFileMode(EnumWindowMode.CAMERA)}   />*/}
                {/*     <Command key={'file-id'} command={{id:'1',name:'Прикрепить файл'}} onSelectCommand={()=>onSelectSendFileMode(EnumWindowMode.ATTACHE)}   />*/}
                {/*</div>*/}
            </div>
        </>
    )
}

