import {EnumUserTinType} from "../../user/@types";

export const MULTIPLE_CARS_OWNER = true;
export const SINGLE_CAR_OWNER = false;
export const TIN_IP = EnumUserTinType.INDIVIDUAL;
export const TIN_SAM = EnumUserTinType.SELF_EMPLOYED;

export const SELECT_OWNER_TYPE = 'SELECT_OWNER_TYPE';
export const SET_TIN_ENTERED = 'SET_TIN_ENTERED';
export const SET_AGREE = 'SET_AGREE';
export const SET_TIN_VERIFYING = 'SET_TIN_VERIFYING';

export interface SelectOwnerTypeAction {
  type: typeof SELECT_OWNER_TYPE;
  owner_type_selected: boolean;
  multiple_car_owner?: boolean;
}

export interface SetTinEnteredAction {
  type: typeof SET_TIN_ENTERED;
  tin_entered: boolean;
  tin_type?: EnumUserTinType;
  tin?: string;
}

export interface SetAgreeAction {
	type: typeof SET_AGREE;
	agree: boolean;
}

export interface SetTinVerifyingAction {
	type: typeof SET_TIN_VERIFYING;
	tin_verifying: boolean;
}

export type OwnerSelectActionTypes = SelectOwnerTypeAction
	| SetTinEnteredAction
	| SetAgreeAction
	| SetTinVerifyingAction;

export interface OwnerSelectState {
	owner_type_selected: boolean;
    multiple_car_owner: boolean;
    tin_entered: boolean;
    type: EnumUserTinType;
    tin: string;
    agree: boolean;
    tin_verifying: boolean;
}
