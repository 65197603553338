import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {PartySuggestions} from "react-dadata";
import {daDataToken} from "../../deployment";
import {RootState} from "../../redux/store";
import {editingChars, RegistrationStatus, SystemStatus} from "../../@types";
import {Owner} from "../Elements/Containers/@types";
import {
	MULTIPLE_CARS_OWNER,
	OwnerSelectState,
	SelectOwnerTypeAction,
	SetAgreeAction,
	SetTinEnteredAction,
	SetTinVerifyingAction,
	SINGLE_CAR_OWNER,
	TIN_IP,
	TIN_SAM
} from "../../redux/reducers/owner_select/@types";
import {FinalizeState, SaveOwnerAction, SetIsOwnerAction} from "../../redux/reducers/finalize/@types";
import {SetRegistrationStatusAction} from "../../redux/reducers/registration/@types";
import {popSystemStatus, setSystemStatus, showMessageError} from "../../redux/reducers/system/actions";
import OwnerSVG from "../App/svg/owner.svg";
import DriversSVG from "../App/svg/drivers.svg";
import CarsSVG from "../App/svg/cars.svg";
import CarSVG from "../App/svg/car.svg";
import {ReactSVG} from "react-svg";
import {CancelBtn} from "../Elements/elements";
import {tinAPI} from "../../api";
import {generateId} from "../../api/validate";
import "./OwnerSelect.css";
import {setUserInfo, updateData} from "../../redux/reducers/user/actions";
import {EnumUserTinType, UserInfo, UserState} from "../../redux/reducers/user/@types";
import {CheckPhoneState} from "../../redux/reducers/check_phone/@types";
import {selectOwnerType, setAgree, setTinEntered, setTinVerifying} from "../../redux/reducers/owner_select/actions";
import {saveOwner, setIsOwner} from "../../redux/reducers/finalize/actions";
import {setRegistrationStatus} from "../../redux/reducers/registration/actions";

interface IProps {
  state: OwnerSelectState,
  finalize: FinalizeState,
  selectOwnerType: (owner_type_selected: boolean, owner_type?: boolean) => SelectOwnerTypeAction,
  setTinEntered: (tin_entered: boolean, tin_type?: EnumUserTinType, tin?: string) => SetTinEnteredAction,
  setAgree: (agree: boolean) => SetAgreeAction,
  setTinVerifying: (tin_verifying: boolean) => SetTinVerifyingAction,
  setIsOwner: (is_owner: boolean) => SetIsOwnerAction,
  saveOwner: (owner: Owner) => SaveOwnerAction,
   setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction
}

const OwnerSelect: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	selectOwnerType,
	setTinEntered,
	setAgree,
	setTinVerifying,
	setIsOwner,
	saveOwner,
	setRegistrationStatus
}) => {
	const tinIP = useRef<PartySuggestions>(null);
	const [tinExists, setTinExists] = useState(false);
	const dispatch = useDispatch();
	const [error, setError] = useState(false);
	const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
	const phone   : CheckPhoneState = useSelector( (state: RootState)=> ({phone:state.checkPhoneReducer})).phone;



	const toCommonForm = () => {
		dispatch(setSystemStatus(SystemStatus.RegistrationCommonForm));
	}

	const toQuickRegistration = async () => {
		let data = user.data ? {...user.data} : new UserInfo();
		data.person.phone_number = phone.phone;
		data.TIN = "";
		data.accept_agreement = state.agree;
		data.multiple_car_owner = state.multiple_car_owner;
		data.type = EnumUserTinType.NON_LEGAL;
		let response : any = await updateData(data,dispatch);
		if (response) {
			data.id = data.id ? data.id : response.id;
			dispatch(setUserInfo(data));
			setTinVerifying(false);
			setTimeout(toCommonForm, 500);
			dispatch(setSystemStatus(SystemStatus.RegistrationCommonForm));
		} else
			showMessageError('Ошибка сохранения данных!', dispatch);
	}

	const toMainScreen = () => {
		dispatch(popSystemStatus( ));
		dispatch(setRegistrationStatus(null));
	}
	const onClickNextButton = async () => {
		setError(false);
		if (state.tin_verifying) return;
		if (!state.tin_entered )  return setError(true);

		setTinVerifying(true);
		let res = await tinAPI.checkTin(state.tin);
		if (res.data && !res.data.exist) {

			setIsOwner(true);
			saveOwner({
				...(finalize.owner),
				id: generateId(),
				multiple_car_owner: state.multiple_car_owner,
				type: state.type,
				tin: state.tin,
				agree: state.agree
			});
			// setTimeout(state.tin_type === TIN_IP ? toBankData : toPassportData, 500);
			let data = user.data ? {...user.data} : new UserInfo();
			data.person.phone_number = phone.phone;
			data.TIN = state.tin;
			data.accept_agreement = state.agree;
			data.multiple_car_owner = state.multiple_car_owner;
			data.type = state.type;
			let response : any = await updateData(data,dispatch);
			if (response) {
				data.id = response.id;
				dispatch(setUserInfo(data));
				setTinVerifying(false);

				setTimeout(toCommonForm, 500);
			} else
				setTinVerifying(false);
		} else {
			setTinVerifying(false);
			setTinExists(true);
			//setTimeout(setTinExists, 5000, false);
		}
	}
	useEffect(()=>{
		if (user.data  )
			selectOwnerType(true, user.data.multiple_car_owner == MULTIPLE_CARS_OWNER ? MULTIPLE_CARS_OWNER : SINGLE_CAR_OWNER);
		if (user.data && user.data.TIN)
			setTinEntered(true, user.data.type, user.data.TIN);

	}, [user.data])
  return (
		<>

			<div className="owner_select_window">
				<CancelBtn onClick={toMainScreen} />
				<div className="owner_select_container">
					<div className="owner_select_title"
						style={{'marginTop': state.owner_type_selected ? '6.66rem' : '2.4rem'}}>Кто вы?</div>
					<div className="owner_select_tile_container">
						<div id="owner_select_multiple" className={'owner_select_tile' +
							(state.owner_type_selected ? (state.multiple_car_owner === MULTIPLE_CARS_OWNER ?
							' owner_select_tile_selected' : ' owner_select_tile_deselected') : '')}
							onClick={() => {
								if (state.multiple_car_owner === SINGLE_CAR_OWNER) {
									setTinEntered(false, EnumUserTinType.NON_LEGAL, '');
									setError(false);
									if (tinIP.current) tinIP.current.setInputValue('');
								}
								selectOwnerType(true, MULTIPLE_CARS_OWNER);
							}}>
							<ReactSVG src={OwnerSVG} />
							<div className="owner_select_tile_subcontainer">
								<ReactSVG src={DriversSVG} />
								<ReactSVG src={CarsSVG} />
							</div>
							<div className="owner_select_tile_text">Владелец нескольких авто</div>
						</div>
						<div id="owner_select_single" className={'owner_select_tile' +
							(state.owner_type_selected ? (state.multiple_car_owner === SINGLE_CAR_OWNER ?
							' owner_select_tile_selected' : ' owner_select_tile_deselected') : '')}
							onClick={() => {
								if (state.multiple_car_owner === MULTIPLE_CARS_OWNER && state.type !== TIN_IP) {
									setTinEntered(false, EnumUserTinType.NON_LEGAL, '');
									setError(false);
									if (tinIP.current) tinIP.current.setInputValue('');
								}
								selectOwnerType(true, SINGLE_CAR_OWNER);
							}}>
							<ReactSVG src={OwnerSVG} />
							<div className="owner_select_tile_subcontainer">
								<ReactSVG src={CarSVG} />
							</div>
							<div className="owner_select_tile_text">Водитель<br />с грузовым авто</div>
						</div>
					</div>
					{state.owner_type_selected &&
						<>
							<div id="owner_select_inn_ip" className={'owner_select_input' +
								(state.tin_entered && state.type === TIN_IP ?
								' owner_select_input_complete' : (!state.tin_entered && error ? ' error' : '')) +
								(state.multiple_car_owner === SINGLE_CAR_OWNER && state.tin_entered && state.type === TIN_SAM ?
								' owner_select_input_disabled' : '')}>
								<PartySuggestions
									token={daDataToken}
									ref={tinIP}
									defaultQuery={state.tin_entered && state.type === TIN_IP ? state.tin : ''}
									count={5}
									onChange={(suggestion) => {
										if (suggestion && suggestion.data && suggestion.data.state &&
											suggestion.data.inn && suggestion.data.state.status === 'ACTIVE' &&
											suggestion.data.inn.length === 12) {
											setTinEntered(true, TIN_IP, suggestion.data.inn);
											if (tinIP.current) tinIP.current.setInputValue(suggestion.data.inn);
										}
									}}
									inputProps={{className: 'owner_select_input_text', placeholder: 'ИНН ИП', type: 'number',
										onKeyDown: (e: React.KeyboardEvent) => {
											if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
												(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 12)) {
												e.preventDefault();
											}
										},
										onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
											if (e.target.value.length === 12) {
												setTinEntered(true, TIN_IP, e.target.value);
											} else {
												setTinEntered(false);
											}
										},
										disabled: state.multiple_car_owner === SINGLE_CAR_OWNER &&
											state.tin_entered && state.type === TIN_SAM
									}}
								/>
							</div>
							{state.multiple_car_owner === SINGLE_CAR_OWNER &&
								<div id="owner_select_inn_sam" className={'owner_select_input' +
									(state.tin_entered && state.type === TIN_SAM ? ' owner_select_input_complete' : (!state.tin_entered && error ? ' error' : '')) +
									(state.tin_entered && state.type === TIN_IP ? ' owner_select_input_disabled' : '')}>
									<input className="owner_select_input_text" placeholder="ИНН САМОЗАНЯТОГО" type="number"
										value={!state.tin_entered || state.type === TIN_SAM ? state.tin : ''}
										onKeyDown={(e: React.KeyboardEvent) => {
											if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
												(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 12)) {
												e.preventDefault();
											}
										}}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											if (e.target.value.length === 12) {
												setTinEntered(true, TIN_SAM, e.target.value);
												if (tinIP.current) tinIP.current.setInputValue('');
											} else {
												setTinEntered(false);
											}
										}}
										disabled={state.tin_entered && state.type === TIN_IP}
									/>
								</div>
							}
							{tinExists && <div className="owner_select_error">ИНН {state.tin} уже зарегистрирован в системе.</div>}
							{!tinExists &&
								<div id="owner_select_later" className="owner_select_link"
									onClick={() => toQuickRegistration()}>Предоставить данные позже</div>
							}
							<section className="owner_select_footer">
								<div id="owner_select_agreed" className={'owner_select_button' + (state.tin_entered ? '' : ' inactive')}
									onClick={onClickNextButton}>
 					    		<div className="owner_select_button_text">Далее</div>
 					    	</div>
							</section>
						</>
					}
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.ownerSelectReducer,
	finalize: state.finalizeReducer
});

export default connect(mapStateToProps, {
	selectOwnerType,
	setTinEntered,
	setAgree,
	setTinVerifying,
	setIsOwner,
	saveOwner,
	setRegistrationStatus
})(OwnerSelect);
