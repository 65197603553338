import React, {useEffect, useRef, useState} from "react";
import './RecomendationRegistration.css';
import BackIcon from '../../App/svg/back-arrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import { popSystemStatus} from "../../../redux/reducers/system/actions";
import {RootState} from "../../../redux/store";


import { SystemState} from "../../../redux/reducers/system/@types";
import {RecommendationsInfo, UserInfo, UserState} from "../../../redux/reducers/user/@types";

import ButtonSave from "../ButtonSave/ButtonSave";

import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import {
    validateBrand, validateName, validatePhone,

} from "../../../api/validate";
import {TemporaryState} from "../../../redux/reducers/temporary/@types";
import {setUserInfo, tryUpdateData, updateData} from "../../../redux/reducers/user/actions";

import {wait_before_update} from "../../../redux/reducers/system/system_functions";

interface IProps {
    zIndex:number;
    val:number;

}

const RecommendationRegistration: React.FunctionComponent<IProps> = ({zIndex,val } ) => {
    const  dispatch = useDispatch();

    const isFirstLoad = useRef({isTrue:true}).current;
    const isFirstLoadChange = useRef({isTrue:true, count_sec:0, data: null}).current;
    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const temp : TemporaryState     = useSelector( (state: RootState)=> ({temp:state.temporaryReducer})).temp;

    const [wasError, setError]                = useState(false);
    const [isAllFieldsFill, setAllFieldsFill] = useState(false);
    const [wasChanged, setChanged]            = useState(false);
    const [isSavingData, setSavingData]       = useState(false);

    // console.log('edited_vehicle', temp.edited_vehicle.data);
    const [state, setState] = useState<RecommendationsInfo[]>(temp.recommendations.length ? temp.recommendations : [new RecommendationsInfo()]);

    const setNewStateItem = (value: RecommendationsInfo, index: number) =>{
        let s = [...state];
        s[index] = value;
        setState(s);
    };
    const closeWindow = () =>{
        dispatch(popSystemStatus());
    };
    const isValidFields = () => {
        return !state.some( x=> x.full_name =="" || x.phone_number == "" || x.position == "");
    };

    const onSaveData = async ()=> {
        if (isSavingData) return ;
        setError(false);

        if (!isValidFields()) {
            setError(true);
            return;
        }
        await saveDataAction();
    }

    const saveDataAction = async (save_by_time=false) => {

        console.log('aaa',save_by_time);

        let newUserData : UserInfo = {...user.data};
        newUserData.recommendations = [...state];
        dispatch(setUserInfo(newUserData));
        if (!save_by_time) {
            setSavingData(true);
            await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
            setSavingData(false);
        }
        else {
            let res = await wait_before_update(3000);
            if (res) {
                setSavingData(true);
                await updateData(newUserData, dispatch);
                setSavingData(false);
            }
        }
    }

    useEffect(()=>{
        //@ts-ignore
        Window.stated = state;

        if (isFirstLoad.isTrue) {

        } else {
            setChanged(true);
            setAllFieldsFill(isValidFields());
            //console.log('useEffect ', isValidFields())

        }
        return () => {
             isFirstLoad.isTrue = false
        }
    }, [ state ]);

    useEffect(()=>{
        if (isFirstLoadChange.isTrue) {


        }else {
            setChanged(true);
            if (!user.authenticated)
                saveDataAction(true);
        }
        return () => {
            isFirstLoadChange.isTrue = false
        }
    }, [state]);

    return (
            <>
                 <div className="registration_driver_data_window">
                     <div className="common_form_window_title" onClick={closeWindow}>
                          <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                        <div className="page_title"> Ваши рекомендации  </div>

                     </div>
                     <div className="page_title page_title_2"> Контакты того, кто может Вас порекомендовать </div>

                     <div className="common_form_window_content ">

                             {state.map((x,index)=>
                                 <div className="driver_input_container_many_items" key={"address_"+index}>
                                     {index > 0 &&
                                        <div className="remove_record">
                                            <span  onClick={() => setState([...state].filter((a,i) => i != index))}>удалить</span>
                                        </div>
                                     }
                                     <InputFilteredValue name="ФИО*" placeholder="Иванов Иван Иванович"
                                                         regexp={/[А-Яа-я -]/} value={x.full_name}
                                                         onValueChanged={ (value) => setNewStateItem({...x, full_name: ''+value}, index)}
                                                         validator={validateName} wasError={wasError} />

                                     <InputFilteredValue name="Телефон*" mask="+7 (999) 999-99-99" type="tel"
                                                         value={x.phone_number}
                                                         placeholder={"+7 (999) 999-99-99"}
                                                         onValueChanged={ (value) => setNewStateItem({...x, phone_number: ''+value}, index)}
                                                         classAppend={'input_long_short_text short input_long_short_text2'}
                                                         validator={validatePhone}  wasError={wasError}/>
                                     <InputFilteredValue name="Должность*" placeholder="начальник отдела"
                                                         regexp={/[А-Яа-я -]/} value={x.position}
                                                         onValueChanged={ (value) => setNewStateItem({...x, position: ''+value}, index)}
                                                         classAppend={'short'}
                                                         validator={validateBrand} wasError={wasError} />

                                 </div>

                              )}

                         {isValidFields() &&
                            <div className='sub_title link'
                                 onClick={() => setState([...state].concat([new RecommendationsInfo()]))}>
                                Добавить еще одну  рекомендацию
                            </div>
                         }

                         <ButtonSave onClickSave={onSaveData} onClickBack={closeWindow} isSavingData={isSavingData} isActive={isAllFieldsFill} />
                     </div>

                 </div>
           </>
    );
};

export default RecommendationRegistration;
