import React from "react";
import "./CargoCard.css";

export enum CargoTypes {
  Packages = "packages",
  Pallets = "pallets",
  Places = "places"
}

type CargoItem = {
  size: {
    height: number;
    length: number;
    weight: number;
    width: number;
  }
}

interface IProps {
  type: CargoTypes;
  data: {
    packages: CargoItem[];
    pallets: CargoItem[];
    places: CargoItem[];
  }
}

const CargoCard: React.FC<IProps> = ({ type, data }) => {
  const typeNames = {
    "packages": "Ящики",
    "pallets": "Паллеты",
    "places": "Коробки",
  }

  return (
    <div className="order_info_cargo_card">
      <div className="order_info_cargo_title">{typeNames[type]} {data[type].length} шт</div>
      <div className="order_info_cargo_size">{data[type][0].size.width} x {data[type][0].size.height} x {data[type][0].size.length} м&nbsp;&nbsp;&nbsp;{data[type][0].size.weight} кг</div>
    </div>
  );
}

export default CargoCard;
