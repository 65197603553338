import axios, { AxiosResponse } from "axios";
import {
				apiOrderUrl,
				apiDriverUrl,
				daDataToken,
				hereMapApiKey,
				apiIPUrl,
				USE_DEBUG_ROUTE,
				apiDebutUrl
} from "../deployment";
import { UserStatus, OrdersListType } from "../@types";
import { Moment } from "moment";
import { sendUsersDataType, SystemMessage } from "./@types";
import {CPackInfo, EnumStatusExecution, IOrderExecutionInfo, PackRequestType} from "../redux/reducers/orders/@types";
import {TypeLonLat} from "../redux/reducers/map/@types";
import {ISystemReceivedObject} from "../redux/reducers/system/@types";

const isDebug = false;
const instanceOrder = axios.create({
	baseURL: apiOrderUrl,
});

const instanceIP = axios.create({
	baseURL: apiIPUrl,
});

const instanceRoute = axios.create({
	baseURL: `https://router.hereapi.com/v8/`
});
const instanceDriver = axios.create({
	baseURL: apiDriverUrl
});

const instanceDebug = axios.create({
	baseURL: apiDebutUrl
});



const _check_data_on_error_array = (response:AxiosResponse) => {
	if (!response || !response.data || response.status !== 200) {
		console.error(response.statusText);
		return true;
	}
	if (!Array.isArray(response.data)) {
		console.error('api call is not returned array');
		return true;
	}
	return false;
};

const dadata = axios.create({
	baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
	headers: {
		Authorization: "Token " + daDataToken,
		ContentType: "application/json",
		Accept: "application/json"
	}
});

export const userAPI = {
	authMe() {
		return new Promise<{ driver_id: string; full_name: string; status: UserStatus; error_code?: number; error_message?: string }>((resolve, reject) => {
			// resolve({
			//   driver_id: "734c6dbb-973a-4e00-8c72-2c8c4b4d395f",
			//   full_name: "Шпагин А.В.",
			//   status: UserStatus.Working
			// });
			// reject({
			//   error_code: 1,
			//   error_message: "Unathorized"
			// });
		});
	},

	takeOrder(driver_id:string, order_id:string, status: string) {
		const body = {
			"order_id": order_id,
			"driver_id": driver_id,
			"status": status
		};
		return instanceDriver.post('/take-order', body )
			.then(response => response)
			.catch(error => error);
	},

	auth(phone: string, code: string) {
		let requestData = {phone_number: phone, sms_code: code};
		return instanceDriver.post('/auth', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getDriverStatus(driver_id: string) {
		return instanceDriver.get('/driver-status?id='+driver_id )
			.then(response => response)
			.catch(error => error);
	},

	getChatGroups(driver_id: string) {
		return instanceDriver.get('/chat-groups?driver_id='+driver_id )
			.then(response => response)
			.catch(error => error);
	},
	getChatMessages(driver_id: string,group_id: string, dialog_id:string = '') {
		return instanceDriver.get('/chat-messages?driver_id='+driver_id + '&group_id=' + group_id + (dialog_id? `&dialog_id=${dialog_id}`:'') )
			.then(response => response)
			.catch(error => error);
	},
	setChatMessagesIsRead(driver_id: string, messages:  {id:string}[]) {
		return instanceDriver.post('/chat-message-read', {driver_id, messages})
			.then(response => response)
			.catch(error => error);
	},
	setChatNewDialog(  driver_id: string, group_id:string) {
		let body =  { driver_id, group_id, id:"00000000-0000-0000-0000-000000000000" };
		return instanceDriver.post('/chat-users', body)
			.then(response => response)
			.catch(error => error);
	},
	getChatAllUsersByOrder(driver_id: string,order_id: string) {
		return instanceDriver.get('/chat-all-users?driver_id='+driver_id + '&order_id=' + order_id )
			.then(response => response)
			.catch(error => error);
	},
	checkChatNewMessages(driver_id: string) {
		return instanceDriver.get(`/chat-new-messages?driver_id=${driver_id}`)
			.then(response => response)
			.catch(error => error);
	},

	sendChatMessages(driver_id: string, dialog_id: string, message: string, group_id:string, file_id = "") {
		let requestData = {dialog_id,  driver_id, group_id, message,  file_id};
		return instanceDriver.post(`/chat-messages`, requestData)
			.then(response => response)
			.catch(error => error)
	},

	getChatCommands(driver_id: string) {
		return instanceDriver.get('/chat-commands?driver_id='+driver_id  )
			.then(response => response)
			.catch(error => error);
	},
	getChatCheckDialogUnreadMessages(driver_id: string, ids:string) {
		return instanceDriver.get(`/chat-message-read?driver_id=${driver_id}&ids=${ids}`)
			.then(response => response)
			.catch(error => error);
	},

	async getChatPhoto( driver_id: string, file_id:string): Promise<any> {
		if (!driver_id || !file_id) return ;
		return instanceDriver.get(`/chat-photos?driver_id=${driver_id}&file_id=${file_id}`);
	},

	async setChatPhoto( driver_id: string, dialog_id:string, name:string, extension:string, file: Blob ): Promise<any> {
		if (!file) return new Promise((resolve, reject) => reject({message:'Ошибка формата файла'}));
		let data = await toBase64(file) as string;
		data = data.split(',')[1];
		return instanceDriver.put(`/chat-photos`,{driver_id, dialog_id, data, extension, name});
	},

	setDriverStatus({id, status, dates}: { id: string, status: UserStatus, dates: {date:string, status:string}[] }) {
		return instanceDriver.put('/driver-status', {id, dates})
			.then(response => response)
			.catch(error => error);
		// return new Promise<{ id: string; status: UserStatus; error_message?: string; }>((resolve, reject) => {
		// 	resolve({
		// 		id,
		// 		status
		// 	});
		// });
	},

	setOrderExecution( body : { status: EnumStatusExecution, driver_id: string, order_id: string, point:number }) {
		return instanceDriver.post('/order-execution', body);
	},

	setLookedOrder( driver_id: string, order_id:string) {
		const body = {
			driver_id,
			orders: [ { id: order_id, is_looked: true }]
		};
		return instanceDriver.post('/looked-orders', body)
	},

	getOrderInProgress(driver_id: string): Promise<any> {

		return instanceDriver.get(`/current-order?driver_id=${driver_id}`);
	},
	getOrderExecution( order_id:string, driver_id:string){
		return instanceDriver.get(`/order-execution?order_id=${order_id}&driver_id=${driver_id}` )
			.then(response => response)
			.catch(error => error);
	},


	setDriverCoordinate(driver_id:string, point:TypeLonLat){
		// let body = {id:driver_id, lat:point.lat, lon: point.lon}
		// return instanceDriver.put(`/car-position`, body);
		return {status:'success', error_message:""}
	},

	setDeviceID(driver_id:string, device_id:string){
		let body = {driver_id:driver_id, device_id:device_id};
		return instanceDriver.post(`/driver-device`, body);
	},

	getDeviceList(driver_id:string){
		return instanceDriver.get(`/driver-device?driver_id=${driver_id}`);
	},
	getDeviceStatus( device_id:string, ip:string = ''){
		return instanceDriver.get(`/device-status?${ device_id ? 'device_id='+device_id : ''}${ ip ? ( (device_id?'&':'') + 'ip='+ip) : ''}`);
	},
	async getAuthUser(driver_id:string){
		return  instanceDriver.get(`/auth/me?driver_id=${driver_id}`);

	},
	async getUserInfo(id:string, key_name = 'driver_id'){
		let data = await instanceDriver.get(`/drivers?${key_name}=${id}`);
		if (data && data.data && data.data.status == 'error') {
			if (key_name == 'driver_id') return instanceDriver.get(`/drivers?id=${id}`);
			else return instanceDriver.get(`/drivers?driver_id=${id}`);
		}
		return data;
	},

	async statistic({driver_id, date_from, date_to}: { driver_id: string, date_from: string, date_to: string }) {
		return instanceDriver.get(`/statistic?driver_id=${driver_id}&date_from=${date_from}&date_to=${date_to}`);
		return new Promise<{ debit: number; credit: number; price: number; fuel_rate: number; distance: number; }>((resolve, reject) => {
			resolve({
				debit: 15590,
				credit: 3223,
				price: 723,
				fuel_rate: 8,
				distance: 154
			});
		});
	},

	getArticles(id?:string) {
		return instanceDriver.get("/articles" + (id ? '?id='+id : ''));
	},

	getSystemMessage(driver_id: string): Promise<AxiosResponse<SystemMessage[]>> {
		return instanceDriver.get(`/system-message?driver_id=${driver_id}`);
	},

	getSystemServiceMessage(driver_id: string): Promise<AxiosResponse<ISystemReceivedObject[]>> {
		return instanceDriver.get(`/system-service?driver_id=${driver_id}`);
	},

	setSystemServiceMessage(driver_id: string, data: {action:string, action_id:number, ip?:string, user_agent: string, driver_id?: string }){
		let body ={ driver_id, 	message: data };
		return instanceDriver.post(`/system-service`, body);
	},

	deleteSystemServiceMessage(driver_id: string, id: string): Promise<AxiosResponse<SystemMessage[]>> {
		return instanceDriver.delete(`/system-service?driver_id=${driver_id}&id=${id}` );
	},

	getUsedOrders(data : {driver_id: string, start: number, count: number}): Promise<any> {
		return instanceDriver.get(`/orders?driver_id=${data.driver_id}&start=${data.start}&count=${data.count}`);
	},

	getCarPosition( driver_id: string ): Promise<any> {
					if (USE_DEBUG_ROUTE)
									return instanceDebug.get(`/car-position-current?driver_id=${driver_id}`);
		return instanceDriver.get(`/car-position-current?driver_id=${driver_id}`);
	},

	async getOrderPhoto( driver_id: string, order_id:string,): Promise<any> {
		return instanceDriver.get(`/order-files?driver_id=${driver_id}&order_id=${order_id}`);
	},

	async setOrderPhoto(order_id:string, driver_id: string, point_number:number, type:string, file: Blob ): Promise<any> {

		if (!file) return new Promise((resolve, reject) => reject({message:'Ошибка формата файла'}));
		let data = await toBase64(file) as string;
		data = data.split(',')[1];
		return instanceDriver.post(`/order-files`,{order_id, driver_id, point_number, data, type, extension:'jpg'});
	},
	async deleteOrderPhoto( driver_id: string, id: string ): Promise<any> {
		return fetch(apiDriverUrl+'/order-files', {
			method: 'DELETE',
			body: JSON.stringify( {driver_id, id})
		}).then(response => response.json());
	},
	getOwnIP(): Promise<any> {
		return instanceIP.get(``);
	},

	checkOrderTaking(driver_id:string, order_id: string, status:string){
		let body ={ driver_id, order_id, status };
		return instanceDriver.post(`/check-order-taking`, body);
	},

	getCategoriesFull() {
		return instanceDriver.get('/car-types-tree')
			.then((response) => {
				if ( _check_data_on_error_array(response)) return [];
				return response.data;
			})
			.catch(error => console.error(error));
	},

	getCarTypes() {
		return instanceOrder.get("/car-types-tree");
	},

	getBodyOptionCharacteristics() {
		return instanceDriver.get("/body-option-characteristics");
	},

	getBodyOptionCharacteristicsValues() {
		return instanceDriver.get("/body-option-characteristics-values");
	},

	getBodyTypes() {
		return instanceDriver.get("/body-types");
	},

	getBodyOptions() {
		return instanceDriver.get("/body-options");
	},

	getCarTypesDriver() {
		return instanceDriver.get("/car-types");
	},

	getAdditionalRequirements() {
		return instanceDriver.get("/additional-requirements");
	},

	getRoute(order_id:string) {
		return instanceDriver.get(`/routes?order_id=${order_id}`);
	},
};

export const ordersAPI = {

	// Получение информации о заказе
	getOrder(id: string, driver_id:string, is_demo:boolean = false) : Promise<any> {
		if (id=="9a8afe95-743a-11ec-8eb2-00155d010f1b" && isDebug )
			return new Promise(resolve => resolve( {data : {"id": "7a58876a-7f60-11ec-8eb2-00155d010f1b",
					"draft": false,
					"max_dimensions": false,
					"date": "2022-02-14T09:00:00T11:45:36",
					"TIN": "7713486435",
					"comment": "",
					"body_option_id": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03",
					"body_type_id": 0,
					"car_type_id": "52b30be4-49d5-11e7-9696-e41f13c2b942",
					"additional_requirements": [
						{
							"id": "ccedba36-bf88-4784-8ed4-d34774ea1759",
							"value": "52b30be4-49d5-11e7-9696-e41f13c2b942"
						},
						{
							"id": "40965daa-9a36-4562-b420-ec4475bb5e51",
							"value": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03"
						},
						{
							"id": "22dba867-4bd4-11df-ab17-005056000008",
							"value": "false"
						},
						{
							"id": "22dba865-4bd4-11df-ab17-005056000008",
							"value": "false"
						},
						{
							"id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42",
							"value": "true"
						},
						{
							"id": "9cebde1b-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde18-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde16-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde10-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "f61aa4e2-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "d8c67cfe-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "b891966b-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "e7736c7c-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "905644c4-86e2-11ea-a9c9-00155d8e4e03",
							"value": "51.98"
						},
						{
							"id": "905644c5-86e2-11ea-a9c9-00155d8e4e03",
							"value": "21.45"
						},
						{
							"id": "e59064a9-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "e59064aa-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44",
							"value": "false"
						},
						{
							"id": "51fed432-fb5e-11e7-9ae7-e41f13c2b942",
							"value": "false"
						},
						{
							"id": "9a05e373-21df-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "aa507685-21df-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "c01ac72c-fcf4-11e7-9ae7-e41f13c2b942",
							"value": "false"
						},
						{
							"id": "8cd783f4-7d03-11ea-a9c9-00155d8e4e03",
							"value": "true"
						},
						{
							"id": "4aebf0f5-7f95-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "c985b210-7f99-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "4cf93c35-9788-11eb-8e9d-00155d010f05",
							"value": "false"
						}
					],
					"body_option_characteristics": [
						{
							"id": "ccedba36-bf88-4784-8ed4-d34774ea1759",
							"value": "52b30be4-49d5-11e7-9696-e41f13c2b942"
						},
						{
							"id": "40965daa-9a36-4562-b420-ec4475bb5e51",
							"value": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03"
						},
						{
							"id": "22dba867-4bd4-11df-ab17-005056000008",
							"value": "false"
						},
						{
							"id": "22dba865-4bd4-11df-ab17-005056000008",
							"value": "false"
						},
						{
							"id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42",
							"value": "true"
						},
						{
							"id": "9cebde1b-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde18-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde16-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "9cebde10-25e2-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "f61aa4e2-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "d8c67cfe-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "b891966b-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "e7736c7c-5fe5-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "905644c4-86e2-11ea-a9c9-00155d8e4e03",
							"value": "51.98"
						},
						{
							"id": "905644c5-86e2-11ea-a9c9-00155d8e4e03",
							"value": "21.45"
						},
						{
							"id": "e59064a9-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "e59064aa-40df-11ea-a9c6-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44",
							"value": "false"
						},
						{
							"id": "51fed432-fb5e-11e7-9ae7-e41f13c2b942",
							"value": "false"
						},
						{
							"id": "9a05e373-21df-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "aa507685-21df-11ea-a9ad-00155d8e4e05",
							"value": "false"
						},
						{
							"id": "c01ac72c-fcf4-11e7-9ae7-e41f13c2b942",
							"value": "false"
						},
						{
							"id": "8cd783f4-7d03-11ea-a9c9-00155d8e4e03",
							"value": "true"
						},
						{
							"id": "4aebf0f5-7f95-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "c985b210-7f99-11ea-a9c9-00155d8e4e03",
							"value": "false"
						},
						{
							"id": "4cf93c35-9788-11eb-8e9d-00155d010f05",
							"value": "false"
						}
					],
					"routes": [
						{
							"id": 0,
							"adress": "РОССИЯ, 129337, Москва г, Красная Сосна ул",
							"adress_comment": "",
							"adress_longitude": 37.682458,
							"adress_latitude": 55.849923,
							"company": "",
							"what_to_do": "",
							"working_hours": {
								"time_from": "",
								"time_to": "",
								"lunch_from": "",
								"lunch_to": "",
								"max_landing_time": "",
								"no_lunch": false
							},
							"action_forwarder": false,
							"action_unloading": false,
							"action_loading": true,
							"action_documents": false,
							"contact_persons": [
								{
									"full_name": "",
									"phone": "",
									"phone_ext": ""
								}
							],
							"files_ids": []
						},
						{
							"id": 1,
							"adress": "РОССИЯ, 142181, Московская обл, Подольск г, Коледино д, Троицкая ул, строение 20",
							"adress_comment": "",
							"adress_longitude": 37.582799,
							"adress_latitude": 55.377117,
							"company": "",
							"what_to_do": "",
							"working_hours": {
								"time_from": "",
								"time_to": "",
								"lunch_from": "",
								"lunch_to": "",
								"max_landing_time": "",
								"no_lunch": false
							},
							"action_forwarder": false,
							"action_unloading": true,
							"action_loading": false,
							"action_documents": false,
							"action_cargo_photo": true,
							"contact_persons": [
								{
									"full_name": "",
									"phone": "",
									"phone_ext": ""
								}
							],
							"files_ids": []
						}
					],

					"cargo": {
						"places":[{"size":{"length":1,"width":2.4,"height":1,"weight":222}}],
						"pallets":[{"pallet_type_id":"053f2dd2-2133-11ea-a9ad-00155d8e4e05","quantity":2,"size":{"length":1.2,"width":1.2,"height":1,"weight":10}}],
						"packages":[{"package_type_id":"fc53d0e8-8537-11ea-a9c9-00155d8e4e03","quantity":30,"size":{"length":0.5,"width":0.6,"height":0.3,"weight":50}}],
						"max_dimensions":false
					},
					"vehicle": null,
					"driver": null,
					"number": "341",
					"status": "Нахначен",
					"status_id": 1,
					"amount": 0,
					"distance": 77.52
				}}));
		return instanceDriver.get("/orders?id=" + id+ `&driver_id=${driver_id}` + (is_demo ? '&demo=true' : ''));
	},



	getArchiveRoute(order_id:string) {
		return instanceOrder.get(`/car-position?order_id=${order_id}`);
	},

	// Получение списка доступных заказов
	getList(driver_id: string, type: OrdersListType,offset:number=0,count:number=10): Promise<any> {
		if (driver_id) {
			if (type == 'set' && isDebug) return new Promise(resolve => resolve( {data : [{
					"id": "9a8afe95-743a-11ec-8eb2-00155d010f1b",
					"address": "РОССИЯ, 129347, Москва г, Ярославское ш, дом 146, корпус 1",
					"lon": 37.731552,
					"lat": 55.87787,
					"price": 2775,
					"date": "2022-01-13T10:00:00",
					"is_looked": false,
					"car_type_id": "52b30bdd-49d5-11e7-9696-e41f13c2b942",
					"car_body_option_id": "c50b92ab-52d3-11ea-a9c9-00155d8e4e03",
					"full_distance": 110.83,
					"point_count": 6,
					"type": "set",
					"status": "Назначен",
					"status_id": 1
				}]} ));
			return instanceDriver.get(`/list-orders?driver_id=${driver_id}&type=${type}&offset=${offset}&count=${count}`);
			// return instanceLocal.get(`/list-orders?driver_id=${driver_id}&type=${type}`);
		}
		else return instanceDriver.get(`/list-orders?&type=reserve`);

	},

	getBodyOptionCharacteristics() {
		return instanceDriver.get("/body-option-characteristics");
	},

	getBodyOptionCharacteristicsValues() {
		return instanceDriver.get("/body-option-characteristics-values");
	},

	getBodyTypes() {
		return instanceOrder.get("/body-types");
	},

	getBodyOptions() {
		return instanceDriver.get("/body-options");
	},

	getCarTypes() {
		return instanceOrder.get("/car-types-tree");
	},
	getCarTypesDriver() {
		return instanceDriver.get("/car-types");
	},
	getCategoriesFull() {
		return instanceOrder.get('/car-types-tree')
			.then((response) => {
				if (_check_data_on_error_array(response)) return [];
				return response.data;
			})
			.catch(error => console.error(error));
	},

	getAdditionalRequirements() {
		return instanceOrder.get("/additional-requirements");
	},

	getPackInfo(data: PackRequestType) : Promise<CPackInfo> {
		return instanceOrder.post('/pack', data).then(response=> response.data)
	}

}

export const phoneAPI = {
	sendSms(phone: string) {
		let requestData = {phone};
		return instanceOrder.post('/send-sms-code', requestData)
			.then(response => response)
			.catch(error => error);
	},

	checkSms(phone: string, code: string) {
		return instanceOrder.get('/check-sms-code?phone=' + phone + '&code=' + code)
			.then(response => response)
			.catch(error => error);
	},

	checkPhone(phone: string) {
		return instanceOrder.get('/check-tin-phone?phone=' + phone)
			.then(response => response)
			.catch(error => error);
	}
};

export const tinAPI = {
	checkTin(tin: string) {
		return instanceOrder.get('/check-tin?bik=' + tin)
			.then(response => response)
			.catch(error => error);
	}
};

export const vehicleAPI = {
	getCarTypes() {
		return instanceDriver.get('/car-types')
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptions(carTypeId: string) {
		return instanceDriver.get('/body-options?car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChs(bodyOptionId: string, bodyTypeId: string, carTypeId: string) {
		return instanceDriver.get('/body-option-characteristics?body_option_id=' +
			bodyOptionId + '&body_type_id=' + bodyTypeId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChValues(bodyOptionChId: string, carTypeId: string) {
		return instanceDriver.get('/body-option-characteristics-values' +
			'?body_option_characteristics_id=' + bodyOptionChId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getCarPasses() {
		return instanceDriver.get('/car-pass')
			.then(response => response)
			.catch(error => error);
	}
};

export const registrationAPI = {
	postData(data: any) {
		return instanceDriver.post('/drivers', data)
			.then(response => response)
			.catch(error => error);
	},
	putData(data: any) {
		return instanceDriver.put('/drivers', data)
			.then(response => response)
			.catch(error => error);
	},
	postFile(data: any) {
		return instanceDriver.post('/driver-files', data)
			.then(response => response)
			.catch(error => error);
	},

	getFile(id: string) {
		return instanceDriver.get('/driver-files?id=' + id)
			.then(response => {
				if (!response.data.file) {
					console.log('Error fetching file');
					return null;
				}
				return response.data.file;
			})
			.catch(error => error);
	},

	deleteFile(id: string) {
		// return instanceDriver.delete('/driver-files?id='+id)
		return fetch(apiDriverUrl+'/driver-files', {
			method: 'DELETE',
			body: JSON.stringify( {id:id})
		}).then(response => response.json())
	}
}

export const referralsAPI = {
	getData(driverId: string) {
		return instanceOrder.get('/referals?driver_id=' + driverId)
			.then(response => response)
			.catch(error => error);
	},

	sendDriver(driverId: string, phone: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			type: 'driver'
		}
		return instanceOrder.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	},

	sendClient(driverId: string, phone: string, name: string, email: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			full_name: name,
			email: email,
			type: 'client'
		}
		return instanceOrder.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	}
}

export const toBase64 = (blob: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

export const addressAPI = {
	getAddressByCoords(lat: string, lon: string) {
		let requestData = { lat, lon };
		return dadata.post('/geolocate/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getCountries(query: string) {
		let requestData = { query, count: 5 };
		return dadata.post('/suggest/country', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getSuggestions(query: string) {
		let requestData = {
			query,
			count: 5,
			locations: [{ country: "*" }],
			from_bound: { value: "country" },
			to_bound: { value: "street" }
		};
		return dadata.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	splitAddress(query: string) {
		let requestData = {
			query,
			count: 1,
			locations: [{ country: "*" }]
		};
		return dadata.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	}
}

export const sendPhoto = async (blob: Blob, name: string) => {
	if (!blob) return null;
	const data = await toBase64(blob) as string;
	let response = await registrationAPI.postFile({
		name: name,
		data: data.split(',')[1]
	});
	if (response.status === 200 && response.data) {
		return response.data;
	} else {
		return null;
	}
}


export const toBlob = (base64: string, contentType = 'image/jpg', sliceSize = 512) => {
	const byteCharacters = atob(base64);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const blob = new Blob(byteArrays, {type: contentType});
	return blob;
}

export const getPhoto = async (id: string) => {
	let data = await registrationAPI.getFile(id);
	if (data) {
		return toBlob(data);
	} else {
		return null;
	}
}

export const loadPhotos = async (ids: string[]): Promise<Blob[]|null[]> => {
	let photos: any[] = [] ;
	let blob: Blob | null;
	for (let i=0; i< ids.length; i++)
		if (ids[i] != "") {
			blob = await getPhoto(ids[i]);
			photos.push(blob ? blob : null);
		}
		else
			photos.push(null);

	return new Promise(resolve => resolve(photos) );
}

export const getAddress = async (value: string) => {

	if (!value || typeof value != "string" || value.trim() == "") return  '';
	let response: any = await addressAPI.splitAddress(value);
	if (response && response.data && response.data.suggestions && response.data.suggestions.length > 0) {
		return response.data.suggestions[0];
	} else {
		return null;
	}
}


export const routeAPI = {
	getRoute(points:TypeLonLat[]) {
					console.log('getRoute points', points)
		let point1 = points[0];
		let point2 = points[points.length-1];
		let via = points.filter((x,i)=> i!=0 && i!=points.length-1)
			//&via=55.6759%2C37.48439&via=55.66453%2C37.47354
			.map((x, i)=> `&via=${x.lat}%2C${x.lon}!passThrough=false`).join('')
		if (via) via=`${via}`;
		return instanceRoute(`routes?xnlp=CL_JSMv3.1.30.10&apikey=${hereMapApiKey}&routeattributes=all&routingMode=fast&`+
																											`transportMode=car&return=polyline%2CtravelSummary&origin=${point1.lat}%2C${point1.lon};matchSideOfStreet=onlyIfDivided&`+
																											`destination=${point2.lat}%2C${point2.lon}${via}`);
		//55.767265%2C37.59807&destination=55.887821%2C37.720555&via=55.7977029%2C37.620632
		//https://router.hereapi.com/v8/routes?xnlp=CL_JSMv3.1.30.10&apikey=VLgmZFK_rJLT4lyZ5W62L2gTZoIT3eFqW5OgEP9V_7Q&routingMode=fast&transportMode=car&origin=55.767265%2C37.59807&destination=55.887821%2C37.720555&return=polyline%2CtravelSummary&via=55.7977029%2C37.620632
	}
}

export const getRouteMutation = async (id:string) =>{
	return userAPI.getRoute(id).then((res)=>{
		if (!res || !res.data) return res;
		console.log('res.data', res.data)
		if (res.data && Array.isArray(res.data.points_to_draw) && res.data.points_to_draw.length ) {
			console.log('if res.data.points_to_draw.length' )
			if (res.data.points_to_draw[0].mkad_start) {
				console.log('if res.data.points_to_draw.mkad_start' )
				res.data.shapes = res.data.shapes.slice(1)
			}
			if (res.data.points_to_draw[res.data.points_to_draw.length -1].mkad_finish) {
				console.log('if res.data.points_to_draw.mkad_finish' )
				res.data.shapes = res.data.shapes.slice(0, res.data.shapes.length - 1)
			}
		}
		console.log('res.data', res.data)
		return res.data == {} ? null : res;
	})
}
