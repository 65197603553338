import React, { FC } from 'react'
import "./ChatMenu.css";
import closeIcon from "../../../images/closeIcon.svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {MenuItem} from "./MenuItem";
import {IChatDialog, IChatGroup} from "../../../redux/reducers/chat/@types";

interface IProps  {

    onClose: () => void,
    group?: IChatGroup| undefined,
    onSelectChatGroup?: (group: IChatGroup) => void | undefined
    onSelectChatDialog?: (group: IChatDialog) => void | undefined
}


export const ChatMenu: FC<IProps> = ({ onClose, group, onSelectChatGroup, onSelectChatDialog  }) => {
    const {  chat } = useSelector((state: RootState) => ({ chat: state.chatReducer }));


    // console.log('chat.groups', chat.groups);
    return (
        <>
            {!group &&
                <div className={"menuHeaderWrapper"}>
                    <div>
                        <div className={"header"}>Чаты </div>
                    </div>
                     <img alt='Закрыть чат' src={closeIcon} onClick={onClose} />
                </div>
            }
            {group &&
                <div className={"menuHeaderWrapper menuHeaderWrapperGroup"} onClick={onClose} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none"
                         className="injected-svg" data-src="/static/media/back-arrow.025de6d0.svg"
                    >
                        <path
                            d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z"
                            fill="#888E99"></path>
                    </svg>


                        <div className={"header"}>Чаты {group ? group.name.toLowerCase() : ''}</div>


                </div>
            }
            <div className={"menuBodyWrapper"}>
                {
                    !group  &&  chat.groups.map((group)=>   <MenuItem onChatEnter={()=>{if (onSelectChatGroup) onSelectChatGroup(group)}} name={group.name} key={group.group_id}   /> )
                }

                {
                    group  &&   group.dialogs.map((dialog)=>   <MenuItem onChatEnter={()=>{if (onSelectChatDialog) onSelectChatDialog(dialog)}} name={dialog.full_name} key={dialog.dialog_id} order_number={dialog.order_number}  order_date={dialog.order_date}  /> )
                }
            </div>
        </>
    )
}


