import {
  SET_EDIT_DRIVER,
  SET_EDIT_VEHICLE,
  SET_RECOMMENDATION,
  SetEditDriverDataAction,
  SetEditVehicleDataAction,
  SetRecommendationDataAction
} from "./@types";
import {DriverInfo, RecommendationsInfo, VehicleInfo} from "../user/@types";


export const setEditDriverData = (just_passport: boolean, index: number, data: DriverInfo, im_a_driver:boolean = true): SetEditDriverDataAction => ({
  type: SET_EDIT_DRIVER,
  just_passport,
  index,
  data,
  im_a_driver
});

export const setEditVehicleData = (index: number, data: VehicleInfo): SetEditVehicleDataAction => ({
  type: SET_EDIT_VEHICLE,
  index,
  data
});

export const setRecommendationData = (recommendations: RecommendationsInfo[]): SetRecommendationDataAction => ({
  type: SET_RECOMMENDATION,
  recommendations,

});
