import React, {useState} from "react";
interface IProps {
				onTurn: Function,
}
const JustNavigator: React.FunctionComponent<IProps> = ({ onTurn }) => {
				const [pushed, togglePushed] = useState(false);
				const toggle = () =>{
								togglePushed(!pushed);
								if (onTurn)	onTurn(!pushed);
				}

				return(
					<>
									<div className={'btn btn_middle btn_default btn_just_navigator ' + (pushed ? 'btn_just_navigator_pushed' : '')} onClick={toggle}>Go</div>
						</>
				)
}

export default JustNavigator;