import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/store";
import {UserState} from "../../redux/reducers/user/@types";
import {userAPI} from "../../api";
import "./OrderBook.css";
import moment from "moment";
import {OrderFullInfo, OrdersState} from "../../redux/reducers/orders/@types";

import {EnumStatusResponse} from "../../api/@types";
import {
    popSystemStatus,
    setOverlay,
    setSystemStatus,
    toggleOrderBookWindow,
    toggleSystemStatus
} from "../../redux/reducers/system/actions";
import {SetOverlayAction, SystemState, ToggleOrderBookWindowAction} from "../../redux/reducers/system/@types";
import OrderSmallCard from "../OrderSmallCard/OrderSmallCard";
import Loader from "react-loader-spinner";

import ArrowUpSVG from '../../../images/arrow-up.svg';
import ArrowDownSVG from '../../../images/arrow-down.svg';

import {OrdersListType, SystemStatus} from "../../@types";
import {setOrder} from "../../redux/reducers/orders/actions";
import { ReactSVG } from "react-svg";

interface IProps {
    state: {
        user: UserState;
        system: SystemState;
        orderState: OrdersState
    },
    toggleOrderBookWindow: ()=> ToggleOrderBookWindowAction
    setOverlay: (overlay:boolean)=> SetOverlayAction
    zIndex?:number;

}

enum EnumSortType{
    Today, Future, Past
}

interface ILocalState {
    orders: OrderFullInfo[];
    orders_today: OrderFullInfo[];
    orders_tomorrow: OrderFullInfo[];
    orders_past: OrderFullInfo[];
    error: string;
    from: number;
    sort: EnumSortType;
    loading: boolean;
    current_order :OrderFullInfo | null;

}

const OrderBook: React.FunctionComponent<IProps> = ({ state,toggleOrderBookWindow, setOverlay, zIndex }) => {
    const dispatch = useDispatch();
    const countInRequest = 10;

    const [localState, setLocalState] = useState<ILocalState>({
        orders: [],
        orders_today:  [],
        orders_tomorrow: [],
        orders_past:  [],
        error:"",
        from:0,
        sort: EnumSortType.Today,
        loading: false,
        current_order: null
    });

    //@ts-ignore
    window.localState = localState;
    const { user } = state;

    const today =  moment(new Date());

    const getOrdersByType = (type : EnumSortType, orders: OrderFullInfo[] | undefined)=>{

        console.log('getOrdersByType', type, orders);

        if (!orders) return [];

        return orders
            .filter( item=> {
                let dt = item.date.split('T');
                // let date_order =  moment(dt[0]+'T'+dt[1]);
                let date_order =  moment(dt[0]).format('YYYY-MM-DD');
                let today_order =  moment(today.format('YYYY-MM-DD'));
                // console.log('today=',today.format('D-M-Y'), 'dt[0]=', dt[0],'date_order=', date_order, today_order.diff(date_order, 'days'));

                return  ((type === EnumSortType.Today && today_order.diff(date_order, 'days') === 0) ||
                         (type === EnumSortType.Future && today_order.diff(date_order, 'days') < 0) ||
                         (type === EnumSortType.Past && today_order.diff(date_order, 'days') > 0) );
            })
    };

    useEffect(() => {
        setLocalState((s) => ({...s, loading:true}));

         userAPI.getUsedOrders({driver_id: user.driver_id, start: localState.from, count: countInRequest}).then((res) => {
            console.log(' res.data ', res.data )
            if (res && res.data && res.data.status === EnumStatusResponse.success) {

                setLocalState((s) => ({...s,
                    orders: res.data.order ? res.data.order : [],
                    orders_today: getOrdersByType( EnumSortType.Today, res.data.order),
                    orders_tomorrow: getOrdersByType( EnumSortType.Future, res.data.order),
                    orders_past: getOrdersByType( EnumSortType.Past, res.data.order).sort( (a,b)=> a.date === b.date ? 0 : a.date >= b.date ? -1 : 1 ),
                }));

            }
            setLocalState((s) => ({...s, loading:false}));

        }).catch(error=>{
            setLocalState((s) => ({...s, error: error}));
            setLocalState((s) => ({...s, loading:false}));
        });
    }, []);

    let setSortMode = (mode:EnumSortType) => {
        setLocalState((s) => ({...s, sort: mode }));
        console.log(localState.orders_past)
    };

    let onOrderClick = (item:any  | null) => {
        dispatch(setOrder(item, localState.sort === EnumSortType.Past ? OrdersListType.Last : OrdersListType.Set));
        dispatch(toggleSystemStatus(SystemStatus.FullOrderCard));

        setLocalState((s) => ({...s, current_order: item }));
    };

    let closeWindow = () => {
        dispatch(popSystemStatus());
    };
    let tmp_array : number[] = [];
    const onCollapse = (id:string) => {
        let el = document.getElementById(id);
        let icon = document.getElementById('icon_'+id);
        if (el && el.classList.contains('hidden_orders') ){
            el.classList.remove('hidden_orders');
            icon && icon.classList.add('order_book_collapse_icon_up');
        }else {
            el && el.classList.add('hidden_orders') ;
            icon && icon.classList.remove('order_book_collapse_icon_up');
        }
    }

    useEffect(()=>{
        console.log('set orders', localState.orders)
    }, [localState.orders])
    // @ts-ignore
    return (
        <>
            {
              <div className="order_book_page">
            <div className="page_back_link" onClick={closeWindow}>
                <svg width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z" fill="#888E99"/>
                </svg>
            </div>
            <div className="order_book_header">
                <div className="page_title">Журнал заказов </div>
                <div className="orders_tabs">
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Today ? "active" : "")}  onClick={()=>setSortMode(EnumSortType.Today)}>Сегодня</div>
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Future ? "active" : "")} onClick={()=>setSortMode(EnumSortType.Future)}>Будущие</div>
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Past ? "active" : "")} onClick={()=>setSortMode(EnumSortType.Past)}>Прошлые</div>
                </div>
            </div>
            <div className="order_book_container">
                <div className="orders_list">
                    {localState.loading  &&
                        <div className="loader">
                            <Loader type="Puff" color="#00BB40" width={'8rem'} height={'8rem'}/>
                            <div className="loader-text">Идет загрузка данных</div>
                        </div>
                    }

                    {!localState.loading && localState.sort === EnumSortType.Today &&
                        localState.orders_today.map((item, i) =>  (<OrderSmallCard key={'card-'+item.id + i} order={item} isLooked={true} onClick={()=>{onOrderClick(item)}} state={state.orderState} user_coordinate={user.current_coordinates}  />))
                    }
                    {!localState.loading && localState.sort === EnumSortType.Future &&
                        localState.orders_tomorrow.map((item, i) => (
                            <OrderSmallCard key={'card-'+item.id + i} order={item} isLooked={true} onClick={()=>{onOrderClick(item)}} state={state.orderState} user_coordinate={user.current_coordinates}  />
                        ))
                    }
                    {!localState.loading && localState.sort === EnumSortType.Past &&
                        localState.orders_past.map((item, i) =>  {
                            if (i === 0 ) tmp_array = [];
                            let order_date = item.date.split('T')[0] ;
                            let order_date_time = item.date.split('T')[0] +'T' + item.date.split('T')[1];
                            console.log('tmp_array', tmp_array)
                            if (tmp_array.some(z=> z===i)) return <div key={'empt'+i}></div>;
                            return  <div className='order_book_collapse ' key={"orders_past_"+i} >
                                        <div className='order_book_collapse_title' onClick={()=> onCollapse('colapser'+i) }  >
                                            {moment(order_date_time).format("DD MMMM YYYY HH:mm")}
                                            <div className='order_book_collapse_icon' id={'icon_colapser'+i}> </div>
                                        </div>
                                        <div className='hidden_orders' id={'colapser'+i}>
                                        { localState.orders_past.map((item2, j) => {
                                             if (j>=i && order_date === item2.date.split('T')[0] && !tmp_array.some(z=> z===j)) {
                                                  tmp_array.push(j);
                                                  return <OrderSmallCard key={'card-'+item2.id + j} order={item2} isLooked={true} onClick={()=>{onOrderClick(item2)}} state={state.orderState} user_coordinate={user.current_coordinates}  />
                                              } else
                                                  return<></>
                                          })
                                        }
                                        </div>
                                    </div>
                        })
                    }

                </div>
            </div>
        </div>

            }
            {/*{localState.current_order != null &&*/}
            {/*    <OrderFullCard order={localState.current_order} onClose={()=> onOrderClick(null)} />*/}
            {/*}*/}
       </>
    );
}

const mapStateToProps = (state: RootState) => ({
    state: {
        user: state.userReducer,
        system: state.systemReducer,
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, {toggleOrderBookWindow, setOverlay })(OrderBook);
