import React, { FC, useRef, useEffect } from "react"
import {useDispatch, useSelector} from "react-redux"

import {EnumChatMessageType, IChatDialog, IChatIsReadDialogMessage} from "../../../redux/reducers/chat/@types";
import {RootState} from "../../../redux/store";
import {ChatMessageItem} from "./ChatMessageItem";
import {ChatFooter} from "./ChatFooter";
import {EnumWindowMessageType} from "../../../redux/reducers/system/@types";
import {userAPI} from "../../../api";
import {setChatNewMessages} from "../../../redux/reducers/chat/chatReducers";
import {setChatMessages} from "../../../redux/reducers/chat/chatActions";

let checkInterval : any = undefined;

interface IProps {
    dialog: IChatDialog,
    onClose: Function
}
export const ChatMessages: FC<IProps> = ({ dialog,onClose }) => {

    const dispatch = useDispatch();
    const scrollRef = useRef(null);
    const {chat} = useSelector((state: RootState)=> ({chat:state.chatReducer}));
    const {user} = useSelector((state: RootState)=> ({user:state.userReducer}));

    // const users = useSelector(getChatUsersSelector),
            // user = users.filter(user => user.id === userID)[0],
            // logistID = users.filter(us => us.type === 'logist' && us.orderID === user.orderID).length ? users.filter(user => user.type === 'logist')[0].id : '',
            // messages = useSelector(getChatMessagesSelector)

    useEffect(() => {
        let isMounted = true;
        if (checkInterval) clearInterval(checkInterval);
        checkInterval = setInterval(async () => {
            if (!isMounted) return clearInterval(checkInterval);
            let unread_messages = chat.messages.filter(x=> !x.read).map(x=>x.id).join(',');
            if (!unread_messages.length) return ;

            let res = await userAPI.getChatCheckDialogUnreadMessages(user.driver_id, unread_messages).catch((e)=> console.error('chat error unread_messages', e.message));
            if (!res || !Array.isArray(res.data) || !res.data.length) return ;
            let arr:IChatIsReadDialogMessage[] = res.data;
            let msg = [...chat.messages];
            msg.forEach(x=> { if ( arr.some(z=> z.id == x.id && z.read))  x.read = true; });
            if (msg.length != chat.messages.length)
                dispatch(setChatMessages(msg));
        }, 3900);

        return () => { isMounted = false };

    }, [chat.messages]);

    useEffect(() => {
       // при получении нового сообщения и при стартовой загрузке прокручиваем к началу
        const block = document.getElementById('chatMessagesBlock');
        block && (block.scrollTop = block.scrollHeight);
        if (!scrollRef.current) return;
         //@ts-ignore
         scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

    }, [chat.messages]);

    return (
        <>
            <div className= "menuHeaderWrapper dialogHeaderWrapper" >
                <div className="dialogHeader" onClick={()=>onClose()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none"
                         className="injected-svg" data-src="/static/media/back-arrow.025de6d0.svg"
                         >
                        <path
                            d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z"
                            fill="#888E99"></path>
                    </svg>

                    <div className={"header"}>{dialog.full_name}</div>
                </div>
            </div>
            <div className={"menuBodyWrapper menuBodyWrapperChatMessages"} >
                <div className="chat_messages_scroll_container" ref={scrollRef}>
                {chat.messages && chat.messages.length > 0 && chat.messages.map( (message,i) =>  <ChatMessageItem message={message} key={message.id+ ''+i} />)}
                </div>
            </div>
            <ChatFooter dialog={dialog} />
        </>
    )
}

