import {
  EnumStatusExecution,
  OrdersActionTypes,
  OrdersState,
  SET_ADDITIONAL_REQUIREMENTS,
  SET_ASSIGNED_ORDER,
  SET_BODY_OPTIONS,
  SET_BODY_OPTIONS_CHARACTERISTICS,
  SET_BODY_OPTIONS_CHARACTERISTICS_VALUES,
  SET_BODY_TYPES,
  SET_CAR_TYPES,
  SET_ORDER,
  SET_ORDER_PHOTOS,
  SET_ORDERS
} from "./@types";
import {removeSystemStatus, setSystemStatus, setVisibilityOverlay, showMessageTrunk} from "../system/actions";
import {OrdersListType, SystemStatus} from "../../../@types";
import {ordersAPI, userAPI} from "../../../api";
import {setOrder} from "./actions";
import {EnumWindowMessageType} from "../system/@types";
import {EnumStatusResponse} from "../../../api/@types";

const initialState: OrdersState = {
  bodyOptionCharacteristics: [],
  bodyOptionCharacteristicsValues: [],
  bodyTypes: [],
  bodyOptions: [],
  carTypes: [],
  additionalRequirements: [],
  current: {},
  assigned: {},
  items: [],
  photos: []
}

const ordersReducer = (state = initialState, action:  OrdersActionTypes): OrdersState => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        items: action.orders
      }
    case SET_BODY_OPTIONS_CHARACTERISTICS:
      return {
        ...state,
        bodyOptionCharacteristics: action.body_option_characteristics
      }
    case SET_BODY_OPTIONS_CHARACTERISTICS_VALUES:
      return {
        ...state,
        bodyOptionCharacteristicsValues: action.body_option_characteristics_values
      }
    case SET_BODY_TYPES:
      return {
        ...state,
        bodyTypes: action.body_types
      }
    case SET_CAR_TYPES:
      return {
        ...state,
        carTypes: action.car_types
      }
      case SET_BODY_OPTIONS:
      return {
        ...state,
        bodyOptions: action.body_options
      }
    case SET_ADDITIONAL_REQUIREMENTS:
      return {
        ...state,
        additionalRequirements: action.additional_requirements
      }
    case SET_ORDER:

      return {
        ...state,
        current: {...action.order,  type: action.orderType}
      }
    case SET_ASSIGNED_ORDER:
      return {
        ...state,
        assigned: action.order
      }
    case SET_ORDER_PHOTOS:
      return {
        ...state,
        photos: action.photos
      }
    default:
    return state;
  }
};


export const openOrderThunk = (order_id:string, driver_id: string, isAuth:boolean, orderType:OrdersListType, dispatch: any, is_looked: boolean) => {

  console.log('openOrderThunk orderType', orderType);
  dispatch(setSystemStatus(SystemStatus.WaitingWindow));

  return ordersAPI.getOrder(order_id, driver_id, !isAuth).then((res) => {
      dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
      dispatch(setVisibilityOverlay(false));

      if (typeof res.data == "string")
        return showMessageTrunk({type:EnumWindowMessageType.Error, text: res.data}, dispatch);

      if (res.data.status == "error")
        return showMessageTrunk({type:EnumWindowMessageType.Error, text: "Ошибка получения данных по заказу <br>" + res.data.error_message}, dispatch);
      if (!Array.isArray(res.data.order) || res.data.order.length == 0)
        return showMessageTrunk({type:EnumWindowMessageType.Error, text: "Заказ не найден в БД."}, dispatch);

      dispatch(setOrder(res.data.order[0], res.data.order[0].type ? res.data.order[0].type : orderType));
      dispatch(setSystemStatus(SystemStatus.FullOrderCard));
      if (!is_looked) {
        userAPI.setLookedOrder(driver_id, order_id).then();
      }
  }).catch( e=> {
    showMessageTrunk({type:EnumWindowMessageType.Error, text: e.message}, dispatch);
  });
};

export const executionOrderThunk = (data :{point:number, status: EnumStatusExecution, order_id:string, driver_id:string}, dispatch : any) =>{
 return  userAPI.setOrderExecution(data )
      .then((response)=>{
            //console.log(response);
            if (!response.data.status  || response.data.status !== EnumStatusResponse.success) {
              showMessageTrunk({text: response.data.error_message, type: EnumWindowMessageType.Error},dispatch);
              return null;
            }
            return response.data;
         }).catch((error)=>{
    showMessageTrunk({text:"Ошибка соедниения с сервером, попробуйте обновить страницу", type: EnumWindowMessageType.Error},dispatch);
  })
};

export default ordersReducer;
