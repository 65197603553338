import React, {FC, useEffect, useState} from 'react'
import {AddressSuggestions, DaDataAddress, DaDataSuggestion} from "react-dadata";
import {daDataToken} from "../../../../deployment";
import './SelectVehicleAddress.css'
interface IProps{
    value: string ;
    name: string ;
    onValueChanged: (value: string | number, lon?:number, lat?:number) => void;
    placeholder:string;
    classAppend?:string;
    wasError?:boolean;
}
const SelectVehicleAddress : FC<IProps>  = ({value, name, onValueChanged, placeholder,wasError}) => {
    const [currentValue, setCurrentValue] = useState<DaDataSuggestion<DaDataAddress> | undefined>(undefined)
    let [isError, setError] = useState(wasError &&   value == "");
    let ref = React.createRef<AddressSuggestions>()

    useEffect(() => {
        ref.current?.setInputValue(value)
    }, [ value, ref])

    const onChangeHandler = (  selected: DaDataSuggestion<DaDataAddress> | undefined) => {
        if (selected) {
            let data: any = selected?.data ? selected.data : {geo_lon:0, geo_lat:0};
            onValueChanged(selected?.value, data.geo_lon , data.geo_lat );
        }
        ref.current?.setInputValue(selected?.value)
    };
    let isComplete = value != '';

    return (
        <div>
            <div className='input_filtered_container_name'>{name}</div>
            <div className={'input_filtered_container ' + (isComplete ? ' complete' : (isError ? ' error' : ''))}>
                <AddressSuggestions
                    ref = {ref}
                    token={daDataToken}
                    value={currentValue}
                    onChange={onChangeHandler}
                    inputProps={{ placeholder: placeholder || 'Адрес' }}
                    defaultQuery={value}

                />
            </div>
        </div>
    )
}

export default SelectVehicleAddress
