import {TypeLonLatExtended} from "../../../redux/reducers/map/@types";
import {createDomMarker, drawDriverRouteToMap, ease, MARKER_4, ROUTE_DRIVER_LINE_FULL, ROUTE_DRIVER_LINE_REAL} from "./hereFunctions";
import {HereMarkerIconDriver} from "./HereMarkerIconFixed";
import {map} from "./HereMap";

import {NAVIGATION_GET_COORDINATE_TIME_IN_MS} from "../../../deployment";
import moment from "moment/moment";
import {Navigator} from "./Navigator";

export class JustNavigatorController {
				static markerCar : any = null;
				static _lastDateTime : number = 0;
				static navigation_time: number = NAVIGATION_GET_COORDINATE_TIME_IN_MS + NAVIGATION_GET_COORDINATE_TIME_IN_MS*0.1;
				static point_list : TypeLonLatExtended[]= []
				static max_point_count : number = 9
				static move_car(driver_point:TypeLonLatExtended, drawDriverRouteToMap:Function){
								this.move_car_to_point([driver_point.lat, driver_point.lon])
							 let point_array = 	this.get_vector(driver_point);
								if (point_array==null) return;
								drawDriverRouteToMap(point_array, map, ROUTE_DRIVER_LINE_REAL);
				}

				static move_car_to_point(pointCurrent:number[]){
								if (!this.markerCar) {
												this.markerCar = createDomMarker(pointCurrent, 0, HereMarkerIconDriver(), MARKER_4,);
												map.addObject(this.markerCar)
								}
								let THIS = this;
								ease(
									this.markerCar.getGeometry(),
									{lat: pointCurrent[0], lng: pointCurrent[1]},
									THIS.getElapsedTime(),
									function(coord) {
													THIS.markerCar.setGeometry(coord);
													let curDate = moment(new Date());
													if (curDate.diff(Navigator.lastTimeMapHandMove,"seconds") > 5)
																	map.setCenter(coord, true)
									}
								);
				}

				static getElapsedTime() {
								let time1 = this._lastDateTime;
								this._lastDateTime = (new Date()).getTime();
								let r = this._lastDateTime - time1;
								r = r ?? this.navigation_time
								return r  < this.navigation_time ? r : this.navigation_time;
				}

				static get_vector(point:TypeLonLatExtended){
								if (!point) return ;
								this.point_list.push(point);
								if (this.point_list.length >= this.max_point_count)
												this.point_list.shift();
								if (this.point_list.length < 2)
												return null;
								// if (this.point_list.length < 4) {
								// 				let point1 = this.point_list[0];
								// 				let point2 = this.point_list[this.point_list.length-1];
								// 				return [[point1.lat, point1.lon], [point2.lat, point2.lon]]
								// }
								const get_point = (v1:TypeLonLatExtended,v2:TypeLonLatExtended ) =>{
												let [x1, x2 ] = [v1.lat,v2.lat];
												let [y1, y2 ] = [v1.lon,v2.lon];
												// let [x1, x2, x3] = [55.7637726,55.7637716, 55.7637716];
												// let [y1, y2, y3] = [37.4879463,37.4879469,37.4879469];
												// let xm =  ( y1*(x2*x2 + y2*y2 - x3*x3 -y3*y3) + y2*(x3*x3 + y3*y3 - x1*x1 - y1*y1) + y3*(x1*x1 + y1*y1 - x2*x2 - y2*y2));
												// xm = -1/2 * (xm / (x1*(y2-y3) + x2*(y3-y1) + x3*(y1-y2)))
												// let ym = ( x1*(x2*x2 + y2*y2 - x3*x3 - y3*y3) + x2*(x3*x3 + y3*y3 - x1*x1 - y1*y1) + x3*(x1*x1 + y1*y1 - x2*x2 - y2*y2));
												// ym = 1/2 * (ym / (x1*(y2-y3) + x2*(y3-y1) + x3*(y1-y2)))
											 // if (xm == Infinity || xm == -Infinity) xm = v1.lat;
											 // if (ym == Infinity|| ym == -Infinity) xm = v1.lon;
												// return [xm, ym]
												let xm =  (x1 + x2)/2
												let ym =  (y1 + y2)/2
												// if (isFinite(xm)) xm = v1.lat
												// if (isFinite(ym)) xm = v1.lon
												return [xm,ym ]

								}
								let point1 = get_point(this.point_list[0], this.point_list[1] )
								let len = this.point_list.length;
								let point2 = get_point(this.point_list[len-1], this.point_list[len-2])
								console.log('pppp', point1, point2, this.point_list)
								return [point1, point2]
				}
}