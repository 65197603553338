import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
    ordersReducer,
    statisticReducer,
    userReducer,
    systemReducer,
    checkPhoneReducer,
    ownerSelectReducer,
    quickRegistrationReducer,
    mapReducer,
    registrationReducer,
    chatReducer,
    temporaryReducer,

} from "./reducers";
import finalizeReducer from "./reducers/finalize/reducers";

const reducers = combineReducers({
  ordersReducer,
  statisticReducer,
  userReducer,
  systemReducer,
  checkPhoneReducer,
  ownerSelectReducer,
    finalizeReducer,
  quickRegistrationReducer,
  mapReducer,
  registrationReducer,
  chatReducer,
  temporaryReducer
});


const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(reducers, composedEnhancer);

//@ts-ignore
window.__store__ = store;

export default store;

export type RootState = ReturnType<typeof reducers>;
