import React, { FC } from 'react'
import  './ChatMenu.css'
import menuItemArrow from '../../../images/menuItemArrow.svg'

interface IProps {
    onChatEnter: () => void
    name: string
    subTitle?:string
    order_date?:string,
    order_number?:string
}

export const MenuItem: FC<IProps> = ({onChatEnter, name, subTitle, order_date,order_number }) => {

    if (order_date) {
        let newDate = new Date(order_date);
        order_date = (newDate.getDate()+'').padStart(2, '0') + '.' + (newDate.getMonth()+'').padStart(2, '0')+ '.' + (newDate.getFullYear()-2000) + ' '+
                     (newDate.getHours()+'').padStart(2, '0') + ':' + (newDate.getMinutes()+'').padStart(2, '0');
    }

    return (
        <div className={"menuBodyItemWrapper"} onClick={onChatEnter} >
            <div>
                <div className={"itemHeader"}>{name}</div>
                <div className={"itemDetails " + (!order_number&& !order_date ? 'hidden' : '')}>{order_number? order_number : ''}<span className={"itemDetailsTime"}>{order_date? 'от ' + order_date : ''}</span></div>
            </div>
            <div className={ "iconWrapper"}>
                {/*{messages.filter(message => message.from_user_id === user.id && message.read === false && message.in_out === "in").length ? <img alt='В чате есть непрочтенные сообщения' src={newMessagesIndicator} /> : ''}*/}
                <img alt='Закрыть чат' src={menuItemArrow} />
            </div>
        </div>
    )
}



