import React, {useEffect, useRef, useState} from "react";
import {editingChars} from "../../../@types";
import './InputFilteredValue.css';
import InputMask from "react-input-mask";

interface IProps {
    name:string;
    value: string | number;
    regexp?: RegExp;
    validator?:Function;
    onValueChanged: (value: string | number) => void;
    placeholder:string;
    mask?:string;
    classAppend?:string;
    type?:string;
    wasError?:boolean;
    subTitle?:string;
    formatChars?:any;
    toUpperCase?:boolean;
    maxLength?:number;
    onBlur? : () => void;

}


const InputFilteredValue: React.FunctionComponent<IProps> = (
    { name,
        value,
        regexp= new RegExp(/.*?/),
        validator = (val:any) => true,
        onValueChanged,
        placeholder="",
        mask="",
        classAppend="",
        type= "text",
        wasError=false,
        subTitle="",
        formatChars = null,
        maxLength= 1000,
        toUpperCase=false,
        onBlur,
         } ) => {
    let [isComplete, setComplete] = useState(value!='');
    let [isError, setError] = useState(false);
    let [localValue, setLocalValue] = useState(value + '');

     // console.log(name + ' value =', value);
    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter')
            onLeave(e);

        if (!regexp.test(e.key) && !editingChars.includes(e.key)) {
            e.preventDefault();
        }
    }

    const onLeave = ( e: any) => {
        // console.log('onleav ', validator(localValue));
        setError(!validator(localValue));
        if ((regexp && !regexp.test(e.target.value)) || !validator(e.target.value)){
            setComplete(false);
            onValueChanged('');
        }

        if (onBlur) onBlur();
    }

    useEffect(()=>{
       // console.log('localValue', localValue);
        setError(wasError && (!validator(value) || value == '' ||  (''+value).indexOf('_')>=0))
    }, [wasError])
    return (
        <div className={classAppend ? classAppend : 'input_filtered_full'}>
            <div className='input_filtered_container_name'>{name}</div>
            <div className={'input_filtered_container '  + (isComplete ? ' complete' : (isError ? ' error' : ''))}>

                {!mask &&
                     <input className="input_filtered_text" placeholder={placeholder} type={type}
                           value={localValue}
                           onBlur={onLeave}
                           maxLength={maxLength}
                           onKeyDown={onKeyDown}

                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               setLocalValue( toUpperCase ? e.target.value.toUpperCase() : e.target.value);
                               setLocalValue(e.target.value);
                               if ((regexp && !regexp.test(e.target.value)) || !validator(e.target.value)){
                                   setComplete(false);

                               } else {

                                   setComplete(true);
                                   // console.log(' onChange =', e.target.value);
                                    onValueChanged(toUpperCase ? e.target.value.toUpperCase() : e.target.value);

                               }
                           }}


                />}
                {mask &&
                    <InputMask mask={mask} value={localValue} placeholder={placeholder}
                               onKeyDown={(e)=>{

                                   if (regexp && !regexp.test(e.key) && !editingChars.includes(e.key)) {
                                       //console.log('bad', e.key, regexp);
                                       e.preventDefault();
                                       return;
                                   }

                                   if (/[а-яa-z]/.test(e.key) && toUpperCase && !editingChars.includes(e.key)) {

                                   }
                               }}
                               onChange={(e) => {

                                        setLocalValue( toUpperCase ? e.target.value.toUpperCase() : e.target.value);
                                        if ( e.target.value !== '' &&
                                            (e.target.value.indexOf('_') === -1 || (mask[e.target.value.indexOf('_')] == '#' )) &&
                                             validator(e.target.value)) {
                                            setComplete(true);
                                            onValueChanged(toUpperCase ? e.target.value.toUpperCase() : e.target.value);
                                        } else {
                                            setComplete(false);
                                            // onValueChanged(value);
                                        }
                                    }}
                                onBlur={(e)=>{
                                    setError(!(e.target.value !== '' && e.target.value.indexOf('_') === -1  &&  validator(e.target.value)))
                                    if (onBlur) onBlur();
                                }}
                               formatChars={ formatChars}
                    >
                        {(inputProps: any) => <input {...inputProps}  className="input_filtered_text"
                                                     placeholder={placeholder} type={type} maxLength={maxLength}/>}
                    </InputMask>
                }
            </div>
            {subTitle && <div className='input_filtered_container_sub_title'>{subTitle}</div>}
        </div>
    )
};

export  default InputFilteredValue;
