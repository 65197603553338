import moment, {Moment} from "moment";

export const ADD_POINT_ON_MAP = 'ADD_POINT_ON_MAP';
export const SET_POINTS_ON_MAP = 'SET_POINTS_ON_MAP';
export const UPDATE_POINT_ON_MAP = 'UPDATE_POINT_ON_MAP';
export const REMOVE_POINT_FROM_MAP = 'REMOVE_POINT_FROM_MAP';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_ROUTE = 'SET_ROUTES';
export const SET_ROUTE_ARCHIVE = 'SET_ROUTE_ARCHIVE';
export const SET_ROUTE_TO_POINT = 'SET_ROUTE_TO_POINT';
export const REMOVE_ROUTE = 'REMOVE_ROUTES';
export const SET_DRIVER_POSITION = 'SET_DRIVER_POSITION';
export const SET_DRIVER_CURRENT_ROUTE = 'SET_DRIVER_CURRENT_ROUTE';
export const SET_DRIVER_CURRENT_ROUTE_TIME = 'SET_DRIVER_CURRENT_ROUTE_TIME';
export const SET_INDEX_NEXT_POINT = 'SET_INDEX_NEXT_POINT';
export const SET_NAVIGATOR_MODE = 'SET_NAVIGATOR_MODE';
export const SET_SHOW_CURRENT_ROUTE_ON_MAP = 'SET_SHOW_CURRENT_ROUTE_ON_MAP';
export const SET_LAST_TIME_MAP_HAND_MOVE = 'SET_LAST_TIME_MAP_HAND_MOVE';
export const SET_LOW_SIGNAL = 'SET_LOW_SIGNAL';

export type MapPoint = {
    lng:number,
    lat:number,
    data?:{
        region_type?: string,
        region?: string,
        street_type?: string,
        street?: string,
        house?: string,
        label?: string
    }
}

export type TypeLonLat = {lat:number, lon:number};

export class TypeLonLatExtended extends Object{
    lat:number = 0;
    lon:number = 0;
    date?: string;
    date_utc?: number;
    accuracy?: number;
    speed?:number;
    altitude?:number;
    bearing?:number;
}

export let to_coordinates_array = (point:TypeLonLatExtended) => [point.lat, point.lon]

export type CoordinatePoint = [number,number];

export interface AddPointOnMap {
    type: typeof ADD_POINT_ON_MAP;
    point: MapPoint;
    index: number;
}

export interface UpdatePointOnMap {
    type: typeof UPDATE_POINT_ON_MAP;
    point: MapPoint;
    index: number;
}

export interface setPointOnMap {
    type: typeof SET_POINTS_ON_MAP;
    points: MapPoint[];
}
export interface setDriverPositionAction {
    type: typeof SET_DRIVER_POSITION;
    driver_point: TypeLonLatExtended | undefined;
}

export interface setArchiveRouteAction  {
    type: typeof SET_ROUTE_ARCHIVE;
    route: RouteArchive[];
}
export interface setRouteOnMap  {
    type: typeof SET_ROUTE;
    route: RouteInfo | null;
}


export interface setRouteToPointAction  {
    type: typeof SET_ROUTE_TO_POINT;
    point1:TypeLonLat;
    point2:TypeLonLat;
}
export interface setDriverCurrentRouteAction  {
    type: typeof SET_DRIVER_CURRENT_ROUTE;
    driver_current_route:any[];
}

export interface setDriverCurrentRouteTimeAction  {
    type: typeof SET_DRIVER_CURRENT_ROUTE_TIME;
    driver_current_route_time:number;
}

export interface removeRouteOnMap  {
    type: typeof REMOVE_ROUTE;
}

export interface RemovePointFromMap {
    type: typeof REMOVE_POINT_FROM_MAP;
    index: number;
}

export interface setMapCenterAction {
    type: typeof SET_MAP_CENTER;
    point:{lat:number,lng:number, zoom?:number};
}
export interface setIndexNextPointAction {
    type: typeof SET_INDEX_NEXT_POINT;
    index_next_point:number
}

export interface setNavigatorModeAction {
    type: typeof SET_NAVIGATOR_MODE;
    navigator_mode_is_on:boolean
}

export interface setShowCurrentRouteOnMapAction {
    type: typeof SET_SHOW_CURRENT_ROUTE_ON_MAP;
    show_current_route:boolean
}
export interface setLastTimeMapHandMoveAction {
    type: typeof SET_LAST_TIME_MAP_HAND_MOVE;
    last_time_map_move: Moment
}

export interface setLowSignalAction  {
    type: typeof SET_LOW_SIGNAL;
    is_low_signal: boolean;
}


export type MapDataActionTypes = AddPointOnMap | setPointOnMap | UpdatePointOnMap | RemovePointFromMap | setMapCenterAction |
            setRouteOnMap | removeRouteOnMap | setRouteToPointAction | setDriverPositionAction | setDriverCurrentRouteAction|
            setIndexNextPointAction | setNavigatorModeAction | setShowCurrentRouteOnMapAction | setDriverCurrentRouteTimeAction |
            setLastTimeMapHandMoveAction | setArchiveRouteAction | setLowSignalAction;

export interface MapDataState {
    points: MapPoint[],
    use_data_from_1c:boolean,
    center: {lat:number,lng:number, zoom?:number},
    route: RouteInfo | null,
    archive_route: RouteArchive[],
    route_to_point:TypeLonLat[],
    driver_point:TypeLonLatExtended | undefined,
    driver_current_route:any [],
    index_next_point: number,
    navigator_mode_is_on:boolean,
    show_current_route:{yes:boolean},
    driver_current_route_time: number,
    last_time_map_move:Moment,
    is_low_signal:boolean,
}
export  interface RouteArchive {
    lat:number,
    lon:number,
    date:string
}

export  interface RouteInfo {
    status: number,
    error_code: number,
    error_message: string,
    base_time: number,
    traffic_time: number,
    distance: number,
    shapes: any[]  ,
    points:
        {
            order_id: string,
            lng: number,
            lat: number,
            time_waiting: number,
            distance: number,
            base_time: number,
            traffic_time: number,
            shape: string[],
            region: string,
            arrival_base_time: string,
            departure_base_time: string,
            arrival_traffic_time: string,
            departure_traffic_time: string
        }[],
    mkad_points_info:
        {
            from: string,
            to: string,
            fromReg: string,
            toReg: string,
            distance: number,
            insideMKAD: boolean
        }[],
    mkad_summary: {
        outside_count: number,
        inside_distance: number ,
        outside_distance: number ,
        outside_distance_minus_30: number
    },
    points_to_draw:
        {
            mkad_start: boolean,
            time_waiting: number,
            order_id: string,
            lng: number,
            lat: number,
            arrival_base_time: string,
            departure_base_time: string,
            arrival_traffic_time: string,
            departure_traffic_time: string,
            distance: number,
            base_time: number,
            traffic_time: number,
            region: string,
            shape: string[]
        } []
}
