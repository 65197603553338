import React from "react";
import './CommonRegistrationInfoButton.css'

interface IProps {
    text_value:string;
    onClick?:Function;
    onEditClick?:(index:number)=>void;
    dataRows?: any[];
    isMaxOneRecord?: boolean;
}


const CommonRegistrationInfoButton: React.FunctionComponent<IProps> = ({
       text_value,
       onClick,
       onEditClick,
       dataRows =[],
       isMaxOneRecord = false}) => {

    const wasEditClick = (e:any, index:number)=>{
       // console.log('onEditClick');
        e.stopPropagation();
        // if (isMaxOneRecord && onClick) onClick();
        if (onEditClick) onEditClick(index);
    }
    const wasClick = (e:any, from: number) =>{
       // console.log('wasClick');

        e.stopPropagation();
        if (from == 1 && dataRows?.length>0 && !isMaxOneRecord) return;
       if (isMaxOneRecord && dataRows?.length && onEditClick) onEditClick(0);
       else if (onClick) onClick();

    };
    return  <div className={'common-registration-info-button ' + ( dataRows.length ? ' has_data' : '')}
                 onClick={(e)=>wasClick(e,1)}>
                <div className='crib-text'>{text_value}</div>
                { dataRows.map((x, index)=>
                    <div key={'val_v'+index} className={'common-registration-info-record ' + (dataRows?.length> 1 || !isMaxOneRecord ? '' : 'is-one')} onClick={(e) => wasEditClick(e, index)}> {x}</div>)}
                {(!isMaxOneRecord || dataRows?.length == 0) && <div className='crib-plus-button'  onClick={(e)=>wasClick(e,2)}>+</div>}
            </div>
};

export default CommonRegistrationInfoButton;
