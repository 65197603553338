//.....................................localhost.................................

const host = window.location.host.replace('www.','').split('.').reverse().splice(0, 2).reverse().join('.');
// const apiHost = host.indexOf('localhost')>=0 ? 'tg-group.ru' : host;
// const apiHost = host.indexOf('localhost')>=0 ||  host.indexOf('50.130')>0 ? 'citycarrier.ru' : host;
 const apiHost = 'citycarrier.ru'  ;
export const apiOrderUrl = `https://api2.${apiHost}/PublicOrdersAPI`;
export const apiDriverUrl = `https://api2.${apiHost}/PublicDriversAPI`;
export const apiIPUrl = `https://api2.${apiHost}/ip.php`;
export const apiDebutUrl = `http://127.0.0.1:3030/api/`;
export const daDataToken = "4907ed3e0ba286c611e621c3db1588fe3ce7f53c";
// export const hereMapApiKey = 'Y0h-TaAfPC5vfGxnxpXZDi6Sqz5j594wIJaBxh0us40';
export const hereMapApiKey = host.indexOf("localhost") >= 0  ? "MiWaYuPKblM-f_Ih7SIJ07tcDkQJvZt13fSxOKdxy6E" : 'VLgmZFK_rJLT4lyZ5W62L2gTZoIT3eFqW5OgEP9V_7Q';
export const SYSTEM_MESSAGE_REFRESH_INTERVAL = 30*1000;
export const PING_DEVICE_INTERVAL = 15*1000;
export const UPDATE_ORDER_WINDOW_LIST_INTERVAL = 60*3*1000;
export const TIMEOUT_TRACKER_SLEEP_SEC = 180;
export const IS_EMULATE_DRIVE = false;
export const IS_TEST_ROUTE_DRIVE = false;
export const NAVIGATION_GET_COORDINATE_TIME_IN_MS = 966;

export const MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS = 300;
export const COUNT_MAX_DISTANCE = 6;
export const TIMEOUT_SHOW_ARE_YOU_AT_POINT_WINDOW_IN_SECONDS = 60;

export const  NEED_NEW_ROUTE = [{max_distance_in_meters:300, count: 6}, {max_distance_in_meters:15, count: 30}]; //
export const  MAX_DEVIATION_FROM_ROUTE= 20; //

export const APP_INSTALL_URL = 'https://msv.citycarrier.ru/app-release.apk';
// export const APP_INSTALL_URL = 'https://play.google.com/store/apps/details?id=com.trackermsv&hl=ru';
export const APP_NAME = '"GD Connector"';
export const IS_DEBUG = false;
export const DRIVER_PHONE = "9153455577";
export const MIN_GPS_ACCURACY = 99;

export let USE_DEBUG_ROUTE = true;
export let USE_JUST_NAVIGATOR = true; // используется как навигатор для отображения движения машины
window.process = process
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
 console.log('this is dev ')

} else {
 console.log('this is prod')
 USE_DEBUG_ROUTE = false;
}
//@ts-ignore
window.USE_DEBUG_ROUTE = USE_DEBUG_ROUTE


