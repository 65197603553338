import React from "react";
import {useDispatch, useSelector} from "react-redux";

import "./MessageWindow.css"
import {RootState} from "../../redux/store";
import {popSystemStatus} from "../../redux/reducers/system/actions";
import Button from "../Elements/Button/Button";
import OkSVG from "../App/svg/ok.svg";
import ErrorSVG from "../App/svg/ok_red.svg";

import {ReactSVG} from "react-svg";
import {EnumWindowMessageType} from "../../redux/reducers/system/@types";

interface IProps {
    zIndex?:number;
}
const MessageWindow: React.FC<IProps> = ({zIndex}) => {
    const dispatch = useDispatch();

    const {system} = useSelector((state: RootState) => ({
        system: state.systemReducer
    }));



    const closeWindow = () => {
        dispatch(popSystemStatus());
    };

    return (
        <div className="message_bg">

            <div className="message_window" >
                <div className="waiting_window_loading">
                    <div><ReactSVG src={system.window_message.type === EnumWindowMessageType.Error ? ErrorSVG: OkSVG} /></div>
                    <div className={"message_window_text" + (system.window_message.type === EnumWindowMessageType.Error? '_error' : '')}>
                        <div dangerouslySetInnerHTML={{__html: system.window_message.text}}/>
                    </div>
                </div>

                <Button type={system.window_message.type === EnumWindowMessageType.Error ? "error": "primary"}
                        size="middle" onClick={closeWindow}>Закрыть</Button>
            </div>
        </div>
    )
};

export default MessageWindow;
