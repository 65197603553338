import React, {useEffect, useState} from "react";
import './TimeBtn.css'
import {setDriverPosition, setNavigatorModeOn} from "../../../redux/reducers/map/actions";
import {useDispatch, useSelector} from "react-redux";
import {MapDataState, TypeLonLat} from "../../../redux/reducers/map/@types";
import {RootState} from "../../../redux/store";
import NavigatorIcon from "../../../images/navigator.svg"
import NavigatorWhiteIcon from "../../../images/navigator-white.svg"
import {SystemState} from "../../../redux/reducers/system/@types";
import {getDistanceLine, getNearestPointOnLine} from "../../Elements/HereMap/hereFunctions";
import {toTwoDigits} from "../../../redux/reducers/system/system_functions";
import {IS_EMULATE_DRIVE} from "../../../deployment";
interface IProps{

}
var driverInterval : any = undefined;
var index = 0;
const TimeBtn: React.FunctionComponent<IProps> = ({}) => {
    const dispatch =  useDispatch();
    const   map : MapDataState = useSelector((state: RootState) =>   state.mapReducer );
    const   system : SystemState  = useSelector( (state: RootState) =>   state.systemReducer );

    const [isTimerStart, setTimerStart] = useState(0);
    const [position, setPosition] = useState<number>(0);
    const [leftTime, setLeftTime] = useState<number>(map.driver_current_route_time);
    const [tikTak, setTickTack] = useState<number>(0);

    const getDriverPoint = (npos:number) : [TypeLonLat, boolean] => {
        if (map.driver_current_route.length <= 0 )
            return [{lat:0, lon:0}, true]
        let pos = npos;
        if (pos >= map.driver_current_route.length)
            pos = map.driver_current_route.length -1;

        return [{lat:map.driver_current_route[pos][0], lon:map.driver_current_route[pos][1]}, npos >= map.driver_current_route.length]
    };

    useEffect(()=>{  index = 0; setLeftTime(map.driver_current_route_time * 60); },[map.driver_current_route]);


    useEffect(()=>{   var tmp = 0;
                            setInterval(() => { setTickTack(tmp); tmp = tmp+1 > 60 ? 0: tmp+1;   }, 1000);}, []);

    useEffect(()=>{
         if(!system.play_mode || !map.driver_point || !map.driver_current_route) return;
         let [point, d, index ] = getNearestPointOnLine( [map.driver_point?.lat, map.driver_point?.lon], map.driver_current_route as [number[]]);
         let distanceLeft = getDistanceLine(point as  number[] , map.driver_current_route  as [number[]]);
         let distanceFull = getDistanceLine(map.driver_current_route[0] , map.driver_current_route  as [number[]]);
         let timeFull = map.driver_current_route_time;
         let timeLeft = Math.round(timeFull / distanceFull * distanceLeft * 60);
         setLeftTime(timeLeft);
         // console.log('timeLeft', timeLeft, Math.ceil(timeLeft/60), timeLeft - Math.ceil(timeLeft/60)*60)
    }, [tikTak]);


    useEffect(()=>{
        let isMounted = true;
        if (driverInterval) {
            clearInterval(driverInterval);
            driverInterval = undefined;
        }
        if (!isTimerStart)   index = 0;

        if (isTimerStart && !driverInterval){
            // console.log('isTimerStart', isTimerStart)

            driverInterval = setInterval(() => {
                if (!isMounted) {
                    clearInterval(driverInterval);
                    driverInterval = undefined;
                    return;
                }
                let [pos, is_end] = getDriverPoint(index);
                // console.log('[pos, is_end]', pos, is_end)
                if (!is_end && pos.lon!=0) {
                    index += 1;
                    // console.log('pos=',pos, index);
                    // dispatch(setDriverPosition(pos))
                }
            }, 1000/isTimerStart);
        }
        return () => { isMounted = false };
    }, [isTimerStart, map.driver_current_route])

    useEffect(() => {
        return () => {
            if (driverInterval) {
                clearInterval(driverInterval);
                driverInterval = undefined;
            }
        };
    }, []);
    return (
        <>
            <div className='time_btn'>
                <div className="two_text"> в пути </div>
                <div className="two_digits">{ toTwoDigits( Math.ceil(leftTime/60)) }</div>
                <div className="two_points">{tikTak % 2 == 0 ? ":" : <>&nbsp;</>}</div>
                <div className="two_digits">{ toTwoDigits(Math.abs(leftTime % 60))}</div>
            </div>
            {IS_EMULATE_DRIVE &&
                <div className="imitation_btn" onClick={( event)=>{
                    console.log('event', event)
                    if (!event.shiftKey)
                        setTimerStart( isTimerStart+1 > 10 ?   10 : isTimerStart+1)
                    else
                        setTimerStart( isTimerStart - 1 < 0 ?   0 : isTimerStart - 1)
                }}>
                    {isTimerStart ? '':'запустить'} имитатор {isTimerStart ? 'работает x' + isTimerStart : ''}
                </div>
            }
            <div className={"navigator_btn " + (map.navigator_mode_is_on ? 'active' : '')} onClick={()=>dispatch(setNavigatorModeOn(!map.navigator_mode_is_on))}>
                <img src={map.navigator_mode_is_on ? NavigatorWhiteIcon : NavigatorIcon} />
            </div>
        </>
    )

}

export default TimeBtn;
