import React, {useEffect, useState} from "react";
import './CommonRegistration.css';
import BackIcon from '../../App/svg/back-arrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import CommonRegistrationInfoButton from "./CommonRegistrationInfoButton/CommonRegistrationInfoButton";
import {SINGLE_CAR_OWNER, TIN_IP} from "../../../redux/reducers/owner_select/@types";
import {RootState} from "../../../redux/store";
import {popSystemStatus, setSystemStatus, showMessageInfo} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import {DriverInfo, UserState, VehicleInfo} from "../../../redux/reducers/user/@types";
import {
    setEditDriverData,
    setEditVehicleData,
    setRecommendationData
} from "../../../redux/reducers/temporary/temporaryActions";
import {updateData} from "../../../redux/reducers/user/actions";
import {setCookie} from "../../../redux/reducers/system/cookies";
import ButtonSave from "../ButtonSave/ButtonSave";
import {EnumStatusResponse} from "../../../api/@types";
import {isSystemStatusActive} from "../../../redux/reducers/system/reducers";
import {SystemState} from "../../../redux/reducers/system/@types";

interface IProps {
    zIndex:number;
}


const CommonRegistration: React.FunctionComponent<IProps> = ({zIndex} ) => {
    const  dispatch = useDispatch();
    const user : UserState = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const [isValidQuestionnaire, setValidQuestionnaire] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const closeWindow = () =>{
        dispatch(popSystemStatus());
    }

    const toBankData = () => {
        //console.log('toBankData');
        dispatch(setSystemStatus(SystemStatus.RegistrationBankData))
     }

    const toEditOwnerData = async () => {
        let st = new DriverInfo();
        st.passport =  {...user.data.passport};
        let driverIndex = user.data.drivers.findIndex(x=> x.passport.full_name == st.passport.full_name);
        let im_a_driver = false;
        if (driverIndex >= 0) {
            st.driver_certificate = {...user.data.drivers[driverIndex].driver_certificate};
            st.driver_certificate.files = [...user.data.drivers[driverIndex].driver_certificate.files];
            im_a_driver = true;
        }
        st.phone_number = user.data.person.phone_number;
        st.email  = user.data.person.email;
        st.driver_certificate.full_name = st.passport.full_name;

        await dispatch(setEditDriverData(true, driverIndex, st, im_a_driver));
        dispatch(setSystemStatus(SystemStatus.RegistrationDriverData));
    }

    const toEditDriverData = async (index:number, just_passport = false) => {
        let st = new  DriverInfo();
        if (user.data.drivers.length>index) {
            st = {...user.data.drivers[index]} ;
            st.passport = {...st.passport};
            st.passport.files = [...st.passport.files];
            st.driver_certificate = {...st.driver_certificate};
            st.driver_certificate.files = [...st.driver_certificate.files];
        }  else {
            st.passport = {...user.data.passport};
        }

        if (user.data.multiple_car_owner == SINGLE_CAR_OWNER) {
            st.phone_number = user.data.person.phone_number;
            st.email  = user.data.person.email;
        }

        await dispatch(setEditDriverData(just_passport, index, st));
        dispatch(setSystemStatus(SystemStatus.RegistrationDriverData));
    }

    const toDriverData = async (just_passport:boolean=false) => {
        let st = new DriverInfo();
        if (user.data.multiple_car_owner == SINGLE_CAR_OWNER) {
            st.phone_number = user.data.person.phone_number;
            st.email  = user.data.person.email;
            st.passport = {...user.data.passport};
        }
        await dispatch(setEditDriverData(just_passport, -1, st, !just_passport));
        dispatch(setSystemStatus(SystemStatus.RegistrationDriverData))
    }


    const toEditVehicleData = async (index:number) => {
        let vh : VehicleInfo =  {...user.data.cars[index]};
        await dispatch(setEditVehicleData( index, vh));
        dispatch(setSystemStatus(SystemStatus.RegistrationVehicleData));
    };

    const toRecommendationData = async () => {
        await dispatch(setRecommendationData( user.data.recommendations));
        dispatch(setSystemStatus(SystemStatus.RegistrationRecommendationData));
    };
    const toVehicleData = async () => {
        let vh = new VehicleInfo();
        await dispatch(setEditVehicleData( -1, vh));
        dispatch(setSystemStatus(SystemStatus.RegistrationVehicleData));
    };

    const  getRecommendationList= () => {
        if (user.data && user.data.recommendations && user.data.recommendations.length)
            return user.data.recommendations.map(x=> <div>{x.full_name + ' (' + x.position+') '} <br/>тел.: {x.phone_number}</div>);
        return [];
    };
    const  getVehicleList= () => {
        if (user.data && user.data.cars && user.data.cars.length)
            return user.data.cars.map(x=> <div>{x.certificate.brand + ' ' + x.certificate.model} <br/>гос. номер: {x.certificate.number}</div>);
        return [];
    };

    const getOwnerInfo = () => {
        if (user.data.passport.full_name !='')
            return [<div>{user.data.passport.full_name} <br/>паспорт: {user.data.passport.series + ' ' + user.data.passport.number}</div>]
        return [];
    }
    const getDriverList = () => {

        if (user.data && user.data.drivers && user.data.drivers.length)
            return user.data.drivers.map(x=> <div>{x.passport.full_name} <br/>паспорт: {x.passport.series + ' ' + x.passport.number}</div>);
        else
        if (user.data.multiple_car_owner == SINGLE_CAR_OWNER && user.data.passport  && user.data.passport.full_name) {
            return [<div>{user.data.passport.full_name} <br/>паспорт: {user.data.passport.series + ' ' + user.data.passport.number}</div>]
        }
        return [];
    };

    const getBankDataText = () => {
        console.log(user.data);
        if (user.data && user.data.banking.BIK && user.data.banking.bank.name) {
            return [(<div>{user.data.banking.bank.name} (БИК {user.data.banking.BIK}) <br/> кор/сч: {user.data.banking.account_number}</div>)];
        }
        return [];
    };

    const saveDriverQuestionnaire = async () => {

            if (!checkField()) return ;

            setSaving(true);
            let data = {...user.data};
            data.draft = false;
            let respData : {status:string, id:string, error_message:string} =  await updateData(data, dispatch) as {status:string, id:string, error_message:string};
            setSaving(false);
            if (respData && respData.status === EnumStatusResponse.success)  {
                // setCookie('id', respData.id, 30)
                showMessageInfo ('Спасибо за регистрацию <br>' + user.data.passport.full_name + '<br> Для продолжение работы вам необходимо авторизоваться с указанным при регистрации номером телефона.', dispatch );
                setTimeout(()=>{
                    window.location.href = '/';
                }, 5000)
            }

    }
    const checkField = () =>{

        if (isSystemStatusActive(SystemStatus.Profile, system.statuses)) return true;

        if (!user.data || !user.data.cars || !user.data.cars.length|| !user.data.passport.full_name || !user.data.drivers || !user.data.drivers.length)   return false;

        return true;
    }

    useEffect(()=>{
            setValidQuestionnaire(checkField())
    }, [user.data, user.data?.banking, user.data?.cars, user.data?.drivers,  user.data?.passport]);


    return (
             <div className="common_form_window registration_window">
                 <div className="common_form_window_title" onClick={closeWindow}>
                      <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                     {isSystemStatusActive(SystemStatus.Registration, system.statuses) ?
                         <div className="page_title"> Регистрация {user.data.multiple_car_owner === SINGLE_CAR_OWNER ? ' водителя с грузовым авто' : ' владельца нескольких авто'}  </div> :
                         <div className="page_title page_title_center">  {user.data.passport.full_name}  </div>}
                 </div>
                 <div className="common_form_window_content">
                     {user.data.type == TIN_IP ? <div className="common_form_tin">ИП : <span>{user.data.TIN}</span></div> :
                         user.data.TIN ? <div className="common_form_tin">Самозанятый : <span>{user.data.TIN}</span></div> :  <div className="common_form_no_tin">&nbsp;</div>}
                     {user.data.type == TIN_IP && user.data.TIN != "" &&
                        <CommonRegistrationInfoButton key="bank_rib" text_value="Реквизиты" dataRows={ getBankDataText() } isMaxOneRecord={true} onClick={toBankData} onEditClick={toBankData}/>
                     }
                     {user.data.multiple_car_owner &&
                         <CommonRegistrationInfoButton key="driver_rib_ls" text_value={"Личная информация"}
                                                       dataRows={getOwnerInfo()}
                                                       isMaxOneRecord={true}
                                                       onClick={()=>toDriverData(true)}
                                                       onEditClick={toEditOwnerData}/>
                     }
                     <CommonRegistrationInfoButton key="driver_rib" text_value={user.data && user.data.multiple_car_owner == SINGLE_CAR_OWNER ? "Личная информация" : "Водители"}
                                                   dataRows={getDriverList()}
                                                   isMaxOneRecord={ user.data && user.data.multiple_car_owner == SINGLE_CAR_OWNER}
                                                   onClick={toDriverData}
                                                   onEditClick={toEditDriverData}/>
                     <CommonRegistrationInfoButton key="ts_rib"
                                                   text_value="Данные ТС"
                                                   dataRows={getVehicleList()}
                                                   isMaxOneRecord={ user.data && user.data.multiple_car_owner == SINGLE_CAR_OWNER}
                                                   onEditClick={toEditVehicleData}
                                                   onClick={toVehicleData}/>
                     <CommonRegistrationInfoButton key="recoment_rib"
                                                   text_value="Вас рекомендуют"
                                                   onEditClick={toRecommendationData}
                                                   dataRows={getRecommendationList()}
                                                   onClick={toRecommendationData}/>

                     <ButtonSave onClickSave={saveDriverQuestionnaire} onClickBack={closeWindow} isSavingData={isSaving} isActive={isValidQuestionnaire} />
                 </div>



            </div>
    );
};

export default CommonRegistration;
