import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {popSystemStatus, setOverlay, setPlayMode} from "../../redux/reducers/system/actions";

import "moment/locale/ru";
import "./OrderPlayWindow.css";
import {EnumStatusExecution, OrderFullInfo} from "../../redux/reducers/orders/@types";
import {getRouteMutation} from "../../api";
import {RouteInfo, TypeLonLat} from "../../redux/reducers/map/@types";
import {
  setDriverCurrentRoute,
  setDriverPosition,
  setIndexNextPoint,
  setMapCenter,
  setNavigatorModeOn,
  setRoutes,
  setShowCurrentRouteOnMap
} from "../../redux/reducers/map/actions";
import SmallPlayWindow from "./SmallPlayWindow/SmallPlayWindow";
import FullPlayWindow from "./FullPlayWindow/FullPlayWindow";
import OnPlacePlayWindow from "./OnPlacePlayWindow/OnPlacePlayWindow";
import SmallOnPlacePlayWindow from "./SmallOnPlacePlayWindow/SmallOnPlacePlayWindow";
import FinishPlayWindow from "./FinishPlayWindow/FinishPlayWindow";
import {executionOrderThunk} from "../../redux/reducers/orders/reducers";
import AskAboutRouteForm from "./AskAboutRouteForm/AskAboutRouteForm";
import YandexRouteForm from "./YandexRouteForm/YandexRouteForm";
import {getDistanceP2P} from "../Elements/HereMap/hereFunctions";
import AreYouOnPointWindow from "./AreYouOnPointWindow/AreYouOnPointWindow";
import {
  clearSystemServiceMessages,
  setCurrentDriverRouteTimeThunk,
  setSystemServiceInfo
} from "../../redux/reducers/system/system_functions";
import {TIMEOUT_SHOW_ARE_YOU_AT_POINT_WINDOW_IN_SECONDS} from "../../deployment";


interface ILocalState {
  route: RouteInfo | null,
  show_vehicle: boolean,
  show_cargo: boolean,
  show_point_index: number[],
}

enum EnumWindowMode   {
  SmallWindow, FullWindow, OnPlaceWindow, SmallOnPlaceWindow, OnPlaceWindowNext,FinishWindow
}

interface IProps {
  zIndex?:number
}
enum EnumOnPointState{None = 0, onPointButtonNotClick, onPointButtonWasClick }
const OrderPlayWindow: React.FC<IProps>  = ({zIndex}) => {
  const dispatch = useDispatch();

  const { user, orders, system, map } = useSelector((state: RootState) => ({
    user: state.userReducer,
    orders: state.ordersReducer,
    system: state.systemReducer,
    map: state.mapReducer
  }));
  const order : OrderFullInfo = orders.current;
  const [selectedMode, setSelectedMode] = useState<EnumWindowMode>(EnumWindowMode.SmallWindow);
  const [prevSelectedMode, setPrevSelectedMode] = useState<EnumWindowMode>(EnumWindowMode.SmallWindow);
  const currentPoint = map.index_next_point;
  const [isYandexMap, setYandexMap] = useState<{show:boolean, point1:TypeLonLat, point2:TypeLonLat}|null>(null);
  const [isAskMap, setAskMap] = useState<boolean>(false);
  const [isOnPointQuestion, setOnPointQuestion] = useState<EnumOnPointState>(EnumOnPointState.None);
  const [lastAreYouAtPointTime, setLastAreYouAtPointTime] = useState<number>((new Date()).getTime());

  const [localState, setLocalState] = useState<ILocalState>( {
    route : null,
    show_vehicle: false,
    show_cargo: false,
    show_point_index: []
  } );

  useEffect(()=>{
    if (order && order.id) {
      getRouteMutation(order.id).then((res)=>{
        if (!res || !res.data) return;
        setLocalState((s) => ({...s, route: res.data }));

      })
    }

    dispatch(setOverlay(false));
  },[]);

  useEffect(()=>{
    if (!system.play_status || !map.route || !map.route.shapes) return ;
    dispatch(setPlayMode(true));
    if (system.play_status.status === EnumStatusExecution.AT_POINT ) {
      dispatch(setIndexNextPoint(system.play_status.point-1));
      setNewMode(EnumWindowMode.OnPlaceWindow);
    }
    if (system.play_status.status === EnumStatusExecution.LEFT_POINT ) {
      let n_point = system.play_status.point-1;
      dispatch(setIndexNextPoint(system.play_status.point));
      setNewMode(EnumWindowMode.SmallWindow);
      if (map.route && map.route.shapes && n_point < map.route.shapes.length) {
        let rt = map.route.shapes[n_point].map((x:string)=> x.split(','));

        dispatch(setDriverCurrentRoute(rt))
        if (map.route && map.route.points && (n_point+1 ) < map.route.points.length) {
          setCurrentDriverRouteTimeThunk(dispatch,  map.route.points[n_point].departure_traffic_time, map.route.points[n_point+1  ].arrival_traffic_time)
        }
      }
    }

  },[system.play_status, map.route]);

  const setNewMode = (mode: EnumWindowMode) => {
    setPrevSelectedMode(selectedMode);
    setSelectedMode(mode);
  }
  const getPointCoordinate = (n: number) => {
    if (!localState.route || n >= localState.route.points.length) return null;
    return  localState.route.points[n];
  };

  const showYandexMap = () =>{
      setAskMap(false);

      let pointTo = getPointCoordinate(currentPoint);
      if (!navigator.geolocation || !pointTo) return null;
      navigator.geolocation.getCurrentPosition(async (pos: any) => {
          console.log()
          setYandexMap({show:true, point1:{ lat:pos.coords.latitude, lon: pos.coords.longitude},  point2:{lat:pointTo ? pointTo.lat:0, lon:pointTo? pointTo.lng : 0}});

      });
  }
  const showPointOnMap = (index:number) =>{
    console.log('showPointOnMap', index)
    let point = getPointCoordinate(index);
    dispatch(setMapCenter({ lat:point ? point.lat : 0, lng:point? point.lng : 0, zoom: 20 }));
    // setNewMode( EnumWindowMode.SmallWindow);
    setNewMode(prevSelectedMode)
  }
  const showRouteToPoint = (index?:number) =>{
    setAskMap(false);
    // let pointTo = getPointCoordinate(index? index :  currentPoint);
    // if (!navigator.geolocation || !pointTo) return null;
    // navigator.geolocation.getCurrentPosition(async (pos: any) => {
    //   console.log({ lat:pos.coords.latitude, lon: pos.coords.longitude}, {lat:pointTo ? pointTo.lat:0, lon:pointTo? pointTo.lng : 0})
    //     dispatch(setRouteToPoint({ lat:pos.coords.latitude, lon: pos.coords.longitude}, {lat:pointTo ? pointTo.lat:0, lon:pointTo? pointTo.lng : 0} ));
    // });
    dispatch(setShowCurrentRouteOnMap(true));
  }


  const onTaskComplete = async (status:EnumStatusExecution, point:number) =>{
    setNewMode(EnumWindowMode.OnPlaceWindowNext);
  }
  const onExecute = async (status:EnumStatusExecution, point:number) =>{
    if (status == EnumStatusExecution.STARTING ||status == EnumStatusExecution.FINISH)
      point = -1;
    else
      point = currentPoint + 1;

    let data : any = await executionOrderThunk({status, point, order_id: order.id, driver_id: user.driver_id}, dispatch);
    if (data) {
        if (status === EnumStatusExecution.AT_POINT )
          setNewMode( EnumWindowMode.OnPlaceWindow);
        if (status === EnumStatusExecution.LEFT_POINT || status === EnumStatusExecution.FINISH) {
            let nextPoint = currentPoint+1;
            if (nextPoint < order.routes.length) {
              dispatch(setIndexNextPoint(nextPoint));
              setNewMode(EnumWindowMode.SmallWindow);
              if (map.route && map.route.shapes && currentPoint < map.route.shapes.length) {
                let rt = map.route.shapes[currentPoint].map((x:string)=> x.split(','));
                console.log('set new route', rt)
                dispatch(setDriverCurrentRoute(rt))
                if (map.route && map.route.points && (currentPoint +1) < map.route.points.length) {
                  setCurrentDriverRouteTimeThunk(dispatch, map.route.points[currentPoint + 1].arrival_traffic_time, map.route.points[currentPoint].departure_traffic_time)
                }
              }

            } else {
              setNewMode(EnumWindowMode.FinishWindow);
              dispatch(setDriverCurrentRoute([]));
              dispatch(setIndexNextPoint(0));
              dispatch(setPlayMode(false));
              dispatch(setDriverPosition(undefined));
              dispatch(setRoutes(null));
              dispatch(setNavigatorModeOn(false));

              let tmp : any = await clearSystemServiceMessages( user.driver_id, dispatch);
            }
           setOnPointQuestion(EnumOnPointState.None)
        }
    }
    console.log(point)
    // if (status === EnumStatusExecution.FINISH)
    //   setSelectedMode( EnumWindowMode.FinishWindow);
  };

  useEffect(()=>{


  },[map.driver_current_route]);
  useEffect(()=>{

    if (map.driver_current_route.length && map.driver_point) {

      let distance = getDistanceP2P([map.driver_point.lat, map.driver_point.lon], map.driver_current_route[map.driver_current_route.length - 1]);


      let timeLeft = ((new Date()).getTime() - lastAreYouAtPointTime)/1000;
      // console.log("timeLeft",timeLeft)
      if (distance < 300 && isOnPointQuestion != EnumOnPointState.onPointButtonWasClick && timeLeft > TIMEOUT_SHOW_ARE_YOU_AT_POINT_WINDOW_IN_SECONDS) {
          setOnPointQuestion(EnumOnPointState.onPointButtonNotClick);
      }
    }
  }, [map.driver_point]);

  let order_date = order.date.split('T')[0]+'T'+order.date.split('T')[1];

  return (
    <>
          {/*{selectedMode === EnumWindowMode.FullWindow &&*/}
          {/*    <FullPlayWindow order={order} currentPoint={currentPoint}*/}
          {/*                    onShowRoute={()=>  {setNewMode(EnumWindowMode.SmallWindow); setAskMap(true)}}*/}
          {/*                    onShowPointOnMap={showPointOnMap}*/}
          {/*                    setOnPlace={onExecute}*/}
          {/*                    onFullMode={()=>{setNewMode(EnumWindowMode.SmallWindow)}}*/}
          {/*                    zIndex={zIndex}*/}
          {/*    />}*/}
        {selectedMode === EnumWindowMode.FullWindow &&
            <FullPlayWindow order={order} currentPoint={currentPoint}
                            onShowRoute={()=>  {setNewMode(prevSelectedMode); setAskMap(true)}}
                            onShowPointOnMap={showPointOnMap}
                            setOnPlace={prevSelectedMode == EnumWindowMode.SmallWindow ? onExecute : undefined}
                            onTaskComplete={ (prevSelectedMode === EnumWindowMode.OnPlaceWindow || prevSelectedMode == EnumWindowMode.SmallOnPlaceWindow) ? onTaskComplete : undefined}
                            onNextOrFinish={undefined}
                            onFullMode={()=>{setNewMode(prevSelectedMode)}}
                            zIndex={zIndex}
            />}

        {selectedMode === EnumWindowMode.OnPlaceWindowNext &&
            <FullPlayWindow order={order} currentPoint={currentPoint}
                            onShowRoute={()=>  {setNewMode(prevSelectedMode); setAskMap(true)}}
                            onShowPointOnMap={showPointOnMap}
                            setOnPlace={prevSelectedMode == EnumWindowMode.SmallWindow ? onExecute : undefined}
                            onNextOrFinish={onExecute}
                            onTaskComplete={undefined}
                            onFullMode={()=>{setNewMode(prevSelectedMode)}}
                            zIndex={zIndex}/>
        }
          {selectedMode === EnumWindowMode.SmallWindow &&
              <SmallPlayWindow order={order}
                               currentPoint={currentPoint}
                               // onShowRoute={()=>dispatch(setRoutes(localState.route))}
                               onShowPointOnMap={showPointOnMap}
                               onShowMap={()=>  setAskMap(true)}
                               onFullMode={()=>{setNewMode(EnumWindowMode.FullWindow)}}
                               zIndex={zIndex}
                          /> }
      {/*{selectedMode === EnumWindowMode.OnPlaceWindow &&*/}
      {/*    <OnPlacePlayWindow order={order} currentPoint={currentPoint}*/}
      {/*                       onGood={()=>{setNewMode(EnumWindowMode.SmallOnPlaceWindow)}}*/}
      {/*                       onNextOrFinish={undefined}*/}
      {/*                       zIndex={zIndex}*/}
      {/*    /> }*/}
     { (selectedMode === EnumWindowMode.OnPlaceWindow || selectedMode === EnumWindowMode.SmallOnPlaceWindow) &&
          <SmallOnPlacePlayWindow order={order} currentPoint={currentPoint}
                                  onFullMode={()=>{setNewMode(EnumWindowMode.FullWindow)}}
                                  onTaskComplete={()=>{setNewMode(EnumWindowMode.OnPlaceWindowNext)}}
                                  zIndex={zIndex}

          /> }

      {/*{selectedMode === EnumWindowMode.SmallOnPlaceWindow &&*/}
      {/*  <SmallOnPlacePlayWindow order={order} currentPoint={currentPoint}*/}
      {/*                          onFullMode={()=>{setSelectedMode(EnumWindowMode.FullWindow)}}*/}
      {/*                          onTaskComplete={()=>{setSelectedMode(EnumWindowMode.OnPlaceWindowNext)}}*/}
      {/*                          zIndex={zIndex}*/}

      {/*  /> }*/}

      {/*{selectedMode === EnumWindowMode.OnPlaceWindowNext &&*/}
      {/*  <OnPlacePlayWindow order={order} currentPoint={currentPoint}*/}
      {/*                          is_next={(currentPoint+1) < order.routes.length}*/}
      {/*                          is_finish={(currentPoint+1) === order.routes.length}*/}
      {/*                          onNextOrFinish={onExecute}*/}
      {/*                          onGood={()=>{*/}
      {/*                            // if ((currentPoint+1) < order.routes.length) {*/}
      {/*                            //   setCurrentPoint(currentPoint + 1);*/}
      {/*                            //   setSelectedMode(EnumWindowMode.SmallWindow)*/}
      {/*                            // } else {*/}
      {/*                            //   setSelectedMode(EnumWindowMode.FinishWindow)*/}
      {/*                            // }*/}
      {/*                          }}*/}
      {/*                     zIndex={zIndex}*/}

      {/*  /> }*/}
      {selectedMode === EnumWindowMode.FinishWindow &&
          <FinishPlayWindow order={order} onClose={() => dispatch(popSystemStatus())} zIndex={zIndex}/>
      }

      {isAskMap && <AskAboutRouteForm pointNumber={currentPoint+1} onCancel={()=>setAskMap(false)}
                                      onSelectHereRoute={showRouteToPoint}
                                      onSelectYandexRoute={showYandexMap}  /> }

      {isYandexMap && <YandexRouteForm point1={isYandexMap?.point1} point2={isYandexMap?.point2} onClose={()=>setYandexMap(null)} />}
      {
        isOnPointQuestion ==  EnumOnPointState.onPointButtonNotClick  &&
          <AreYouOnPointWindow
              order={order}
              currentPoint={currentPoint}
              onPoint={ () => {
                          setOnPointQuestion(EnumOnPointState.onPointButtonWasClick);
                          setNewMode(EnumWindowMode.OnPlaceWindow )
                          onExecute(EnumStatusExecution.AT_POINT, currentPoint)
                        }

                    }
              onClose={ ()=> {
                               setLastAreYouAtPointTime((new Date()).getTime());
                               setOnPointQuestion(EnumOnPointState.None);
                             }
              }
          />
      }
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    orderState: state.ordersReducer,
  }
});

export default connect(mapStateToProps, { })(OrderPlayWindow);

