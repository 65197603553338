import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {LogOutAction, UserState} from "../../../redux/reducers/user/@types";
import {SystemState} from "../../../redux/reducers/system/@types";
import {SystemStatus} from "../../../@types";
import {
  clearSystemStatus,
  popSystemStatus, removeSystemStatus,
  setDataLoading,
  setSystemStatus, showMessageError, showMessageInfo
} from "../../../redux/reducers/system/actions";
import {setRegistrationStatus} from "../../../redux/reducers/registration/actions";
import BarsSVG from "../../App/svg/bars.svg";
import HelpSVG from "../../App/svg/help.svg";
import LogOutSVG from "../../App/svg/logout.svg";
import PartnerSVG from "../../App/svg/partner.svg";
import JournalSVG from "../../App/svg/journal.svg";
import UserSVG from "../../App/svg/user.svg";
import StatisticSVG from "../../App/svg/statistic.svg";
import ChatsSVG from "../../App/svg/chats-gray.svg";
import TrackerSVG from "../../App/svg/tracker.svg";
import {ReactSVG} from "react-svg";
import "./Menu.css";
import {deleteCookie} from "../../../redux/reducers/system/cookies";
import {setLogOut} from "../../../redux/reducers/user/actions";
import {isSystemStatusActive} from "../../../redux/reducers/system/reducers";
import Overlay from "../Overlay/Overlay";
import {userAPI} from "../../../api";
import {installDevice, pingDevice} from "../../../redux/reducers/system/system_functions";
import {APP_NAME} from "../../../deployment";


interface IProps {
  state: {
    user: UserState,
    system: SystemState
  },

  setLogOut: () => LogOutAction,
  zIndex?:number;
}

const Menu: React.FunctionComponent<IProps> = ({ state, setLogOut, zIndex}) => {
  const { user, system } = state;

  const dispatch = useDispatch();

  const showOrderBook = () =>{
    dispatch(setSystemStatus(SystemStatus.OrderBook));
  };

  const showStatisticInfo = () =>{
    dispatch(setSystemStatus(SystemStatus.Statistic));
  };

  const showHelp = () =>{
    dispatch(setSystemStatus(SystemStatus.Help));
  };
  const showChat = () =>{
    // dispatch(setSystemStatus(SystemStatus.Chat));
    dispatch(setSystemStatus(SystemStatus.VeryQuickRegistration));
  };

  const showPartnership = () =>{

    dispatch(setSystemStatus(SystemStatus.Partnership));

  };

  const showRegistration = () => {
      dispatch(setRegistrationStatus(null));
      dispatch(setSystemStatus(SystemStatus.Registration));
  }

  const showProfile = () =>{
    // dispatch(setRegistrationStatus(null));
    // dispatch(setSystemStatus(SystemStatus.Profile));
    dispatch(setSystemStatus(SystemStatus.RegistrationCommonForm));
  };

  const logout = ()=> {
    deleteCookie("id");
    deleteCookie("driver_id");
    setLogOut();
    dispatch(clearSystemStatus());
  }

  const linkTracker  = async ()=> {
    // dispatch(setDataLoading(true));
    // dispatch(clearSystemStatus());
    dispatch(setSystemStatus(SystemStatus.WaitingWindow));
    // let isActive = await  pingDevice(user.driver_id, dispatch).catch( e=>  showMessageError(e, dispatch) );
    let isActive = await installDevice(user.data.id, dispatch).catch( e=>  showMessageError(e, dispatch) );
    dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
    if (isActive === undefined) return ;

    if (!isActive)
      showMessageError(`Ошибка ! На вашем устройстве не удалось обнаружить запущенное приложение ${APP_NAME}!`, dispatch);
    else
      showMessageInfo(`Приложение ${APP_NAME} успешно обнаружено на вашем устройстве`, dispatch);
  }

  const toggleMenuWindow = () => {
    //dispatch(toggleSystemStatus(SystemStatus.Menu));
    if (isSystemStatusActive(SystemStatus.Menu, state.system.statuses))
      dispatch(popSystemStatus());
    else
      dispatch(setSystemStatus(SystemStatus.Menu));
  }

  if (!user || !user.authenticated) {
    return (
      <>

        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {isSystemStatusActive(SystemStatus.Menu, state.system.statuses) &&
          <>
              <Overlay zIndex={34} />
              <div className="menu_window" style={zIndex ? {zIndex: zIndex} : {}}>
            <div className="menu_items">
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title"
										onClick={showRegistration}>Вход / регистрация</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
              </div>
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Партнерская программа</div>*/}
              {/*    <div className="menu_item_description">Взаимовыгодное сотрудничество</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>*/}
              {/*</div>*/}
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Свободные заказы</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>*/}
              {/*</div>*/}

              <div className="menu_item" onClick={()=> showChat()}>
                <div className="menu_item_info">
                  <div className="menu_item_title"  >Служба поддержки</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={ChatsSVG} /></div>
              </div>

                {/*<div className="menu_item" onClick={showHelp}>*/}
                {/*  <div className="menu_item_info">*/}
                {/*    <div className="menu_item_title" onClick={()=>console.log('fff')} >Помощь</div>*/}
                {/*  </div>*/}
                {/*  <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>*/}
                {/*</div>*/}
            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
          </>
        }
      </>
    )
  } else {
    return (
      <>

        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {isSystemStatusActive(SystemStatus.Menu, state.system.statuses) &&
          <>
            <Overlay zIndex={34} />
            <div className="menu_window" style={zIndex ? {zIndex: zIndex} : {}}>
            <div className="menu_window_header" onClick={showProfile}>
              <div className="user_avatar"/>
              <div className="user_info">
                <div className="user_fullname">{user.full_name ? user.full_name : user.data ? user.data.passport.full_name : ''}</div>
              </div>
            </div>
            <div className="menu_items">

              <div className="menu_item" onClick={showProfile}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Профиль</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
              </div>

              <div className="menu_item" onClick={showPartnership}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Партнерская программа</div>
                  <div className="menu_item_description">Взаимовыгодное сотрудничество</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>
              </div>
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info" onClick={()=>showExecutionForm()}>*/}
              {/*    <div className="menu_item_title">Выполнение заказа</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={CargoTruckSVG} /></div>*/}
              {/*</div>*/}
              {/*<div className="menu_item" onClick={showFreeOrders}>*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Свободные заказы</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>*/}
              {/*</div>*/}
              <div className="menu_item"  onClick={()=>showOrderBook()}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Журнал заказов</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={JournalSVG} /></div>
              </div>

              <div className="menu_item" onClick={showStatisticInfo}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Статистика</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={StatisticSVG} /></div>
              </div>


              <div className="menu_item" onClick={showHelp}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Помощь</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>
              </div>
              {system.is_tracker_mode === false &&
                <div className="menu_item">
                  <div className="menu_item_info">
                    <div className="menu_item_title" onClick={linkTracker}>Привязать {APP_NAME}</div>
                  </div>
                  <div className="menu_item_icon"><ReactSVG src={TrackerSVG} /></div>
                </div>
              }
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title" onClick={logout}>Выход</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={LogOutSVG} /></div>
              </div>

            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    system: state.systemReducer
  }
});

export default connect(mapStateToProps, {  setLogOut})(Menu);
