import React, {useState} from "react";
import {OrdersState} from "../../../redux/reducers/orders/@types";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {passList} from "../../Registration/VehicleRegistration/VehicleRegistration";
import moment from "moment";
import {convertDate, getDateFromDMY, validateDateFutureString, validateDateString} from "../../../api/validate";
import InputFilteredValue from "../../Registration/InputFilteredValue/InputFilteredValue";
interface IProps {
    ch_id: string;
    title: string;
    value: any;
    onSetYes: (value_id: string | boolean)=>void;
    onSetNo: ()=>void;
}
const SelectorYesNo: React.FC<IProps> = ({ch_id, title, value,  onSetYes, onSetNo }) => {
    var mayBeDate = moment(value);
    const [isSetYes, setYes] = useState<boolean|undefined>(undefined);
    const [sDate, setDate] = useState<string>(mayBeDate.isValid() ? new Date(value).toLocaleString() : '');
    const [wasError, setError] = useState<boolean>(false);

    const orders  : OrdersState      = useSelector( (state: RootState)=> ({orders:state.ordersReducer})).orders;
    let bov = undefined;
    const ch = orders.bodyOptionCharacteristics.find(x=> x.id == ch_id);
    if (typeof value == "string") value = value.toLowerCase().trim();
    if (ch_id == "pass"){
            bov = passList.find(x => x.id.toLowerCase().trim() == value);
    }

    if (ch){
        if (ch.type == "ref")
            bov = orders.bodyOptionCharacteristicsValues.find(x => x.id.toLowerCase().trim() == value);
        if (ch.type == "number")
                bov = {name: value};
        if (ch.type == "Boolean")
            bov = {name: ''};
        if (ch.type == "date")
            bov = {name: 'в наличии'};

    }
    const onSetYesValue = () => {
        setYes(true);
        onSetYes(value);
        // if (!ch || (ch && ch.type != "date")) return onSetYes(value);
        // console.log('onSetYesValue', ch, ch.type, mayBeDate.isValid(), value);
        // if (ch && ch.type != "date" && mayBeDate.isValid()) onSetYes(value);

    };

    const onDateChanged = (value:string | number) => {
        console.log('onDateChanged', value);
        let v = convertDate(getDateFromDMY(value));
        v = v.replaceAll('.','-') + "T00:00:00";
        onSetYes(v);
        // setDate(value as string);
        console.log('onDateChanged', v);
    };
    const validateDate = (dt: string  ): boolean => {
        dt = convertDate(getDateFromDMY(dt));

        console.log('mayBeDate.diff(dt, \'days\')', mayBeDate.diff(dt, 'days'), dt);
        return mayBeDate.isValid() && mayBeDate.diff(moment(dt), 'days') < 1
    };

    return (
        <div className="selector-yes-no-block">
            <div className="selector-yes-no-block_title">{title} {bov ? bov.name : 'не найдено значение'}: </div>
            <div className="selector-yes-no-block_buttons">
                <div className={"selector-yes-no-block_buttons_yes" + (isSetYes ? ' active':'')}
                     onClick={()=>{onSetYesValue();}}>ЕСТЬ</div>
                <div className={"selector-yes-no-block_buttons_no " + (isSetYes === false ? ' active':'')}
                     onClick={()=>{setYes(false);onSetNo();}}>НЕТ</div>
            </div>
            {
                ch && ch.type == "date" && isSetYes &&
                <div className={"selector-yes-no-block_date"}>
                    <InputFilteredValue name="срок действия до :"
                                        mask="99.99.9999"
                                        value={sDate}
                                        placeholder={"01.01.2023"}
                                        classAppend="short"
                                        onValueChanged={onDateChanged}
                                        validator={validateDate}
                                        wasError={wasError}/>
                </div>
            }
        </div>
    )
};

export default SelectorYesNo;
