import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { setRegistrationStatus } from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import CloseSVG from "../App/svg/close.svg";
import {  TArticle} from "./@types";
import { userAPI } from "../../api";
import "./Help.css";
import Loader from "react-loader-spinner";
import {Overlay} from "../Elements/elements";

interface IProps {
  zIndex?:number;
}
const Help: React.FC<IProps> = ({zIndex}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [articleList, setArticlesList] = useState<TArticle[]>([]);
  const [selectedItem, setSelectedItem] = useState<TArticle|undefined>(undefined);
  const [selectedFolder, setSelectedFolder] = useState<TArticle|undefined>(undefined);



  const dispatch = useDispatch();

  const openArticle = (id: string) => {
    let item = articleList.find((item) => item.id === id);
    if (!item) return setSelectedItem(undefined);
    if (item.is_folder) {
      return setSelectedFolder(item)
    }

    setLoading(true);
    userAPI.getArticles(id).then((res) => {
      console.log('getArticles()', res)
      setLoading(false);
      if (res.data && Array.isArray(res.data) && item?.is_folder) {
        let data = res.data.sort((a,b)=>  a.is_folder && !b.is_folder ? -1 : 1 )
        setArticlesList(data)
        setSelectedItem(item)
      }
      if (res.data && Array.isArray(res.data) && !item?.is_folder) {
        setSelectedItem(res.data[0])
      }
    });

  }

  const closeArticle = () => {
    setSelectedItem(undefined)
  }

  const getArticles = () => {
    setLoading(true);

    userAPI.getArticles().then((res) => {
      console.log('getArticles()', res)
        setLoading(false);
        if (res.data && Array.isArray(res.data)) {
          let data = res.data.sort((a,b)=>  a.is_folder && !b.is_folder ? -1 : 1 )
          setArticlesList(data)
        }
      });
  }

  useEffect(() => {
    getArticles();
  }, []);

  const toMainScreen = () => {
      if (selectedFolder) {
          setSelectedFolder(undefined);
          return;
      }
      dispatch(popSystemStatus());
      dispatch(setRegistrationStatus(null));
  }

  return (
    <div className="screen_help">
      <div className="page_back_link" onClick={toMainScreen}>
        <svg width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z" fill="#888E99"/>
        </svg>
      </div>
      <div className="screen_help_header">Помощь</div>
      <div className="screen_help_list">
        {articleList.filter(x=> (!selectedItem && !selectedFolder) ? x.is_folder : x.parent == (selectedFolder ? selectedFolder.id :''))
            .map((item) => {
          return (
            <div key={item.id} className="screen_help_article" onClick={() => openArticle(item.id)}>
              <div className="screen_help_article_title">{item.title}</div>
              <div className="screen_help_article_description" dangerouslySetInnerHTML={{__html:item.description.replace('\n','<br/><br/>')}}/>
              <div className="screen_help_article_footer"><span className="screen_help_article_btn">
                {item.is_folder ? 'Перейти в категорию' : 'Подробнее'}</span></div>
            </div>
          );
        })}
      </div>
      {selectedItem && !selectedItem.is_folder &&
        <div className="article_window">
          <div className="window_close_btn" onClick={closeArticle}><ReactSVG src={CloseSVG} /></div>
          <div className="article_window_header">{selectedItem.title}</div>
          <div className="article_window_content" dangerouslySetInnerHTML={{__html : selectedItem && selectedItem.article? selectedItem.article : ''}}/>
        </div>
      }

      {isLoading  &&
       <div>
          <div className="loader">
            <Loader type="Puff" color="#00BB40" width={'8rem'} height={'8rem'}/>
            <div className="loader-text">Идет загрузка данных</div>
          </div>
         <Overlay is_visible={true} />
       </div>
      }
    </div>
  );
}

export default Help;
