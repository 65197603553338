import React from 'react'
import marker from '../../../images/marker-blue.svg';

// const HereMarkerIcon =`
// <div style="background: #fff">
//     <div  class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">
//         <div class="marker-number">{NUMBER}</div>
//         <img className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:24px;height:40px;"    src="${marker}"/>
//
//      </div>
// </div>`;

// const HereMarkerIcon =`
// <div>
//     <div  class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">
//         <div class="marker-number">&nbsp;</div>
//         <img className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:24px;height:40px;"    src="${marker}"/>
//         <span class="cloud">{NUMBER}</span>
//      </div>
// </div>`;

export function HereMarkerBlue(label: string) {
     return '<div style="background: transparent">' +
                '<div class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">' +
                    `<div class="marker-number ${label ? '' : 'marker-number-hidden'}"> <span class="cloud-blue">${label}</span></div>` +
                    `<img class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:31px;height:38px;" src="${marker}"/>` +
                '</div>' +
            '</div>';

}

export function HereMarkerBlueAndMessage(label: string, info : {arrivalDate?:string, departureDate?:string, address?: string, distance?:number, time?:number }) {
     return '<div style="background: transparent">' +
         '<div class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">' +
         `<div class="marker-number marker-number-2">
            <div class="marker-address">${info.address ? info.address : ''}</div>  
            <div class="marker-distance">${info.distance ? info.distance + ' км от вас' : ''}</div>  
            <div class="marker-time">${info.time ? 'ехать ' + info.time + ' минут' : ''}</div>  
          </div>` +
         `<img class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:31px;height:38px;" src="${marker}"/>` +
         '</div>' +
         '</div>';

}
