import React, { useState, CSSProperties } from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOverlay, setSystemStatus} from "../../../redux/reducers/system/actions";
import { ReactSVG } from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import FilterSVG from "../../../images/filter.svg";
import "./BottomWindow.css";
import {RootState} from "../../../redux/store";
import Overlay from "../Overlay/Overlay";
import ChatsSVG from "../../App/svg/chats.svg";
import {Button} from "../elements";
import {SystemStatus} from "../../../@types";
import ChatButton from "../../Chat/ChatButton/ChatButton";

interface IProps {
  title: string;
  resizable: boolean;
  visible: boolean;
  description?: string;
  onClose?: () => void;
  style?: CSSProperties;
  isFullMode?:boolean;
}

interface ILocalState {
  position: {
    y: number;
  }
}

const BottomWindow: React.FunctionComponent<IProps> = ({ title, resizable, visible, description, onClose, style, children, isFullMode }) => {
  const dispatch = useDispatch();

  const {  system, user } = useSelector((state: RootState) => ({
    system: state.systemReducer,
    user: state.userReducer
  }));

  const [localState, setLocalState] = useState<ILocalState>({
    position: {
      y: isFullMode ? 30 : 0
    }
  });

  const { position } = localState;

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (resizable) {
      const touch = e.touches[0] || e.changedTouches[0];
      setLocalState((s) => ({
        ...s,
        position: {
          y: touch.pageY,
        }
      }));
      dispatch(setOverlay(true));
    }
  }

  const handleTouchEnd = () => {

    if (  position.y && position.y > window.innerHeight / 2) {
      dispatch(setOverlay(false));

      setLocalState((s) => ({
        ...s,
        position: {
          y: 0,
        }
      }));
    }

    if (  position.y < window.innerHeight / 2) {
      setLocalState((s) => ({
        ...s,
        position: {
          y: 30,
        }
      }));
    }
  };

  let hideWindow = (e:any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (onClose) onClose();
  }

  return (
    <>

      {position.y > 0 && <Overlay />}
      {visible &&
        <div className="bottom_window" style={{ top: (position.y) ? `${position.y}px` : "auto",
          zIndex: (position.y) ? 45 : 20, ...style }}>
          {resizable &&
            <div className="bottom_window_btn_container">
              <div className="bottom_window_btn"></div>
            </div>
          }

          { position.y ? '' :
              <div className="btn btn_middle btn_referal order_window_btn order_window_btn_referals"
                   onClick={(e)=>{e.stopPropagation(); dispatch(setSystemStatus(SystemStatus.Partnership))}}
              >Партнерская  программа
              </div>}
          <div className="bottom_window_header"  onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove}  >
            {onClose && <div className="window_close_btn" onTouchEnd={(e)=> e.stopPropagation()} onClick={(e) =>  hideWindow(e)} ><ReactSVG src={CloseSVG} /></div>}
            <div className="bottom_window_title">{ title }

              </div>
              {description && <div className="bottom_window_description">{ description }</div>}
          </div>
          {position.y ? <div className="bottom_window_full_content">{ children }</div> :
              <div className={"bottom_window_short_content " + (user.authenticated ? 'bottom_window_plus' : '')}>{ children }</div>}
        </div>
      }
    </>
  );
}

export default BottomWindow;
