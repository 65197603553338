import React, {useEffect, useState} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {setAuthenticated, setStatus, setUserCoordinates, setUserInfo} from "../../redux/reducers/user/actions";
import {
    clearSystemStatus,
    loadInitDataThunk,
    popSystemStatus,
    setDeviceID,
    setMessage,
    setPlayMode,
    setSystemPlayStatus,
    setSystemStatus,
    showMessageError,
    showMessageInfo,
    showMessageTrunk
} from "../../redux/reducers/system/actions";
import store, {RootState} from "../../redux/store";
import {ordersAPI, userAPI} from "../../api";
import {
    CheckPhone,
    Help,
    MainScreen,
    OwnerSelect,
    Partnership,
    Profile,
    QuickRegistration,
    Statistic,
} from "../components";
import {DatePickerWindow, DriverStatusWindow, Overlay, SystemMessage} from "../Elements/elements";
import "./App.css";
import "./Registration.css";
import {OrdersListType, RegistrationStatus, SystemStatus, UserStatus} from "../../@types";
import OrderBook from "../OrderBook/OrderBook";
import {getCookie} from "../../redux/reducers/system/cookies";
import Menu from "../Elements/Menu/Menu";
import {getStatusWorking} from "../../redux/reducers/user/reducers";
import CloseRoute from "../Elements/HereMap/CloseRoute";
import WaitingWindow from "../WaitingWindow/WaitingWindow";
import OrderFullCard from "../OrderFullCard/OrderFullCard";
import MessageWindow from "../MessageWindow/MessageWindow";
import OrderPlayWindow from "../OrderPlayWindow/OrderPlayWindow";

import {
    APP_INSTALL_URL,
    APP_NAME,
    IS_EMULATE_DRIVE,
    IS_TEST_ROUTE_DRIVE,
    NAVIGATION_GET_COORDINATE_TIME_IN_MS, PING_DEVICE_INTERVAL,
    SYSTEM_MESSAGE_REFRESH_INTERVAL
} from "../../deployment";
import {Chat} from "../Chat/Chat";
import {EnumWindowMessageType, ERROR_CODE} from "../../redux/reducers/system/@types";
import {setChatGroups} from "../../redux/reducers/chat/chatActions";
import CommonRegistration from "../Registration/CommonRegistration/CommonRegistration";
import BankDataRegistration from "../Registration/BankDataRegistration/BankDataRegistration";
import DriverRegistration from "../Registration/DriverRegistration/DriverRegistration";
import {UserInfo} from "../../redux/reducers/user/@types";
import VehicleRegistration from "../Registration/VehicleRegistration/VehicleRegistration";
import RecommendationRegistration from "../Registration/RecomendationRegistration/RecommendationRegistration";
import {
    clearSystemServiceMessages,
    getDriverRouteToNextPoint,
    isIOS,
    pingDevice,
    registrationUserDevice,
    setCurrentDriverPosition
} from "../../redux/reducers/system/system_functions";
import {EnumStatusExecution} from "../../redux/reducers/orders/@types";
import {openOrderThunk} from "../../redux/reducers/orders/reducers";
import {setIndexNextPoint, setRoutes} from "../../redux/reducers/map/actions";
import TestRouter from "../OrderPlayWindow/TestRouter/TestRouter";
import MiddleRegistrationForm from "../Registration/MiddleRegistrationForm/MiddleRegistrationForm";
import DownloadApplicationForm from "../Registration/DownloadApplicationForm/DownloadApplicationForm";
import {getChatInitialStat, openChatWindow} from "../../redux/reducers/chat/chatReducers";
import VeryQuickRegistration from "../Registration/VeryQuickRegistration/VeryQuickRegistration";

export var iPhoneVariant = 1;

let interval1:any = undefined;
let intervalCheckSystemMessages:any = undefined;
let intervalPingDevice:any = undefined;


const App: React.FunctionComponent = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => ({
        user: state.userReducer,
        system: state.systemReducer,
        registration: state.registrationReducer,
        orders: state.ordersReducer,
        owner: state.ownerSelectReducer,
        quick:   state.quickRegistrationReducer,
        chat:   state.chatReducer,
        finalize:   state.finalizeReducer,
        map: state.mapReducer,
        phone:   state.checkPhoneReducer,
        temp:   state.temporaryReducer,
    }));

    const [isUpdate, setUpdate] = useState<number>(1);
    let device_id_query_param = (new URL(window.location.href)).searchParams.get('device_id');
    let driver_id_query_param = (new URL(window.location.href)).searchParams.get('driver_id');
    let order_id_query_param = (new URL(window.location.href)).searchParams.get('order_id');
    let dialog_id_query_param = (new URL(window.location.href)).searchParams.get('dialog_id');

    useEffect(()=>{
        console.log('useEffect setDeviceID');
        if (device_id_query_param) dispatch(setDeviceID(device_id_query_param))
    }, []);

    //@ts-ignore
    window.state = state;
    const { user, system, registration } = state;


    const openOrderInQuery = async (order_id:string|null, driver_id: string) => {
        if (!order_id) return ;
        await openOrderThunk(order_id,  driver_id, true, OrdersListType.Set, dispatch , false);
    }
    const getOrderInProgressStatus = async (driver_id:string) => {
        let res = await userAPI.getOrderInProgress(driver_id).catch((err) => showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка получения текущего заказа " + err.message},dispatch));
        if (!res.data || res.status !== 200)
            return showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка загрузки данных выполняемого заказа !"},dispatch)

        let order_id = res.data.order_id;

        if (!order_id) {
            await clearSystemServiceMessages( driver_id, dispatch);
            return ;
        }


        res = await userAPI.getOrderExecution(order_id, driver_id).catch((err) => showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка получения данных текущего заказа " + err.message},dispatch));
        if (!res.data || res.status !== 200 || !Array.isArray(res.data) )
            return showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка структуры данных информации о выполняемом заказе !"},dispatch)

        if (res.data.some((x:any)=> x.status == "finish") || res.data.length == 0)
            return ;
        let point = res.data[res.data.length-1].point;
        let status : EnumStatusExecution = res.data[res.data.length-1].status ;


        let route_data = await userAPI.getRoute(order_id).catch((err) => showMessageTrunk({type: EnumWindowMessageType.Error, text: "Ошибка получения данных по маршурту выполняемого заказа " + err.message},dispatch));
        if (!route_data || !route_data.data) return showMessageError("Не найден маршрут для заказа !", dispatch);
        await dispatch(setRoutes(route_data.data));

        await openOrderThunk(order_id,  driver_id, true, OrdersListType.Set, dispatch , false);

        dispatch(clearSystemStatus());
        dispatch(setIndexNextPoint(point+1));
        dispatch(setSystemStatus(SystemStatus.PlayWindow));
        dispatch(setSystemPlayStatus(point, status));

        if (route_data?.data ) {

            let pos = await setCurrentDriverPosition(dispatch, user.driver_id, point == -1, route_data.data, system.is_tracker_mode);
            if (point+1 >=  route_data.data.points.length) point = route_data.data.points.length-1
            else point += 1
            let npos = {lat: route_data.data.points[point].lat, lon: route_data.data.points[point].lng} ;
            if (pos) await getDriverRouteToNextPoint(dispatch, [pos, npos]);
            dispatch(setPlayMode(true));
        }

        // dispatch(clearSystemStatus());
        // dispatch(setSystemStatus(SystemStatus.PlayWindow));
    }


    useEffect( ()  => {
        console.log('useEffect loadInitDataThunk');
        loadInitDataThunk(dispatch);

        let driver_id = driver_id_query_param ? driver_id_query_param : getCookie('driver_id');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (pos: any) => {
                dispatch(setUserCoordinates({ lat:pos.coords.latitude, lon: pos.coords.longitude} ));
            });
        }

        if (driver_id) {
                (async function f() {
                    let response = await userAPI.getAuthUser(driver_id);
                    if (!response || !response.data || response.data.status=="error") {
                        if (response?.data?.error_code == ERROR_CODE.NEED_AUTHORIZATION){
                            await showMessageInfo('Внимание время сессии истекло, необходимо повторно авторизоваться', dispatch);
                            return false;
                         }

                        await showMessageError('Ошибка авторизации !' + (response?.data?.error_message), dispatch);
                        return false;
                    }
                    driver_id = response.data.driver_id;

                    userAPI.getUserInfo(driver_id,  'driver_id').then((res) => {
                        if (!res.data || res.status !== 200 || res.data.status === "error") return;
                         dispatch(setAuthenticated({
                            authenticated: true,
                            driver_id: driver_id,
                            full_name: res.data && res.data.persone && res.data.person.full_name ? res.data.person.full_name: '',
                            status: UserStatus.Working,
                            data: new UserInfo(),
                            current_coordinates: user.current_coordinates,
                            has_tracker:''
                        }));
                        userAPI.getDriverStatus(driver_id).then(res => {
                            console.log('getDriverStatus',driver_id, res.data);
                            if (res.data && res.status === 200 && res.data.status!="error") {

                                dispatch(setStatus(getStatusWorking(res.data.status, res.data.dates)));
                            }
                        }).catch((err) => console.log(err));

                        if (Array.isArray(res.data.cars) && res.data.cars.length>0 && res.data.cars[0].certificate.number == "") {
                            res.data.cars[0].certificate.VIN = "70101110011";
                            res.data.cars[0].certificate.number = res.data.person.phone_number;
                        }

                        dispatch(setUserInfo({...res.data}));
                        if ( Array.isArray(res.data.cars) && res.data.cars.length && res.data.cars[0].certificate.VIN == "70101110011" && !order_id_query_param) {
                            dispatch(setSystemStatus(SystemStatus.Registration));
                            dispatch(setSystemStatus(SystemStatus.RegistrationOwnerSelect));
                        }
                        let driver_form_id = res.data.id;
                        registrationUserDevice(driver_form_id, dispatch, device_id_query_param);
                        // pingDevice(id, dispatch).catch( e=> {
                        //     showMessageError(e, dispatch)
                        // });

                        getChatInitialStat(driver_id, dispatch).then( async ()=>{
                            await openChatWindow(dialog_id_query_param, dispatch);
                        });
                        openOrderInQuery(order_id_query_param, driver_id);

                    }).catch((err) => console.log(err));

                    getOrderInProgressStatus(driver_id);
                })();




        }

    }, []);

    useEffect(() => {
        console.log('App system.statuses', system.statuses)
    }, [system.statuses]);

    useEffect(() => {
        // if (intervalCheckSystemMessages)  clearInterval(intervalCheckSystemMessages);
        if (intervalPingDevice)  clearInterval(intervalPingDevice);

        if (user.data && user.data.id) {
            // системные сообщения должен отрабатывать трекер по идее
            // intervalCheckSystemMessages = setInterval(() => {
            //     userAPI.getSystemMessage(user.driver_id).then((res) => {
            //         if (res.data[0] && system && !system.message.id) {
            //             dispatch(setMessage(res.data[0]));
            //         }
            //     }).catch((err) => {});
            // }, SYSTEM_MESSAGE_REFRESH_INTERVAL);
            pingDevice(user.data.id, system.tracker_id, dispatch);

            intervalPingDevice = setInterval(() => {
                pingDevice(user.data.id, system.tracker_id, dispatch).catch((err) => {});
            }, PING_DEVICE_INTERVAL);
        }

        return () => {
            clearInterval(intervalCheckSystemMessages);
            clearInterval(intervalPingDevice);
        }
    }, [user.driver_id, system.tracker_id, user.data]);

    const registrationRouter = (status: RegistrationStatus | null) => {
        switch (status) {
            case RegistrationStatus.CheckPhone: return <CheckPhone  key="wnd_CheckPhone" />;
            case RegistrationStatus.OwnerSelect: return <OwnerSelect key="wnd_OwnerSelect"/>;
            // case RegistrationStatus.CommonForm: return <CommonRegistration key="wnd_CommonForm" />;
            case RegistrationStatus.QuickRegistration: return <QuickRegistration  key="wnd_QuickRegistration"/>;
            default: return <CheckPhone  key="wnd_CheckPhone"  />;
        }
    }

    const router = (statuses: SystemStatus[], registration: RegistrationStatus | null) => {
        let arr : any []= [];
        arr.push(<MainScreen  key="wnd_MainScreen"/>);

        if (statuses)
            statuses.forEach( (status, index)=> {
                switch (status) {

                    case SystemStatus.Help:
                        arr.push(<Help key="wnd_Help" zIndex={index+30}/>);
                        break;
                    case SystemStatus.Statistic:
                        arr.push(<Statistic key="wnd_Statistic" zIndex={index+30}/>);
                        break;
                    case SystemStatus.Profile:
                        arr.push(<Profile  key="wnd_Profile" zIndex={index+30}/>);
                        break;
                    case SystemStatus.Partnership:
                        arr.push(<Partnership key="wnd_Partnership" zIndex={index+30}/>);
                        break;
                    case SystemStatus.OrderBook:
                        arr.push(<OrderBook key="wnd_OrderBook" zIndex={index+30}/>);
                        break;
                    case SystemStatus.Registration:
                        arr.push(registrationRouter(registration));
                        break;
                    case SystemStatus.RegistrationCommonForm:
                        arr.push(<CommonRegistration key={SystemStatus.RegistrationCommonForm} zIndex={index+29}/>);
                        break;
                    case SystemStatus.RegistrationBankData:
                        arr.push(<BankDataRegistration key={SystemStatus.RegistrationBankData} zIndex={index+29}/>);
                        break;
                    case SystemStatus.RegistrationDriverData:
                        arr.push(<DriverRegistration key={SystemStatus.RegistrationDriverData} zIndex={index+29} val={Math.random()}/>);
                        break;
                    case SystemStatus.RegistrationVehicleData:
                        arr.push(<VehicleRegistration key={SystemStatus.RegistrationVehicleData} zIndex={index+29} val={Math.random()}/>);
                        break;
                    case SystemStatus.RegistrationRecommendationData:
                        arr.push(<RecommendationRegistration key={SystemStatus.RegistrationRecommendationData} zIndex={index+29} val={Math.random()}/>);
                        break;

                    case SystemStatus.RegistrationMiddleForm:
                        arr.push(<MiddleRegistrationForm  key={SystemStatus.RegistrationMiddleForm} />);
                        break;
                    case SystemStatus.RegistrationDownloadApplication:
                        arr.push(<DownloadApplicationForm  key={SystemStatus.RegistrationDownloadApplication} />);
                        break;

                    case SystemStatus.RegistrationOwnerSelect:
                        arr.push(<OwnerSelect  key={SystemStatus.RegistrationOwnerSelect} />);
                        break;
                    case SystemStatus.Overlay:
                        arr.push(<Overlay key="wnd_Overlay" zIndex={index+29}/>);
                        break;
                    case SystemStatus.Menu:
                        arr.push(<Menu key="wnd_Menu" zIndex={index+30}/>);
                        break;
                    case SystemStatus.DriverStatusWindow:
                        arr.push(<DriverStatusWindow key="wnd_DriverStatusWindow" zIndex={index+30}/>);
                        break;
                    case SystemStatus.CloseRoute:
                        arr.push(<CloseRoute key="wnd_CloseRoute" zIndex={index+30}/>);
                        break;
                    case SystemStatus.WaitingWindow:
                        arr.push(<WaitingWindow key="wnd_WaitingWindow" zIndex={index+30}/>);
                        break;
                    case SystemStatus.MessageWindow:
                        arr.push(<MessageWindow key="wnd_MessageWindow" zIndex={index+30}/>);
                        break;
                    case SystemStatus.DatePickerWindow:
                        arr.push(<DatePickerWindow key="wnd_DatePickerWindow" zIndex={index+30}/>);
                        break;
                    case SystemStatus.PlayWindow:
                        arr.push(<OrderPlayWindow  key="wnd_OrderPlayWindow" zIndex={index+30}/>);
                        break;
                    case SystemStatus.Chat:
                        arr.push(<Chat  key={SystemStatus.Chat }/>);
                        break;
                    case SystemStatus.VeryQuickRegistration:
                        arr.push(<VeryQuickRegistration  key={SystemStatus.VeryQuickRegistration }/>);
                        break;
                    case SystemStatus.FullOrderCard:
                        arr.push(<OrderFullCard order={state.orders.current} onClose={ async  ()=>{
                           await dispatch(popSystemStatus())
                        }}  key="wnd_OrderFullCard" zIndex={index+30}/>);
                        break;

                    case SystemStatus.SmallOrderCard:
                        arr.push(<OrderFullCard order={state.orders.current} onClose={ async ()=>{ await dispatch(popSystemStatus()) }}
                                                full_mode={false}
                                                key="wnd_OrderFullCard" zIndex={index+30}/>
                        );
                        break
                }
            });
        //console.log('App arr', arr);
        return arr;
    };

    const requestWakeLock = async () => {
        try {
            //@ts-ignore
            const wakeLock = await navigator.wakeLock.request('screen');
        } catch (err) {
            // the wake lock request fails - usually system related, such low as battery
            console.log(`${err.name}, ${err.message}`);
        }
    };

    // myWorker.onmessage = async (m) => {
    //
    //     if (IS_EMULATE_DRIVE  || !user.driver_id) return;
    //     currentCoordinate = await new Promise( ((resolve, reject) => {
    //             if (!navigator.geolocation) return reject(undefined);
    //             navigator.geolocation.getCurrentPosition(async (pos) => {
    //                 return resolve({lat: pos.coords.latitude, lon: pos.coords.longitude})
    //             }, ()=>{}, 	{	enableHighAccuracy:	true,	timeout: 10,	maximumAge:	0});
    //         }));
    //     console.log("msg from worker: ", m.data, currentCoordinate);
    //     await setCurrentDriverPosition(dispatch, user.driver_id);
    // };

    useEffect(()=> {

        console.log('useEffect user.driver_id, system.is_tracker_mode', user.driver_id, system.is_tracker_mode);
        if (user.driver_id) {
            // myWorker.postMessage(NAVIGATION_GET_COORDINATE_TIME_IN_MS);
            if (interval1) clearInterval(interval1) ;
            interval1 = setInterval(async () => {
                if (IS_EMULATE_DRIVE  || !user.driver_id) return;
                await setCurrentDriverPosition(dispatch, user.driver_id, false, undefined, system.is_tracker_mode  );
            }, NAVIGATION_GET_COORDINATE_TIME_IN_MS)
        } else
            if (interval1) clearInterval(interval1);

        return () => {
            if (interval1) clearInterval(interval1)
        };
    },[user.driver_id, system.is_tracker_mode]);

    useEffect(()=> {
        console.log('useEffect [l]');
        let interval : any = setInterval(async () => {  requestWakeLock() }, 500);
        return () => {
            if (interval) clearInterval(interval)
        };
    },[]);
    return (
        <>
            {router(system.statuses, registration.status)}
            <SystemMessage  key="wnd_SystemMessage" />
            <Chat/>
            {IS_TEST_ROUTE_DRIVE &&  <TestRouter/>}
            {false && isIOS() && <div className="isIOS-device">Это iPhone
                <button onClick={()=> {iPhoneVariant = 1; setUpdate(iPhoneVariant)}} className={ iPhoneVariant == 1 ? 'selected' : ''}>Вариант 1</button>
                <button onClick={()=> {iPhoneVariant = 2; setUpdate(iPhoneVariant)}} className={ iPhoneVariant == 2 ? 'selected' : ''}>Вариант 2</button>
                <button onClick={()=> {iPhoneVariant = 3; setUpdate(iPhoneVariant)}} className={ iPhoneVariant == 3 ? 'selected' : ''}>Вариант 3</button>
                <button onClick={()=> {iPhoneVariant = 4; setUpdate(iPhoneVariant)}} className={ iPhoneVariant == 4 ? 'selected' : ''}>Вариант 4</button>
                <button onClick={()=> {iPhoneVariant = 5; setUpdate(iPhoneVariant)}} className={ iPhoneVariant == 5 ? 'selected' : ''}>Вариант 5</button>

            </div>}
            {/*<div className="isIOS-device">*/}
            {/*    <button onClick={()=>  dispatch(setUseTracker(!system.is_tracker_mode))}*/}
            {/*            className={ system.is_tracker_mode ? 'selected' : ''}*/}
            {/*        >*/}
            {/*        { system.is_tracker_mode ? 'Трекер' : 'Браузер'}*/}
            {/*    </button>*/}
            {/*</div>*/}
            {user.authenticated && system.is_tracker_mode === false &&
                <div className="isCheckDevice" onClick={()=>
                    showMessageInfo(`<div style="font-size: 16px;">Нам не удалось найти на вашем устройстве приложение ${APP_NAME}.<br/>&nbsp;<br/>
                                          Для корректной работы приложения, необходимо <br/>
                                          <a href="${APP_INSTALL_URL}" style="text-decoration: underline" target="_blank">установить ${APP_NAME}</a><br/>&nbsp;<br/>
                                          После чего в меню выбрать пункт 'Привязать ${APP_NAME}'.</div>`, dispatch)}>
                    Необходимо сделать привязку { APP_NAME} !</div>
            }
        </>
    )
}

const Container: React.FunctionComponent = (props) => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}
export default Container;

