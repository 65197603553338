import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Menu, OrdersWindow, StatusBtn} from "../Elements/elements";
import HereMap from "../Elements/HereMap/HereMap";
import {isSystemStatusActive} from "../../redux/reducers/system/reducers";
import {SystemStatus} from "../../@types";
import TimeBtn from "../OrderPlayWindow/TimeBtn/TimeBtn";

const MainScreen: React.FC = () => {
  const state = useSelector((state: RootState) => ({
        user: state.userReducer,
      system: state.systemReducer,
  }));

  const { user, system } = state;

  return (
    <>
      <Menu />
        {!isSystemStatusActive(SystemStatus.PlayWindow, state.system.statuses ) && <StatusBtn />}
        {isSystemStatusActive(SystemStatus.PlayWindow, state.system.statuses ) && <TimeBtn />}
        <HereMap />

      {!isSystemStatusActive(SystemStatus.PlayWindow, state.system.statuses ) &&
       !isSystemStatusActive(SystemStatus.SmallOrderCard, state.system.statuses ) &&
       <OrdersWindow /> }
    </>
  )
}

export default MainScreen;
