import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/store";
import InputMask from "react-input-mask";
import {
    CheckPhoneState,
    SetCodeEnteredAction,
    SetCodeSendingAction,
    SetCodeSentAction,
    SetCodeVerifyingAction,
    SetPhoneEnteredAction
} from "../../redux/reducers/check_phone/@types";
import {SetAuthenticatedAction, SetUserInfoAction, UserInfo, UserState} from "../../redux/reducers/user/@types";
import {SetRegistrationStatusAction} from "../../redux/reducers/registration/@types";
import {SetSystemStatusAction, SystemState} from "../../redux/reducers/system/@types";
import {
    setCodeEntered,
    setCodeSending,
    setCodeSent,
    setCodeVerifying,
    setPhoneEntered
} from "../../redux/reducers/check_phone/actions";
import {authUser, setAuthenticated, setStatus, setUserInfo} from "../../redux/reducers/user/actions";
import {setRegistrationStatus} from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus, showMessageError} from "../../redux/reducers/system/actions";

import BulletSVG from "../App/svg/bullet.svg";
import CheckSVG from "../App/svg/check.svg";
import {ReactSVG} from "react-svg";
import {CancelBtn} from "../Elements/elements";
import {phoneAPI, userAPI} from "../../api";
import {trimPhone} from "../../api/validate";
import {RegistrationStatus, SystemStatus, UserStatus} from "../../@types";
import "./CheckPhone.css";
import {getStatusWorking} from "../../redux/reducers/user/reducers";
import {OrdersState} from "../../redux/reducers/orders/@types";
import {EnumStatusResponse} from "../../api/@types";
import {pingDevice, registrationUserDevice} from "../../redux/reducers/system/system_functions";
import Loader from "react-loader-spinner";
import {getChatInitialStat} from "../../redux/reducers/chat/chatReducers";

interface IProps {
    state: CheckPhoneState,
    user: UserState,
    system: SystemState,
    orders: OrdersState,
    setPhoneEntered: (phone_entered: boolean, phone?: string) => SetPhoneEnteredAction,
    setCodeSending: (code_sending: boolean) => SetCodeSendingAction,
    setCodeSent: (code_sent: boolean) => SetCodeSentAction,
    setCodeEntered: (code_entered: boolean, code?: string) => SetCodeEnteredAction,
    setCodeVerifying: (code_verifying: boolean) => SetCodeVerifyingAction,
    setAuthenticated: (state: UserState) => SetAuthenticatedAction,
    setUserInfo: (state: UserInfo) => SetUserInfoAction,
    setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
    setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const CheckPhone: React.FunctionComponent<IProps> = ({
                                                         state,
                                                         user,
                                                         system,
                                                         orders,
                                                         setPhoneEntered,
                                                         setCodeSending,
                                                         setCodeSent,
                                                         setCodeEntered,
                                                         setCodeVerifying,
                                                         setAuthenticated,
                                                         setUserInfo,
                                                         setRegistrationStatus,
                                                         setSystemStatus
                                                     }) => {

    const [numOrders, setNumOrders] = useState(0);
    const [sendCodeError, setSendCodeError] = useState(false);
    const [verifyCodeError, setVerifyCodeError] = useState(false);
    const [checkPhoneError, setCheckPhoneError] = useState(false);
    const [time, setTime] = useState(60);
    const timeLeft = useRef(0);
    const dispatch = useDispatch();
    const [registrationStarted, setRegistrationStarted] = useState(false);
    const [error, setError] = useState(false);

    const [localState, setLocalState] = useState<{is_accepted: boolean}>({ is_accepted: false });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // ordersAPI.getList(user.driver_id, OrdersListType.All).then((res) => {
        //   setNumOrders(res.data.length);
        // }).catch((err) => {})
        setNumOrders(orders.items.length)
    }, []);

    const toOwnerSelect = () => {
        // dispatch(setSystemStatus(SystemStatus.RegistrationOwnerSelect))
        dispatch(setSystemStatus(SystemStatus.RegistrationMiddleForm));
    }

    const toMainScreen = () => {
		setCodeSent(false);
        dispatch(popSystemStatus());
        dispatch(setRegistrationStatus(null));
    }
    let smsInterval: any = null;

	const setAccepted =  (e : any) => {
     setLocalState((s)=>({is_accepted: !localState.is_accepted}));
    }
	const onPhoneChanged =  (e : any) => {
			let phone = e.target.value;
			if (phone.indexOf('_') === -1) {
				phone = trimPhone(phone);
				if (phone=="")
                    setPhoneEntered(false);
				else
				    setPhoneEntered(true, phone);
			} else {
				setPhoneEntered(false);
			}
	};

	const onNextButtonClick = async () => {
			if (isLoading) return ;

			setError(false);
			if (state.code_sending) return;
			if (!state.phone_entered || !localState.is_accepted) {
				setError(true);
				return;
			}
			setCodeSending(true);
			timeLeft.current = 60;
			setTime(60);
			smsInterval = setInterval(() => {
				timeLeft.current -= 1;
				setTime(timeLeft.current);
				if (timeLeft.current === 0) {
					clearInterval(smsInterval);
					setCodeSent(false);
					setCodeEntered(false, '');
					setError(false);
				}
			}, 1000);
			setIsLoading(true);
			let res = await phoneAPI.sendSms(state.phone);
			setCodeSending(false);
			setIsLoading(false);
			if (res.data && res.data.status === 'success') {
				setCodeSent(true);
			} else {
				setSendCodeError(true);
				setTimeout(setSendCodeError, 5000, false);
			}

	}
	const onCodeEnter = async () => {
			if (isLoading) return ;

			setError(false);
			if (state.code_verifying) return;
			if (!state.code_entered) {
				setError(true);
				return;
			}
			setCodeVerifying(true);
			// if (state.code === '6292') {
			// 	setCodeVerifying(false);
			// 	setTimeout(toOwnerSelect, 500);
			// 	return;
			// }
			setIsLoading(true);
			dispatch(setAuthenticated({
				authenticated: false,
				driver_id: '',
				full_name: '',
				status: UserStatus.Unauthenticated,
				data: new UserInfo(),
				current_coordinates: user.current_coordinates,
				has_tracker:''
			}));

			dispatch(setUserInfo(new UserInfo()));

			let res = await userAPI.auth(state.phone, state.code);
			setCodeVerifying(false);
			setIsLoading(false);
			if (res.data && res.data.status === 'error' && res.data.error_code === 1) {
				setVerifyCodeError(true);
				setTimeout(setVerifyCodeError, 5000, false);
				return;
			}

			if (res.data && res.data.status === 'error' && res.data.error_code === 2) {
				setTimeout(toOwnerSelect, 500);
				setTimeout(()=>setCodeSent(false),600);
				return;
			}
			if (res.data && res.data.status === 'not_registered' ) {

				let id = res.data.id;
				registrationUserDevice(id, dispatch);
				res = await userAPI.getUserInfo(res.data.id, 'id');
				if (res.data && res.status === 200) {
					if (res.data.status === 'error') {
						dispatch(setUserInfo({...(new UserInfo()), id : id}));
					} else
						dispatch(setUserInfo({...(res.data[0]), id : id}));
				}
				setTimeout(toOwnerSelect, 500);
				setTimeout(()=>setCodeSent(false),600);
				return;
			} else
			if (res.data && (res.data.status === 'success' || res.data.status === 0)) {

				// let driver_id = res.data.driver_id;
				// getChatInitialStat(driver_id, dispatch);
				// registrationUserDevice(driver_id, dispatch);
				// res = await userAPI.getUserInfo(driver_id);
				// if (res.data && res.status === 200 && res.data.status != EnumStatusResponse.error) {
				// 	setAuthenticated({authenticated: true, driver_id: driver_id, ...(res.data)});
				// 	setUserInfo({... res.data, driver_id: driver_id});
				// } else
				// {
				// 	res = await userAPI.getUserInfo(driver_id, 'id');
				// 	if (res.data && res.status === 200 && res.data.status == EnumStatusResponse.success) {
				// 		setUserInfo({... res.data, id: driver_id});
				// 	}
				// 	setTimeout(toOwnerSelect, 500);
				// 	setTimeout(()=>setCodeSent(false),600);
				// 	return;
				// }
				//
				// res = await userAPI.getDriverStatus(driver_id);
				// if (res.data && res.status === 200)
				// 	dispatch(setStatus(getStatusWorking(res.data.status, res.data.dates)));
				//
				//
				// setTimeout(toMainScreen, 500);
				// setTimeout(()=>setCodeSent(false),600);
				//
				// pingDevice(driver_id, dispatch).catch( e=> {
				// 	showMessageError(e, dispatch)
				// });
				authUser(res.data.driver_id, dispatch).then( action=> {
					setTimeout( action ? toMainScreen : toOwnerSelect, 500);
					setTimeout(()=>setCodeSent(false),600);
				});
				return;
			}

			setCheckPhoneError(true);
			setTimeout(setCheckPhoneError, 5000, false);

	};

    return (
        <>

            {state.code_sent ?
                <>
                    <div className="check_phone_window">
                        <CancelBtn onClick={toMainScreen}/>
                        <div className="check_phone_container">
                            <label className="check_phone_label">
                                <div className="check_phone_label_text">На ваш номер придет SMS-сообщение с кодом,
                                    который нужно ввести ниже
                                </div>
                                <div className="check_phone_input_container">
                                    <InputMask mask="9 9 9 9" id="check_phone_code"
                                               className={'check_phone_input' + (!state.code_entered && error ? ' error' : '')}
                                               type="tel" value={state.code} onChange={(e) => {
                                        let code = e.target.value;
                                        if (code.indexOf('_') === -1) {
                                            code = code.replace(/ /g, '');
                                            setCodeEntered(true, code);

                                        } else {
                                            setCodeEntered(false);
                                        }
                                    }}>
                                        {(inputProps: any) => <input {...inputProps} />}
                                    </InputMask>
                                    {state.code_entered &&
                                    <ReactSVG src={CheckSVG} className="check_phone_check" style={{"right": "33%"}}/>
                                    }
                                    {verifyCodeError &&
                                    <div className="check_phone_error">Введенный код некорректен.</div>}
                                    {checkPhoneError &&
                                    <div className="check_phone_error">Ошибка. Попробуйте еще раз.</div>}
                                    {timeLeft.current > 0 && !verifyCodeError &&
                                    <div className="check_phone_text_grey">Отправить новый код можно
                                        через {time} сек...</div>
                                    }
                                </div>
								<div className="check_phone_input_container pt40">
									<div id="check_phone_verify_code"
										 className={'check_phone_next_button' + (state.code_entered && !isLoading ? '' : ' inactive')}
										 onClick={onCodeEnter}>
										{isLoading && <div className={'button_loader'}><Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'}/></div>}
										<div className="check_phone_start_text">Далее</div>
									</div>
								</div>
                            </label>


                        </div>
                    </div>
                </> :
                <>
                    {registrationStarted ?
                        <div className="check_phone_window">
                            <CancelBtn onClick={toMainScreen}/>
                            <div className="check_phone_container">
                                <label className="check_phone_label">
                                    <div className="check_phone_label_text">Введите свой номер телефона</div>
                                    <div className="check_phone_input_container">
                                        <InputMask mask="+7(999) 999-99-99" id="check_phone_phone"
                                                   className={'check_phone_input' + (!state.phone_entered && error ? ' error' : '')}
                                                   type="tel" value={state.phone} onChange={onPhoneChanged}>
                                            {(inputProps: any) => <input {...inputProps} />}
                                        </InputMask>
                                        {state.phone_entered &&
                                        <ReactSVG src={CheckSVG} className="check_phone_check"/>}
                                        {sendCodeError &&
                                        <div className="check_phone_error">Ошибка отправки кода. Попробуйте еще
                                            раз.</div>}
                                    </div>
                                    <div className={"check_phone_input_container check_phone_accept " + (!state.code_entered && error ? ' error' : '')}>
                                        <input type="checkbox" className="check_phone_accept_checkbox" defaultChecked={localState.is_accepted} onClick={setAccepted}/>
                                        <span className="check_phone_accept_checkbox_text">Я принимаю <a target="_blank" href="/terms-of-use">условия публичной оферты</a> и <a target="_blank" href="/privacy-policy">политику конфиденциальности</a></span>
                                    </div>
									<div className="check_phone_input_container">
										<div id="check_phone_send_code"
											 className={'check_phone_next_button' + (state.phone_entered && localState.is_accepted && !isLoading ? '' : ' inactive') }
											 onClick={onNextButtonClick}>
											{isLoading && <div className={'button_loader'}><Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'}/></div>}
											<div className="check_phone_start_text">Далее {isLoading}</div>
										</div>
									</div>
                                </label>

                            </div>
                        </div> :
                        <div className="check_phone_window">
                            <CancelBtn onClick={toMainScreen}/>
                            <div className="check_phone_container">
                                <div className="check_phone_title">Получите доступ ко всем  заказам в
                                    Москве
                                </div>
                                <div className="check_phone_text_bold">а также</div>
                                <table className="check_phone_list">
                                    <tbody>
                                    <tr>
                                        <td className="check_phone_list_bullet"><ReactSVG src={BulletSVG}/></td>
                                        <td className="check_phone_list_item">Получайте заказы, подобранные специально
                                            для вас
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="check_phone_list_bullet"><ReactSVG src={BulletSVG}/></td>
                                        <td className="check_phone_list_item">Зарабатывайте каждый день</td>
                                    </tr>
                                    <tr>
                                        <td className="check_phone_list_bullet"><ReactSVG src={BulletSVG}/></td>
                                        <td className="check_phone_list_item">Удобно и просто общайтесь с клиентом, не
                                            покидая сайта
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div id="check_phone_start" className="check_phone_start_button"
                                     onClick={() => setTimeout(setRegistrationStarted, 500, true)}>
                                    <div className="check_phone_start_text">Начать работу</div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    state: state.checkPhoneReducer,
    user: state.userReducer,
    system: state.systemReducer,
    orders: state.ordersReducer
});

export default connect(mapStateToProps, {
    setPhoneEntered,
    setCodeSending,
    setCodeSent,
    setCodeEntered,
    setCodeVerifying,
    setAuthenticated,
    setUserInfo,
    setRegistrationStatus,
    setSystemStatus
})(CheckPhone);
