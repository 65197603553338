import {
    CChatMessage, IChatCommands,
    IChatGroup, SET_CHAT_COMMANDS, SET_CHAT_DIALOG_ID,
    SET_CHAT_GROUPS,
    SET_CHAT_MESSAGES,
    SET_CHAT_UNREAD_MESSAGES,
    SET_CHAT_VISIBLE, SetChatCommandsAction,
    SetChatGroupsAction,
    SetChatMessagesAction, SetChatSelectedDialogIdAction,
    SetChatUnreadMessagesAction,
    SetChatVisibleAction,
} from "./@types"


export const setChatGroups = (groups:IChatGroup[]): SetChatGroupsAction => ({
    type: SET_CHAT_GROUPS,
    groups
});

export const setChatCommands = (commands:IChatCommands[]): SetChatCommandsAction => ({
    type: SET_CHAT_COMMANDS,
    commands
});

export const setChatUnreadMessages = (has_unread_messages:{status: boolean, list: string[]}): SetChatUnreadMessagesAction => ({
    type: SET_CHAT_UNREAD_MESSAGES,
    has_unread_messages
});


export const setChatMessages = (messages: CChatMessage[]): SetChatMessagesAction => ({
    type: SET_CHAT_MESSAGES,
    messages
});
export const setChatVisible  = (is_visible: boolean): SetChatVisibleAction => ({
    type: SET_CHAT_VISIBLE,
    is_visible
});
export const setChatSelectedDialogId  = (selected_dialog_id: string): SetChatSelectedDialogIdAction => ({
    type: SET_CHAT_DIALOG_ID,
    selected_dialog_id
});

