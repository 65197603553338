import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { setSystemStatus } from "../../../redux/reducers/system/actions";
import moment from "moment";

import "./OrderCard.css";
import {RootState} from "../../../redux/store";
import {Order} from "../../../redux/reducers/orders/@types";
import {OrdersListType, SystemStatus} from "../../../@types";
import {openOrderThunk} from "../../../redux/reducers/orders/reducers";
import {setRegistrationStatus} from "../../../redux/reducers/registration/actions";
import {getDistance} from "../../Elements/HereMap/hereFunctions";



interface IProps {

  order: Order;
  isLooked: boolean;
  onClick?: () => void;
  orderType : OrdersListType,
  isFull?: boolean;
  isAuth? :boolean;
  user_coordinate:{lat:number, lon:number} | null;

}
interface ILocalState {
  weight: string,
  name: string,
}

const OrderCard: React.FC<IProps> = ({  order, isLooked, onClick, orderType, isFull = false, isAuth = true, user_coordinate= null}) => {
  const dispatch = useDispatch();
  const {  orders, user } = useSelector((state: RootState) => ({
    orders: state.ordersReducer,
    user:state.userReducer
  }));

  const [localState, setLocalState] = useState<ILocalState>({
    weight: 'Уточняется',
    name: 'Уточняется',
  });
  // if (order.id == '9a8afe95-743a-11ec-8eb2-00155d010f1b')
  //   console.log('isLooked', isLooked, order.is_looked, order);


  useEffect(() => {
    if (orders.carTypes.length){
      let b = orders.carTypes.find(x => x.id === order.car_type_id);
      setLocalState((s) => ({
          ...s,
        weight: b ? b.weight_to + ' Кг' : 'Уточняется'
      }));
      if (!b) return;
      let bo = b.body_options.find(x=> x.id == order.car_body_option_id);
      setLocalState((s) => ({
        ...s,
        name: bo ? bo.name : 'Уточняется'
      }));


    }
   // console.log(orders.carTypes)
  }, [orders.carTypes]);


  const openOrder =  async (e: any) => {
      if (!isAuth) {
        dispatch(setSystemStatus(SystemStatus.Registration));
        dispatch(setRegistrationStatus(null));
      } else
         openOrderThunk(order.id,  user.driver_id, isAuth , order.type, dispatch, order.is_looked ).then(()=>{
           if (onClick) onClick();
         }) ;


  };


  // console.log( order.date, );
  // console.log(moment(order.date).isSame(moment(), 'day') );
  return (
    <div className={`order_block ${!isLooked && `unviewed`} ${isFull ? `full_order_block` : `short_order_block`}`} onClick={(e) => openOrder(e) }>
      <div className="order_block_info">
        <div className="order_block_info_left">
          {!isFull && <>
            <div className="order_block_title">{order.point_count} точек | {Math.round(order.full_distance)} км</div>
            <div className="order_block_datetime">{moment(order.date).isSame(moment(), 'day') ? 'Сегодня ' : moment(order.date).format("DD.MM.YY")} в {moment(order.date).format("HH:mm")}</div>
          </>
          } {
            isFull && <>
              <div className="order_block_info_left">
                <div className="order_block_title">
                  <div className="order_block_street">{order.address}</div>
                </div>
                <div className="order_block_points">{order.point_count} точек | {Math.round(order.full_distance)} км</div>
              </div>
            </>
        }
        </div>
        <div className="order_block_info_right order_block_info_right_full">
          {isFull &&
          <div className="order_block_datetime">{moment(order.date).isSame(moment(), 'day') ? 'Сегодня ' : moment(order.date).format("DD.MM.YY")} в {moment(order.date).format("HH:mm")}</div>
          }
          <div className="order_block_distantion">{user_coordinate ? getDistance(order.lat , order.lon, user_coordinate.lat, user_coordinate.lon) : '0'} км {!isFull && <br/>} от вас</div>
        </div>
      </div>
      <div className="order_block_footer">
        <div className="order_block_conditions">
          <ul>
            <li>{localState.weight}</li>
            <li>{localState.name}</li>
          </ul>
        </div>
        {/* {isFull && <div className="order_block_id">№{orderNumber}</div>} */}
      </div>
    </div>
  );
}

export default OrderCard;
