import React, {useState, CSSProperties, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import './HeaderCollapseExpand.css';
import {is} from "@babel/types";

interface IProps {
  window_name:string;
  class_name?:string;
  onMove?: Function;
  onMoveEnd?:Function;
  isFullMode?:boolean;
}

interface ILocalState {
  position: {
    y: number;
  },
  start_position: number,
  is_full_mode:boolean
}

const HeaderCollapseExpand: React.FunctionComponent<IProps> = ({window_name, onMove , onMoveEnd,class_name,isFullMode,children}) => {
  const dispatch = useDispatch();
  const getSmallHeight =() =>  window.innerHeight + window.pageYOffset - Math.round(window.innerHeight*0.36);

  const {  system } = useSelector((state: RootState) => ({
    system: state.systemReducer
  }));

  const [localState, setLocalState] = useState<ILocalState>({
    position: {
      y:  isFullMode ? 0 :  getSmallHeight()
    },
    start_position: -1,
    is_full_mode: isFullMode ? isFullMode : false
  });
  useEffect(()=>{
    setLocalState((s)=> ({...s, is_full_mode: isFullMode ? isFullMode : false, position: {  y:  isFullMode ? 0 :  getSmallHeight() },}))
  }, [isFullMode]);

  const { position } = localState;

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0] || e.changedTouches[0];
    // console.log(e);
    let val = (!localState.is_full_mode  || (touch &&  touch.pageY > 0 && touch.pageY < 100 )) ? touch.pageY : -1;
    setLocalState((s) => ({ ...s,  start_position: val}));
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (localState.start_position == -1) return;
      const touch = e.touches[0] || e.changedTouches[0];
      setLocalState((s) => ({ ...s,  position: { y: touch.pageY, } }));
      if (onMove) onMove();
  };



  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (localState.start_position == -1 ) return;
    const touch = e.touches[0] || e.changedTouches[0];
    if (Math.abs( touch.pageY - localState.start_position) < 10 )  {
      setLocalState((s) => ({ ...s,  start_position:-1}));
      return;
    }
    // console.log(position.y - localState.start_position);
    let is_full_mode =  !(position.y && position.y > localState.start_position);

      setLocalState((s) => ({
        ...s,
        position: { y: is_full_mode ? 0:  getSmallHeight()   },
        start_position:-1,
        is_full_mode:is_full_mode
      }));
    if (onMoveEnd)  onMoveEnd(is_full_mode);
  };

  // console.log('localState',localState)
  return (
      <div className={"move_window "+ (localState.is_full_mode ? ' full-window ' : ' small-window ') + (class_name ? class_name : '')} data-name={window_name}
           style={{ top: (position.y) ? `${position.y}px` : "auto" }}
           onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart}

      >
          <div className="bottom_window_btn_container" style={{'top':'20px'}}>
            <div className="bottom_window_btn"></div>
          </div>
        { children }
      </div>
  );
}

export default HeaderCollapseExpand;
