import {SystemStatus} from "../../../@types"
import {
  CLEAR_MESSAGE,
  CLEAR_SYSTEM_STATUS,
  ClearSystemStatusAction,
  CLOSE_ALL_WINDOWS,
  CloseAllWindowsAction,
  DATA_LOADING,
  EnumWindowMessageType,
  IFullCategory,
  POP_SYSTEM_STATUS,
  PopSystemStatusAction,
  REMOVE_SYSTEM_STATUS,
  RemoveSystemStatusAction,
  SET_CAMERA_ACCESS,
  SET_CAR_TYPES_DRIVER,
  SET_CATEGORIES,
  SET_CURRENT_ID,
  SET_DEVICE_ID,
  SET_MESSAGE,
  SET_OVERLAY,
  SET_PLAY_MODE,
  SET_SELECTED_POINT_INDEX,
  SET_SHOW_IMAGE_VIEW,
  SET_SYSTEM_PLAY_STATUS,
  SET_SYSTEM_STATUS,
  SET_TRACKER_ID,
  SET_USE_TRACKER,
  SET_VISIBILITY_OVERLAY,
  SET_WINDOW_MESSAGE,
  SetCameraAccessAction,
  SetCarTypesDriverAction,
  SetCategoriesAction,
  SetCurrentIdAction,
  SetDataLoadingAction,
  SetDeviceIdAction,
  SetOverlayAction,
  SetPlayModeAction,
  SetSelectedPointIndexAction,
  setSystemPlayStatusAction,
  SetSystemStatusAction,
  SetTrackerIDAction,
  SetUseTrackerAction,
  SetVisibilityOverlayAction,
  ShowWindowMessageAction,
  TOGGLE_DATEPICKER_WINDOW,
  TOGGLE_DRIVER_STATUS_WINDOW,
  TOGGLE_ORDER_BOOK_WINDOW,
  TOGGLE_ORDERS_WINDOW,
  TOGGLE_PARTNERSHIP_WINDOW,
  TOGGLE_PROFILE_WINDOW,
  TOGGLE_SYSTEM_STATUS,
  ToggleDatepickerWindowAction,
  ToggleDriverStatusWindowAction,
  ToggleOrderBookWindowAction,
  ToggleOrdersWindowAction,
  TogglePartnershipWindowAction,
  ToggleProfileWindowAction,
  ToggleSystemStatusAction,
  SET_DRIVER_BODY_OPTIONS,
  SetDriverBodyOptionsCharacteristicsAction,
  SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS,
  SetDriverBodyOptionsCharacteristicsValuesAction,
  SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS_VALUES,
  SetDriverBodyTypesAction, SET_DRIVER_BODY_TYPES,
  SetDriverBodyOptionsAction, SetReloadOrderListAction, SET_RELOAD_ORDER_LIST
} from "./@types";
import {SystemMessage} from "../../../api/@types";
import {ordersAPI, userAPI} from "../../../api";
import {
  setAdditionalRequirements,
  setBodyOptionCharacteristics,
  setBodyOptionCharacteristicsValues,
  setBodyOptions,
  setBodyTypes,
  setCarTypes
} from "../orders/actions";
import {BodyOption, EnumStatusExecution} from "../orders/@types";


export const setOverlay = (overlay: boolean, zIndex?: number): SetOverlayAction => ({
  type: SET_OVERLAY,
  overlay,
  zIndex
});

export const toggleOrdersWindow = (visible: boolean): ToggleOrdersWindowAction => ({
  type: TOGGLE_ORDERS_WINDOW,
  visible,
});
export const setSelectedPointIndex = (selected_point_index: number): SetSelectedPointIndexAction => ({
  type: SET_SELECTED_POINT_INDEX,
  selected_point_index,
});



export const toggleDatepickerWindow = (): ToggleDatepickerWindowAction => ({
  type: TOGGLE_DATEPICKER_WINDOW,
});

export const toggleDriverStatusWindow = (): ToggleDriverStatusWindowAction => ({
  type: TOGGLE_DRIVER_STATUS_WINDOW,
});

export const toggleOrderBookWindow = (): ToggleOrderBookWindowAction => ({
  type: TOGGLE_ORDER_BOOK_WINDOW
});

export const toggleProfileWindow = (): ToggleProfileWindowAction  => ({
  type: TOGGLE_PROFILE_WINDOW
});

export const togglePartnershipWindow = (): TogglePartnershipWindowAction  => ({
  type: TOGGLE_PARTNERSHIP_WINDOW
});

export const closeAllWindows = (): CloseAllWindowsAction => ({
  type: CLOSE_ALL_WINDOWS
});

export const setMessage = (message: SystemMessage) => ({
  type: SET_MESSAGE,
  ...message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
  id: "",
  statuses: [],
  message: "",
  order_id: "",
});

export const setSystemStatus = (status: SystemStatus): SetSystemStatusAction => ({
	type: SET_SYSTEM_STATUS,
	status
});

export const setSystemPlayStatus = (point: number, status: EnumStatusExecution ): setSystemPlayStatusAction => ({
  type: SET_SYSTEM_PLAY_STATUS,
  point,
  status
});

export const popSystemStatus = (): PopSystemStatusAction => ({
  type: POP_SYSTEM_STATUS,

});

export const removeSystemStatus = (status: SystemStatus): RemoveSystemStatusAction => ({
  type: REMOVE_SYSTEM_STATUS,
  status
});

export const clearSystemStatus = (): ClearSystemStatusAction => ({
  type: CLEAR_SYSTEM_STATUS,
});

export const setVisibilityOverlay = (visible:boolean): SetVisibilityOverlayAction => ({
  type: SET_VISIBILITY_OVERLAY,
  visible
});

export const toggleSystemStatus = (status: SystemStatus): ToggleSystemStatusAction => ({
  type: TOGGLE_SYSTEM_STATUS,
  status
});

export const setReloadOrderList  = (data: {order_id: string}) : SetReloadOrderListAction => ({
  type: SET_RELOAD_ORDER_LIST,
  data
});

export const setCameraAccess = (was_camera_access: boolean): SetCameraAccessAction => ({
  type: SET_CAMERA_ACCESS,
  was_camera_access
});

export const setCurrentId = (id: string): SetCurrentIdAction => ({
	type: SET_CURRENT_ID,
	id
});

export const setPlayMode = (play_mode: boolean): SetPlayModeAction => ({
	type: SET_PLAY_MODE,
	play_mode
});

export const showWindowMessage = ( msg : {type: EnumWindowMessageType, text:string}): ShowWindowMessageAction => ({
	type: SET_WINDOW_MESSAGE,
    message_type:msg.type,
    message_text:msg.text
});

export const  showMessageError = (text:string, dispatch:any) => showMessageTrunk({type:EnumWindowMessageType.Error, text:text}, dispatch);
export const  showMessageInfo = (text:string, dispatch:any) => showMessageTrunk({type:EnumWindowMessageType.Info, text:text}, dispatch);

export const  showMessageErrorPromise = (text:string, dispatch:any) =>  showMessageInfoPromise(text, dispatch, EnumWindowMessageType.Error, false);


export const  showMessageInfoPromise = (text:string, dispatch:any, type: EnumWindowMessageType = EnumWindowMessageType.Info, returnValue = true) => {
  showMessageTrunk({type, text:text}, dispatch);
  return new Promise(resolve => {
    let timer = setInterval(()=>{
      //@ts-ignore
      if (window.state.system.statuses.some(x=> x== SystemStatus.MessageWindow)) return ;
      clearInterval(timer);
      resolve(returnValue);
    }, 100)
  })
}



export const  showMessageTrunk = ( msg : {type: EnumWindowMessageType, text:string}, dispatch:any) =>{
  dispatch(showWindowMessage(msg));
  dispatch(setSystemStatus(SystemStatus.MessageWindow));
};

export const setCategories = (categories:IFullCategory[]) : SetCategoriesAction => ({type: SET_CATEGORIES, categories});
export const setDataLoading = (value:boolean) : SetDataLoadingAction => ({type: DATA_LOADING, is_loading: value});
export const setDeviceID = (device_id:string) : SetDeviceIdAction => ({type: SET_DEVICE_ID, device_id});
export const setUseTracker = (is_tracker_mode:boolean ) : SetUseTrackerAction => ({type: SET_USE_TRACKER, is_tracker_mode});
export const setTrackerID = (tracker_id:string) : SetTrackerIDAction => ({type: SET_TRACKER_ID, tracker_id});
export const setCarTypesDriver = (driver_car_types:[]) : SetCarTypesDriverAction => ({type: SET_CAR_TYPES_DRIVER, driver_car_types});
export const setDriverBodyOptions = (driver_body_options:BodyOption[]) : SetDriverBodyOptionsAction => ({type: SET_DRIVER_BODY_OPTIONS, driver_body_options});
export const setDriverBodyOptionsCharacteristics = (driver_body_option_characteristics:[]) : SetDriverBodyOptionsCharacteristicsAction => ({type: SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS, driver_body_option_characteristics});
export const setDriverBodyOptionsCharacteristicsValues = (driver_body_option_characteristics_values:[]) : SetDriverBodyOptionsCharacteristicsValuesAction => ({type: SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS_VALUES, driver_body_option_characteristics_values});
export const setDriverBodyTypes = (driver_body_types:[]) : SetDriverBodyTypesAction => ({type: SET_DRIVER_BODY_TYPES, driver_body_types});



export const loadInitDataThunk = async (dispatch: any) => {

  dispatch(setDataLoading(true));

  let categories_full : IFullCategory[]  = await userAPI.getCategoriesFull().catch( () => dispatch(showMessageError(  'Ошибка загрузки категорий технических средств!', dispatch)));
  if (!Array.isArray(categories_full)) categories_full = [];
  dispatch(setCategories(categories_full));

  userAPI.getBodyOptionCharacteristics().then((res) => {
    dispatch(setBodyOptionCharacteristics(res.data));
    dispatch(setDriverBodyOptionsCharacteristics(res.data));
  }).catch((err) => {});

  userAPI.getBodyOptionCharacteristicsValues().then((res) => {
    dispatch(setBodyOptionCharacteristicsValues(res.data));
    dispatch(setDriverBodyOptionsCharacteristicsValues(res.data));
  }).catch((err) => {});

  userAPI.getBodyTypes().then((res) => {
    dispatch(setBodyTypes(res.data));
    dispatch(setDriverBodyTypes(res.data));
  }).catch((err) => {});

  userAPI.getBodyOptions().then((res) => {
    dispatch(setBodyOptions(res.data));
    dispatch(setDriverBodyOptions(res.data));
  }).catch((err) => {});

  userAPI.getCarTypes().then((res) => {
    dispatch(setCarTypes(res.data));
  }).catch((err) => {});

  userAPI.getCarTypesDriver().then((res) => {
    dispatch(setCarTypesDriver(res.data));
  }).catch((err) => {});

  userAPI.getAdditionalRequirements().then((res) => {
    dispatch(setAdditionalRequirements(res.data));
  }).catch((err) => {});

  dispatch(setDataLoading(false));
};


