import React, {useEffect, useRef, useState} from "react";
import './InputPhotoValue.css';
import {setCameraAccess} from "../../../redux/reducers/system/actions";
import {PhotoTaker} from "../../Elements/elements";
import {SystemState} from "../../../redux/reducers/system/@types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import Loader from "react-loader-spinner";


const FramesSettings = [
    {
        id:0,
        index: 0,
        title: "Разворот",
        box: true,
        facing: 'environment',
        width: '80%',
        height: '45%'
    },
    {   id:1,
        index: 1,
        title: "Прописка",
        box: true,
        facing: 'environment',
        width: '62%',
        height: '37%'
    },
    {
        id:2,
        index: 2,
        title: "Фото с паспортом в руке",
        box: true,
        facing: 'user',
        width: '90%',
        height: '45%'
    },
    {
        id:3,
        index: 0,
        title: "Сторона с фотографией",
        box: true,
        facing: 'environment',
        width: '80%',
        height: '25%'
    },
    {
        id:4,
        index: 1,
        title: "Обратная сторона",
        box: true,
        facing: 'environment',
        width: '80%',
        height: '25%'
    },

    {
        id:5,
        index: 0,
        title: "Кузов изнутри",
        box: false,
        facing: 'environment',
        width: '100%',
        height: '32%'
    },
    {
        id:6,
        index: 1,
        title: "ТС спереди",
        box: false,
        facing: 'environment',
        width: '100%',
        height: '32%'
    },
    {
        id:7,
        index: 2,
        title: "ТС сбоку",
        box: false,
        facing: 'environment',
        width: '100%',
        height: '32%'
    },
    {
        id:8,
        index: 0,
        title: "Сторона 1",
        box: true,
        facing: 'environment',
        width: '80%',
        height: '43%'
    },
    {
        id:9,
        index: 1,
        title: "Сторона 2",
        box: true,
        facing: 'environment',
        width: '80%',
        height: '45%'
    }

];

interface IProps {
    name:string;
    file: Blob|null;
    onValueChanged: (value: Blob | null , index:number) => void;
    classAppend?:string;
    wasError:boolean;
    fileIndex:number;
    settingIndex:number;
    is_loading?:boolean;
    onPhotoClick?:()=>void
}


const InputPhotoValue: React.FunctionComponent<IProps> = (props ) => {
    const dispatch = useDispatch();
    const refInput = useRef(null);
    // if (props.settingIndex == 0)
    //     console.log('props.file', props.file, props.file ? URL.createObjectURL(props.file) : '');
    let [isError, setError] = useState(props.wasError);
    let [localBlobFile, setLocalBlobFile] = useState(props.file);

    let [isCamera, setCamera] = useState(false);
    let system : SystemState = useSelector((state:RootState)=> ({system: state.systemReducer})).system;


    let editing = 1;

    const handleChange = (photos: Blob[]) => {
        dispatch(setCameraAccess(true));
        if (photos.length) {
            setLocalBlobFile(photos[0]);
            props.onValueChanged(photos[0], props.fileIndex)
        } else
            props.onValueChanged(null, props.fileIndex)

        setCamera(false);

    }

    useEffect(()=>{
        setError(props.wasError && !localBlobFile);

    }, [props.wasError, props.file]);
    useEffect(()=>{
        setLocalBlobFile(props.file)
    }, [ props.file]);


    return (
        <>
            {isCamera && <PhotoTaker
                id="passport_photo"
                title={<div>Сделайте фотографии<br/> {props.name.toLowerCase()}</div>}
                editing={true}
                ask={!system.was_camera_accessed}
                frames={[FramesSettings[props.settingIndex]]}
                pictures={ localBlobFile ? [localBlobFile] : []}
                onChange={(photos: Blob[]) => {  handleChange(photos); }}
                onError={() => dispatch(setCameraAccess(true))}
                camera={isCamera}
                onClose={() => setCamera(false)}
            />}
            <div className={"input_photo_button " + (props.classAppend ? props.classAppend : '')} ref={refInput}>
                <div className='input_filtered_container_name'>{props.name}</div>
                {!localBlobFile && !props.is_loading &&
                    <div className={'photo_button ' + (isError ? ' error' : '')} onClick={() => setCamera(true)}>
                        Загрузить
                    </div>
                }
                { props.is_loading &&
                    <div className={'photo_button'}  >
                        <div> Загрузка</div> <div className={'photo_loader'}><Loader  type="ThreeDots" color="#FFFFFF" width={20} height={5} /></div>
                    </div>

                }
                {localBlobFile && <img className="photo_button_img" src={URL.createObjectURL(localBlobFile)} onClick={ ()=> {if (props.onPhotoClick) props.onPhotoClick() }}/> }
            </div>
        </>
    )
};

export  default InputPhotoValue;
