import {
	Owner,
	VehicleData,
	Driver,
} from "../../../components/Elements/Containers/@types";
import {
	SET_FINALIZING,
	SET_ASSIGNING_DRIVER,
	SET_IS_OWNER,
	SET_CAMERA_ACCESSED,
	SAVE_OWNER,
	SAVE_DRIVER,
	UPDATE_DRIVER,
	SAVE_VEHICLE,
	UPDATE_VEHICLE,
	REMOVE_VEHICLE,
	SetFinalizingAction,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SetCameraAccessedAction,
	SaveOwnerAction,
	SaveDriverAction,
	UpdateDriverAction,
	SaveVehicleAction,
	UpdateVehicleAction,
	RemoveVehicleAction
} from "./@types";

// const MEDICAL_BOOK = '9cebde1b-25e2-11ea-a9ad-00155d8e4e05';
// const DRIVER_LOADER = '9cebde1a-25e2-11ea-a9ad-00155d8e4e05';
// const HYDROBOARD = '9cebde15-25e2-11ea-a9ad-00155d8e4e05';
// const RAMP = 'fc3de6d0-40dd-11ea-a9c6-00155d8e4e05';

// export const setFinalizing = (finalizing: boolean): SetFinalizingAction => ({
//   type: SET_FINALIZING,
//   finalizing
// });

// export const setAssigningDriver = (assigning_driver: boolean): SetAssigningDriverAction => ({
//   type: SET_ASSIGNING_DRIVER,
//   assigning_driver
// });

export const setIsOwner = (is_owner: boolean): SetIsOwnerAction => ({
  type: SET_IS_OWNER,
  is_owner
});

// export const setCameraAccessed = (camera_accessed: boolean): SetCameraAccessedAction => ({
//   type: SET_CAMERA_ACCESSED,
//   camera_accessed
// });

export const saveOwner = (owner: Owner): SaveOwnerAction => ({
	type: SAVE_OWNER,
	owner
});

// export const saveDriver = (driver: Driver): SaveDriverAction => ({
// 	type: SAVE_DRIVER,
// 	driver
// });
//
// export const updateDriver = (): UpdateDriverAction => ({
// 	type: UPDATE_DRIVER
// });
//
// export const saveVehicle = (vehicle: VehicleData): SaveVehicleAction => ({
// 	type: SAVE_VEHICLE,
// 	vehicle
// });
//
// export const updateVehicle = (): UpdateVehicleAction => ({
// 	type: UPDATE_VEHICLE
// });
//
// export const removeVehicle = (): RemoveVehicleAction => ({
// 	type: REMOVE_VEHICLE
// });

