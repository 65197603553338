import turf from "turf";
import {NearestPointData} from "./Navigator";

export class Interpolation{
				points: NearestPointData[] = []
   	constructor() { }

				append_point = (point: NearestPointData) => this.points.push(point);
				clear = () =>  this.points=[];
				has_points = () =>  this.points.length > 1;
				get_distance_to_next_point(): number{
								let len = this.points.length;
								if (len < 2)  return 0

								let point_1 = turf.point(this.points[len - 1].pointOnRoute as []);
								let point_2 = turf.point(this.points[len - 2].pointOnRoute as []);
								let distance = turf.distance(point_1, point_2, 'meters') + 0.1
								console.log('real_distance', distance)
								let last_point_time = Date.parse(this.points[len-1].date ?? '')
								let last_point_time_1 = Date.parse(this.points[len-2].date ?? '')
								let cur_diff_time = Math.abs(last_point_time - Date.now())/1000 + 0.1

								let last_diff_time = Math.abs(last_point_time_1 - last_point_time)/1000 + 0.1
								let speed = distance/last_diff_time + 0.1
								console.log('calc_distance', speed * cur_diff_time)
								return  Math.round(speed * cur_diff_time);
								// console.log('new_distance', new_distance)
								// if (this.turf_route) {
								// 				let start_point = this.turf_route.geometry.coordinates[0]
								// 			 let d =	turf.lineDistance(turf.lineSlice(turf.point(start_point), this.turf_points[len-1], this.turf_route),  'meters' );
								// 				console.log('new_distance+d=', new_distance+ d)
								// 		  let pt = turf.along(this.turf_route, d + new_distance, 'meters');
								// 			 return pt.geometry.coordinates
								// }
								// return []
				}


}