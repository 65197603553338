import { SystemStatus } from "../../../../@types"
import {
    BodyOption,
    BodyOptionCharacteristic,
    BodyOptionCharacteristicValue,
    BodyType,
    CarType,
    EnumStatusExecution,
    SET_BODY_OPTIONS,
    SET_BODY_OPTIONS_CHARACTERISTICS,
    SET_BODY_OPTIONS_CHARACTERISTICS_VALUES,
    SET_BODY_TYPES, SET_CAR_TYPES
} from "../../orders/@types";

export const SET_OVERLAY = 'SET_OVERLAY';
export const TOGGLE_ORDERS_WINDOW = 'TOGGLE_ORDERS_WINDOW';

export const TOGGLE_DATEPICKER_WINDOW = 'TOGGLE_DATEPICKER_WINDOW';
export const TOGGLE_DRIVER_STATUS_WINDOW = 'TOGGLE_DRIVER_STATUS_WINDOW';
export const TOGGLE_ORDER_BOOK_WINDOW = 'TOGGLE_ORDER_BOOK_WINDOW';
export const TOGGLE_PROFILE_WINDOW = 'TOGGLE_PROFILE_WINDOW';
export const TOGGLE_PARTNERSHIP_WINDOW = 'TOGGLE_PARTNERSHIP_WINDOW';
export const CLOSE_ALL_WINDOWS = 'CLOSE_ALL_WINDOWS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_SYSTEM_STATUS = 'SET_SYSTEM_STATUS';
export const POP_SYSTEM_STATUS = 'POP_SYSTEM_STATUS';
export const REMOVE_SYSTEM_STATUS = 'REMOVE_SYSTEM_STATUS';
export const CLEAR_SYSTEM_STATUS = 'CLEAR_SYSTEM_STATUS';
export const SET_VISIBILITY_OVERLAY = 'SET_VISIBILITY_OVERLAY';
export const TOGGLE_SYSTEM_STATUS = 'TOGGLE_SYSTEM_STATUS';
export const SET_CURRENT_ID = 'SET_CURRENT_ID';
export const SET_WINDOW_MESSAGE = 'SET_WINDOW_MESSAGE';
export const SET_CAMERA_ACCESS = 'SET_CAMERA_ACCESS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const DATA_LOADING = 'DATA_LOADING';
export const SET_SELECTED_POINT_INDEX = 'SET_SELECTED_POINT_INDEX';
export const SET_PLAY_MODE = 'SET_PLAY_MODE';
export const SET_SHOW_IMAGE_VIEW = 'SET_SHOW_IMAGE_VIEW';
export const SET_SYSTEM_PLAY_STATUS = 'SET_SYSTEM_PLAY_STATUS';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_USE_TRACKER = 'SET_USE_TRACKER';
export const SET_TRACKER_ID = 'SET_TRACKER_ID';
export const SET_CAR_TYPES_DRIVER = 'SET_CAR_TYPES_DRIVER';

export const SET_DRIVER_BODY_OPTIONS = 'SET_DRIVER_BODY_OPTIONS';
export const SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS = 'SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS';
export const SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS_VALUES = 'SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS_VALUES';
export const SET_DRIVER_BODY_TYPES = 'SET_DRIVER_BODY_TYPES';
export const SET_RELOAD_ORDER_LIST = 'SET_RELOAD_ORDER_LIST';


export interface SetDriverBodyOptionsCharacteristicsAction {
    type: typeof SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS;
    driver_body_option_characteristics: BodyOptionCharacteristic[];
}

export interface SetDriverBodyOptionsCharacteristicsValuesAction {
    type: typeof SET_DRIVER_BODY_OPTIONS_CHARACTERISTICS_VALUES;
    driver_body_option_characteristics_values: BodyOptionCharacteristicValue[];
}

export interface SetDriverBodyTypesAction {
    type: typeof SET_DRIVER_BODY_TYPES;
    driver_body_types: BodyType[];
}

export interface SetDriverBodyOptionsAction {
    type: typeof SET_DRIVER_BODY_OPTIONS;
    driver_body_options: BodyOption[];
}


export interface SetOverlayAction {
  type: typeof SET_OVERLAY;
  overlay: boolean;
  zIndex?: number;
}

export interface ToggleOrdersWindowAction {
  type: typeof TOGGLE_ORDERS_WINDOW;
  visible: boolean;
}
export interface SetSelectedPointIndexAction {
  type: typeof SET_SELECTED_POINT_INDEX;
  selected_point_index: number;
}

export interface SetDataLoadingAction {
    type: typeof DATA_LOADING;
    is_loading: boolean;
}

export interface SetCategoriesAction {
    type:typeof SET_CATEGORIES,
    categories: IFullCategory[]
}

export interface ToggleDatepickerWindowAction {
  type: typeof TOGGLE_DATEPICKER_WINDOW;
}

export interface ToggleDriverStatusWindowAction {
  type: typeof TOGGLE_DRIVER_STATUS_WINDOW;
}

export interface ToggleOrderBookWindowAction {
  type: typeof TOGGLE_ORDER_BOOK_WINDOW;
}

export interface ToggleProfileWindowAction {
  type: typeof TOGGLE_PROFILE_WINDOW;
}

export interface TogglePartnershipWindowAction {
    type: typeof TOGGLE_PARTNERSHIP_WINDOW;
}

export interface CloseAllWindowsAction {
  type: typeof CLOSE_ALL_WINDOWS;
}

export interface SetMessageAction {
  type: typeof SET_MESSAGE,
  id: string;
  statuses: { status: string; status_id: string; }[];
  message: string;
  order_id: string;
}

export interface ClearMessageAction {
  type: typeof CLEAR_MESSAGE;
  id: string;
  statuses: { status: string; status_id: string; }[];
  message: string;
  order_id: string;
}

export interface SetSystemStatusAction {
	type: typeof SET_SYSTEM_STATUS;
	status: SystemStatus;
}
export interface setSystemPlayStatusAction {
	type: typeof SET_SYSTEM_PLAY_STATUS;
	point:number;
	status: EnumStatusExecution;
}
export interface SetDeviceIdAction {
	type: typeof SET_DEVICE_ID;
	device_id:string;
}

export interface PopSystemStatusAction {
    type: typeof POP_SYSTEM_STATUS;
}

export interface RemoveSystemStatusAction {
    type: typeof REMOVE_SYSTEM_STATUS;
    status: SystemStatus;
}

export interface ClearSystemStatusAction {
    type: typeof CLEAR_SYSTEM_STATUS;
}

export interface SetVisibilityOverlayAction {
    type: typeof SET_VISIBILITY_OVERLAY;
    visible:boolean;
};

export interface ToggleSystemStatusAction {
    type: typeof TOGGLE_SYSTEM_STATUS;
    status: SystemStatus;
}

export interface SetReloadOrderListAction {
    type: typeof SET_RELOAD_ORDER_LIST;
    data: {order_id: string};
}

export interface SetCurrentIdAction {
	type: typeof SET_CURRENT_ID;
	id: string;
}
export interface ShowWindowMessageAction {
	type: typeof SET_WINDOW_MESSAGE;
    message_type:EnumWindowMessageType,
    message_text: string
}

export interface SetCameraAccessAction {
    type: typeof SET_CAMERA_ACCESS;
    was_camera_access:boolean;
}
export interface SetPlayModeAction {
    type: typeof SET_PLAY_MODE;
    play_mode:boolean;
}

export interface SetUseTrackerAction {
    type: typeof SET_USE_TRACKER;
    is_tracker_mode: boolean ;
}

export interface SetTrackerIDAction {
    type: typeof SET_TRACKER_ID;
    tracker_id: string;
}
export interface SetCarTypesDriverAction {
    type: typeof SET_CAR_TYPES_DRIVER;
    driver_car_types: [];
}



export type SystemActionTypes = SetOverlayAction
	| ToggleOrdersWindowAction
	| ToggleSystemStatusAction
	| ToggleDatepickerWindowAction
	| ToggleDriverStatusWindowAction
	| CloseAllWindowsAction
	| SetMessageAction
	| ClearMessageAction
	| ToggleOrderBookWindowAction
	| ToggleProfileWindowAction
	| SetSystemStatusAction
    | PopSystemStatusAction
    | ClearSystemStatusAction
    | RemoveSystemStatusAction
    | SetVisibilityOverlayAction
	| SetCurrentIdAction
    | TogglePartnershipWindowAction
    | ShowWindowMessageAction
    | SetCategoriesAction
    | SetDataLoadingAction
    | SetCameraAccessAction
    | SetSelectedPointIndexAction
    | SetPlayModeAction
    | setSystemPlayStatusAction
    | SetUseTrackerAction
    | SetTrackerIDAction
    | SetDeviceIdAction
    | SetCarTypesDriverAction
    | SetDriverBodyOptionsCharacteristicsAction
    | SetDriverBodyOptionsCharacteristicsValuesAction
    | SetDriverBodyTypesAction
    | SetDriverBodyOptionsAction
    | SetReloadOrderListAction;

export interface SystemState {
  overlay: {
    visible: boolean;
    zIndex: number;
  },
  menuWindow: boolean;
  ordersWindow: boolean;
  datepickerWindow: boolean;
  driverStatusWindow: boolean;
  profileWindow: boolean;
  partnershipWindow: boolean;
  message: {
    id: string;
    statuses: { status: string; status_id: string; }[];
    message: string;
    order_id: string;
  },
  statuses: SystemStatus[],
  currentId: string;
  window_message:{type:EnumWindowMessageType, text :string};
  was_camera_accessed:boolean;
  categories: IFullCategory[];
  is_loading:boolean;
  selected_point_index:number;
  play_mode:boolean;
  play_status: {status: EnumStatusExecution, point: number}|undefined;
  device_id:string;
  is_tracker_mode: boolean  | undefined;
  tracker_id: string;
  driver_car_types: CarType[];
  driver_body_option_characteristics: BodyOptionCharacteristic[];
  driver_body_option_characteristics_values: BodyOptionCharacteristicValue[];
  driver_body_types: BodyType[];
  driver_body_options: BodyOption[];
  reload_order_list: {order_id:string};
}

export enum EnumWindowMessageType {
    None,Error,Warning,Info
}
export enum EnumOrderBookView {
  Today, Future, Past
}


export interface IFullCategory {
    name: string,
    length_from: number,
    length_to: number,
    width_from: number,
    width_to: number,
    height_from: number,
    height_to: number,
    weight_from: number,
    weight_to: number,
    area_from: number,
    area_to: number,
    volume_from: number,
    volume_to: number,
    pallets_from: number,
    pallets_to: number,
    pass_sk: boolean,
    pass_ttk: boolean,
    pass_mkad: boolean,
    ramp: boolean,
    tail_lift: boolean,
    board: boolean,
    refrigerator: boolean,
    id: string,
    body_options:IBodyOptions[]
}

export interface IBodyOptions {
    id: string,
    name: string,
    body_type_id: number,
    body_option_characteristics: IBOCharacteristics[]
}

export enum CharacteristicTypeEnum {
    Boolean = "Boolean", Ref = "ref", Number = "number"
}
export interface IBOCharacteristics {
    id: string,
    name: string,
    type: CharacteristicTypeEnum,
    selected:boolean,
    value:any,
    body_option_characteristics_values: IBOCharacteristicsValue[]
}

export interface DropDownListItem {
    id: string;
    text: string;
}

export interface IBOCharacteristicsValue {
    id: string,
    name: string,
    selected:boolean,
    body_option_characteristics_id: string
}

export interface ISystemReceivedMessage {
    ip: string,
    action: string,
    action_id: number,
    user_agent?:string,
    device_id:string,
}

export interface ISystemReceivedObject {
    id: string,
    message: ISystemReceivedMessage
}


export enum ERROR_CODE {
    UNKNOWN = -1,// Неизвестная ошибка
    NO_SMS = 1,// Код из смс не подходит
    NO_DRIVER_FORM = 2,//  Не найдена анкета водителя
    NO_DRIVER_ID = 3,// Не найдена информация по идентификатору / не авторизован
    NEED_AUTHORIZATION = 4,// Требуется повторная авторизация
    NO_DRIVER_CONTRACT = 5,//  Не найден договор водителя
    CANT_UPDATE_CONTRACT = 6,//  Не удалось обновить договор
    CANT_SET_DRIVER_FOR_ORDER = 7,//  Ошибка при установке водителя на заказ
    NO_ORDER_FOR_DRIVER = 8,//  Заказ не доступен для установки водителя
    CANT_CANCEL_ORDER = 9,//  Нельзя отменить не распределенный заказ
    CANCELED_ORDER= 10,//  Не удалось отменить распределение для заказа
    NO_GROUP_ID = 11,//  Не указана группа диалога (group_id)
    NO_SET_DRIVER_ID = 12,//  Не указан идентификатор водителя driver_id
    NO_ORDER_ID = 13,//  Не указан идентификатор заказа
    CANT_UPDATE_DATA_CAR = 14,//  Не удалось обновить данные автомобиля
    CAR_NOT_SUITABLE_FOR_ORDER = 15,//  Автомобиль водителя не соответствует заказу
    NO_FILE = 16,//  Файл не найден
    NO_DRIVERS_IN_FORM = 17,//  Анкета водителя не содержит водителей
}

