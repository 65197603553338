import {ReactSVG} from "react-svg";
import BackIcon from "../../App/svg/back-arrow.svg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserState} from "../../../redux/reducers/user/@types";
import {RootState} from "../../../redux/store";
import {SystemState} from "../../../redux/reducers/system/@types";
import './DownloadApplicationForm.css'
import {APP_INSTALL_URL, APP_NAME} from "../../../deployment";
import {
    popSystemStatus,
    removeSystemStatus,
    setSystemStatus,
    showMessageError,
    showMessageInfo
} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import {installDevice, pingDevice} from "../../../redux/reducers/system/system_functions";
import Loader from "react-loader-spinner";

const DownloadApplicationForm=()=>{
    const  dispatch = useDispatch();
    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;

    const [isLoading, setLoading] = useState(false);
    const closeWindow = () =>{
        dispatch(popSystemStatus());
    };

    useEffect(()=>{
        //@ts-ignore
        window.state_middle = state;
    }, []);

    const checkApp = async () =>{
        setLoading(true);
        let isActive = await  pingDevice(user.data.id, '', dispatch).catch( e=>  showMessageError(e, dispatch) );
        setLoading(false);
        if (isActive === undefined) return ;

        if (!isActive)
            showMessageError(`Ошибка ! На вашем устройстве не удалось обнаружить приложение ${APP_NAME}!`, dispatch);
        else {
            showMessageInfo(`Приложение ${APP_NAME} успешно обнаружено на вашем устройстве`, dispatch);
            dispatch(popSystemStatus());
            dispatch(setSystemStatus(SystemStatus.RegistrationOwnerSelect));
        }
    };

    const startInstall = async () =>{
        //<a href="https://msv.citycarrier.ru/app-release.apk" style="text-decoration: underline">
        let res = await installDevice(user.data.id, dispatch).catch( e=>  showMessageError(e, dispatch) );
        //if (!res) return ;

        const link : any = document.createElement('a');
        link.href = APP_INSTALL_URL;
        // link.setAttribute(
        //     'download',
        //     `app.connect.apk`,
        // );
        link.target='_blank';
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    return (
        <div className="registration_driver_data_window download_registration_form">
            <div className="common_form_window_title" onClick={closeWindow}>
                <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                <div className="page_title"> Установка приложения  </div>

            </div>
            <div className="common_form_window_content ">
                <div className="page_title page_message"> Для корректной работы <br/>необходимо установить на ваше устройство приложение <br/><strong>{APP_NAME}</strong></div>

                <div  className="saving_button" onClick={()=>startInstall()}>
                    <div className="next_button_text">Установить приложение</div>
                </div>
                {!isLoading ?
                    <div className="saving_button next_button" onClick={() => checkApp()}>
                        <div className="next_button_text">Приложение уже установлено</div>
                    </div>
                    :
                    <div className="saving_button next_button inactive">
                        <div className={'button_loader'}><Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'}/></div>
                        <div className="next_button_text"> Поиск приложения ...</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DownloadApplicationForm;
