import React, { useState, useRef, useEffect } from "react";
import PhotoSVG from "../../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import Camera from "./Camera";
import "./PhotoTaker.css";
import CloseSVG from "../../App/svg/close.svg";

type FrameType = {
	index: number;
	title: string;
	box: boolean;
	facing: string,
	width: string;
	height: string;
}

interface IProps {
	id?: string;
	title: any;
	editing: boolean;
	ask: boolean;
	frames: FrameType[];
	pictures: Blob[];
	onChange: (pictures: Blob[]) => void;
	onError?: () => void;
	camera: boolean;
	onClose?: () => void;
	dontLoadFromHardDrive?:boolean
}

const PhotoTaker: React.FunctionComponent<IProps> = (props) => {

	const numFrames = props.frames.length;
	const [currentFrame, setCurrentFrame] = useState<FrameType>({
		index: 0,
		title: props.frames[0].title,
		box: props.frames[0].box,
		facing: props.frames[0].facing,
		width: props.frames[0].width,
		height: props.frames[0].height
	});
	const [pictures, changePictures] = useState<Blob[]>(props.pictures);
	const picturesRef = useRef(pictures);
	const [cameraShown, showCamera] = useState(!props.ask);
	const [cameraVisible, setCameraVisible] = useState(false);
	const [content, setContent] = useState({width: 0, height: 0});
	const [error, setError] = useState(false);

	useEffect(() => {
		setContent({
			width: window.innerWidth * parseInt(currentFrame.width) / 100,
			height: window.innerHeight * parseInt(currentFrame.height) / 100
		});
	}, [currentFrame]);

	useEffect(() => {
		if (!props.camera) {
			resetCamera();
			if (props.onError) props.onError();
		}
	}, [props.camera]);

	const fileUploadInputChange = (e : any) => {
		let reader = new FileReader();
		reader.onload = async function(e: any) {
			console.log(e.target.result);
			const byteCharacters = atob(e.target.result.replace(/^data:image\/(png|jpeg|jpg);base64,/,''));

			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);

			const blob = new Blob([byteArray], {type: ''});

			handleCapture( blob);
		};
		console.log(e.target.files[0]);
		// handleCapture( new Blob(e.target.files[0]));
		reader.readAsDataURL(e.target.files[0]);
	}

	const handleCapture = (blob: Blob) => {
		if (pictures.length <= currentFrame.index) {
			pictures.push(blob);
		} else {
			pictures[currentFrame.index] = blob;
		}
		changePictures(pictures);
		props.onChange(pictures);
		if (currentFrame.index < numFrames - 1) {
			setCurrentFrame(props.frames[currentFrame.index + 1]);
		}
	}

	const resetCamera = () => {
		setError(false);
		setCameraVisible(false);
		showCamera(false);
	}

	const handleError = () => {
		setError(true);
		setTimeout(() => {
			resetCamera();
			if (props.onError) props.onError();
		}, 5000);
	}

	return (
		<div id={props.id} className="photo-taker_container">
			{props.onClose && <div className="window_close_btn" onTouchEnd={(e)=> e.stopPropagation()} onClick={props.onClose} ><ReactSVG src={CloseSVG} /></div>}

			<div className="photo-taker_title">{props.title}</div>
			{(props.editing || cameraShown) &&
				<Camera
					facingMode={currentFrame.facing}
					title={currentFrame.title}
					box={currentFrame.box}
					content={content}
					pictures={picturesRef && picturesRef.current}
					onVisible={() => setCameraVisible(true)}
					onCapture={(blob) => handleCapture(blob)}
					onError={() => handleError()}
				/>
			}
			{!cameraVisible &&
				<div className="photo-taker_frame">
					<div className="photo-taker_subtitle">{currentFrame.title}</div>
					<div className={'photo-taker_box' + (currentFrame.box ? ' photo-taker_box_border' : '')}
						style={{'width': currentFrame.width, 'height': currentFrame.height}}>
						{error ?
							<div className="photo-taker_error">Произошла ошибка</div> :
							<div className="photo-taker_show_camera"
								onClick={() => showCamera(true)}>
								<div className="photo-taker_show_camera_text">
									{props.editing || cameraShown ? 'Пожалуйста, подождите...' :
										<>
											Открыть камеру&nbsp;&nbsp;
											<ReactSVG src={PhotoSVG} wrapper="span" />
										</>
									}
								</div>
							</div>
						}
					</div>
					<div className="photo-taker_image_container">
						{pictures.map((picture, index) => (
							<img key={'photo-taker_image_' + index} alt=""
								className="photo-taker_image" src={URL.createObjectURL(picture)} />
						))}
					</div>
				</div>
			}
			{!props.dontLoadFromHardDrive &&
				<>
					<label className="photo-taker_from_disk" htmlFor="photo_file">Загрузить с диска</label>
					<input type="file" name="myFile" accept="image/*" id="photo_file"
						   onChange={fileUploadInputChange} style={{"display": "none"}}/>
				</>
			}
		</div>
	);
}

export default PhotoTaker;
