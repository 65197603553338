import React, {useEffect, useRef, useState} from "react";
import './DriverRegistration.css';
import BackIcon from '../../App/svg/back-arrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import {popSystemStatus, showMessageError} from "../../../redux/reducers/system/actions";
import {RootState} from "../../../redux/store";
import {MULTIPLE_CARS_OWNER, OwnerSelectState, SINGLE_CAR_OWNER} from "../../../redux/reducers/owner_select/@types";

import {SystemState} from "../../../redux/reducers/system/@types";
import {DriverInfo, UserInfo, UserState} from "../../../redux/reducers/user/@types";
import {BankSuggestions} from "react-dadata";


import ButtonSave from "../ButtonSave/ButtonSave";

import InputFilteredValue from "../InputFilteredValue/InputFilteredValue";
import {
    convertDate, getDateFromDMY,
    validateDate, validateDateFutureString, validateDateString, validateDateTo,
    validateEmail,
    validateName,
    validateOrg,
    validatePhone
} from "../../../api/validate";
import InputDateValue from "../InputDateValue/InputDateValue";
import InputAddressValue from "../InputAddressValue/InputAddressValue";
import InputPhotoValue from "../InputPhotoValue/InputPhotoValue";
import InputCheckBoxValue from "../InputCheckBoxValue/InputCheckBoxValue";
import {TemporaryState} from "../../../redux/reducers/temporary/@types";
import {setUserInfo, tryUpdateData, updateData} from "../../../redux/reducers/user/actions";
import {loadPhotos, registrationAPI, sendPhoto} from "../../../api";
import QuestionWindow from "../../Elements/QuestionWindow/QuestionWindow";
import {wait_before_update} from "../../../redux/reducers/system/system_functions";
import moment from "moment";
import ImageViewWindow from "../../ImageViewWindow/ImageViewWindow";

interface IProps {
    zIndex:number;
    val:number;

}

const DriverRegistration: React.FunctionComponent<IProps> = ({zIndex,val } ) => {
    const  dispatch = useDispatch();
    const bicRef = useRef<BankSuggestions>(null);
    const isFirstLoad = useRef({isTrue:true}).current;
    const isFirstLoad2 = useRef({isTrue:true}).current;

    const owner  : OwnerSelectState = useSelector( (state: RootState)=> ({owner:state.ownerSelectReducer})).owner;
    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;
    const temp : TemporaryState     = useSelector( (state: RootState)=> ({temp:state.temporaryReducer})).temp;

    const [wasError, setError]                = useState(false);
    const [isImgLoading, setImgLoading] = useState(false);
    const [wasChanged, setChanged]            = useState(false);
    const [imADriver, setImADriver]           = useState(temp.edited_driver.im_a_driver);
    const [isSavingData, setSavingData]       = useState(false);
    const [localBlobPassportFiles, setBlobPassportFiles] = useState<(Blob|null)[]>([null,null,null]);
    const [localBlobDriverFiles, setBlobDriverFiles]   = useState<(Blob|null)[]>([null,null]);

    const [photoFile, setPhotoFile] = useState<Blob|null>(null);
    const [isImageView, showImageView] = useState<string>('');

    let [isShowQuestionWindow, setShowQuestionWindow] = useState<boolean>( false);

    const [state, setState] = useState<DriverInfo>(temp.edited_driver.data);
    // console.log('temp.edited_driver.data', {...temp.edited_driver.data})
    if (!state.driver_certificate || !Array.isArray(state.driver_certificate.category))
         state.driver_certificate.category=[];


    const closeWindow = () =>{
        dispatch(popSystemStatus());
    }

    const onDeleteDriver = async ()   => {
        let newUserData : UserInfo = {...user.data};
        let driver : DriverInfo | undefined = newUserData.drivers.find((x,index)=> index == temp.edited_driver.index);
        if (driver) {
            newUserData.cars.forEach(x=> {
                x.drivers = x.drivers.filter( d=> d.passport.full_name != driver?.passport.full_name)
            })
        }
        newUserData.drivers = newUserData.drivers.filter((x,index)=> index != temp.edited_driver.index);

        setSavingData(true);
        dispatch(setUserInfo(newUserData));
        await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
        setSavingData(false);
    }

    const onSaveData = async ()=> {

        if (isSavingData) return ;
        setError(false);

        if (!isValidFields()) {
            setError(true);
            return;
        }

       await saveDataAction();
    }

    const  saveDataAction = async (save_by_time=false) => {
        console.log('aaa');
        let newUserData : UserInfo = {...user.data};

        if (user.data?.multiple_car_owner == SINGLE_CAR_OWNER ){
            newUserData.passport = {...state.passport};
            newUserData.drivers = [state];
            newUserData.person = {full_name: state.passport.full_name, email:  state.email? state.email : '', phone_number: state.phone_number};
        }

        if (user.data?.multiple_car_owner == MULTIPLE_CARS_OWNER){
            if(temp.edited_driver.just_passport) {
                newUserData.person = {full_name: state.passport.full_name, email: state.email? state.email : '', phone_number: state.phone_number};
                newUserData.passport = {...state.passport};

            }

            if (imADriver || !temp.edited_driver.just_passport) {
                if(temp.edited_driver.index == -1) {
                    newUserData.drivers.push({...state});
                    if (save_by_time)
                        temp.edited_driver.index = 0;
                }
                else
                    newUserData.drivers[temp.edited_driver.index] = {...state}
            }

            if (!imADriver && temp.edited_driver.just_passport){
                newUserData.drivers = newUserData.drivers.filter(x=> x.passport.full_name != user.data.passport.full_name);
                newUserData.cars.forEach(car => car.drivers = car.drivers.filter(x=> x.passport.full_name != user.data.passport.full_name));
            }

        }

        dispatch(setUserInfo(newUserData));
        if (!save_by_time) {
            setSavingData(true);
            await tryUpdateData(newUserData, dispatch, wasChanged, user.authenticated, system.statuses);
        }
        else {
            let res = await wait_before_update(3000);
            if (res) {
                setSavingData(true);
                await updateData(newUserData, dispatch);
            }
        }

        setSavingData(false);
    }





    const onFullNameChanged = (value:string | any) => {

        setState((s)=>({...s, passport:{...s.passport, full_name: value}, driver_certificate:{...s.driver_certificate, full_name: value}}));
    };
    const onBirthDayChanged = (value:string | number) => {
        console.log('onBirthDayChanged', value)
        setState((s)=>({...s, passport:{...s.passport, birth_date: value ? convertDate(getDateFromDMY(value)) : ''}}));
    };

    const onIssueDayChanged = (value:string | number) => {

        setState((s)=>({...s, passport:{...s.passport, issue_date: value ? convertDate(getDateFromDMY(value)) : ''}}));

    };

    const onSerialAndNumberChanged= (value: string | any) => {
        if (!value ) return;
        setState((s)=>({...s, passport:{...s.passport, series: value.substring(0,5).trim(), number: value.substring(5).trim()}}));

    }
    const onPhoneChanged= (value:string | any) => {

        setState((s)=>({...s, phone_number : value}));
    };

    const onEmailChanged= (value:string | any) => {
        setState((s)=>({...s, email : value}));
    };

    const onCodeChanged= (value:string | any) => {

        setState((s)=>({...s, passport : {...s.passport, department_code: value}}));
    };

    const onIssueByChanged= (value:string | any) => {

        setState((s)=>({...s, passport : {...s.passport, issued_by: value}}));
    };
    const onAddressChanged= (value:string | any) => {

        setState((s)=>({...s, passport : {...s.passport, adress: value}}));
    };

    const setPassportPhotoFileName= async (value:Blob|null , index:number) => {

        let files = [...localBlobPassportFiles];
        files[index] = value;
        setBlobPassportFiles(files);
        if (!value) return ;
        let response = await sendPhoto(value , `${user.driver_id}-passport-${index}.jpg`);
        if (response) {
            state.passport.files[index] = response.id;
            setState((s)=> ({...s, passport: {...s.passport, files: state.passport.files}}))
        }
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////// ВОДИТЕЛЬСКОЕ УДОСТОВeРЕНИЕ //////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onDriverSerial= (value:string | any) => {

        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, serial: value}}));
    };

    const onDriverNumber= (value:string | any) => {

        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, number: value}}));

    };

    const onDriverIssueDateChanged = (value:string | number) => {
        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, issue_date: value ? convertDate(getDateFromDMY(value)) : ''}}));

    };

    const onDriverIssueByChanged = (value:string | any) => {
        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, issued_by: value}}));
    }

    const onDriverCountryChanged = (value:string | any) => {
        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, country: value}}));
    }

    const onSetCategories = (value: boolean, category:string) => {
        let categories = state.driver_certificate.category.filter( x=> x != category);
        if (value) categories.push(category);
        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, category: categories}}));

    }

    const onDriverValidityChanged = (value:string | number) => {
        setState((s)=>({...s, driver_certificate : {...s.driver_certificate, validity: value ? convertDate(getDateFromDMY(value)) : ''}}));

    };
    const setDriverPhotoFileName = async (value:Blob|null , index:number) => {
        let files : (Blob|null)[] = [...localBlobDriverFiles];
        files[index] = value;
        setBlobDriverFiles(files);
        if (!value) return ;
        let response = await sendPhoto(value , `${user.driver_id}-driver-${index}.jpg`);
        if (response) {
            state.driver_certificate.files[index] = response.id;
            setState((s)=> ({...s, driver_certificate: {...s.driver_certificate, files: state.driver_certificate.files}}))
        }
    };

    const isValidFields = () : boolean =>{
        if (user.authenticated) return true;

        if (localBlobDriverFiles.some(x => x ==null) || localBlobPassportFiles.some(x => x ==null) ) return false;
        if (!state.phone_number) return false;

        let val = state.passport.number !='' && state.passport.series!=''  && state.passport.issue_date!=''  && state.passport.adress!=''  && state.passport.issued_by!='' &&
            state.passport.department_code!=''  && state.passport.birth_date!=''  && state.passport.full_name!='' ;
        //console.log('isValidFields' , val);
        if (!temp.edited_driver.just_passport || imADriver)
            val = val && state.driver_certificate.country!='' && state.driver_certificate.category.length>0 && state.driver_certificate.validity!='' && state.driver_certificate.issued_by!='' &&
                    state.driver_certificate.issue_date!='' && state.driver_certificate.number!='' && state.driver_certificate.serial!='' && state.driver_certificate.full_name!='' ;
        //console.log('isValidFields' , val);
        return  val;
    };

    // useEffect(()=>{
    //     //@ts-ignore
    //     Window.stated = state;
    //     //@ts-ignore
    //     Window.blob1 = localBlobPassportFiles;
    //     //@ts-ignore
    //     Window.blob2 = localBlobDriverFiles;
    //     setChanged(true);
    //
    // }, []);


    useEffect(()=>{
        if (!isFirstLoad2.isTrue) {
            setChanged(true);
            if (!user.authenticated)
                saveDataAction(true);
        }
        return () => {
            isFirstLoad2.isTrue = false
        }
    }, [state]);
    useEffect(()=>{
        //@ts-ignore
        Window.stated = state;
        //@ts-ignore
        Window.blob1 = localBlobPassportFiles;
        //@ts-ignore
        Window.blob2 = localBlobDriverFiles;
        //console.log('useEffect')
        if (isFirstLoad.isTrue) {
            ( async () => {
                setImgLoading(true);
                let passport_id = state.passport.files;
                if (passport_id.length == 0 && user.data.passport.full_name == state.passport.full_name) {
                    passport_id = user.data.passport.files;
                }
                let filesBlob =  await loadPhotos(passport_id);
                setBlobPassportFiles(filesBlob.length ? filesBlob : [null,null,null]);

                filesBlob = await loadPhotos(state.driver_certificate.files);
                setBlobDriverFiles(filesBlob.length ? filesBlob : [null,null]);
                setImgLoading(false);
            })();
        } else {

           // console.log('isValidFields', isValidFields())
        }
        return () => {
             isFirstLoad.isTrue = false
        }
    }, [localBlobDriverFiles]);

    const getPhoneFormatted = (phone:string)=>{
        if (phone == "") return "";
        if (phone.indexOf('+')<0) phone = '+'+phone;
        if (phone.indexOf('(')>0) return  phone;
        phone = phone.substring(0,2) +' (' + phone.substring(2,5) + ') '+phone.substring(5,8)+'-'+phone.substring(8,10)+'-'+phone.substring(10);
        return phone;
    }
    // console.log(state.passport)
    const deleteFile = async () =>{
       let index = localBlobPassportFiles.findIndex(x=> x==photoFile);
       console.log('index1', index)
       if (index >=0 ) {
           let tmp :any = [...state.passport.files];
           console.log('tmp', tmp, tmp[0])
           let response = await registrationAPI.deleteFile(tmp[index]);
           console.log('response', response)
           if (response.status === 'error') return showMessageError('Ошибка удаления файла ' + response.error_message, dispatch );
           tmp[index] = '';
           setState((s)=> ({...s, passport: {...s.passport, files: tmp}}))
           tmp = [...localBlobPassportFiles];
           tmp[index] = null;
           setBlobPassportFiles(tmp);
       }
       index = localBlobDriverFiles.findIndex(x=> x==photoFile);
        console.log('index2', index)
       if (index >=0 ) {
            let tmp :any = [...state.driver_certificate.files];
            let response = await registrationAPI.deleteFile(tmp[index]);
            console.log('response', response)
            if (response.status === 'error') return showMessageError('Ошибка удаления файла ' + response.error_message, dispatch );
            tmp[index] = '';
            setState((s)=> ({...s, driver_certificate: {...s.driver_certificate, files: tmp}}))
            tmp = [...localBlobDriverFiles];
            tmp[index] = null;
            setBlobDriverFiles(tmp);
       }
        showImageView('');
        setPhotoFile(null);
    };

    if (isImageView && photoFile)
        return(<ImageViewWindow  title={isImageView} onClose={()=>showImageView('')} onDelete={deleteFile} file={photoFile} />);

    return (
            <>
                 <div className="registration_driver_data_window">
                     <div className="common_form_window_title" onClick={closeWindow}>
                          <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                        <div className="page_title"> Данные водителя  </div>
                     </div>
                     <div className="common_form_window_content driver_container_m">
                         <div className="sub_title">Паспортные данные:</div>
                         <InputFilteredValue name="ФИО*" placeholder="Иванов Иван Иванович"
                                             regexp={/[А-Яа-я -]/} value={state.passport.full_name}
                                             onValueChanged={onFullNameChanged}
                                             validator={validateName} wasError={wasError} />

                         <InputFilteredValue name="Телефон*" mask="+7 (999) 999-99-99" type="tel"
                                             value={state.phone_number}
                                             placeholder={"+7 (999) 999-99-99"}
                                             onValueChanged={onPhoneChanged}
                                             validator={validatePhone}  wasError={wasError}/>
                         {
                             temp.edited_driver.just_passport &&
                                 <InputFilteredValue name="E-mail"  type="email"
                                                     value={state.email ? state.email :''}
                                                     placeholder={"user@mail.ru"}
                                                     onValueChanged={onEmailChanged}
                                                     validator={validateEmail} />}




                         <InputFilteredValue name="Кем выдан (как в паспорте)*" placeholder="наименование подразделения"
                                             regexp={/[-а-яА-Я0-9№.,/ ()"]/} value={state.passport.issued_by}
                                             onValueChanged={onIssueByChanged}
                                             validator={validateOrg}
                                             wasError={wasError}/>

                         <div className="driver_2input_container">
                             <InputFilteredValue name="Дата рождения*" mask="99.99.9999"
                                                 value={state.passport.birth_date ? moment(new Date(state.passport.birth_date)).format("DD.MM.yyyy") : ''}
                                                 placeholder={"01.01.1980"}
                                                 classAppend="short"
                                                 onValueChanged={onBirthDayChanged}
                                                 validator={validateDateString}  wasError={wasError}/>
                             {/*<InputDateValue name="Дата рождения*"*/}
                             {/*                value={state.passport.birth_date ? new Date(state.passport.birth_date) : null  }*/}
                             {/*                onValueChanged={onBirthDayChanged}*/}
                             {/*                validator={validateDate}*/}
                             {/*                maxDate={new Date()}*/}
                             {/*                wasError={wasError}/>*/}
                             <div className="splitter"/>
                             <InputFilteredValue  name="Код подразделения" placeholder="127-012" mask={"999-999"}
                                                  value={state.passport.department_code}
                                                  onValueChanged={onCodeChanged}
                                                  classAppend="short"  wasError={wasError}/>
                         </div>

                         <div className="driver_input_container_many_items">
                             <InputFilteredValue name="Дата выдачи*" mask="99.99.9999"
                                                 value={state.passport.issue_date ? moment(new Date(state.passport.issue_date)).format("DD.MM.yyyy") : ''}
                                                 placeholder={"01.01.1980"}
                                                 classAppend="short"
                                                 onValueChanged={onIssueDayChanged}
                                                 validator={validateDateString}  wasError={wasError}/>

                             {/*<InputDateValue name="Дата выдачи*" value={state.passport.issue_date ? new Date(state.passport.issue_date) : null  }*/}
                             {/*                onValueChanged={onIssueDayChanged}*/}
                             {/*                validator={validateDate}*/}
                             {/*                maxDate={new Date()}*/}
                             {/*                wasError={wasError}/>*/}
                             <InputFilteredValue  name="Серия и номер*" placeholder="40 04 906606" mask={"99 99 999999"}
                                                  value={state.passport.series ? state.passport.series + ' ' + state.passport.number : ''}
                                                  onValueChanged={onSerialAndNumberChanged}
                                                  classAppend="short"
                                                  wasError={wasError}/>
                         </div>
                         <InputAddressValue name={"Место гаража (стоянки) ТС или адрес проживания*"}
                                            value={state.passport.adress} onValueChanged={onAddressChanged}
                                            placeholder={"Россия, Москва, Московский проспект"}
                                            wasError={wasError}/>

                         <div className='sub_title'>Прикрепите фото паспорта</div>
                         <div className="driver_input_container_many_items">
                             {["Страницы 2-3", "Страницы 3-4", "Вы с паспортом"].map( (x, index)=>
                                 <InputPhotoValue name={x}
                                                  key = {"input_photo"+index}
                                                  file={localBlobPassportFiles[index]}
                                                  fileIndex={index}
                                                  settingIndex={index}
                                                  is_loading={isImgLoading && !localBlobPassportFiles[index]}
                                                  onValueChanged={(value,index) => setPassportPhotoFileName(value, index)}
                                                  onPhotoClick={()=>{showImageView(x); setPhotoFile(localBlobPassportFiles[index])}}
                                                  wasError={wasError}/>

                             )}
                         </div>
                         { temp.edited_driver && temp.edited_driver.just_passport &&
                             <InputCheckBoxValue name={'Я - водитель'} key={"imadriver"}
                                                 classAppend={"full-width"}
                                                 isSet={ imADriver} onChange={(value) => setImADriver(value)}/>
                         }
                         {(!temp.edited_driver.just_passport || imADriver) &&
                             <>
                                 <div className='sub_title'>Водительское удостоверение</div>
                                 <div className="driver_input_container_many_items">
                                     <InputFilteredValue name="Серия ВУ*" mask="99 99"
                                                         value={state.driver_certificate.serial}
                                                         placeholder={"10 10"}
                                                         onValueChanged={onDriverSerial}
                                                         classAppend="short"
                                                         wasError={wasError}
                                                         />
                                     <InputFilteredValue name="Номер ВУ*" mask="999999"
                                                         value={state.driver_certificate.number}
                                                         placeholder={"400419"}
                                                         onValueChanged={onDriverNumber}
                                                         classAppend="short"
                                                         wasError={wasError}
                                     />
                                     <InputFilteredValue name="Дата выдачи*" mask="99.99.9999"
                                                         value={state.driver_certificate.issue_date ? moment(new Date(state.driver_certificate.issue_date)).format("DD.MM.yyyy") : ''}
                                                         placeholder={"01.01.1980"}
                                                         classAppend="short"
                                                         onValueChanged={onDriverIssueDateChanged}
                                                         validator={validateDateString}  wasError={wasError}/>

                                     {/*<InputDateValue name="Дата выдачи*" value={state.driver_certificate.issue_date ? new Date(state.driver_certificate.issue_date) : null  }*/}
                                     {/*                onValueChanged={onDriverIssueDateChanged}*/}
                                     {/*                validator={validateDate}*/}
                                     {/*                maxDate={new Date()}*/}
                                     {/*                wasError={wasError}/>*/}

                                     <InputFilteredValue name="Срок действия*" mask="99.99.9999"
                                                         value={state.driver_certificate.validity ? moment(new Date(state.driver_certificate.validity)).format("DD.MM.yyyy") : ''}
                                                         placeholder={"01.01.1980"}
                                                         classAppend="short"
                                                         onValueChanged={onDriverValidityChanged}
                                                         validator={validateDateFutureString}  wasError={wasError}/>

                                     {/*<InputDateValue name="Срок действия*" value={state.driver_certificate.validity ? new Date(state.driver_certificate.validity) : null  }*/}
                                     {/*                onValueChanged={onDriverValidityChanged}*/}
                                     {/*                validator={validateDateTo}*/}
                                     {/*                wasError={wasError}/>*/}

                                     <InputFilteredValue name="Кем выдано*" placeholder="ГИБДД"
                                                         regexp={/[-а-яА-Я0-9№.,/ ()"]/} value={state.driver_certificate.issued_by}
                                                         onValueChanged={onDriverIssueByChanged}
                                                         validator={validateOrg}
                                                         classAppend="short"
                                                         wasError={wasError}/>
                                     <InputFilteredValue name="Страна*" placeholder="Россия"
                                                         regexp={/^[а-яА-Я -]{1,100}$/} value={state.driver_certificate.country}
                                                         onValueChanged={onDriverCountryChanged}
                                                         validator={validateOrg}
                                                         classAppend="short"
                                                         wasError={wasError}/>
                                 </div>

                                 <div className={"driver_input_container_many_items flex-start " + ( wasError && state.driver_certificate.category.length ==0 ? 'error' : '')}
                                           style={{padding:5}}>
                                     {["A", "B", "C", "B1", "C1", "C1E", "D1", "D1E", "BE", "CE", "DE"].map((x,index)=>
                                      <InputCheckBoxValue name={x} key={"cb"+x} isSet={ state.driver_certificate.category.some(z=>z==x)} onChange={(value) => onSetCategories(value, x)}/>)}
                                 </div>
                                 <div className='sub_title'>Прикрепите фото водительского удостоверения</div>
                                 <div className={"driver_input_container_many_items " }>
                                     {["Сторона 1", "Сторона 2"].map( (x, index)=>
                                         <InputPhotoValue name={x}
                                                          key = {"input_driver_photo"+index}
                                                          file={localBlobDriverFiles[index]}
                                                          fileIndex={index}
                                                          wasError={wasError}
                                                          is_loading={isImgLoading && !localBlobDriverFiles[index]}
                                                          settingIndex={3 + index}
                                                          onValueChanged={(value,index) => setDriverPhotoFileName(value, index)}
                                                          onPhotoClick={()=>{showImageView(x); setPhotoFile(localBlobDriverFiles[index])}}
                                         />

                                     )}
                                </div>
                         </>}
                         {user.data.multiple_car_owner && temp.edited_driver.index!=-1 &&
                          <div className='sub_title sub_title_delete' onClick={()=> setShowQuestionWindow(true)}>Удалить данного водителя</div>}

                         <ButtonSave onClickSave={onSaveData} onClickBack={closeWindow} isSavingData={isSavingData} isActive={isValidFields()} />
                     </div>
                     {
                         isShowQuestionWindow &&
                         <QuestionWindow title={"Вы уверены, что хотите удалить выбранного водителя ?"}
                                         onYes={onDeleteDriver}
                                         onNo={()=>setShowQuestionWindow(false)}/>
                     }
                 </div>
           </>
    );
};

export default DriverRegistration;
