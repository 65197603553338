import React, {useEffect, useRef, useState} from "react";
import './BankDataRegistration.css';
import BackIcon from '../../App/svg/back-arrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {ReactSVG} from "react-svg";
import {popSystemStatus} from "../../../redux/reducers/system/actions";
import {RootState} from "../../../redux/store";
import {OwnerSelectState, TIN_IP} from "../../../redux/reducers/owner_select/@types";
import {isSystemStatusActive} from "../../../redux/reducers/system/reducers";
import {editingChars, SystemStatus} from "../../../@types";
import {setUserInfo, updateData} from "../../../redux/reducers/user/actions";
import {SystemState} from "../../../redux/reducers/system/@types";
import { BankingInfo, UserInfo, UserState} from "../../../redux/reducers/user/@types";
import {BankSuggestions} from "react-dadata";
import {daDataToken} from "../../../deployment";

import ButtonSave from "../ButtonSave/ButtonSave";

interface IProps {
    zIndex:number;
}


const BankDataRegistration: React.FunctionComponent<IProps> = ({zIndex} ) => {
    const  dispatch = useDispatch();
    const bicRef = useRef<BankSuggestions>(null);

    const user   : UserState        = useSelector( (state: RootState)=> ({user:state.userReducer})).user;
    const system : SystemState      = useSelector( (state: RootState)=> ({system:state.systemReducer})).system;

    const [error, setError]        = useState(false);
    const [wasChanged, setChanged] = useState(false);
    const [isAccountEntered, setAccountEntered] = useState(false);
    const [isBIKEntered, setBIKEntered] = useState(false);
    const [isSavingData, setSavingData] = useState(false);

    const [state, setState] = useState( user.data && user.data.banking ? user.data && user.data.banking : new BankingInfo());
    const closeWindow = () =>{
        dispatch(popSystemStatus());
    }



    const onSaveData = async ()=> {
        if (isSavingData) return ;

        setError(false);
        if (!isAccountEntered || !isBIKEntered) {
            setError(true);
            return;
        }
        setSavingData(true);
        let data: UserInfo = new UserInfo();
        if (user.data != null)
            data = {...user.data, banking: {...state}};
        else
            data = {...data, banking: {...state}};

        dispatch(setUserInfo(data));

        if (isSystemStatusActive(SystemStatus.Registration, system.statuses)) {
            let respData : UserInfo | any = await updateData(data, dispatch);
            if (respData)
                dispatch(popSystemStatus());
        } else
        if (user.authenticated) {
            if (wasChanged) {
                if ( await updateData(data, dispatch)) dispatch(popSystemStatus());
            } else
                dispatch(popSystemStatus());
        } else
            dispatch(popSystemStatus());
        setSavingData(false);

    }
    useEffect(()=>{
        if (user.data && user.data.banking) {
            if (user.data.banking.BIK.length === 9) setBIKEntered(true);
            if (user.data.banking.account_number.length === 20) setAccountEntered(true);
        }
    }, [user.data])

    return (
             <div className="registration_bank_data_window">
                 <div className="common_form_window_title" onClick={closeWindow}>
                      <ReactSVG src={BackIcon} className="common_form_window_icon"  />
                    <div className="page_title"> Банковские реквизиты {user.data.type == TIN_IP ? ' ИП ' : ' Самозанятого'}  </div>
                 </div>
                 <div className="common_form_window_content bank_container_m">
                     <div id="bank_bic" className={'bank_input' + (isBIKEntered ? ' bank_input_complete' : (error ? ' error': ''))}>
                         <BankSuggestions
                             token={daDataToken}
                             ref={bicRef}
                             defaultQuery={state.bank.BIK}
                             count={5}
                             onChange={(suggestion) => {
                                 setChanged(true);
                                 if (suggestion && suggestion.data && suggestion.data.state &&
                                     suggestion.data.state.status === 'ACTIVE') {
                                     setState((s)=>  ({...s,  BIK:suggestion.data.bic,
                                                  correction_number: suggestion.data.correspondent_account,
                                                  bank: {...s.bank, correction_number: suggestion.data.correspondent_account,
                                                                     BIK: suggestion.data.bic, TIN:  suggestion.data.inn,
                                                                     KPP:  suggestion.data.kpp, adress: suggestion.value,
                                                                     name: suggestion.value}
                                           } ));
                                     // console.log(suggestion.value, suggestion.data);
                                     if (bicRef.current) bicRef.current.setInputValue(suggestion.data.bic);
                                     setBIKEntered(true);
                                 }
                             }}
                             inputProps={{className: 'bank_input_text', placeholder: 'БИК банка', type: 'number',
                                 onKeyDown: (e: React.KeyboardEvent) => {
                                     if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
                                         (/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 9)) {
                                         e.preventDefault();
                                     }
                                 },
                                 onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                     setChanged(true);
                                     setState((s)=>({...s, BIK: e.target.value, bank:{...state.bank, BIK: e.target.value} }));
                                     setBIKEntered(e.target.value.length === 9)

                                 },
                             }}
                         />
                     </div>
                     <div id="bank_name" className={'bank_input bank_autofill' + (isBIKEntered  ? ' bank_input_complete' : '')}>
                         <input className="bank_input_text" placeholder="Наименование банка" type="text" value={state.bank.name} readOnly/>
                     </div>
                     <div id="bank_corr" className={'bank_input bank_autofill' + (isBIKEntered  ?
                         ' bank_input_complete' : '')}>
                         <input className="bank_input_text" placeholder="Корр. счет банка" type="number"
                                value={state.correction_number} readOnly
                         />
                     </div>
                     <div id="bank_inn" className={'bank_input bank_autofill' + (isBIKEntered ?' bank_input_complete' : '')}>
                         <input className="bank_input_text" placeholder="ИНН банка" type="number"
                                value={state.bank.TIN} readOnly
                         />
                     </div>
                     <div id="bank_kpp" className={'bank_input bank_autofill' + (isBIKEntered ? ' bank_input_complete' : '')}>
                         <input className="bank_input_text" placeholder="КПП банка" type="number"
                                value={state.bank.KPP} readOnly
                         />
                     </div>
                     <div id="bank_address" className={'bank_input bank_autofill' + (isBIKEntered ? ' bank_input_complete' : '')}>
                         <input className="bank_input_text" placeholder="Юридический адрес банка" type="text"
                                value={state.bank.adress || ''} readOnly
                         />
                     </div>
                     <div id="bank_account" className={'bank_input' + (isAccountEntered ? ' bank_input_complete' : (error ? ' error' : ''))}>
                         <input className="bank_input_text" placeholder="Рассчетный счет в банке" type="number"
                                value={state.account_number} onKeyDown={(e: React.KeyboardEvent) => {
                             if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
                                 (/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 20)) {
                                 e.preventDefault();
                             }
                         }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setChanged(true);
                                    setState((s)=>  ({...s,  account_number:e.target.value}));
                                    setAccountEntered( e.target.value.length === 20)
                                    //console.log(e.target.value.length === 20)

                                }}
                         />
                     </div>
                     <ButtonSave onClickSave={onSaveData} onClickBack={closeWindow} isSavingData={isSavingData} isActive={isBIKEntered && isAccountEntered} />
                 </div>
            </div>
    );
};

export default BankDataRegistration;
