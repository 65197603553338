import { UserStatus } from "../../../../@types";
import {IBOCharacteristics, SET_CURRENT_ID} from "../../system/@types";

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_STATUS = 'SET_STATUS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const LOG_OUT = 'LOG_OUT';
export const SET_CURRENT_COORDINATE = 'SET_CURRENT_COORDINATE';
export const SET_USER_TRACKER = 'SET_USER_TRACKER';

export interface SetAuthenticatedAction {
  type: typeof SET_AUTHENTICATED;
  authenticated: boolean;
  driver_id: string;
  full_name: string;
  status: UserStatus
}

export interface SetStatusAction {
  type: typeof SET_STATUS;
  status: UserStatus
}

export interface SetUserInfoAction {
  type: typeof SET_USER_INFO;
  data: UserInfo
}

export interface LogOutAction {
  type: typeof LOG_OUT;
}

export interface SetCurrentCoordinateAction {
  type: typeof SET_CURRENT_COORDINATE;
  current_coordinates: {lon:number, lat: number};
}

export interface SetUserTrackerAction {
  type: typeof SET_USER_TRACKER;
  has_tracker: string;
}

export type UserActionTypes = SetAuthenticatedAction | SetStatusAction | SetUserInfoAction | LogOutAction | SetCurrentCoordinateAction | SetUserTrackerAction;

export interface UserState {
  authenticated: boolean;
  driver_id: string;
  full_name: string;
  status: UserStatus,
  data: UserInfo ,
  current_coordinates : {lon:number,lat:number} | null,
  has_tracker: string,
}

export class PassportInfo {
  full_name = "";
  birth_date = "";
  series = "";
  number = "";
  issued_by = "";
  department_code = "";
  issue_date = "";
  adress = "";
  real_adress = "";
  files : string[] = ['','',''];
}

export class DriverCertificateInfo {
  full_name: string= "";
  serial: string= "";
  number: string= "";
  issue_date: string= "";
  validity: string= "";
  issued_by: string= "";
  country: string = "";
  category:string[] = [];
  files: string[] = ["",""];
}

export class DriverInfo {
  driver_certificate : DriverCertificateInfo = new DriverCertificateInfo() ;
  phone_number : string = "";
  email? : string = "";
  passport : PassportInfo = new PassportInfo();
}

export interface CarInfo {
  "car_type_id": string,
  "body_option_id": string,
  "body_option_characteristics":{
    "id": string,
    "value": string
  }[],
  "size": {
    "length": string,
    "width": string,
    "height": string
  },
  "certificate": {
    "number": string,
    "VIN": string,
    "brand": string,
    "model": string,
    "date": string,
    "type": string,
    "category": string,
    "class": string,
    "passport_serial": string,
    "passport_number": string,
    "certificate_serial": string,
    "certificate_number": string,
    "free_weight": string,
    "max_weight": 0,
    "files":  string[]
  },
  "files": string[],
  "adress": {
    "adress": string,
    "longitude": string,
    "latitude": string,
  },
  "drivers":DriverInfo[]
}


export class VehicleCertificate {
  number: string = "";
  VIN: string = "";
  brand: string = "";
  model: string = "";
  date: string = "";
  type: string = "";
  category: string = "";
  class: string = "";
  passport_serial: string = "";
  passport_number: string = "";
  certificate_serial: string = "";
  certificate_number: string = "";
  free_weight: string = "";
  max_weight: string = "";
  files:  string[] = ['',''];

}
export class VehicleAddress {
  adress: string = "";
  longitude:  string = "";
  latitude:  string = "";
}
export class VehicleSize {
  length: string = "";
  width: string = "";
  height: string = "";
  constructor(l:string="0", w: string="0", h:string="0") {
    this.length = l;
    this.width  = w;
    this.height = h;
  }
}

export interface IBOSmallCharacteristics {
  id: string;
  value: any;
}
export class VehicleInfo {
  car_type_id: string = "";
  body_option_id: string = "";
  body_option_characteristics :IBOSmallCharacteristics[] = [];
  size: VehicleSize = new VehicleSize();
  certificate: VehicleCertificate = new VehicleCertificate();
  files: string[] = ['',''];
  adress: VehicleAddress = new VehicleAddress();
  drivers: DriverInfo[] = [];
  pass: string = "";
}


export class BankingInfo {
  account_number = "";
  BIK = "";
  correction_number = "";
  bank = {  BIK:"", name : "", correction_number : "", TIN : "", KPP : "", OGRN : "", adress : ""};
}


export class RecommendationsInfo {
  full_name ="";
  position = "";
  phone_number = "";
}

export enum EnumUserTinType {
  INDIVIDUAL='INDIVIDUAL', SELF_EMPLOYED = 'SELF_EMPLOYED', LEGAL = 'LEGAL', NON_LEGAL = 'NON_LEGAL'
}

export class UserInfo {
  id="";
  driver_id="";
  TIN = "";
  draft = true;
  type : EnumUserTinType = EnumUserTinType.NON_LEGAL;
  multiple_car_owner : boolean = false;
  person = { full_name :"", phone_number:"", email :"" };
  banking : BankingInfo = new BankingInfo();
  passport : PassportInfo = new PassportInfo();
  cars : VehicleInfo[] = [];
  recommendations : RecommendationsInfo[]  = [];
  accept_agreement : boolean = false;
  drivers: DriverInfo[] = [];
  msv?: boolean = true;
}
