import React, {FC, useEffect, useState} from 'react'
import './Chat.css'
import {ChatMenu} from './ChatMenu/ChatMenu'
import {ChatMessages} from './ChatMessages/ChatMessages'
import {popSystemStatus, showMessageError, showMessageTrunk} from "../../redux/reducers/system/actions";
import {useDispatch, useSelector} from "react-redux";
import {OrderFullInfo} from "../../redux/reducers/orders/@types";
import {userAPI} from "../../api";
import {RootState} from "../../redux/store";
import {CChatMessage,   IChatDialog, IChatGroup} from "../../redux/reducers/chat/@types";
import {setChatMessages, setChatSelectedDialogId, setChatVisible} from "../../redux/reducers/chat/chatActions";
import Loader from "react-loader-spinner";
import {setChatNewMessages, setChatMessagesIsRead} from "../../redux/reducers/chat/chatReducers";
import ChatButton from "./ChatButton/ChatButton";

var  msgInterval: any = undefined;
enum EnumChatWindowMode {
    LOADING, MAIN_MENU, MENU_DIALOGS, MESSAGES
}
export const Chat: FC<propTypes> = ({  order}) => {
    const dispatch = useDispatch();

    const {user} = useSelector((state: RootState)=> ({user:state.userReducer}));
    const {chat} = useSelector((state: RootState)=> ({chat:state.chatReducer}));

    const [chatMode, setChatMode] = useState<EnumChatWindowMode>(EnumChatWindowMode.MAIN_MENU);
    const [selectedGroup, setSelectedGroup] = useState<IChatGroup | undefined>(undefined);
    const [selectedDialog, setSelectedDialog] = useState<IChatDialog | undefined>(undefined);

    const getDialogById = async (dialog_id: string) => {
        setChatMode(EnumChatWindowMode.LOADING);
        let group : IChatGroup | undefined = chat.groups.find(x=> x.dialogs.some(d=> d.dialog_id == dialog_id));
        console.log('chat.groups', chat.groups.length, chat.groups);
        if (!group) {
            setChatMode(EnumChatWindowMode.MAIN_MENU);
            dispatch(setChatSelectedDialogId(''));
            return showMessageError('Не найдена группа диалога id=' + chat.selected_dialog_id, dispatch);
        }
        onChatSelected(group);
        console.log('group', group);

        let dialog = undefined;
        chat.groups.forEach(x=> x.dialogs.forEach(d=> d.dialog_id == chat.selected_dialog_id ? dialog = d : null));
        console.log('dialog', dialog);

        let res = await userAPI.getChatMessages(user.driver_id,    group.group_id, dialog_id)
            .catch((e)=>{
                setChatMode(EnumChatWindowMode.MENU_DIALOGS);
                showMessageError('Ошибка получения сообщений ' + e.message, dispatch)
            });
        console.log('dialog', dialog);
        if(!res) return setChatMode(EnumChatWindowMode.MENU_DIALOGS);

        setSelectedDialog(dialog);
        let messages : CChatMessage[] | undefined =  await setChatMessagesIsRead(user.driver_id, res.data, dispatch);
        if (messages === undefined) return setChatMode(EnumChatWindowMode.MENU_DIALOGS);

        messages.sort((a,b) => a.date > b.date ? 1 : a.date == b.date ? 0 : -1 );
        dispatch(setChatMessages(messages));
        setChatMode(EnumChatWindowMode.MESSAGES);
    }
    useEffect(()=>{
        if ( chat.selected_dialog_id)
            getDialogById(chat.selected_dialog_id);

    },[chat.selected_dialog_id]);

    const onCloseMenuDialog = () => {
        setSelectedGroup(undefined);
        setChatMode(EnumChatWindowMode.MAIN_MENU);
    }
    const onClose = () => {
        dispatch(setChatVisible(false));
    };

    const onCloseDialog = () => {
        setSelectedDialog(undefined);
        setChatMode(EnumChatWindowMode.MENU_DIALOGS);
        dispatch(setChatSelectedDialogId(''));
    }


    const onChatDialogSelected = async (dialog:IChatDialog) => {
        setChatMode(EnumChatWindowMode.LOADING);
        console.log('selectedGroup', selectedGroup)
        let res = await userAPI.getChatMessages(user.driver_id,   selectedGroup ? selectedGroup.group_id :'', dialog.dialog_id)
                               .catch((e)=>{
                                            setChatMode(EnumChatWindowMode.MENU_DIALOGS);
                                            showMessageError('Ошибка получения сообщений ' + e.message, dispatch)
                                        });

        if(!res) return setChatMode(EnumChatWindowMode.MENU_DIALOGS);
        if (!Array.isArray(res.data) || res.data.status === 'error') {
            setChatMode(EnumChatWindowMode.MENU_DIALOGS);
            return showMessageError('Ошибка получения сообщений ' + res.data.error_message, dispatch);
        }

        dialog.group_id = selectedGroup ? selectedGroup.group_id : '';
        setSelectedDialog(dialog);
        let messages : CChatMessage[] | undefined =  await setChatMessagesIsRead(user.driver_id, res.data, dispatch);
        if (messages === undefined) return setChatMode(EnumChatWindowMode.MENU_DIALOGS);

        messages.sort((a,b) => a.date > b.date ? 1 : a.date == b.date ? 0 : -1 );
        dispatch(setChatMessages(messages));

        setChatMode(EnumChatWindowMode.MESSAGES);
    };

    const onChatSelected = (group:IChatGroup) => {
        setSelectedGroup(group);
        setChatMode(EnumChatWindowMode.MENU_DIALOGS);
    };

    useEffect(()=>{
        let isMounted = true;
        if ( msgInterval) {
            clearInterval(msgInterval);
            msgInterval = undefined;
        }


        msgInterval = setInterval(async () => {
            if (!isMounted) return clearInterval(msgInterval);
            if (!user.authenticated) return ;

            let res = await userAPI.checkChatNewMessages(user.driver_id).catch((e)=> console.error('chat error get new message', e.message));
            if (!res || !Array.isArray(res.data) ) return ;
            await setChatNewMessages(user.driver_id,  res.data,selectedDialog ? selectedDialog.dialog_id : '', chat, dispatch);
        }, 3000);

        return () => { isMounted = false };
    }, [chatMode, chat.groups])

    return (
        <>
        {chat.is_visible ?
            <section>
                <div className="chatBackground" onClick={onClose} />
                <div className="commonContainer">
                    {chatMode == EnumChatWindowMode.LOADING  &&  <div className="loadingForm"><Loader type="Puff" color="#bbb" width={'100'} height={'100'}/></div>}
                    {chatMode == EnumChatWindowMode.MAIN_MENU     &&  <ChatMenu onClose={onClose} onSelectChatGroup={onChatSelected} />}
                    {chatMode == EnumChatWindowMode.MENU_DIALOGS     &&  <ChatMenu  onClose={onCloseMenuDialog} group={selectedGroup} onSelectChatDialog={(dialog)=> {onChatDialogSelected(dialog)}} />}
                    {chatMode == EnumChatWindowMode.MESSAGES &&  selectedDialog && <ChatMessages dialog={selectedDialog} onClose={onCloseDialog} />}
                    {/*{chat.selected_dialog_id &&  selectedDialog && <ChatMessages dialog={getDialog(chat.selected_dialog_id)} onClose={onCloseDialog} />}*/}
                </div>
            </section>
            :
            <ChatButton />
        }
        </>
    )
}


type propTypes = {
    order?: OrderFullInfo
}
