import React from "react";
import {connect, useDispatch} from "react-redux";
import "./QuestionWindow.css";
import {Button, Overlay} from "../elements";

interface IProps {
  title:string;
  onYes: ()=>void;
  onNo: ()=>void;
}

const QuestionWindow: React.FunctionComponent<IProps> = (props) => {

    return (
        <>
          <div className={'question_window'}>
            <div className={'question_window_title'}>{props.title}</div>
            <div className={'question_window_buttons'}>
                <Button type={"green"} onClick={props.onYes} size={"middle"}   > ДА</Button>
                <Button type={"error"} onClick={props.onNo} size={"middle"}   > НЕТ</Button>
            </div>
          </div>
            <Overlay zIndex={32} onClick={props.onNo}/>
        </>
    );

}


export default QuestionWindow;
