export function HereMarkerIconFixed(labels, pointNumber) {
  let margin = labels.length;
  margin = margin === 1 ? 12 : margin * 4.5;
  let marginStr = margin.toString();

  let color = pointNumber === 1 ? '#FF9900' : '#5364FF';
  let markerStyle = pointNumber === 1 ? 'marker-time-orange' : 'marker-time-blue';

  let t =  '<div>' +
    '<div class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: 0px;margin-top: 0px">';
  //t += labels.length ? '<div class="'+markerStyle+'" style="margin-left: -'+marginStr+'px; margin-top: -30px;">' + labels + '</div>' : '';
  t += labels.length ? '<div class="'+markerStyle+'" style="max-width: 200px; margin-top: -30px;">' + labels + '</div>' : '';

  t += '<svg width="30" height="20"><circle cx="10" cy="8" r="6" stroke="'+color+'" fill="#FFFF" style="stroke-width: 4px" /></svg> </div> </div>';
  return t;
}


export const HereMarkerIconExecute = () => `
    <div> 
             <div  style="margin-top: -27px; margin-left: -7px;"> 
                 <svg width="24" height="35" viewBox="0 0 24 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2701 25.1836L12 25.9629L12.7299 25.1836L15.2551 22.4872C19.4184 18.0417 22.0534 12.3828 22.7759 6.33509C22.9471 4.90207 22.4072 3.26433 20.8452 2.559C19.208 1.81972 16.3981 1 12 1C7.60188 1 4.792 1.81972 3.15484 2.559C1.59284 3.26434 1.05287 4.90207 1.22408 6.33509C1.94662 12.3828 4.58155 18.0417 8.74491 22.4872L11.2701 25.1836Z" fill="#999999" stroke="white" stroke-width="2"/>
                   <circle cx="12" cy="30" r="4" fill="#999999" stroke="white" stroke-width="2"/>
                 </svg>
            </div>
    </div>
`;

export const HereMarkerIconDriver = () => `
    <div> 
       <div   style="margin-top: -46px; margin-left: -15px;"> 
        <svg width="36" height="50" viewBox="0 0 36 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2564 40.1686L18 40.9957L18.7436 40.1686L22.8597 35.5904C29.386 28.3314 33.4902 19.2205 34.6025 9.52268L34.6567 9.05047C34.9013 6.91737 34.1144 4.57883 31.923 3.54745C29.3863 2.35354 24.9699 1 18 1C11.0301 1 6.61371 2.35354 4.07696 3.54745C1.88556 4.57883 1.09866 6.91737 1.34332 9.05047L1.39748 9.52269C2.50977 19.2205 6.61398 28.3314 13.1403 35.5904L17.2564 40.1686Z" fill="#5364FF" stroke="white" stroke-width="2"/>
            <circle cx="18" cy="45" r="4" fill="#3B3F49" stroke="white" stroke-width="2"/>
        </svg>
      </div>
    </div>
`;

export const HereMarkerIconPulsar = () => `
    <div class="pulsar-sphere"></div>
`;

