import {KalmanFilterGPS} from "./KalmanFilterGPS";
import {
    createDomMarker, createDomMarkerPulsar,
    drawDriverRouteToMap, ease,
    getDistanceABLine,
    getNearestPointOnLine,
    MARKER_4, ROUTE_DRIVER_LINE_FULL, ROUTE_DRIVER_LINE_MEDIAN, ROUTE_DRIVER_LINE_REAL
} from "./hereFunctions";
import {
    COUNT_MAX_DISTANCE, MAX_DEVIATION_FROM_ROUTE,
    MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS, MIN_GPS_ACCURACY,
    NAVIGATION_GET_COORDINATE_TIME_IN_MS, NEED_NEW_ROUTE
} from "../../../deployment";
import {HereMarkerIconDriver} from "./HereMarkerIconFixed";
import {getDriverRouteToNextPoint} from "../../../redux/reducers/system/system_functions";
import {map} from "./HereMap";
import {to_coordinates_array, TypeLonLat, TypeLonLatExtended} from "../../../redux/reducers/map/@types";
import turf from "turf";
import moment, {Moment} from "moment";
import {Interpolation} from "./Interpolation";
import {NavigatorController} from "./NavigatorController";
import {setLowSignal} from "../../../redux/reducers/map/actions";
export type NearestPointData = {pointOnRoute:number[] | undefined; distance:number; index:number; delta:number; date:string;};

const EmptyNearestPointData = {pointOnRoute:[], distance:0, index:0, delta:33, date:''};
export class Navigator {

    static kalmanFilter : KalmanFilterGPS | null= null;
    static interpolation : Interpolation | null= null;
    static count_max_distance : number = 0;
    static current_route : number[][] = [];
    static detail_route : number[][] = [];
    static good_gps_coordinate : TypeLonLatExtended[] = [];
    static line_route : any = null;
    static dispatch : any = [];
    static markerCar : any = null;
    static markerPulsar : any = null;
    static markerPulsarOnMapObject : any = null;
    static routeArchiveInfo : {point: number[], vi:number, sit: number}[] = [];
    static prevPoint : NearestPointData  = EmptyNearestPointData;
    static prev_point : TypeLonLatExtended | null = null;
    static lastTimeMapHandMove: Moment = moment(new Date());
    static statistic: number[] = Array(NEED_NEW_ROUTE.length).fill(+0);
    static navigation_time: number = NAVIGATION_GET_COORDINATE_TIME_IN_MS + NAVIGATION_GET_COORDINATE_TIME_IN_MS*0.1;
    static _lastDateTime : number = 0;
    static _lastDistance : number = 0;
    // static _lastAccuracy : number = 0;
    static _lastSpeed : number = 0;
    static _lastMapPoint : {lat:number, lng:number} | null = null;
    static _countNoCoordinateOrBadAccuracy : number = 0; // количество сколько раз не пришли данные или пришли плохие
    static _isLoadingNewRoute : boolean = false; // идет процесс загрузки нового маршрута
    static _prevRealPoint : TypeLonLatExtended | null = null; // предыдущая точка которая пришла
    static _constCountPointToCalculate : number = 6; // предыдущая точка которая пришла
    static _setDebugData :Function; // предыдущая точка которая пришла
    static is_low_signal : boolean = false; // предыдущая точка которая пришла

    static getVal = (va:number|undefined) => va ?? 0

    static drawRealRoute(driver_point:TypeLonLatExtended, drawDriverRouteToMap:Function){
        if (!this._prevRealPoint  ) return this._prevRealPoint = driver_point;

        drawDriverRouteToMap([
            [this._prevRealPoint.lat, this._prevRealPoint.lon],
            [driver_point.lat, driver_point.lon]
        ],map, ROUTE_DRIVER_LINE_FULL);

        drawDriverRouteToMap([
                                 [this._prevRealPoint.lat, this._prevRealPoint.lon],
                                 [driver_point.lat, driver_point.lon]
                             ],map, ROUTE_DRIVER_LINE_REAL);

        this._prevRealPoint = driver_point
    }
    static showPulsar ( isShow = true) {
        if (isShow) {
            if (this.markerPulsarOnMapObject || !this.markerCar) return;
            let g = this.markerCar.getGeometry()
            this.markerPulsar = createDomMarkerPulsar([g.lat, g.lng]);
            this.markerPulsarOnMapObject = map.addObject(this.markerPulsar)
        } else {
            if (this.markerPulsarOnMapObject) {
                map.removeObject(this.markerPulsarOnMapObject)
                this.markerPulsarOnMapObject = null;
            }
            this._countNoCoordinateOrBadAccuracy=0;
        }
    }
    static showDebugInformation(driver_point:TypeLonLatExtended, distance :number | undefined = undefined, text=''){
        this._setDebugData({point:driver_point,
            distance:  (distance ? distance.toFixed(2) + ' из ' + MAX_DEVIATION_FROM_ROUTE :
                                   '<span style="color:#ff0000;background: #fff">нет данных от GPS</span>')+ '<br>' +
                        `accuracy = ${driver_point.accuracy}<br>` ,
            count: text});
    }

    static async create_new_route(dispatch:any, driver_point:TypeLonLatExtended, mapPoint: {lat:number, lng:number}){
        this._isLoadingNewRoute = true;
        // let pnts:TypeLonLatExtended[] = NavigatorController.get_last_good_points(2, driver_point);
        let lastPoint = this.markerCar? this.markerCar.getGeometry() : null;
        let pnts: TypeLonLatExtended[] = lastPoint ? [{lat: lastPoint.lat, lon: lastPoint.lng}] : [];
        this.detail_route = await getDriverRouteToNextPoint(dispatch, [ ...pnts,  driver_point, {lat: mapPoint.lat, lon: mapPoint.lng}])
        this.line_route = turf.lineString(this.detail_route);
        this._isLoadingNewRoute = false;
        //@ts-ignore
        this.interpolation.clear();
        this.log(` set this.detail_route length=${this.detail_route.length}`, driver_point)
        NavigatorController.set_route(this.detail_route);
        NavigatorController.set_index_point(0);
        NavigatorController.is_show_pulsar('no');
        NavigatorController.is_need_new_route('no');
    }
    static move_car_to_interpolate_point(){

        //@ts-ignore
        let distance = this.interpolation.get_distance_to_next_point();
        let index_point_on_route = NavigatorController.get_index_point_by_distance(distance)
        let pnt = this.detail_route[index_point_on_route];
        console.log(`interpolate_point distance=${distance}`)

        this.move_car(pnt);
    }
    static async process(driver_point:TypeLonLatExtended, current_route : [number[]], mapPoint: {lat:number, lng:number},
                       drawDriverRouteToMap:Function, setDebugData:Function, dispatch: any, is_low_signal: boolean){
        if (this._isLoadingNewRoute) return 'loading';

        this._setDebugData = setDebugData;
        this.current_route = current_route;
        if (!this.interpolation)  this.interpolation = new Interpolation();
        // если пришла точка с теми же данными/координатами, считаем что новой точки не было
        if (this.prev_point && this.prev_point.date == driver_point.date){
            this.showDebugInformation(driver_point);
            this.log(` same point`, driver_point);
            if (NavigatorController.is_show_pulsar() && this.prevPoint.pointOnRoute)
                this.showPulsar()
            return '';
        }
        this.prev_point = driver_point;

        NavigatorController.is_show_pulsar('no');

        let lower_data = NavigatorController.check_low_signal()
        this.is_low_signal = lower_data.is_lower;

        if (this.is_low_signal != is_low_signal)
            dispatch(setLowSignal(this.is_low_signal))


        if (this.detail_route.length == 0) {
            await this.create_new_route(dispatch, driver_point, mapPoint);
        }

        this.drawRealRoute(driver_point, drawDriverRouteToMap)


        // drawDriverRouteToMap(NavigatorController.get_median(),map, ROUTE_DRIVER_LINE_MEDIAN);

        let t = NavigatorController.get_time_difference(driver_point.date_utc ?? 0);
        let r = this.is_low_signal ? 100000 : NavigatorController.get_radius_search(t);

        let tmp = NavigatorController.get_index_nearest_point_on_route(driver_point, r < 99 ? 99 : r)
        let index_point_on_route=0, distance=0, route_distance=0;

        if (tmp)
            [index_point_on_route, distance, route_distance] = tmp;
        else {
            tmp = NavigatorController.get_index_nearest_point_on_route(driver_point)
            if (tmp) [index_point_on_route, distance, route_distance] = tmp;
            else return console.warn('не найден маршрут');
        }

        let is_good_accuracy = NavigatorController.is_good_accuracy(driver_point);
        // let is_good_distance = NavigatorController.is_good_distance(driver_point);
        let is_good_distance = driver_point.accuracy ? distance < Math.max(driver_point.accuracy + driver_point.accuracy*0.3, 19) : NavigatorController.is_good_distance(driver_point);

        let angle = NavigatorController.get_angle(driver_point)
        let middle_angle = NavigatorController.get_middle_angle()
        // let is_same_angel = NavigatorController.is_same_angel(get_angel)
        // let is_near_route = NavigatorController.is_lat_2_points_near_route(distance)
        // let side = NavigatorController.get_route_side(distance)
        this.showDebugInformation(driver_point, distance,`good_distance ${is_good_distance ? 'да' : 'нет'} <br/>` +
                                                              `good_accuracy ${is_good_accuracy ? 'да' : 'нет'} <br/>` +
                                                              `${lower_data.arr}`
                                 );

        NavigatorController.append_point(driver_point, is_good_distance)
        // if (this.is_low_signal && !is_good_distance)
        //     return this.move_car_to_interpolate_point()


        this._lastDistance = distance;
        // this._lastAccuracy = driver_point.accuracy ?? -1;
        this._lastSpeed = driver_point.speed ?? -1;
        let percent_good_points = NavigatorController.get_percent_good_points();
        let was_new_route  = false;
        if (is_good_distance)
            NavigatorController.is_need_new_route('no')
        else
        {
            if (NavigatorController.is_need_new_route()) {
                this.log(`need_new_route index_point_on_route=${index_point_on_route}, distance=${distance}, route_distance=${route_distance}`, driver_point, distance);
                await this.create_new_route(dispatch, driver_point, mapPoint)

                //TODO надо подумать что сделать после того как получили новый маршрут
                // по хорошему нужно пройти все действия заново, но если делать рекурсию можно зациклиться
                was_new_route = true;
                tmp = NavigatorController.get_index_nearest_point_on_route(driver_point);
                if (tmp)
                    [index_point_on_route, distance, route_distance] = tmp;
            } else {
                this.log(`move_car_to_interpolate_point index_point_on_route=${index_point_on_route}, distance=${distance.toFixed(2)}, `+
                 ` route_distance=${route_distance} percent_good_points=${percent_good_points} `, driver_point );
                //return this.move_car_to_interpolate_point()
                if (NavigatorController.is_show_pulsar())
                    this.showPulsar();
                return;
            }
        }



        this.move_car(this.detail_route[index_point_on_route]);
        this.showPulsar( NavigatorController.is_show_pulsar(false));

        this.log(`move_car index_point_on_route=${index_point_on_route}, distance=${distance.toFixed(2)}, route_distance=${route_distance} `+
                     ` percent_good_points=${percent_good_points} r=${r} t=${t}`, driver_point);

        if (!this.is_low_signal)
            NavigatorController.set_index_point(index_point_on_route);

        this.interpolation.append_point( {pointOnRoute:to_coordinates_array(driver_point), distance, index: index_point_on_route,
                delta: 0, date: driver_point.date?? ''})



    }
    static log(str:string, driver_point: TypeLonLatExtended, distance:number|undefined = undefined, type='log') {

        if ( type=='error')
            console.error('navigator ', str)
        if ( type=='info')
            console.info('navigator ', str)
        if ( type=='debug')
            console.debug('navigator ', str)
        else
            console.log('navigator ', str)

    }
    static process_old(driver_point:TypeLonLatExtended, current_route : [number[]], mapPoint: {lat:number, lng:number},
                   drawDriverRouteToMap:Function, setDebugData:Function, dispatch: any){
        this._setDebugData = setDebugData;
        this.current_route = current_route;

        // if (!this.kalmanFilter)  this.kalmanFilter = new KalmanFilterGPS();
        if (!this.interpolation)  this.interpolation = new Interpolation();

        // console.log('is_this_point_good ', NavigatorController.is_this_point_good(driver_point));
        console.log('this.prevPoint', this.prevPoint)



        if (this.prevPoint.date == driver_point.date){
            this.showDebugInformation(driver_point);
            this._countNoCoordinateOrBadAccuracy++;
            if (this._countNoCoordinateOrBadAccuracy > 3 )
                this.showPulsar()
            return '';
        }

        this.drawRealRoute(driver_point, drawDriverRouteToMap)


        const kalmanPoint = this.kalmanFilter ? this.kalmanFilter.process(driver_point.lat, driver_point.lon, 1, (new Date()).getTime()) : [driver_point.lat, driver_point.lon];
        let search_distance = this.prevPoint.delta * 3;
        if (driver_point.accuracy && driver_point.accuracy  < 30) {
            let delta = this._lastSpeed *  this._countNoCoordinateOrBadAccuracy;
            search_distance = 10000
        }

        this.showPulsar(  NavigatorController.is_show_pulsar(false));


        let tmpPointData = this.nearestPointOnLine(kalmanPoint, this.prevPoint.index - 100, driver_point.date ?? '', search_distance ) ;

        // если точность ужасная то находим следующую расчетную точку на маршруте
        if (this.getVal(driver_point.accuracy) > MIN_GPS_ACCURACY && this.interpolation.has_points() &&
            Math.abs(tmpPointData.index - this.prevPoint.index) > 99) {

            let distance = this.interpolation.get_distance_to_next_point();
            this.prevPoint = this.nearestPointOnLineByDistance( this.prevPoint?.index, distance, driver_point.date ?? '');

        }  else {
            if (this.prevPoint != EmptyNearestPointData) {
                let rt = this.prevPoint.index < this.detail_route.length ? this.detail_route.slice(this.prevPoint.index as number) : [];
                drawDriverRouteToMap(rt, map);
            }
            this.prevPoint = tmpPointData;
            this.interpolation.append_point({...this.prevPoint})
        }


        const {pointOnRoute, distance} = {...this.prevPoint} ;
        // нужно найти ближайшую точку и удалить от начала до нее маршрут
        this._lastDistance = distance;
        // this._lastAccuracy = driver_point.accuracy ?? -1;
        this._lastSpeed = driver_point.speed ?? -1;
        this.showDebugInformation(driver_point, distance);

        if ((!this.isNeedNewRoute(distance) || this.getVal(driver_point.accuracy) > MIN_GPS_ACCURACY) && current_route.length && pointOnRoute &&
         mapPoint == this._lastMapPoint
        ) {
            this.moveCarPosition(pointOnRoute);
        }
        else {
            if (this._isLoadingNewRoute) return ;
            console.log(`create new route isNeedNewRoute=${this.isNeedNewRoute(distance)} `+
                        ` accuracy>MIN_GPS_ACCURACY = ${this.getVal(driver_point.accuracy) > MIN_GPS_ACCURACY}`+
                        ` mapPoint == this._lastMapPoint = ${mapPoint == this._lastMapPoint}` +
                        ` current_route.length = ${current_route.length}` +
                        ` pointOnRoute = ${pointOnRoute}`
            );


            this._lastMapPoint = mapPoint;
            this._isLoadingNewRoute = true;
            let next_point: any = {lat: mapPoint.lat, lon: mapPoint.lng};
            let prev_point: any = this.prevPoint && this.prevPoint.pointOnRoute?.length ?
                                  {lat: this.prevPoint?.pointOnRoute[0], lon: this.prevPoint?.pointOnRoute[1]} : null;

            getDriverRouteToNextPoint(dispatch, [prev_point, driver_point,  next_point], (coordinates : [])=> {
                this.detail_route = coordinates;
                this.line_route = turf.lineString(coordinates);
                this._isLoadingNewRoute = false;
            } ).catch( e=> this._isLoadingNewRoute = false );
            this.count_max_distance = 0;
            this.prevPoint = EmptyNearestPointData;
            this.interpolation.clear();
        }
    }
    static move_car(pointCurrent:number[] ){

        if (!this.markerCar) {
            this.markerCar = createDomMarker(pointCurrent, 0, HereMarkerIconDriver(), MARKER_4,);
            map.addObject(this.markerCar)
        }
        let THIS = this;
        ease(
         this.markerCar.getGeometry(),
         {lat: pointCurrent[0], lng: pointCurrent[1]},
         THIS.getElapsedTime(),
         function(coord) {
             THIS.markerCar.setGeometry(coord);
             let curDate = moment(new Date());
             if (curDate.diff(THIS.lastTimeMapHandMove,"seconds") > 5)
                 map.setCenter(coord, true)
         }
        );
    };

    static moveCarPosition(pointCurrent:number[] ){

        if (!this.current_route.length) return ;

        if (!this.markerCar) {
            this.markerCar = createDomMarker(pointCurrent, 0, HereMarkerIconDriver(), MARKER_4,);
            map.addObject(this.markerCar)
        }

        if (!this.routeArchiveInfo.length) return  this.routeArchiveInfo.push({point: pointCurrent, vi:0, sit: 0});
        let last = this.routeArchiveInfo[this.routeArchiveInfo.length-1];

        let distance_to_current_point = getDistanceABLine(pointCurrent,  this.current_route[0], this.current_route as [number[]]);
        this.routeArchiveInfo.push({point:pointCurrent, vi: 0, sit: distance_to_current_point});

        if (distance_to_current_point < last.sit) return ;
        // update marker's position within ease function callback
        let THIS = this;
        // this.markerCar.setGeometry({lat: pointCurrent[0], lng: pointCurrent[1]});

        ease(
            this.markerCar.getGeometry(),
            {lat: pointCurrent[0], lng: pointCurrent[1]},
            THIS.getElapsedTime(),
            function(coord) {
                THIS.markerCar.setGeometry(coord);
                let curDate = moment(new Date());
                if (curDate.diff(THIS.lastTimeMapHandMove,"seconds") > 5)
                    map.setCenter(coord, true)
            }
        );
    };
    static getElapsedTime() {
        let time1 = this._lastDateTime;
        this._lastDateTime = (new Date()).getTime();
        let r = this._lastDateTime - time1;
        r = r ?? this.navigation_time
        return r  < this.navigation_time ? r : this.navigation_time;
    }

    static nearestPointOnLineByDistance(index:number, distance:number, point_datetime: string): NearestPointData  {
        let detail_route = [...this.detail_route]
        for (let i = index; i   < this.detail_route.length-1; i++) {
            distance -= turf.distance(turf.point(this.detail_route[i]), turf.point(this.detail_route[i+1]), 'meters');
            if (distance<0) {
                return {pointOnRoute: this.detail_route[i], distance: distance, index: i,
                        delta: i - index, date: point_datetime};
            }
        }
        return {pointOnRoute: this.detail_route[index], distance: 0, index, delta: 0, date: point_datetime};
    }

    static nearestPointOnLine(point: number[], lastIndex:number, point_datetime:string, distance: number = 100) : NearestPointData   {
        // distance = distance > 50 ? distance : 100;
        lastIndex = lastIndex < 0 ? 0 : lastIndex;
        if (!this.detail_route.length|| !Array.isArray(point) || point.length !=2)
            return {pointOnRoute: undefined, distance: 0, index: 0, delta: 0, date: point_datetime};

        let detail_route = [...this.detail_route]
        let nearestPoint, minDist = Infinity, minIndex = Infinity;
        let tPoint = turf.point(point);

        for (let i = lastIndex; i < (lastIndex + distance) && i < this.detail_route.length ; i++) {

            let distanceToPoint = turf.distance(tPoint, turf.point(this.detail_route[i]), 'meters');
            if (distanceToPoint < minDist) {
                nearestPoint = this.detail_route[i];
                minDist = distanceToPoint;
                minIndex = i;
            }
        }

        if (minDist > MAX_DISTANCE_FOR_NEW_ROUTE_IN_METERS) {
            let startIndex = lastIndex - minDist*1.5;
            for (var i = startIndex > 0 ? lastIndex : 0; i < minIndex  && i < this.detail_route.length ; i++) {
                var distanceToPoint = turf.distance(tPoint, turf.point(detail_route[i]), 'meters');
                if (distanceToPoint < minDist) {
                    minDist = distanceToPoint;
                }
            }
        }
        if (nearestPoint == undefined){
            nearestPoint = nearestPoint
        }
        return {pointOnRoute: nearestPoint, distance: minDist, index: minIndex, delta: minIndex - lastIndex, date: point_datetime};
    }

    static isNeedNewRoute(distance:number) : boolean{
        for (let i=0; i< NEED_NEW_ROUTE.length; i++) {
            this.statistic[i]  = distance >= NEED_NEW_ROUTE[i].max_distance_in_meters ? this.statistic[i] + 1 : 0;
            if (this.statistic[i] > NEED_NEW_ROUTE[i].count) {
                this.statistic.fill(0);
                return true;
            }
        }
        return false;
    }


    static getDistanceABLine = (pointA: number[], pointB: number[], line: [number[]]  ) => {
        const from = turf.point( pointA  );
        const to = turf.point( pointB);
        const way = turf.lineString( line );

        var sliced =  turf.lineSlice(from, to, way) ;
        let distance = turf.lineDistance(sliced,   "meters" );
        return distance;
    };
}
