import React, {FC} from "react";
import Button from "../../Elements/Button/Button";
import {setSystemStatus} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import {useDispatch} from "react-redux";
import {setChatVisible} from "../../../redux/reducers/chat/chatActions";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import "./AreYouOnPointWindow.css";
import {OrderFullInfo} from "../../../redux/reducers/orders/@types";
interface IProps{
    onPoint:()=> void;
    onClose:()=> void;

    order: OrderFullInfo;
    currentPoint: number;
}
const AreYouOnPointWindow :FC<IProps> = ({onPoint, onClose, order, currentPoint}) => {
    const dispatch = useDispatch();
    return (
        <div className="play_window small_window are_you_on_point_window">


            <div className="window_close_btn" onClick={onClose}><ReactSVG src={CloseSVG} /></div>
            <div className={"are_you_on_point_window__header"}>Вы прибыли на точку?</div>
            <div className="play_window_row">
                <div className="point_number">{currentPoint + 1}</div>
                <div className="point_address">{order.routes[currentPoint].adress}</div>
            </div>
            <Button type={"primary"} size={"large"} onClick={onPoint} >Я прибыл на точку</Button>
            {/*<Button type={"grey"} size={"large"} onClick={()=> dispatch(setSystemStatus(SystemStatus.Chat)) } >Открыть чат</Button>*/}
            <div className="bottom_window_problem_link"  onClick={()=>dispatch(setChatVisible(true))}>Сообщить о проблеме</div>
        </div>
    )
}

export default AreYouOnPointWindow;
