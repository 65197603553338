import React, {CSSProperties} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import {closeAllWindows, popSystemStatus} from "../../../redux/reducers/system/actions";
import "./Overlay.css";

interface IProps {
  z_index?: number;
  zIndex?:number;
  onClick?:Function;
  is_visible?:boolean;
}

const Overlay: React.FunctionComponent<IProps> = ({z_index,zIndex,onClick, is_visible=false}) => {
  const dispatch = useDispatch();
  const { overlay } = useSelector((state: RootState) => state.systemReducer);
// {overlay.visible && <div className="overlay" onClick={() => dispatch(closeAllWindows())} style={{ zIndex: overlay.zIndex }}></div>}
  return (
    <>
        <div style={{ zIndex: zIndex ? zIndex : z_index ? z_index : 35  }}
             className={ overlay.visible ||is_visible? "overlay " : 'hidden_overlay'}
             onClick={() => onClick? onClick() : dispatch(popSystemStatus())} />
    </>
  )
}

export default Overlay;
