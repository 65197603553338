import Button from "../../Elements/Button/Button";
import {EnumPhotoType} from "../OnPlacePlayWindow/OnPlacePlayWindow";
import {ReactSVG} from "react-svg";
import IconPhoto from "../../App/svg/photo2.svg";
import React, {useEffect, useState} from "react";
import {UserState} from "../../../redux/reducers/user/@types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {COrderPhoto, OrdersState} from "../../../redux/reducers/orders/@types";
import {userAPI} from "../../../api";
import {showMessageError} from "../../../redux/reducers/system/actions";
import {setOrderPhotos} from "../../../redux/reducers/orders/actions";
import MakePhotoPlayWindow from "../MakePhotoPlayWindow/MakePhotoPlayWindow";
import ImageViewWindow from "../../ImageViewWindow/ImageViewWindow";
import "./PhotoButtons.css";

interface ILocalProps {
    orderPoint :number;
}
const PhotoButtons: React.FC<ILocalProps>  = ({orderPoint}) =>{
    const dispatch = useDispatch();
    const user : UserState = useSelector((state: RootState) => ({user: state.userReducer})).user;
    const orders : OrdersState = useSelector((state: RootState) => ({orders: state.ordersReducer})).orders;

    const [photoFiles, setPhotoFiles] = useState<COrderPhoto[]>(orders.photos.filter(x=> x.point_number == orderPoint));
    const [currentImageView, showImageView] = useState<{id:string, blob:Blob}|undefined>(undefined);

    const [isOrderPhotoShow, setOrderPhotoShow] = useState<EnumPhotoType>(EnumPhotoType.UNKNOWN);

    useEffect(()=>{
        setPhotoFiles(orders.photos.filter(x=> x.point_number ==  orderPoint ));
    }, [orders.photos]);


    const savePhoto = async (blob:Blob) => {
        console.log('savePhoto');
        if (blob) {
            let res = await userAPI.setOrderPhoto(orders.current.id, user.data.driver_id, orderPoint, isOrderPhotoShow, blob).catch( e=> showMessageError('Ошибка сохранения фото<br>' + e.message, dispatch));
            if (!res) return;
            if (res.data.status !== 'success') return showMessageError('Ошибка сохранения фото<br>' + res.data.error_message, dispatch);

            dispatch(setOrderPhotos([...orders.photos, new COrderPhoto(res.data.id, orderPoint, blob)]))
        }
        setOrderPhotoShow(EnumPhotoType.UNKNOWN)
    };

    const deleteFile = async () =>{
        if (!currentImageView) return ;
        let res = await userAPI.deleteOrderPhoto(  user.data.id, currentImageView.id).catch(  e=>  showMessageError('Ошибка удаления фото<br>' + e.message, dispatch));
        if (!res) return ;

        dispatch(setOrderPhotos([...orders.photos.filter(x=>x.id!=currentImageView.id)]));
        showImageView(undefined);
    };

    if (isOrderPhotoShow) return(<MakePhotoPlayWindow title={isOrderPhotoShow == EnumPhotoType.ORDER_DOCUMENTS_PHOTO ? 'Сделать фото документов' : undefined}  onClose={()=>setOrderPhotoShow(EnumPhotoType.UNKNOWN)} onSave={savePhoto} />);
    if (currentImageView && photoFiles.length) return(<ImageViewWindow  title={"Фото кузова с грузом"} onClose={()=>showImageView(undefined)} onDelete={deleteFile} file={currentImageView.blob} />);


    return (
        <div className="photo_buttons_container">
            <div className="photo_buttons_content_title">Добавить фотографии:</div>
            <div className="photo_buttons_content_buttons">

                    <div className="btn btn_small btn_blue Button45" onClick={() =>setOrderPhotoShow(EnumPhotoType.ORDER_CARGO_PHOTO)}>
                        груза
                    </div>
                    <div className="btn btn_small btn_green Button45" onClick={() =>setOrderPhotoShow(EnumPhotoType.ORDER_DOCUMENTS_PHOTO)}>
                        документов
                    </div>

            </div>
            {photoFiles.length > 0 &&
                <div className="on_place_window__photo">
                    {photoFiles.map((x,i)=> <img key={"img_"+orderPoint+"_"+i} className="photo_button_img" src={URL.createObjectURL(x.blob)} onClick={() => showImageView(x)}/>)}
                </div>
            }
        </div>
    )
}

export default PhotoButtons;