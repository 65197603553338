export type SystemMessage = {
  id: string;
  statuses: { status: string; status_id: string; }[];
  order_id: string;
  message: string;
}

export enum EnumStatusResponse {
  success= "success",
  error = "error"
}

export type sendUsersDataType = {
  driver_id: string
  order_id: string
  group_id: string
  id: string
}

export interface ResponseCheckOrderTaking {
  id: string,
  status: "success" | "error",
  unsuitable_values:{
      "name": string,
      "description": string,
      "id": string
    }[],
  empty_values: {
    "name": string,
    "description": string,
    "id": string
  }[]
}
