import React, {useState} from "react";
import './InputCheckBoxValue.css';


interface IProps {
    name:string;
    isSet: boolean ;
    onChange: (value : boolean) => void;
    classAppend?:string;

}


const InputCheckBoxValue: React.FunctionComponent<IProps> = (props) => {
    let key = "CheckBox_"+Math.random()*1000;

    return (
        <div className={"input_checkbox_item " + (props.classAppend ? props.classAppend : '')}>
            <div className="input_checkbox_item_check">
                    <input id={key} type="checkbox" checked={props.isSet} onChange={(e)=> props.onChange(e.target.checked)} />
            </div>
            <label htmlFor={key} className="input_checkbox_item_label">{props.name}</label>
        </div>
    )
};

export  default InputCheckBoxValue;
