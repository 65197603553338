import { SystemStatus } from "../../../../@types"
import {DriverInfo, RecommendationsInfo, VehicleInfo} from "../../user/@types";

export const SET_EDIT_DRIVER = 'SET_EDIT_DRIVER';
export const SET_EDIT_VEHICLE = 'SET_EDIT_VEHICLE';
export const SET_RECOMMENDATION = 'SET_RECOMMENDATION';


export interface SetEditDriverDataAction {
  type: typeof SET_EDIT_DRIVER;
  just_passport: boolean;
  index: number;
  data: DriverInfo;
  im_a_driver:boolean;
}
export interface SetEditVehicleDataAction {
  type: typeof SET_EDIT_VEHICLE;
  index: number;
  data: VehicleInfo;

}export interface SetRecommendationDataAction {
  type: typeof SET_RECOMMENDATION;
  recommendations: RecommendationsInfo[];
}



export type TemporaryActionTypes = SetEditDriverDataAction | SetEditVehicleDataAction | SetRecommendationDataAction;

export interface TemporaryState {
    edited_driver: { just_passport: boolean; index: number; data: DriverInfo; im_a_driver: boolean} ;
    edited_vehicle: { index: number; data: VehicleInfo } ;
    recommendations: RecommendationsInfo[];
}
