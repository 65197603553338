import React, {useEffect, useState} from "react";
import { useDispatch, useSelector} from "react-redux";
import { RootState } from "../../redux/store";

import { FoldableBlock } from "../../components/Elements/elements";
import { ScreenTab } from "./Screen/Screen";

import "./Profile.css";
import ScreenWindow from "./Screen/ScreenWindow";
import { popSystemStatus } from "../../redux/reducers/system/actions";

import RowScreenTab from "./Screen/RowScreenTab/RowScreenTab";

import {
 	emptyPassport,
	emptyLicense,
	emptyCertificate
} from "../Elements/Containers/@types";
import {RegistrationStatus, SystemStatus, UserStatus} from "../../@types";
import {UserState} from "../../redux/reducers/user/@types";



interface IProps {
	zIndex?:number;
}
interface ILocalState {
	passport_errors: string,
	transport_errors: string,
	contract_errors: string,
	car_list : any[],
	drivers_list: any[]
}

const Profile: React.FunctionComponent<IProps> = ({ zIndex}) => {

  const user : UserState = useSelector((state: RootState) => ({user: state.userReducer})).user;

  const dispatch = useDispatch();

	const [localState, setLocalState] = useState<ILocalState>({
		passport_errors: "",
		transport_errors: "",
		contract_errors: "Реквизиты и подписи",
		car_list : [],
	    drivers_list: []
	});

	// let { passport_errors,transport_errors, contract_errors, car_list, drivers_list } = localState;

	useEffect(() => {
		initData();

  }, [zIndex]);


	const initData = () => {
	 /*
		if (finalize.owner) {
			let car_list: any[] = [];
			if (finalize.owner.vehicles && finalize.owner.vehicles.length) {
				car_list = finalize.owner.vehicles.map((car, i) => (
					<ScreenTab key={'car' + i} title={`${car.certificate.brand_model} ${car.certificate.license_plate}`}
							   icon="next" onClick={() => {
						setAssigningDriver(false);
						saveVehicle(car);
						toVehicle();
					}}
					/>
				));
			}
			setLocalState((s) => ({...s, car_list}));

			let drivers_list: any[] = [];
			if (finalize.owner.drivers && finalize.owner.drivers.length) {
				drivers_list = finalize.owner.drivers.map((driver, index) => (
					<ScreenTab key={'drv' + index}
							   title={driver.id === finalize.owner.id ? "Вы" :
								   driver.passport.name} description={"Категории " + driver.license.categories.join(", ")}
							   icon="next" onClick={() => {
						setIsOwner(false);
						saveDriver(driver);
 						toPassportData();
					}}
					/>
				));
			}
			setLocalState((s) => ({...s, drivers_list}));
		}
		if (state.data) {
			if (!state.data.passport) setLocalState((s) => ({...s, passport_errors: "Отсутствуют паспортные данные"}));
			else if (!state.data.passport.full_name ||
				!state.data.passport.birth_date ||
				!state.data.passport.series ||
				!state.data.passport.number ||
				!state.data.passport.department_code ||
				!state.data.passport.issue_date ||
				!state.data.passport.adress ||
				!state.data.passport.real_adress) setLocalState((s) => ({
				...s,
				passport_errors: "Паспортные данные заполнены не полностью"
			}));
			else if (!state.data.passport.files.length) setLocalState((s) => ({
				...s,
				passport_errors: "Не прикреплены фото документов"
			}));

		}

	  */
	}

	let closeWindow = () => {
		dispatch(popSystemStatus())
	};

	const toLicenseData = () => {
		//setRegistrationStatus(RegistrationStatus.LicenseData);
	}

	const toPassportData = () => {
		//setRegistrationStatus(RegistrationStatus.PassportData);
	}

	const toVehicle = () => {
		//setRegistrationStatus(RegistrationStatus.Vehicle);
	}


	return (
  	<>
		{user.data &&
		<ScreenWindow title={user.data.passport.full_name}
					  status={user.status === UserStatus.Working ? "Статус: работаю" : "Статус: не работаю"}
					  onClose={closeWindow}>
			<div className={'screen_scroll'}>
				<RowScreenTab title="Договор" warning={localState.contract_errors} icon="next"/>
				{localState.passport_errors &&
				<RowScreenTab title="Паспорт" warning={localState.passport_errors} icon="next"
							  onClick={() => {
								  toPassportData();
							  }}
				/>}
				{localState.transport_errors &&
				<RowScreenTab title="Транспортные средства" warning={localState.transport_errors} icon="next"/>}

				<FoldableBlock title="Ваши ТС" style={{marginTop: "3rem"}}>
					{localState.car_list.length>0 && localState.car_list}
					<ScreenTab title={<span style={{color: "#888e99", fontWeight: 400}}>Добавить ТС</span>} icon="plus"
							   onClick={() => {
							   }}
					/>
				</FoldableBlock>
				<FoldableBlock title="Водители" style={{margin: "3rem 0"}}>
					{localState.drivers_list.length>0 && localState.drivers_list}
					<ScreenTab title={<span style={{color: "#888e99", fontWeight: 400}}>Добавить водителя</span>}
							   icon="plus"
							   onClick={() => {
							   }}
					/>
				</FoldableBlock>
			</div>
		</ScreenWindow>
		}

	  </>
  )
};

export default Profile;

