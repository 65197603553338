class TouchWorker{
    deltaTouch : number = 0;
    setMinSize : Function | null = null;
    constructor(setMinSize: Function) {
        this.setMinSize = setMinSize;
    }
    onMouseMove = (e: any) => {
        if (this.deltaTouch == 0 && e.changedTouches.length >0) this.deltaTouch =  e.changedTouches[0].clientY};
    onMouseEnd = (e: any) => {
        console.log('mooving', e.changedTouches[0].clientY)
        if (e.changedTouches.length <= 0) return;
        if (e.changedTouches[0].clientY - this.deltaTouch > 30 && this.setMinSize)
            this.setMinSize(  true);
        if (e.changedTouches[0].clientY - this.deltaTouch < -19 && this.setMinSize)
            this.setMinSize(  false);
        console.log(e.changedTouches[0].clientY - this.deltaTouch)
        this.deltaTouch = 0;
    };
}

export  default TouchWorker;