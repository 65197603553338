import {SystemStatus} from "../../../@types"
import {
  SET_EDIT_VEHICLE, SET_EDIT_DRIVER, TemporaryActionTypes, TemporaryState, SET_RECOMMENDATION
} from "./@types";
import {DriverInfo, RecommendationsInfo, VehicleInfo} from "../user/@types";

const initialState: TemporaryState = {
   edited_driver: { just_passport: false, index: 0, data: new DriverInfo(), im_a_driver: true},
   edited_vehicle: {  index: 0, data: new VehicleInfo()},
  recommendations: [],
}


const temporaryReduser = (state = initialState, action: TemporaryActionTypes) => {
  switch (action.type) {
    case SET_EDIT_DRIVER:
      return {
        ...state,
        edited_driver: { just_passport: action.just_passport, index: action.index, data: action.data, im_a_driver :action.im_a_driver}
      };

    case SET_EDIT_VEHICLE:
      return {
        ...state,
        edited_vehicle: { index: action.index, data: action.data}
      };

    case SET_RECOMMENDATION:
      return {
        ...state,
        recommendations:  action.recommendations
      };

    default:
      return state;
  }
}


export default temporaryReduser;
