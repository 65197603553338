import React, { useRef, useState, useEffect } from "react";
import "./Camera.css";
import {awaitExpression} from "@babel/types";


interface IProps {
	facingMode: string;
	title: string;
	box: boolean;
	content: {width: number, height: number};
	pictures: Blob[];
	onVisible: () => void;
	onError?: () => void;
	onCapture: (blob: Blob) => void;
	onClear?: () => void;

}

let videoMode = ["user", "environment", "left", "right"];
let getVideoMode = ( function(){
	let index = 0;
	return function (){
		index = index > 2 ? 0 : index + 1;
		return index;
	}
})()

const Camera: React.FunctionComponent<IProps> = (props) => {

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const canvasHiddenRef = useRef<HTMLCanvasElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [isFlashing, setIsFlashing] = useState(false);
	const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

	useEffect(() => {

		async function enableStream() {

			try {

				var constraints: any = {
					"audio": false,
					"video": {
						width: { ideal: 1280 },
						height: { ideal: 1024 },
						facingMode:  props.facingMode
					},
				};

				const stream = await navigator.mediaDevices.getUserMedia(constraints);
				setMediaStream(stream);
			} catch(err) {
				if (props.onError) {
					console.log(err);
					props.onError();
					return ;
				}
			}
		};

		if (!mediaStream) {
			console.log('mediaStream');
			enableStream();
		} else {
			return function cleanup() {
				if (mediaStream) {
					mediaStream.getTracks().forEach((track: any) => {
						track.stop();
					});
					setMediaStream(null);
				}
			}
		}
	}, [mediaStream, props.facingMode]);

	useEffect(() => {
		if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
			videoRef.current.srcObject = mediaStream;
			console.log(videoRef.current.videoWidth, videoRef.current.videoHeight,  props.content)
		}
	}, [mediaStream]);

	const handleCanPlay = () => {
		setIsVideoPlaying(true);
		if (videoRef && videoRef.current)
			videoRef.current.play();
		props.onVisible();
	}

	const handleCapture = () => {
		const context = canvasRef?.current?.getContext("2d");

		if (context && videoRef.current && props.content) {
			console.log(videoRef.current.videoWidth, videoRef.current.videoHeight,  props.content);
			context.canvas.width = videoRef.current.videoWidth;
			context.canvas.height = videoRef.current.videoHeight;
			context.drawImage(
				videoRef.current,
				0,
				0,
				videoRef.current.videoWidth,
				videoRef.current.videoHeight,
				0,
				0,
				videoRef.current.videoWidth,
				videoRef.current.videoHeight,
			);
		}

		canvasRef?.current?.toBlob((blob) => {
			if (blob) props.onCapture(blob);
		}, "image/jpeg", 100);
		setIsFlashing(true);
		handleClear();
	}

	const handleClear = () => {
		const context = canvasRef?.current?.getContext("2d");
		if (context && canvasRef.current) {
			context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
			if (props.onClear) props.onClear();
		}
	}

	if (!mediaStream) {
		return null;
	}

	return (
		<div className="camera_wrapper">
			<video
				className="camera_video"
				ref={videoRef}
				onCanPlay={handleCanPlay}
				autoPlay
				playsInline
				muted
				hidden={!isVideoPlaying}
			/>

			<div className="camera_overlay_container">
				<div className="camera_subtitle">{props.title}</div>
				<div className={'camera_overlay' + (props.box ? ' camera_box' : '')}
					style={{
						'width': props.content?.width || 0,
						'height': props.content?.height || 0
					}}>
					<canvas
						ref={canvasRef}
						className="camera_canvas"
						hidden={isVideoPlaying}
						width={640}
						height={480}
					/>
					<div className={'camera_flash' + (isFlashing ? ' camera_flashing' : '')}
						onAnimationEnd={() => setIsFlashing(false)}>
					</div>
				</div>
				<div className="camera_image_container">
					{props.pictures.map((picture, index) => (
						<img key={'camera_image_' + index} alt=""
							className="camera_image" src={URL.createObjectURL(picture)} />
					))}
				</div>
			</div>
			{isVideoPlaying && (
				<div className="camera_capture"
					onClick={handleCapture}>
					<div className="camera_capture_inner"/>
				</div>
			)}
		</div>
	);
}

export default Camera;
