import {
  AdditionalRequirement,
  BodyOption,
  BodyOptionCharacteristic,
  BodyOptionCharacteristicValue,
  BodyType,
  CarType, COrderPhoto,
  Order,
  OrdersActionTypes,
  SET_ADDITIONAL_REQUIREMENTS,
  SET_ASSIGNED_ORDER,
  SET_BODY_OPTIONS,
  SET_BODY_OPTIONS_CHARACTERISTICS,
  SET_BODY_OPTIONS_CHARACTERISTICS_VALUES,
  SET_BODY_TYPES,
  SET_CAR_TYPES,
  SET_ORDER,
  SET_ORDER_PHOTOS,
  SET_ORDERS,
  SetOrderPhotosAction
} from "./@types";
import {OrdersListType} from "../../../@types";

export const setOrders = (orders: Order[]): OrdersActionTypes => ({
  type: SET_ORDERS,
  orders
});

export const setBodyOptionCharacteristics = (body_option_characteristics: BodyOptionCharacteristic[]): OrdersActionTypes => ({
  type: SET_BODY_OPTIONS_CHARACTERISTICS,
  body_option_characteristics
});

export const setBodyOptionCharacteristicsValues = (body_option_characteristics_values: BodyOptionCharacteristicValue[]): OrdersActionTypes => ({
  type: SET_BODY_OPTIONS_CHARACTERISTICS_VALUES,
  body_option_characteristics_values
});

export const setBodyTypes = (body_types: BodyType[]): OrdersActionTypes => ({
  type: SET_BODY_TYPES,
  body_types
});

export const setBodyOptions = (body_options: BodyOption[]): OrdersActionTypes => ({
  type: SET_BODY_OPTIONS,
  body_options
});

export const setCarTypes = (car_types: CarType[]): OrdersActionTypes => ({
  type: SET_CAR_TYPES,
  car_types
});



export const setAdditionalRequirements = (additional_requirements: AdditionalRequirement[]): OrdersActionTypes => ({
  type: SET_ADDITIONAL_REQUIREMENTS,
  additional_requirements
});

export const setOrder = (order: object, orderType: OrdersListType): OrdersActionTypes => ({
  type: SET_ORDER,
  order,
  orderType,
});

export const setAssignedOrder = (order: object): OrdersActionTypes => ({
  type: SET_ASSIGNED_ORDER,
  order
});

export const setOrderPhotos = (photos: COrderPhoto[]): SetOrderPhotosAction => ({
  type: SET_ORDER_PHOTOS,
  photos
});
