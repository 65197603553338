import {
    ADD_POINT_ON_MAP,
    MapDataActionTypes,
    MapDataState,
    MapPoint,
    REMOVE_POINT_FROM_MAP,
    REMOVE_ROUTE,
    SET_DRIVER_CURRENT_ROUTE,
    SET_DRIVER_CURRENT_ROUTE_TIME,
    SET_DRIVER_POSITION,
    SET_INDEX_NEXT_POINT,
    SET_LAST_TIME_MAP_HAND_MOVE, SET_LOW_SIGNAL,
    SET_MAP_CENTER,
    SET_NAVIGATOR_MODE,
    SET_POINTS_ON_MAP,
    SET_ROUTE,
    SET_ROUTE_ARCHIVE,
    SET_ROUTE_TO_POINT,
    SET_SHOW_CURRENT_ROUTE_ON_MAP,
    UPDATE_POINT_ON_MAP
} from "./@types";
import moment from "moment";

let initialState: MapDataState = {
    points: [],
    use_data_from_1c: false,
    center: {lat:0,lng:0,zoom:0},
    route: null,
    archive_route:[],
    route_to_point:[],
    driver_point: undefined,
    driver_current_route: [],
    index_next_point: -1,
    navigator_mode_is_on: false,
    show_current_route: {yes:false},
    driver_current_route_time: 0,
    last_time_map_move: moment(new Date()),
    is_low_signal: false
};

const MapDataReducer = (state = initialState, action: MapDataActionTypes) => {
    switch (action.type) {
        case SET_POINTS_ON_MAP:

            return {
                ...state,
                points: [...action.points ]
            };
        case ADD_POINT_ON_MAP:
            return {
                ...state,
                points: [...state.points, action.point]
            };
        case UPDATE_POINT_ON_MAP:

            let updPoints : MapPoint[] = [...state.points];
            if (action.index < state.points.length)
                updPoints[action.index] = {...action.point};
            return {
                ...state,
                points: updPoints
            };
        case REMOVE_POINT_FROM_MAP:
            return {
                ...state,
                points: action.index < state.points.length ? [...state.points].slice(action.index, 1) :  state.points
            };

        case SET_ROUTE_ARCHIVE:
            return {
                ...state,
                archive_route: action.route
            };

        case SET_ROUTE:
            return {
                ...state,
                route: action.route
            };

        case REMOVE_ROUTE:
            return {
                ...state,
                route: null
            };

        case SET_MAP_CENTER:
            return {
                ...state,
                center: action.point
            };
        case SET_DRIVER_POSITION:
            return {
                ...state,
                driver_point: action.driver_point
            };

        case SET_DRIVER_CURRENT_ROUTE:
            return {
                ...state,
                driver_current_route:  action.driver_current_route
            };
        case SET_INDEX_NEXT_POINT:
            return {
                ...state,
                index_next_point:  action.index_next_point
            };

        case SET_NAVIGATOR_MODE:
            return {
                ...state,
                navigator_mode_is_on:  action.navigator_mode_is_on
            };

        case SET_ROUTE_TO_POINT:
            return {
                ...state,
                route_to_point: [action.point1,action.point2]
            };
        case SET_SHOW_CURRENT_ROUTE_ON_MAP:
            return {
                ...state,
                show_current_route: {yes: action.show_current_route},
                navigator_mode_is_on: false
            };

        case SET_LAST_TIME_MAP_HAND_MOVE:
            return  {
            ...state,
            last_time_map_move: action.last_time_map_move
        };
        case SET_DRIVER_CURRENT_ROUTE_TIME:
            return {
                ...state,

                driver_current_route_time: action.driver_current_route_time
            };
        case SET_LOW_SIGNAL:
            return {
                ...state,

                is_low_signal: action.is_low_signal
            };
        default:
            return state;
    }
};


export default MapDataReducer;
