import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderCard from "./OrderCard/OrderCard";
import "./OrdersWindow.css";

import {ordersAPI} from "../../api";
import {OrdersListType, SystemStatus} from "../../@types";
import {RootState} from "../../redux/store";
import {setOrders} from "../../redux/reducers/orders/actions";
import {isSystemStatusActive} from "../../redux/reducers/system/reducers";
import BottomWindow from "../Elements/BottomWindow/BottomWindow";
import Loader from "react-loader-spinner";
import {wait_before_update} from "../../redux/reducers/system/system_functions";
import {UPDATE_ORDER_WINDOW_LIST_INTERVAL} from "../../deployment";
import {showMessageError} from "../../redux/reducers/system/actions";

let loadOrderListInterval : any = undefined;
export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrdersWindow: React.FC = () => {
  const dispatch = useDispatch();

  const { user, orders, system } = useSelector((state: RootState) => ({
    user: state.userReducer,
    orders: state.ordersReducer,
    system: state.systemReducer
  }));

  const [localType, setType] = useState<OrdersListType>( OrdersListType.Set),
  [isLoading, setLoading] = useState<boolean>( true),
  [countOrders, setCountOrders] = useState<{ set:number, offer:number, free:number }>( {set:0, offer:0, free:0});


  // в случае изменения в заказах стирает пользователей
  useEffect(() => {
      setCountOrders({
          set: orders.items.filter(x=>x.type == OrdersListType.Set).length,
          offer: orders.items.filter(x=>x.type == OrdersListType.Offer).length,
          free: orders.items.filter(x=>x.type == OrdersListType.Free).length
      });
  }, [orders.items])

  //--------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------
  const loadOrderList = async (is_need_update: boolean) => {
      setLoading(true);

      let res = await wait_before_update(1000);
      if (!res) return;
      // if (is_change_type || (orders.items.length % 10) == 0) {
      //
      //     ordersAPI.getList(user.driver_id, localType, is_change_type ? 0 : orders.items.length ).then((res) => {
      //         if (Array.isArray(res.data)) {
      //             if (!is_change_type) {
      //                 let itm = [...orders.items].concat(res.data);
      //                 dispatch(setOrders(itm));
      //             } else
      //                 dispatch(setOrders(res.data));
      //         }
      //         setLoading(false);
      //     }).catch(()=>setLoading(false));
      // } else  setLoading(false);
      ordersAPI.getList(user.driver_id,  OrdersListType.OnComing ,   0 , 1000  ).then((res) => {
          if (Array.isArray(res.data)) {
              let needUpdate = orders.items.length != res.data.length;
              if (!needUpdate)
                  res.data.forEach( (x:any) => {
                      if (!orders.items.some(z=> z.id == x.id))
                          needUpdate = true;
                  });
              if (needUpdate || is_need_update)
                dispatch(setOrders(res.data));

              console.log('needUpdate', needUpdate);
              console.log('loadOrderList user.driver_id=', user.driver_id, res.data);
          }
          setLoading(false);
      }).catch(( e)=> {showMessageError("Ошибка получения списка заказов!", dispatch); setLoading(false)});

  }

  useEffect( ()  => {

    loadOrderList(true );
    if (loadOrderListInterval) clearInterval(loadOrderListInterval);
    loadOrderListInterval = setInterval(()=> loadOrderList(false), UPDATE_ORDER_WINDOW_LIST_INTERVAL);

    return  () => {
        if (loadOrderListInterval) clearInterval(loadOrderListInterval)
    }
  }, [user.driver_id]);
  useEffect( ()  => {
      if (!system.reload_order_list.order_id) return ;
     let or = [...orders.items];
      or.filter(x=> x.id == system.reload_order_list.order_id).forEach(x=>x.type = OrdersListType.Set);
      dispatch(setOrders(or));
  }, [system.reload_order_list]);

    // useEffect( ()  => {
    //     console.log('system.statuses', system.statuses);
    //     if (isSystemStatusActive(SystemStatus.Main, system.statuses) && system.statuses.length==1) {
    //         dispatch(setOrders([]));
    //         loadOrderList(true);
    //     }
    // }, [system.statuses]);

  // console.log( orders.items, orders.items.length);
  const orders_sum : number = orders && orders.items && Array.isArray(orders.items) && orders.items.length>0 ? orders.items.reduce((sum, current) => (sum + current.price), 0) : 0;

  const checkOrderType = (orderType:OrdersListType)=>{
      if (orderType == localType) return true;
      if (localType == OrdersListType.Offer && orderType == OrdersListType.Free) return true;
      return false
  }
  const onScrollList = (event: any)=>{

        const scrollBottom = event.target.scrollTop > 0 && (event.target.scrollTop +  event.target.offsetHeight) >= (event.target.scrollHeight - 30);
        const scrollRight = event.target.scrollLeft > 0 && (event.target.scrollLeft  +  event.target.offsetWidth) >= (event.target.scrollWidth - 30);
        if (scrollBottom || scrollRight) {
            //console.log('scroll', scrollBottom, scrollRight,event.target.scrollTop);
            //loadOrderList(false);
         }
      // console.log('scroll-left',  event.target.scrollLeft ,  event.target.offsetWidth , event.target.scrollWidth)
  }

  if (isSystemStatusActive(SystemStatus.FullOrderCard, system.statuses))
    return (<></>);
//   console.log('orders.items', orders.items);
  if (!user.authenticated) {
    return (
      <>
        <BottomWindow
          title={`${orders.items.length} доступных заказов`}
          description={`Стоимостью до ${numberWithCommas(Math.round(orders_sum))} ₽`}
          resizable={true}
          visible={system.ordersWindow}
          style={{ background: "#ecedef" }}
        >
          <div className="short_content">
            <div className="orders_list" onScroll={event => onScrollList(event)} style={{ transition: 'ease 0.5s'}}>
              {orders.items.map((item, i) => (
                <OrderCard key={'ff' + item.id} order={item} isAuth={false} isLooked={false}
                           user_coordinate={user.current_coordinates}  orderType={localType}/>
              ))}
            </div>
            {/*<OrdersMenu setChatOpen={setChatOpen} />*/}
          </div>

          <div className="full_content" onScroll={event => onScrollList(event)} style={{ transition: 'ease 0.5s'}}>
            <div className="orders_list" onScroll={event => onScrollList(event)} style={{ transition: 'ease 0.5s'}}>
              {orders.items.map((item, i) => (
                <OrderCard key={'ful_card' + item.id} order={item} isAuth={false} isFull={true} isLooked={false}
                           orderType={localType} user_coordinate={user.current_coordinates} />
              ))}
            </div>
          </div>
        </BottomWindow>

      </>

  );
  } else {
    return (
      <>
        <BottomWindow title="Список заказов" resizable={true} visible={system.ordersWindow} style={{ background: "#ecedef" }}>
          <div className="orders_tabs">
            {/*<div className={localType === OrdersListType.OnComing ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.OnComing)}>Все</div>*/}
            <div className={localType === OrdersListType.Set ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Set)}>
                <span className="orders_tab_count">{countOrders.set > 99 ? 99 : countOrders.set }</span>
                Назначенные
            </div>
            <div className={localType === OrdersListType.Offer ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Offer)}>
                <span className="orders_tab_count">{(countOrders.offer + countOrders.free) > 99 ? 99 : (countOrders.offer + countOrders.free) }</span>
                Предложенные
            </div>
          </div>

          <div className="short_content">
            <div className="orders_list"  onScroll={event => onScrollList(event)} style={{ transition: 'ease 0.5s'}} >
              {orders.items.filter(x=> checkOrderType(x.type)).length == 0 && !isLoading && <div className="order_block short_order_block loader-order empty-block"> Заказы не найдены </div>}
              {orders.items.filter(x=> checkOrderType(x.type)).map((item, i) => (
                <OrderCard key={'card' + item.id + i} order={item} isLooked={item.is_looked}
                           orderType={localType} user_coordinate={user.current_coordinates} />
              ))}

              {orders.items.filter(x=> checkOrderType(x.type)).length == 0 && isLoading &&
                <div className="order_block short_order_block loader-order">
                  <Loader type="ThreeDots" color="#fff" width={'8rem'} height={'5rem'} />
                </div>
              }
            </div>
          </div>

          <div className="full_content"  onScroll={onScrollList} style={{ transition: 'ease 0.5s'}}>
            <div className="orders_list" onScroll={onScrollList} style={{ transition: 'ease 0.5s'}}>
              {orders.items.filter(x=> checkOrderType(x.type)).map((item, i) => (
                <OrderCard key={'full_card' + item.id} order={item} isFull={true} isLooked={item.is_looked}
                           orderType={localType} user_coordinate={user.current_coordinates} />
              ))}
              {isLoading &&
                  <div className="order_block full_order_block loader-order">
                    <Loader type="ThreeDots" color="#fff" width={'8rem'} height={'5rem'} />
                  </div>
              }
            </div>
          </div>
        </BottomWindow>

      </>
    );
  }
}

export default OrdersWindow;
