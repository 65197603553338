import React from "react";
import { AdditionalRequirement, BodyOptionCharacteristic, BodyOptionCharacteristicValue, BodyType, CarType } from "../../../redux/reducers/orders/@types";
import "./VehicleRequirements.css";

interface IProps {
  bodyTypes: BodyType[],
  carTypes: CarType[],
  bodyOptionCharacteristics: BodyOptionCharacteristic[],
  bodyOptionCharacteristicsValues: BodyOptionCharacteristicValue[],
  additionalRequirements: AdditionalRequirement[],
  orderData: any
}

const VehicleRequirements: React.FC<IProps> = (props) => {
  const {
    bodyTypes,
    carTypes,
    bodyOptionCharacteristics,
    bodyOptionCharacteristicsValues,
    additionalRequirements,
    orderData
  } = props;

  // Возвращает минимальную массу
  const getMinWeight = (): number => {

    if (!carTypes) return 0;

    const carType = carTypes.find((item) => item.id === orderData.car_type_id);
    return (carType) ? carType.weight_to : 0;
  }

  // Возвращает название типа кузова
  const getBodyTypeName = (): string => {
    const bodyType = Array.isArray(bodyTypes) ? bodyTypes.find((item) => item.id === orderData.body_type_id) : undefined;
    return (bodyType) ? bodyType.name : "Неизвестен";
  }

  // Возвращает массив требований к ТС
  const getBodyOptionCharacteristics = (): { id: string; name: string; value: string; }[] => {
    const result: { id: string; name: string; value: string; }[] = [];
    const characteristics = (orderData.body_option_characteristics) ? orderData.body_option_characteristics : [];
    const booleanWords: any = {
      "false": "Нет",
      "true": "Да"
    };

    characteristics.forEach((item: { id: string; value: string; }) => {
      const characteristicOption = bodyOptionCharacteristics.find((row) => row.id === item.id);
      const characteristicValue = (characteristicOption?.type === "Boolean") ? booleanWords[item.value] : bodyOptionCharacteristicsValues.find((row) => row.id === item.value)?.name;

      if (characteristicOption && characteristicValue) {
        result.push({
          id: item.id,
          name: characteristicOption.name,
          value: characteristicValue
        });
      }
    });

    return result;
  }

  // Возвращает массив дополнительных требований
  const getAdditionalRequirements = (): { id: string; name: string; }[] => {
    const result: { id: string; name: string; }[] = [];
    const requirements = (orderData.additional_requirements) ? orderData.additional_requirements : [];

    requirements.forEach((item: { id: string; value: string; }) => {
      const additionalRequirement = additionalRequirements.find((row) => row.id === item.id);

      if (additionalRequirement && additionalRequirement.type === "Boolean" && item.value === "true") {
        result.push({
          id: item.id,
          name: additionalRequirement.name
        });
      }
      if (additionalRequirement && additionalRequirement.type !== "Boolean") {
        result.push({
          id: item.id,
          name: additionalRequirement.name
        });
      }
    });

    return result;
  }

  const currentAdditionalRequirements = getAdditionalRequirements();
  const currentBodyOptionCharacteristics = getBodyOptionCharacteristics();

  return (
    <div className="order_info_section">
      <div className="order_info_section_name">Требования к ТС</div>
      <div className="order_info_vehicle_requirements">
        <div className="order_info_vehicle_grid order_info_vehicle_header">
          <div className="vehicle_grid_item">
            <div className="vehicle_grid_item_name">Мин. масса</div>
            <div className="vehicle_grid_item_value_700">{getMinWeight()}</div>
          </div>
          <div className="vehicle_grid_item">
            <div className="vehicle_grid_item_name">Тип кузова</div>
            <div className="vehicle_grid_item_value">{getBodyTypeName()}</div>
          </div>
        </div>
        {currentBodyOptionCharacteristics.filter(x=> x.value !='Нет').length>0 &&
          <div className="order_info_vehicle_grid">
            {currentBodyOptionCharacteristics.filter(x=> x.value !='Нет').map((item) => {
              return (
                <div className="vehicle_grid_item" key={item.id}>
                  <div className="vehicle_grid_item_name">{item.name}</div>
                  <div className="vehicle_grid_item_value">{item.value}</div>
                </div>
              );
            })}
          </div>
        }
        {currentAdditionalRequirements.length > 0 &&
          <div className="order_info_vehicle_footer">
            <div className="vehicle_grid_item">
              <div className="vehicle_grid_item_name">Дополнительно</div>
              <div className="vehicle_grid_item_value">
                <ul>
                  {currentAdditionalRequirements.map((item) => <li key={item.id}>{item.name}</li>)}
                </ul>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default VehicleRequirements;
