import React, { FC } from "react"
import {IChatCommands} from "../../../redux/reducers/chat/@types";

interface IProps {
    command: IChatCommands
    onSelectCommand:(command: IChatCommands) => void

}

export const Command: FC<IProps> = ({ command, onSelectCommand}) => {
     return (
        <div className={'chat-command-item'} onClick={()=>onSelectCommand(command)} >
            {command.name}
        </div>
    )
};




