import React, {useEffect, useRef, useState} from "react";
import {editingChars} from "../../../@types";
import './InputAddressValue.css';

import {DaData} from "../../Elements/elements";
import {addressAPI, getAddress} from "../../../api";

interface IProps {
    name:string;
    value: string ;
    onValueChanged: (value: string | number, lon?:number, lat?:number) => void;
    placeholder:string;
    classAppend?:string;
    wasError?:boolean;
}

interface LocalState {
    dadata:any;
    house:string;
    block:string;
    flat:string;
    wasError?:boolean;
}


const InputAddressValue: React.FunctionComponent<IProps> = ({ name, value, onValueChanged, placeholder,  classAppend="",  wasError=false} ) => {

    let [isError, setError] = useState(false);

    let [localValue, setLocalValue] = useState<string>(value ? '' + value  : '');
    // let [localValue, setLocalValue] = useState<string>("Россия, г Санкт-Петербург, Адмиралтейский пр-кт, д 11, к 1, кв 2");
    let [state, setLocalState] = useState<LocalState>({dadata:null, house:'', block:'', flat:''});

    const registration = useRef<HTMLInputElement>(null);
    const registrationHouse = useRef<HTMLInputElement>(null);


    const setDaDataAddress = (dadata:any) => {
        let st = {...state, dadata: dadata};
        setLocalState(st);
        onStringValueChanged(st);
    };

    const setHouse = (house:string) =>  {
        let st = {...state, house: house};
        setLocalState(st);
        onStringValueChanged(st);
    }
    const setBlock = (block:string) => {
        let st = {...state, block: block};
        setLocalState(st);
        onStringValueChanged(st);
    }
    const setFlat = (flat:string) =>  {
        let st = {...state, flat: flat};
        setLocalState(st);
        onStringValueChanged(st);
    }




    const onStringValueChanged = (newState : any) =>{
        if (!state.dadata || !state.dadata.data) return  onValueChanged('');
        let val = getStreetValue();
        console.log(state.dadata);
        if (state.house)  val +=', д ' + state.house;
        if (state.block)  val +=', к ' + state.block;
        if (state.flat)   val +=', кв ' + state.flat;

        onValueChanged(val, state.dadata.geo_lon, state.dadata.geo_lat);

    };

    const isFullAddress = () =>{
        return state.dadata && isStreetEntered() && state.house ;
    }

    const isStreetEntered = (data: any = null) =>{
        let daData = data ? data : state.dadata;
        let val = daData && ((daData.data.city &&  daData.data.settlement) || (daData.data.city && daData.data.street));
        return val;
    }

    const getStreetValue = () =>{
        if (!state.dadata || !state.dadata.data) return '';
        let val = state.dadata.data.country;
        if (state.dadata.data.city) val +=', ' + state.dadata.data.city_with_type;
        else if (state.dadata.data.settlement) val +=', ' + state.dadata.data.settlement;
        if (state.dadata.data.street) val +=', ' +state.dadata.data.street_with_type;
        return val;
    }
    const getAddressValue = () =>{
        // state.registration && state.registration !== undefined && state.registration.data ?
        //     state.registration.data.country + (state.registration.data.city_fias_id ?
        //     ', ' + state.registration.data.city_with_type :
        //     state.registration.data.settlement_fias_id ?
        //         ', ' + state.registration.data.settlement_with_type : '') +
        //     (state.registration.data.street_fias_id ? ', ' +
        //         state.registration.data.street_with_type : '') : ''
    }

     useEffect( () => {
        if (localValue == "") return;

        const loadAddress = async () => {
            let data = await getAddress(localValue);
            //console.log(data);
            // let data = await addressAPI.getSuggestions(localValue);
            // console.log('was load adress', localValue, data);
            if (data && data.data ) {
                //console.log(data.data);
                let st = {...state};
                st.dadata = data;

                if (data.data.house) st.house = data.data.house;
                if (data.data.flat) st.flat = data.data.flat;
                if (data.data.block) st.block = data.data.block;
                setLocalState(st);

            }

        };
         loadAddress();

    }, [localValue]);

    useEffect(()=>{
        setError(wasError && (!isStreetEntered() || !state.house))
    },[ wasError]);

    return (
        <div className={classAppend ? classAppend : 'input_filtered_full'}>
            <div className='input_filtered_container_name'>{name}</div>
            <div className={'input_filtered_container '  + (isFullAddress() ? ' complete' : (isError ? ' error' : ''))}>
                     <DaData ref={registration}
                            value={getStreetValue()}
                            inputClassName="input_filtered_text"
                            placeholder={placeholder}
                            onBlur={async () => {
                                if (registration.current && registration.current.value !== '') {
                                    let data = await addressAPI.getSuggestions(registration.current.value);

                                    if (data && data.data && data.data.suggestions && data.data.suggestions.length) {
                                       // console.log(data.data);
                                        await setDaDataAddress(data.data.suggestions[0]);
                                        setError( !isStreetEntered(data.data.suggestions[0]));
                                        if (isStreetEntered(data.data.suggestions[0]) && registrationHouse.current) {
                                            registrationHouse.current.focus();
                                        }
                                    } else {
                                        await setDaDataAddress(null);
                                        setError(true);
                                    }
                                } else {

                                    setError(true);
                                }
                                //console.log('onBlur');
                            }}
                            onChange={async (value) => {

                                let data = await addressAPI.getSuggestions(value);

                                if (data && data.data && data.data.suggestions) {

                                    return data.data.suggestions;
                                }
                                return [];
                            }}
                            onSelect={ async (suggestion) => {
                               // console.log('suggestion', suggestion);
                                await setDaDataAddress( suggestion ? suggestion  : null);
                                if (registration.current)
                                    registration.current.focus();
                                if (isStreetEntered(suggestion.data.suggestions) && registrationHouse.current)
                                        registrationHouse.current.focus();



                                // setRegistrationEntered(suggestion?.data?.house, suggestion);
                                // if (state.same_address)
                                //     setAddressEntered(state.address_entered && suggestion?.data?.house, true, suggestion);
                            }}
                    />
                </div>
                <div className="driver_input_container_many_items">
                    <div className={'input_filtered_container tiny' + (isFullAddress() ? ' complete' : ( isError ? ' error' : ''))}>
                        <input type="text" placeholder="Дом" className="input_filtered_text"
                               id="passport_registration_house"
                               ref={registrationHouse}
                               value={state.house}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                           if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
                                           setHouse(e.target.value);
                                        }}

                        />
                    </div>
                    <div className={'input_filtered_container tiny' + (isFullAddress() && state.block ? ' complete' : '')}>
                        <input type="text" placeholder="Корп." className="input_filtered_text"

                            // disabled={!state.registration || !state.registration.data.house}
                               value={state.block}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
                                    setBlock(e.target.value);

                               }}
                        />
                    </div>
                    <div  className={'input_filtered_container tiny' + (isFullAddress() && state.flat  ? ' complete' : '')}>
                        <input type="text" placeholder="Кв." className="input_filtered_text"
                               value={state.flat}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
                                   setFlat(e.target.value);
                               }}
                        />
                    </div>
                </div>
                {navigator.geolocation &&
                    <div className="address-geo" onClick={() => {
                        navigator.geolocation.getCurrentPosition(async (pos: any) => {

                            let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
                                pos.coords.longitude);
                           // console.log(response);
                            if (response && response.data && response.data.suggestions.length > 0) {
                                setDaDataAddress(response.data.suggestions[0]);
                                setError( !isStreetEntered(response.data.suggestions[0]));
                                if (response.data.suggestions[0].house)
                                    setHouse(response.data.suggestions[0].house)
                            }
                        });
                    }}>
                    Моё местоположение
                </div>
                }
            </div>

    )
};

export  default InputAddressValue;
