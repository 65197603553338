import React, {FC, useState} from "react";
import './MakePhotoPlayWindow.css'
import {PhotoTaker} from "../../Elements/elements";
import {setCameraAccess} from "../../../redux/reducers/system/actions";
import {SystemState} from "../../../redux/reducers/system/@types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

interface IProps{
    onClose:()=>void
    onSave: (file:Blob)=>void
    title?:string
    file?: any
}
const MakePhotoPlayWindow : FC<IProps>  = ({onSave, onClose,title}) =>{
    const dispatch = useDispatch();

    let system : SystemState = useSelector((state:RootState)=> ({system: state.systemReducer})).system;
    let [localBlobFile, setLocalBlobFile] = useState<Blob|undefined>(undefined);
    let [isCamera, setCamera] = useState<boolean>(false);

    const handleChange = (photos: Blob[]) => {
        console.log('handleChange', photos)
        dispatch(setCameraAccess(true));
        if (photos.length) {
            setLocalBlobFile(photos[0]);
            onSave(photos[0])
            // props.onValueChanged(photos[0], props.fileIndex)
        } else
            // props.onValueChanged(null, props.fileIndex)

        setCamera(false);

    }

    return (<div className="make_photo_play_window">
        {/*<div className="make_photo_play_window__title">Подтверждение</div>*/}
        {/*<div className="make_photo_play_window__title2">Сделайте фото кузова с грузом</div>*/}
        <PhotoTaker
            id="passport_photo"
            title={<div style={{paddingTop:"20px"}}>Подтверждение</div>}
            editing={true}
            dontLoadFromHardDrive={true}
            ask={!system.was_camera_accessed}
            frames={[
                {
                    index: 1,
                    title: title ? title : "Сделайте фото кузова с грузом",
                    box: true,
                    facing: 'environment',
                    width: '99%',
                    height: '42%'
                }
            ]}
            pictures={ localBlobFile ? [localBlobFile] : []}
            onChange={(photos: Blob[]) => {  handleChange(photos); }}
            onError={() => dispatch(setCameraAccess(true))}
            camera={isCamera}
            onClose={() => {  onClose()}}
        />
    </div>)
}

export default MakePhotoPlayWindow;
