import {UserStatus} from "../../../@types";
import {
  EnumUserTinType,
  LOG_OUT,
  SET_AUTHENTICATED,
  SET_CURRENT_COORDINATE,
  SET_STATUS,
  SET_USER_INFO, SET_USER_TRACKER,
  UserActionTypes,
  UserInfo,
  UserState
} from "./@types";
import {setCookie} from "../system/cookies";
import moment from "moment";

let initialState: UserState = {
  authenticated: false,
  driver_id: "",
  full_name: "",
  status: UserStatus.Unauthenticated,
  data:  new UserInfo(),
  current_coordinates: null,
  has_tracker: '',
}

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      action.authenticated ? setCookie("driver_id", action.driver_id, 100) : setCookie("driver_id", "", 0);
      return {
        ...state,
        authenticated: action.authenticated,
        driver_id: action.driver_id,
        full_name: action.full_name,
      }
    case SET_STATUS:
      return {
        ...state,
        status: action.status
      }
    case SET_USER_INFO:
      let data = action.data;
      if (!data.drivers) data.drivers = [];
      if (!data.cars) data.cars = [];
      if (!data.hasOwnProperty('multiple_car_owner')) data.multiple_car_owner = data.cars.length > 1 || data.drivers.length > 1;
      if (!data.hasOwnProperty('type')) data.type = EnumUserTinType.SELF_EMPLOYED;
      if (!data.recommendations) data.recommendations = [];

      return {
        ...state,
        data: data,
        full_name: action.data && action.data.passport?  action.data.passport.full_name : '',
        driver_id: action.data && action.data.driver_id?  action.data.driver_id : state.driver_id,
      }

    case LOG_OUT:
      return {
        ...state,
        authenticated: false,
        driver_id: '',
        full_name: '',
        status: UserStatus.Unauthenticated
      }

    case SET_USER_TRACKER:
      return {
        ...state,
        has_tracker: action.has_tracker
    }

    case SET_CURRENT_COORDINATE:
      return {
        ...state,
        current_coordinates: action.current_coordinates
    }
    default:
      return state;
  }
}


export  const getStatusWorking = (status:UserStatus, dates: {date:string, status:string} []) => {
  if ( !dates || dates.length === 0 ) return status ? status :  UserStatus.Working;
  let st = UserStatus.Working;
  dates.forEach( x=> {

    if (moment().diff(moment(x.date), 'days') === 0 && moment().diff(moment(x.date), 'hours') > 0 && x.status === UserStatus.DayOff) st = UserStatus.DayOff;
  });
  return  st;
}

export default userReducer;
