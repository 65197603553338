import Button from "../../Elements/Button/Button";
import React from "react";
import {EnumStatusExecution, OrderFullInfo} from "../../../redux/reducers/orders/@types";
import "./SmallOnPlacePlayWindow.css"

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onTaskComplete:Function,
    onFullMode:Function,
    zIndex?:number,
}

const SmallOnPlacePlayWindow: React.FC<ILocalProps> = ({order, currentPoint,onTaskComplete,onFullMode,zIndex}) => {

    const item = order.routes[currentPoint];
    // console.log("SmallOnPlacePlayWindow", currentPoint, item);
    return (
        <>
            <div className="SmallOnPlacePlayWindow play_window small_window" style={zIndex ? {zIndex:zIndex} : {}}>
                <div className="play_window_row">
                    <div className="point_number">{currentPoint + 1}</div>
                    <div className="point_address">{order.routes[currentPoint].adress}</div>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="primary" size="large" className="Button100"
                            onClick={()=> {  onTaskComplete(EnumStatusExecution.LEFT_POINT, currentPoint) }}> {
                        item.action_loading ? 'Погрузка выполнена':
                        item.action_unloading ? 'Разгрузка выполнена':
                        item.action_documents ? 'Документы получены':
                        item.action_documents ? 'Экспедирование выполнено': 'Задача выполнена'
                    }
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="transparent" size="large" className="Button100"
                            onClick={()=> { if (onFullMode) onFullMode() }}>Открыть заказ
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <div>&nbsp;</div>
                </div>
            </div>
        </>
    );
};


export default SmallOnPlacePlayWindow;
