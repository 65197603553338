import React, {FC, useEffect, useState} from "react";
import './ChatMessages.css'
import {CChatMessage} from "../../../redux/reducers/chat/@types";
import {getBoolean} from "../../../api/validate";
import {apiDriverUrl} from "../../../deployment";
import {userAPI} from "../../../api";
import {UserState} from "../../../redux/reducers/user/@types";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import Loader from "react-loader-spinner";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";

const badLoadImage = '';

interface IProps {
    message:CChatMessage;
}

export const ChatMessageItem: FC<IProps> = ({ message }) => {

    const [isLoadingPhoto, setLoadingPhoto] = useState( false);
    const [isFullScreen, setFullScreen] = useState( false);
    let user : UserState = useSelector((state:RootState)=> ({user: state.userReducer})).user;

    const day = new Date().getDay(),
        date = message.date.split('T')[0].split('-'),
        time = message.date.split('T')[1].split(':');
    time.pop();


    useEffect(()=>{
        if (!message.file_id) return;

        (async function f() {
            setLoadingPhoto(true);
            let res = await userAPI.getChatPhoto(user.driver_id, message.file_id);
            setLoadingPhoto(false);
            if (res && res.data && res.data.status !== "success") message.img=badLoadImage;
                else message.img = `data:image/${res.data.extension};base64,` + res.data.data;
        })();

    }, []);

    const showFullScreenMessage = () => {

    }
    return (
        <div className="chat_message_container">
            <div className={"chat_message_container-block-"+message.in_out}>

                <div>
                    <div className="chat_message_container-text">
                        {message.file_id  && isLoadingPhoto &&
                            <div className="chat_message_loading_photo">
                                <Loader type="ThreeDots" color="#fff" width={'3rem'} height={'3rem'} />
                            </div>
                        }
                        {message.file_id && message.img &&
                            <div className="chat_message_photo">
                                <img src={message.img} alt="img" onClick={()=>setFullScreen(true)}/>
                            </div>
                        }
                        {message.message}
                    </div>
                    <div className={"chat_message_container-time"}>
                        {`${(date[2] === day.toString()) ? date.slice().reverse().join('-') : ''} ${time.join(':')}`}
                    </div>
                    <div className={"chat_message_container-is-read "+message.read}/>
                </div>
            </div>
            {isFullScreen &&
                <div className="chat_message_container_fullscreen">
                    <div className="window_close_btn" onClick={()=>setFullScreen(false)}><ReactSVG src={CloseSVG} /></div>
                    <div className="chat_message_photo">
                        <img src={message.img} alt="img" onClick={()=>setFullScreen(true)}/>
                    </div>
                </div>
                }
        </div>
    )
}
