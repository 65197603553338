import {
    CharacteristicTypeEnum,
    DropDownListItem,
    IBOCharacteristics,
    IBodyOptions,
    ISystemReceivedObject,
    SystemState
} from "./@types";
import {IFullCategory} from "../vehicle/@types";
import {
    IS_EMULATE_DRIVE,
    NAVIGATION_GET_COORDINATE_TIME_IN_MS,
    TIMEOUT_TRACKER_SLEEP_SEC,
    USE_DEBUG_ROUTE, USE_JUST_NAVIGATOR
} from "../../../deployment";
import {setStatus, setUserCoordinates} from "../user/actions";
import {setDriverCurrentRoute, setDriverCurrentRouteTime, setDriverPosition, setRoutes} from "../map/actions";
import {RouteInfo, setDriverCurrentRouteAction, TypeLonLat} from "../map/@types";
import {decodeHerePolyline} from "../../../components/Elements/HereMap/HereMap";
import {routeAPI, userAPI} from "../../../api";
import turf from "turf";
import {iPhoneVariant} from "../../../components/App/App";
import {getStatusWorking} from "../user/reducers";
import {
    removeSystemStatus,
    setDeviceID,
    setTrackerID,
    setUseTracker,
    showMessageError,
    showMessageInfo
} from "./actions";
import {SystemStatus} from "../../../@types";
import {passList} from "../../../components/Registration/VehicleRegistration/VehicleRegistration";
import {temporaryReducer} from "../index";
import {IBOSmallCharacteristics} from "../user/@types";
// import {currentCoordinate} from "../../../components/App/App";


export const  getBodyOptionsList  = (categories_full : IFullCategory[]) : IBodyOptions[] => {
    let body_options : IBodyOptions[] = [];
    categories_full.forEach( category=> {
        body_options = body_options.concat([...category.body_options.filter( x => !body_options.find(z=> z.id === x.id) )])
    });

    body_options.forEach(x=> {
        x.name = x.name !== "ЦМФ" ? x.name.toUpperCase()[0] + x.name.toLowerCase().substring(1,x.name.length) :x.name;
    });
    return  body_options;
};

export const  getBOCharacteristics  = (categories_full : IFullCategory[], body_option_id: string) : IBOCharacteristics[] => {
    let body_options : IBodyOptions[] = getBodyOptionsList(categories_full).filter( x=> x.id == body_option_id);
    let body_option_characteristics: IBOCharacteristics[] = [];
    body_options.forEach( body=> {
        body_option_characteristics = body_option_characteristics.concat(
            [...body.body_option_characteristics.filter( x => !body_option_characteristics.find(z=> z.id === x.id) )])
    });
    return body_option_characteristics;
};

export const  getCharacteristicValueList = (categories : IFullCategory[], characteristic:IBOCharacteristics | {id:string}, bodyID:string, categoryID:string, is_debug?:boolean) : DropDownListItem[] => {
    // console.log('categories', categories, characteristic);
    if (is_debug) {
        // console.log('getCharacteristicValueList ', categories, characteristic, bodyID, categoryID);
    }
    let dd : DropDownListItem[] = [{id:'', text :'Любой'}];
    categories.filter(x=> x.id === categoryID || categoryID === '')
        .forEach(category => {
            category.body_options.filter( bo=> bo.id === bodyID || bodyID ==='')
                .forEach( bo=> {
                    bo.body_option_characteristics.filter(ch => ch.id === characteristic.id)
                        .forEach(ch=> {
                            // console.log('ch', ch);
                            ch.body_option_characteristics_values.forEach(v=> {
                                if (!dd.some(lv=>lv.id === v.id)) dd.push({id:v.id, text:v.name});
                            })
                        })
                })
        });

    if (is_debug) {
        console.log('getCharacteristicValueList ', dd);
    }
    return dd;
};



export const filterCategory = (categories: IFullCategory[], category_id:string, body_id:string) => {

    let m_categories : IFullCategory[] = JSON.parse(JSON.stringify(categories));
    m_categories = m_categories.filter(category => !category_id || category.id === category_id)
        .filter(category => {

            category.body_options = category.body_options.filter(bo=> !body_id || bo.id === body_id);
                /*.filter(car_body => {
                    if (activeCharacteristics.length === 0) return true;
                    let tmp = car_body.body_option_characteristics.filter(character => {
                        let index = activeCharacteristics.filter(active_ch => active_ch.id === character.id)
                            .findIndex(active_ch => {
                                    if (active_ch.type !== CharacteristicTypeEnum.Ref) return true;
                                    let selectedValue = character.body_option_characteristics_values.find(val => val.id === active_ch.value);
                                    return !!selectedValue;

                                }
                            );
                        return index >= 0;
                    });
                    return tmp.length >= activeCharacteristics.length;
                }); */
            return category.body_options.length
        });

    return m_categories;
};

export const  getCategoriesList = (state: SystemState, body_option_id: string) => {
    let dd : DropDownListItem[] = [];
    let cat = filterCategory(state.categories, '', body_option_id);
    cat && cat.forEach(x=> {
        if (!dd.some(z=> z.text == 'до '+ x.weight_to + ' кг'))
            dd.push({id:x.id, text : 'до '+ x.weight_to + ' кг'})
    });
    dd.push({id:'', text :'не выбрана'});
    return dd;
}


export const  getDriverCategoriesList = (state: SystemState ) => {
    let dd : DropDownListItem[] = state.driver_car_types.map( (x: any)=> ({id: x.id, text : 'до ' + x.weight_to + ' кг.'}));
    dd.push({id:'', text :'не выбран'});
    return dd;
}
export const  getBodyOptionsListIDs = (state: SystemState, category_id: string, not_user_close_type = false) => {
    let dd : DropDownListItem[] = [];
    let categoryList : IFullCategory[] = filterCategory(state.categories, category_id, "");
    getBodyOptionsList(categoryList).forEach(x=> {
        if (not_user_close_type && x.name.toUpperCase() == 'ЗАКРЫТЫЙ ТИП КУЗОВА') return;
        dd.push({id:x.id, text : x.name.toUpperCase()})
    });
    dd.push({id:'', text :'не выбран'});
    return dd;
}


var dl = 0;
var stack : number[] = [];
var counter = 0;
export function wait_before_update(n:number){

    let my_counter = counter++;
    dl = n;
    stack.push(my_counter);
    return new Promise( resolve => {
        let m = setInterval(()=>{
            // console.log('getTariffInfo  stack is', stack.length, 'my_counter', my_counter, stack);
            if (stack.length > 1 && stack[0] == my_counter) {
                stack.shift();
                clearInterval(m);
                resolve(false);
                return;
            }

            dl-=100;
            if (dl <=0 ) {
                dl = 0;
                clearInterval(m);
                stack = [];
                resolve (true)
            }
        }, 100);
    })
}

export const sleep = (milliseconds:number) => {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

export const  getNextDistance = (pointA:number[],pointB:number[], Vi_1:number) : {sit: number, vi1 : number, si :number }=>{
    let Si =   turf.distance(turf.point( pointA  ), turf.point( pointB  ),"metres")
    //длина отрезка между последним и предпоследним смещением на соответствующий временной интервалб где v_i — оценка скорости по отсчётам
    const Vi = Si/ (NAVIGATION_GET_COORDINATE_TIME_IN_MS/1000);
    if (Vi_1 === 0)
        return {sit:Si, vi1 : Vi, si: Si }

    //определяем ускорение/замедление
    const Ai = Vi_1 != undefined ? Vi - Vi_1 / (NAVIGATION_GET_COORDINATE_TIME_IN_MS/1000) : 0;
    Vi_1 = Vi;
    // квадратичная модель равнопеременного движения, дистанция на которой будет машина через сек
    const Sit=   Vi*(NAVIGATION_GET_COORDINATE_TIME_IN_MS/1000) + (Vi_1 !=0 ? 0.5*Ai : 0);
    return {sit:Sit, vi1 : Vi_1, si :Si }
}

/**
 * Функция расширения точек на маршруте до дистанции 1 метр
 * @param route - набо координат маршрута
 * @return route - расширенный набор координат маршрута
 */
const extendsCoordinates = (route: number[][]) =>{

    let detailRoute : number[][] = [];
    var line = turf.lineString(route);
    var length = turf.lineDistance(line,   'meters' );
    let inc= Math.ceil(length/15000);

    for (let i=0; i < length;i+=inc){
        let along = turf.along(line, i, 'meters');

        detailRoute.push( along.geometry.coordinates );
    }
    //    console.log('extendsCoordinates', detailRoute );

    // return detailRoute.map(x=> ( {lat: x[0], lon: x[1]}));
    return detailRoute;

}

export const SYSTEM_SERVICE_ACTION_PING = 1;
export const SYSTEM_SERVICE_ACTION_ACTIVE = 2;
export const SYSTEM_SERVICE_ACTION_ORDER_EXECUTE = 3;
export const SYSTEM_SERVICE_ACTION_INSTALL = 4;
export  const driver_service_id = '00000000-0000-0000-0000-0000000000da';
const responseDataError = (data:any, result : boolean) => !data || !data.data || data.status !== 200 || data.data.status=="error" || result;

export const installDevice  = async (driver_form_id: string, dispatch: any) => {

    let mIp = await getOwnIP();
    console.log('mIp', mIp);
    if (!mIp) return false;
    let dev = await userAPI.getDeviceStatus('', mIp);
    console.log('dev', dev);
    if (responseDataError(dev, !Array.isArray(dev?.data.devices) || !dev?.data.devices.length) ) return  false;

    let devices : {"device_id": string, "date": string, "ip": string}[] = dev?.data.devices;
    devices = devices.filter(x=>{
        let val =  Math.abs(((new Date(x.date)).getTime() - (new Date()).getTime())/1000);
        console.log(x.device_id, val);
        return val < 180
    }) ;
    console.log("devices", devices);
    if (devices.length == 0) return false;
    if (devices.length > 1) {
        await showMessageError('Найдено более одного устройства, попробуйте повторить попытку позднее или выключить и включить мобильный интернет.', dispatch);
        return false;
    }

    registrationUserDevice(driver_form_id,dispatch,devices[0].device_id);
    console.log("setUseTracker", devices);
    dispatch(setUseTracker(true));
    dispatch(setTrackerID(devices[0].device_id));
    return true;
}

export const getOwnIP  = async () => {
    let res = await userAPI.getOwnIP();
    // console.log('ip f', res.data);
    if (!res || !res.data || !res.data.ip) return  new Promise( (resolve, reject) => {
            console.log('Невозможно определить ip адрес');
            reject('Невозможно определить ip адрес')}
        );
    return res.data.ip
};

export const pingDevice  = async (driver_id: string, tracker_id: string, dispatch: any) => {

    const returnValue = (tracker_id:string) => {
        dispatch(setUseTracker(tracker_id!=''));
        dispatch(setTrackerID(tracker_id));
        return tracker_id!='';
    };

    const isDeviceActive = async (device_id:string, ip:string = '') => {
        let dev = await userAPI.getDeviceStatus(device_id);
        if (responseDataError(dev, !Array.isArray(dev?.data.devices) || !dev?.data.devices.length) ) return false;
        let device = dev?.data.devices[0];
        let val = ( Math.abs(((new Date(device.date)).getTime() - (new Date()).getTime())/1000) <= TIMEOUT_TRACKER_SLEEP_SEC ) && (!ip || device.ip == ip);
        return val;
    };

    if (tracker_id && (await isDeviceActive(tracker_id))) return true;

    let mIp = await getOwnIP();
    if (!mIp) return false;
    let res = await userAPI.getDeviceList(driver_id).catch((err) => console.log(err));
    if (responseDataError(res, !Array.isArray(res?.data.device_id) ))   return returnValue('');
    let lst : {device_id:string, 'user_agent':string} [] = res?.data.device_id;

    for (let i =0; i< lst.length; i++) {
        if (await isDeviceActive(lst[i].device_id, mIp)) {
            dispatch(setDeviceID(lst[i].device_id));
            return returnValue(lst[i].device_id);
        }
    }

    return returnValue('');
}

export  const clearSystemServiceMessages = async  (driver_id: string , dispatch: any, action_id = SYSTEM_SERVICE_ACTION_ORDER_EXECUTE, ip= undefined)=>{
    let res = await userAPI.getSystemServiceMessage(driver_id);
    if (!res || !res.data || !Array.isArray(res.data)) return true;
    res.data.filter((x:ISystemReceivedObject)=> x.message.action_id == action_id && (!ip || x.message.ip === ip )).forEach( async (x:ISystemReceivedObject)=> {
        await userAPI.deleteSystemServiceMessage(driver_id, x.id).catch(e=> showMessageError('Ошибка работы сервисного обмена', dispatch));
    })
    return true
}


export  const setSystemServiceInfo = async  (data: {order_id: string, driver_id: string, device_id: string}, dispatch: any)=>{
    let body = {ip:'', action:'execute', action_id: SYSTEM_SERVICE_ACTION_ORDER_EXECUTE, user_agent: navigator.userAgent, device_id: data.device_id};
    let res = await userAPI.setSystemServiceMessage(data.driver_id, body).catch(e=> showMessageError('Ошибка работы сервисного обмена', dispatch));
    console.log('setSystemServiceInfo', res);
}

export const registrationUserDevice  = (driver_id: string, dispatch: any, device_id_in: string | null = '') => {
    let device_id = device_id_in ? device_id_in : (new URL(window.location.href)).searchParams.get('device_id');
    if (!device_id) return;

    userAPI.getDeviceList(driver_id).then(res => {
        console.log('getDeviceList', res.data, Array.isArray(res.data.device_id));
        if (!res.data || res.status !== 200 || res.data.status=="error" || !Array.isArray(res.data.device_id)) return;
        let lst : {device_id:string} [] = res.data.device_id;
        if (lst.some(x=> x.device_id === device_id)) return;

        userAPI.setDeviceID(driver_id, device_id ? device_id : '').then(res => {
            if (res.data && res.status === 200 && res.data.status!="error") {
                dispatch(setDeviceID(device_id ? device_id : ''));
                showMessageInfo("Ваше устройство успешно активировано. Спасибо !", dispatch);
            }
        }).catch((err) => console.log(err));

    }).catch((err) => console.log(err));
}

export const getDriverRouteToNextPoint = (dispatch:any, points:TypeLonLat[], extendedRoute: Function | null = null) => {
    console.log('getDriverRouteToNextPoint points', points)
    return routeAPI.getRoute(points.filter(x=> x!=null))
        .then((res: any) => {
            let coordinates: number[][] = [];

            if (res && res.data && res.data.routes)
                res.data.routes.forEach((route: any) => {

                    route.sections.forEach((section: any) => {
                        // console.log('section.polyline = ', section.polyline);

                        let arr: any = decodeHerePolyline(section.polyline);
                        // console.log('section.polyline as arr ', arr);
                        coordinates = coordinates.concat(arr)
                    })
                })
            dispatch(setDriverCurrentRoute( coordinates ));
            if (extendedRoute) extendedRoute(extendsCoordinates(coordinates));
            if (res.data.routes.length && res.data.routes[0].sections && res.data.routes[0].sections.length) {
                let timeLeft = res.data.routes[0].sections[0].departure.time;
                let timeArrival = res.data.routes[0].sections[0].arrival.time;
                setCurrentDriverRouteTimeThunk(dispatch, timeLeft, timeArrival);
            }
            return extendsCoordinates(coordinates)
        })
};

export const setCurrentDriverRouteTimeThunk = async (dispatch:any, timeLeft : string,  timeArrival : string) => {
    let min = ((new Date(timeLeft)).getTime() - (new Date(timeArrival)).getTime()) / 60000;
//    console.log('min', min, timeLeft, timeArrival)
    dispatch(setDriverCurrentRouteTime(min));
};

export const isIOS = () : boolean => {
    var iosQuirkPresent = function () {
        var audio = new Audio();

        audio.volume = 0.5;
        return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
    };

    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    var isAppleDevice = navigator.userAgent.includes('Macintosh');
    var isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

    return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
}

var _HighAccuracy : any = undefined;
var _lastDateTime : any = undefined;
var _lastCoordinate : any = undefined;

const getIPhoneVariantParams = () => {
    // console.log('iPhoneVariant =', iPhoneVariant);
    switch (iPhoneVariant) {
        case 2: return {	enableHighAccuracy:	true,	timeout:	0,	maximumAge: Infinity};
        case 3: return {	enableHighAccuracy:	true,	timeout:	100,	maximumAge: 100};
        case 4: return {	enableHighAccuracy:	true,	timeout:	1000,	maximumAge: 100};
        case 5: return {	enableHighAccuracy:	true,	timeout:	1000,	maximumAge: 1000};
        case 6: return {	enableHighAccuracy:	false,	timeout:	1000,	maximumAge: 1000};
        default:  return undefined;
    }
};


export const setCurrentDriverPosition = async (dispatch:any, driver_id:string, is_start: boolean = false, route: RouteInfo | undefined = undefined, is_use_tracker = false ) => {
        if (IS_EMULATE_DRIVE && is_start) {
            if (!route || !route.points.length ) return undefined;
            let p = route.points[0];
            let point_start = {lat: +p.lat - 0.01, lon:  +p.lng - 0.01};
            dispatch(setDriverPosition(point_start));
            return point_start
        }
        if (is_use_tracker || USE_DEBUG_ROUTE || USE_JUST_NAVIGATOR) {
            let res = await userAPI.getCarPosition(driver_id);
            // console.log('is_use_tracker res=', res.data[res.data.length-1]);
            if (res && res.data && Array.isArray(res.data) && res.data.length > 0) {
                // let val = {lat: res.data[res.data.length-1].lat, lon: res.data[res.data.length-1].lon};
                dispatch(setDriverPosition(res.data[res.data.length-1]));
                return res.data[res.data.length-1];
            }

        }
        return undefined;

        // if (isIOS()) {
        //     return new Promise<TypeLonLat|undefined>( ((resolve, reject) => {
        //         if (!navigator.geolocation) return reject(undefined);
        //
        //         navigator.geolocation.getCurrentPosition(async (pos: any) => {
        //             dispatch(setDriverPosition({lat: pos.coords.latitude, lon: pos.coords.longitude}));
        //             userAPI.setDriverCoordinate(driver_id,{lat: pos.coords.latitude, lon: pos.coords.longitude});
        //             return resolve({lat: pos.coords.latitude, lon: pos.coords.longitude})
        //         },  (error)=>{ console.log('error', error)},  {	enableHighAccuracy:	true,	timeout:	100,	maximumAge: 100});
        //     }));
        // }
        // return undefined;
        // dispatch(setDriverPosition(currentCoordinate));
        // userAPI.setDriverCoordinate(driver_id,currentCoordinate ? currentCoordinate : {lat:0,lon:0});
        //
        // return  currentCoordinate;

        // if (navigator.geolocation && !_HighAccuracy && driver_id) {
        //     _lastDateTime = (new Date()).getTime();
        //     _HighAccuracy = navigator.geolocation.watchPosition(async (pos: any) => {
        //         _lastDateTime = (new Date()).getTime();
        //         _lastCoordinate = {lat: pos.coords.latitude, lon: pos.coords.longitude};
        //         dispatch(setDriverPosition({lat: pos.coords.latitude, lon: pos.coords.longitude}));
        //         userAPI.setDriverCoordinate(driver_id,{lat: pos.coords.latitude, lon: pos.coords.longitude});
        //         // console.log('watchPosition', Math.ceil((new Date()).getTime() - _lastDateTime)/100);
        //
        //         //{	enableHighAccuracy:	true,	timeout:	0,	maximumAge: Infinity	} работало норм
        //     },  ()=>{}, 	{	enableHighAccuracy:	true,	timeout:	0,	maximumAge: Infinity	});
        // }
        //
        // if ((new Date()).getTime() - _lastDateTime < NAVIGATION_GET_COORDINATE_TIME_IN_MS && _lastCoordinate) {
        //     return  new Promise<TypeLonLat|undefined>( (resolve) => resolve(_lastCoordinate));
        // }
        //
        // return new Promise<TypeLonLat|undefined>( ((resolve, reject) => {
        //     if (!navigator.geolocation) return reject(undefined);
        //
        //         navigator.geolocation.getCurrentPosition(async (pos: any) => {
        //             dispatch(setDriverPosition({lat: pos.coords.latitude, lon: pos.coords.longitude}));
        //             userAPI.setDriverCoordinate(driver_id,{lat: pos.coords.latitude, lon: pos.coords.longitude});
        //             // console.log('getCurrentPosition')
        //             return resolve({lat: pos.coords.latitude, lon: pos.coords.longitude})
        //         },  ()=>{ console.log('error')}, {	enableHighAccuracy:	false,	timeout:	0,	maximumAge: Infinity	}); //
        // }));
};

export const isValueTrue = (value:any) =>  value == true || value == "true" || value == 1;

export function getPassName(id:string) {
    if (id=="" || !passList.some(z=> z.id == id)) return "";
    let v = passList.find(x=> x.id == id);
    return v ? v.name : '';
}
export function getPassFromCharacteristics(characteristics : IBOCharacteristics[]|IBOSmallCharacteristics[]) {

    let id_pass = "";
    let id_index = 100;
    characteristics.forEach(x=>{
        if (!passList.some(z=> z.id == x.id && isValueTrue(x.value)  )) return;
        let index = passList.findIndex(z=> z.id == x.id);
        if (index < id_index) id_pass = passList[index].id;
    })
    // console.log('getPassFromCharacteristics id_pass=%s, id_index=%s', id_pass, id_index);
    return id_pass;
}

export const toTwoDigits = (val:string | number) : string => {
    if (( Math.abs(+val) + '').length == 1) {
        if (val < 0) return  '-0' + Math.abs(+val);
        return '0' + Math.abs(+val);
    }
    if (isNaN(Math.round(+val))) return '00';

    return (Math.round(+val)+'');
}


export const numWord = (value: number, words: string[]) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num === 1) return words[0];
    return words[2];
};
