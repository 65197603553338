import {
    ADD_POINT_ON_MAP,
    AddPointOnMap,
    MapPoint,
    REMOVE_POINT_FROM_MAP,
    RemovePointFromMap,
    RouteArchive,
    RouteInfo,
    SET_DRIVER_CURRENT_ROUTE,
    SET_DRIVER_CURRENT_ROUTE_TIME,
    SET_DRIVER_POSITION,
    SET_INDEX_NEXT_POINT,
    SET_LAST_TIME_MAP_HAND_MOVE,
    SET_LOW_SIGNAL,
    SET_MAP_CENTER,
    SET_NAVIGATOR_MODE,
    SET_POINTS_ON_MAP,
    SET_ROUTE,
    SET_ROUTE_ARCHIVE,
    SET_ROUTE_TO_POINT,
    SET_SHOW_CURRENT_ROUTE_ON_MAP,
    setArchiveRouteAction,
    setDriverCurrentRouteAction,
    setDriverCurrentRouteTimeAction,
    setDriverPositionAction,
    setIndexNextPointAction,
    setLastTimeMapHandMoveAction,
    setLowSignalAction,
    setMapCenterAction,
    setNavigatorModeAction,
    setPointOnMap,
    setRouteOnMap,
    setRouteToPointAction,
    setShowCurrentRouteOnMapAction,
    TypeLonLat,
    TypeLonLatExtended,
    UPDATE_POINT_ON_MAP,
    UpdatePointOnMap
} from "./@types";
import {Moment} from "moment";


export const appendPoint = (point: MapPoint, index: number):
    AddPointOnMap => ({
    type: ADD_POINT_ON_MAP,
    point,
    index
});


export const setPoints =  (points:MapPoint[]):
    setPointOnMap =>  ({
    type: SET_POINTS_ON_MAP,
    points
});

export const setArchiveRoute =  (route: RouteArchive[]): setArchiveRouteAction =>  ({
    type: SET_ROUTE_ARCHIVE,
    route
});

export const setRoutes =  (route: RouteInfo | null): setRouteOnMap =>  ({
    type: SET_ROUTE,
    route
});

export const setMapCenter =  (point :{lat:number,lng:number, zoom?:number} ): setMapCenterAction =>  ({
    type: SET_MAP_CENTER,
    point,
});

export const setRouteToPoint =  (point1:TypeLonLat, point2:TypeLonLat ): setRouteToPointAction =>  ({
    type: SET_ROUTE_TO_POINT,
    point1,
    point2
});
export const setDriverPosition =  (driver_point:TypeLonLatExtended | undefined ): setDriverPositionAction =>  ({
    type: SET_DRIVER_POSITION,
    driver_point
});

export const setIndexNextPoint =  (index_next_point:number): setIndexNextPointAction =>  ({
    type: SET_INDEX_NEXT_POINT,
    index_next_point
});

export const setNavigatorModeOn =  (navigator_mode_is_on:boolean): setNavigatorModeAction =>  ({
    type: SET_NAVIGATOR_MODE,
    navigator_mode_is_on
});

export const updatePoint =  (point:MapPoint, index:number):
    UpdatePointOnMap =>  ({
    type: UPDATE_POINT_ON_MAP,
    point,
    index
});

export const removePoint =  ( index:number):
    RemovePointFromMap =>  ({
    type: REMOVE_POINT_FROM_MAP,
    index
});

export const setDriverCurrentRoute =  (driver_current_route:any[] ): setDriverCurrentRouteAction =>  ({
    type: SET_DRIVER_CURRENT_ROUTE,
    driver_current_route,
});
export const setDriverCurrentRouteTime =  (driver_current_route_time:number): setDriverCurrentRouteTimeAction =>  ({
    type: SET_DRIVER_CURRENT_ROUTE_TIME,
    driver_current_route_time,
});

export const setShowCurrentRouteOnMap =  (show_current_route: boolean ): setShowCurrentRouteOnMapAction =>  ({
    type: SET_SHOW_CURRENT_ROUTE_ON_MAP,
    show_current_route,
    // start_time:string,
    // finish_time:string
});


export const setLastTimeMapHandMove =  (last_time_map_move: Moment): setLastTimeMapHandMoveAction =>  ({
    type: SET_LAST_TIME_MAP_HAND_MOVE,
    last_time_map_move
});

export const setLowSignal =  (is_low_signal: boolean): setLowSignalAction =>  ({
    type: SET_LOW_SIGNAL,
    is_low_signal
});
