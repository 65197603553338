import React, {FC} from "react";
import './ImageViewWindow.css'
import Button from "../Elements/Button/Button";
interface IProps {
    file:Blob
    onDelete?:()=>void
    onClose:()=>void
    title:string
}
const ImageViewWindow:FC<IProps> = ({file, onDelete, onClose, title}) => {
    return (<div className="image_view_window">
                <div className="image_view_window__title">{title}</div>
                <div className="image_view_window__photo">
                    <img  src={URL.createObjectURL(file)} />
                </div>
                <div className="image_view_window__buttons">
                    <Button type={"primary"} size={"large"} onClick={onClose} >Закрыть</Button>

                    {onDelete && <div className='image_view_window__delete' onClick={onDelete}>Удалить данное изображение</div>}
                </div>
            </div>)
}

export default ImageViewWindow;
